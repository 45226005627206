import React from 'react'
import './create-question.styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import instance from '../../utils/axios';
import { CKEditor } from 'ckeditor4-react';

export default function QuestionDetails({ step, state,saveQuestDetails }) {
    const editorConfiguration = {
        toolbar: ['heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo',
            'sourceEditing',
            // 'wproofreader'
        ]
    };
    const tags = [
        { value: 'Arrays', label: 'Arrays' },
        { value: 'Strings', label: 'Strings' },
        { value: 'Bit Manipulation', label: 'Bit Manipulation' },
        { value: 'Searching and Sorting', label: 'Searching and Sorting' }
    ];

    const levels = [
        { value: 'Easy', label: 'Easy' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Difficult', label: 'Difficult' }
    ]

    const language = [
        { value: "Javascript", label: "Javascript" },
        { value: "Python", label: "Python" },
        { value: "Java", label: "Java" },
        { value: "CPP", label: "CPP" }
    ];

    const datatype = [{ value: 'int', label: 'int', message: 'An integer value representing' },
    { value: 'int[]', label: 'int[]', message: 'An integer array representing' },
    { value: 'String', label: 'String', message: 'A string value representing' },
    { value: 'String[]', label: 'String[]', message: 'A string array representing' },
    { value: 'boolean', label: 'boolean' }
    ]

    const [langArray,setLangArray]=React.useState([]);
    const [allTags,setAllTags]=React.useState([]);
    const[inputTags,setInputTags]=React.useState([]);
    const [questionDetails, setQuestionDetails] = React.useState({
        tagIds: [],
        difficultyLevel: {label:state.difficultyLevel,value:state.difficultyLevel},
        questionTitle: "",
        questionText: "",
        codeLanguages: [],
        functionName: "",
        topicName: state.topicName,
        inputType: [],
        outputType: [],
        inputSpecification: [],
        outputSpecification: [],
        questionId: 1,
        taskId: 1
    });

    const getPreviousData=()=>
    {
        instance.get(`${process.env.REACT_APP_BASE_URL}/cms/v1/get-code-language`)
                .then(function (response) {
                    let langList = [];
                    response.data.forEach((item, index) => {
                        langList.push({label:item.codeName,value:item.codeName})
                    });
                    setLangArray(langList);
                })
                .catch(function (error) {
                    console.log(error);
                });

                instance.get('/cms/v1/getAllTags')
                .then(function (response) {
                    if (response.data) {
                        //const filteredArr = response.data.filter(el => !el.active);
                        const tagList=[]
                         response.data.forEach((item,index)=>
                         {
                              tagList.push({label:item.tagName,value:item.tagName,id:item.tagId})
                         });
                        setAllTags(tagList);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        
    }

    React.useEffect(() => {
        getPreviousData();
     }, []);

    var handleChangeDatatype = (selectedOption) => {

        const getVal = selectedOption.map(el => el.value);
        setDatatypes(getVal);
        const inList = []
        selectedOption.forEach((item, index) => {
            inList.push(item.value)
        });
        setQuestionDetails({ ...questionDetails, ['inputType']: selectedOption });
    };

    var handleChangeOutDatatype = (selectedOption) => {

        const getVal = selectedOption.map(el => el.value);
        setOutDatatypes(getVal);
        const outList = []
        selectedOption.forEach((item, index) => {
            outList.push(item.value)
        });
        setQuestionDetails({ ...questionDetails, ['outputType']: selectedOption })
    };

    const [datatypes, setDatatypes] = React.useState([]);
    const [outDatatypes, setOutDatatypes] = React.useState([]);

    var handleMultiSelect = (selectedOption, fieldName) => {
        setQuestionDetails({ ...questionDetails, [fieldName]: selectedOption })
        const langList = [];
        selectedOption.forEach((item, index) => {
            langList.push(item.value)
        });
    };

    React.useEffect(() => {
        saveQuestDetails(questionDetails);
    }, [questionDetails]);


    const handleSingleSelect = (val, fieldName) => {
        setQuestionDetails({
            ...questionDetails,
            [fieldName]: val
        })
    }

    const handleQuestionTag=(val,fieldName)=>
{
    var tagIds=[];
    for(var i=0;i<val.length;i++){
    allTags.forEach((item,index)=>
    {
        if(item.tagName==val[i])
          tagIds.push(item.id);
    })

    setQuestionDetails({...questionDetails,[fieldName]:tagIds})
}
 

}


    const handleInputChange = (e, fieldName) => {
        setQuestionDetails({
            ...questionDetails,
            [fieldName]: e.target.value
        })
    }

    const inputHandler = (e, editor) => {
        setQuestionDetails({ ...questionDetails, ['questionText']: editor.getData() })
    }

    const handleInputSpecs = (e, index, msg) => {
        const inpSpecArr = questionDetails.inputSpecification;

        inpSpecArr[index] = {
            firstInp: msg,
            secondInp: e.target.value
        }

        setQuestionDetails({
            ...questionDetails,
            ['inputSpecification']: inpSpecArr
        })
    }

    const handleOutputSpecs = (e, index, msg) => {
        const outSpecArr = questionDetails.outputSpecification;

        outSpecArr[index] = {
            firstInp: msg,
            secondInp: e.target.value
        }

        setQuestionDetails({
            ...questionDetails,
            ['inputSpecification']: outSpecArr
        })
    }

    const handleSpecs=(e,index)=>
    {
        const inSpec=questionDetails.inputSpecification
        inSpec[index]=e.target.value
        setQuestionDetails({...questionDetails,
            ['inputSpecification']: inSpec})
    }
    
    const handleOutSpecs=(e,index)=>
    {
        const outSpec=questionDetails.outputSpecification
        outSpec[index]=e.target.value
        setQuestionDetails({...questionDetails,
            ['outputSpecification']: outSpec})
    }

    return (
        <>
            <p className="headText pt-4"> Question Details </p>
            <form className='ps-4 pb-4'>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <label htmlFor="lastName" className='pb-2 labelText'>Question Topic</label>
                            <div className="cm-icon-field position-relative">
                                <input className="form-control text-blue-800 fw-medium" value={questionDetails.topicName} onChange={(e) => handleInputChange(e, 'topicName')} disabled/>
                                <FontAwesomeIcon className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <label htmlFor="lastName" className='pb-2 labelText'>Difficulty Level</label>
                            <Select name="" className="" options={levels}
                                value={questionDetails.difficultyLevel}
                                onChange={(selectedVal) => handleSingleSelect(selectedVal, 'difficultyLevel')}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <label className='pb-2 labelText'>Question Tag</label>
                            <Select isMulti options={tags}/>
                            {/* <Select name="" className="" options={tags}
                                value={questionDetails.questionTag}
                                // onChange={(selectedVal) => handleSingleSelect(selectedVal, 'questionTag')}
                            /> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mb-3">
                            <label className='pb-2 labelText'>Function Name</label>
                            <div className="cm-icon-field position-relative">
                                <input className="form-control text-blue-800 fw-medium" onChange={(e) => handleInputChange(e, 'functionName')} />
                                <FontAwesomeIcon className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="email" className='pb-2 labelText'>Question Title</label>
                    <input type="email" className="form-control text-blue-800 fw-medium cm-box-lg" onChange={(e) => handleInputChange(e, 'questionTitle')} />
                </div>
                <div className="form-group mb-3">
                    <label className='pb-2 labelText'>Question Text</label>
                    {/* <CKEditor editor={Editor} config={editorConfiguration} onChange={inputHandler} /> */}
                </div>
                <div className="form-group mb-3">
                    <label className='pb-2 labelText'>Coding Languages</label>
                    <Select isMulti onChange={(e) => handleMultiSelect(e, 'codeLanguages')} options={langArray} />
                </div>
                <div className="form-group mb-3">
                    <label className='pb-2 labelText'>Add Inputs</label>
                    <Select isMulti  hideSelectedOptions={false} onChange={handleChangeDatatype} options={datatype} />
                </div>
                {datatypes.map((c, index) => {
                    return (<div key={index}>
                        <label className='pb-2 labelText'>Input{index + 1} Specification</label>
                        <input type="text" className="form-control text-blue-800 fw-small " defaultValue={questionDetails.inputType[index].message} onChange={(e)=>handleSpecs(e,index)} />
                                {/* </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="form-group mb-3">
                                    <label className='pb-2 labelText'></label>
                                    <input type="text" value={questionDetails.inputSpecification[index]} className="form-control text-blue-800 fw-medium mt-1" onChange={(e) => handleInputSpecs(e, index, questionDetails.inputType[index].message)} />
                                </div>
                            </div>
                        </div> */}
                    </div>);
                })}
                <div className="form-group mb-3">
                    <label className='pb-2 labelText'>Add Outputs</label>
                    <Select isMulti  hideSelectedOptions={false} onChange={handleChangeOutDatatype} options={datatype} />
                </div>
                {outDatatypes.map((c, index) => {
                    return (<div key={index}>
                                    <label className='pb-2 labelText'>Output{index + 1} Specification</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium mt-1"defaultValue={questionDetails.outputType[index].message}   onChange={(e) => handleOutSpecs(e, index)} />                                  
                    </div>);
                })}
            </form>
        </>
    )
}
