import * as React from 'react';
import { Modal } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import "./CreateBankModal.css";
import { useNavigate } from 'react-router-dom';
const LanguageTranslateModal = ({ modalData, handleTranslateToggle }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const translateBankOptions = React.useMemo(() => [{ value: 'SELF_TRANSLATE', label: "Self Translate" }, { value: 'AI_TRANSLATE', label: "Translate by AI" }])
    const [optionChoiced, setOptionChoiced] = React.useState(translateBankOptions[0].value);
    const handleRadioChange = (e) => {
        setOptionChoiced(e.target.value);
    }

    const handleTranslate = () => {
        navigate(`/translation-mode/${modalData.bankId}`, { state: {translationMode: optionChoiced} });
    }

    return (
        <div>
            <Modal open={modalData.status} onClose={handleTranslateToggle} disableAutoFocus={true}>
                <div className='cm-create-bank-form-container cm-add-bank-form-container bg-white my-3'>
                    <div className='cm-create-modal-form-header py-2 px-4 d-flex align-items-center justify-content-between'>
                        <h5 className='text-blue-800 my-0'>{t("translate_bank")}</h5>
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleTranslateToggle} />
                    </div>
                    <div className="cm-create-task-form px-4 pb-4">
                        <div className="mt-4 d-flex align-items-center justify-content-start">
                            {
                                translateBankOptions.map((el, ind) => <div key={el.value} className={`d-flex ${ind > 0 ? 'ms-4' : ''}`}>
                                    <input disabled={el.value === 'AI_TRANSLATE'} type="radio" id={el.value} value={el.value} checked={optionChoiced == el.value} onChange={handleRadioChange} />
                                    <label htmlFor={el.value} className=' text-blue-gray-700 cm-sm-txt fw-medium ms-2'>{el.label}</label>
                                </div>)
                            }
                        </div>
                    </div>
                    <div className="cm-form-btn-group py-2  cm-create-modal-form-footer mt-2  d-flex align-items-center justify-content-end">
                        <button onClick={handleTranslate} className={`cm-icon-btn position-relative btn btn-primary bg-action-blue cm-sm-txt cm-btn cm-prim-btn d-flex align-items-center`}>
                            <span className="text-white">{t("translate")}</span>
                            <FontAwesomeIcon icon={regular('angle-right')} className="ps-2" />
                        </button>
                    </div>

                </div>
            </Modal>
        </div>
    )
}
export default LanguageTranslateModal;