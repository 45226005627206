import React, { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { useTranslation } from "react-i18next";
import instance from "../../utils/axios";
import { saveSmeInfo } from "../../redux/actions/user.actions";
import { apiErrorHandler } from "../../utils/errorHandler";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { ReactComponent as EmptyIcon } from "../../assets/images/home/empty-data.svg";
import ErrorController from "../../pages/error/ErrorController.page";
import { appError } from "../../redux/actions/error.action";
import { getApiUrl } from "../../utils/apiUrls";
import SmeDashboardTaskItemComponent from "./SmeDashboardTaskItem.component";
import AnimationDataLoader from "../../components/Loaders/animationData";

const SmeDashboardContainer = ({ eventId }) => {
  //data, candidateCount
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { vid } = useParams();
  const [selDtRange, setSelDtRange] = React.useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [allData, setAllData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const { t } = useTranslation();
  const chkError = useSelector((state) => state.error);

  const breadcrumbData = [
    {
      iconClass: regular("house"),
      link: "/",
    },
    {
      label: t("dashboard"),
    },
  ];

  // eslint-disable-next-line
  const [candReport, setCandReport] = React.useState({
    data: [1],
    searchTerm: "",
    searchActive: false,
    isLoading: false,
    offset: 0,
    pageNum: 0,
    previous: false,
    next: true,
    totalPage: 1,
  });

  const dashboardData = (startDate, endDate) => {
    let toDate = "";
    let fromDate = "";
    if (startDate !== null && endDate !== null) {
      toDate =
        selDtRange.endDate == null
          ? ""
          : moment(selDtRange.endDate).endOf("day").unix() * 1000;
      fromDate =
        selDtRange.startDate == null
          ? ""
          : moment(selDtRange.startDate).startOf("day").unix() * 1000;
    }
    if (user.role !== "SME") {
      instance
        .get(
          `${getApiUrl(
            "getSmeData"
          )}/${vid}?fromDate=${fromDate}&toDate=${toDate}`
        )
        .then(function (response) {
          if (response.data) {
            setAllData(response.data);
            setIsLoading(false);
          }
          if(response.data.smeData){
          dispatch(
            saveSmeInfo(
              response.data.smeData.firstName,
              response.data.smeData.lastName,
              response.data.smeData.userEmail,
              response.data.smeData.userId
            )
          );
        }
        else{
          dispatch(
            saveSmeInfo(
              "open",
              "Ai",
              null,
              0
            )
          );
        }

        })
        .catch(function (error) {
          console.log(error);
          const errObj = apiErrorHandler(error);
          dispatch(
            openSnackBar({
              msg: t(errObj.statusText),
              type: "error",
            })
          );
        });
    } else if (user.role === "SME") {
      instance
        .get(
          `${getApiUrl("smeDashboard")}?fromDate=${fromDate}&toDate=${toDate}`
        )
        .then(function (response) {
          if (response.data) {
            setAllData(response.data);
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          console.log(error);
          appError("AdminSmeDashboard", error, dispatch);
        });
    }

    // console.log(allData);
  };

  // React.useEffect(() => {
  //   console.log(allData)
  // }, [allData])

  React.useEffect(() => {
    dashboardData();
    // eslint-disable-next-line
  }, []);

  const handleDtSubmit = () => {
    dashboardData();
  };

  const handleDtClear = () => {
    setSelDtRange({
      ...selDtRange,
      startDate: null,
      endDate: null,
    });

    dashboardData(null, null);
  };
  const handleDtRangeChange = (dt) => {
    setSelDtRange(dt.selection);
  };
  const handleError = () => chkError.location === "AdminSmeDashboard";

  return handleError() ? (
    <ErrorController data={chkError} />
  ) : (
    <>
      <Breadcrumb
        data={breadcrumbData}
        enableDateRange={true}
        selDtRange={selDtRange}
        handleDtRangeChange={handleDtRangeChange}
        handleDtSubmit={handleDtSubmit}
        handleDtClear={handleDtClear}
      />
      <div className="cm-content-wrappper cm-home-dash-content-wrapper pb-5">
        <div className="cm-sme-dash-top-cards cm-home-dash-top-cards d-flex align-items-center justify-content-start pb-5 flex-wrap">
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Active Request
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.task?.active?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">
                  {allData.task?.active?.review}
                </div>
              </div>
            </div>
          </div>
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Closed Request
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.task?.closed?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">
                  {allData.task?.closed?.review}
                </div>
              </div>
            </div>
          </div>
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Yet To Start Request
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.task?.yetToStart?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">-</div>
              </div>
            </div>
          </div>
          <div className="cm-home-dash-item">
            <p className="my-0 text-blue-gray-700 fw-medium"></p>
          </div>
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Questions In-Progress
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.question?.inProgress?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">
                  {allData.question?.inProgress?.review}
                </div>
              </div>
            </div>
          </div>
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Questions Closed
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.question?.completed?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">
                  {allData.question?.completed?.review}
                </div>
              </div>
            </div>
          </div>
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Questions Rejected
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.question?.rejected?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">
                  {allData.question?.rejected?.review}
                </div>
              </div>
            </div>
          </div>
          <div className="cm-home-dash-item bg-white">
            <div className="row">
              <p className="my-0 col-12 text-blue-gray-700 fw-medium txt-align">
                <FontAwesomeIcon
                  icon={regular("clipboard-list")}
                  className="text-blue-gray-700 me-2 cm-sm-txt"
                />
                Questions In Review
              </p>
            </div>
            <hr></hr>
            <div className="row d-flex">
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Creation</p>
                <div className="dashboardCards">
                  {allData.question?.inReview?.creation}
                </div>
              </div>
              <div className="col-6 txt-align">
                <p className="my-0 text-blue-gray-700 fw-medium">Review</p>
                <div className="dashboardCards">-</div>
              </div>
            </div>
          </div>
        </div>

        {!isLoading ? (
          <>
            <div className="cm-candidate-report-list-container mt-4">
              <>
                <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50 text-center">
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Request Id/Type
                    </p>
                  </div>
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Question Count
                    </p>
                  </div>
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Topic
                    </p>
                  </div>
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Level
                    </p>
                  </div>
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Type
                    </p>
                  </div>
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Assigned On
                    </p>
                  </div>
                  <div className="col-sm-2">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Assigned By
                    </p>
                  </div>
                  <div className="col-sm-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Deadline
                    </p>
                  </div>

                  <div className="col-sm-2">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Status
                    </p>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
                {allData.tasksList.length !== 0 && (
                  <>
                    {allData.tasksList?.map((task, index) => (
                      <SmeDashboardTaskItemComponent index={index} task={task} allData={allData} setAllData={setAllData} />
                    ))}
                  </>
                )}

                {allData.tasksList.length === 0 && (
                  <div className="cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center">
                    <div className="py-4 text-center px-4">
                      <EmptyIcon />
                      <p className="text-blue-700 mt-3 mb-0 fw-semibold">
                        Nothing to see!
                      </p>
                    </div>
                  </div>
                )}
              </>

              {/* {candReport.data.length === 0 && candReport.pageNum === 0 ? null : <CandidateReportPagination t={t} handlePagination={handlePagination} candidateData={candReport} /> } */}
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <AnimationDataLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default SmeDashboardContainer;
