import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import './not-found-page.css';

const NotFoundPage = () => {
    return(
        <div className='cm-error-container cm-notfound-page-container d-flex align-items-center justify-content-center flex-column'>
            <h1 className='h1 text-primary text-uppercase fw-bold my-4'>Page not found</h1>
            <Link className='btn btn-primary cm-btn' to="/login">Return to home</Link>
            <Outlet />
        </div>
    )
}

export default NotFoundPage;