import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { useTranslation } from "react-i18next";
import CustomPieChart from "../../components/charts/CustomPieChart.component";
import CreateOrEditTaskModal from "../create-edit-task/CreateOrEditTaskModal.container";
import instance from "../../utils/axios";
import { ReactComponent as EmptyIcon } from "../../assets/images/home/empty-data.svg";
import { saveSmeInfo } from "../../redux/actions/user.actions";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import { appError } from "../../redux/actions/error.action";
import ErrorController from "../../pages/error/ErrorController.page";
import { getApiUrl } from "../../utils/apiUrls";
import ViewAllActiveRequestModal from "./ViewAllActiveRequestModal.container";
import CreateOrEditAllTaskModalContainer from "../create-edit-all-task/CreateOrEditAllTaskModal.container";
import AnimationDataLoader from "../../components/Loaders/animationData";

const AdminDashboardContainer = ({}) => {
  const [searchTxt, setSearchTxt] = React.useState("");
  const [modalStatus, setModalStatus] = React.useState(false);
  const chkError = useSelector((state) => state.error);
  const [selDtRange, setSelDtRange] = React.useState({
    startDate: null,
    endDate: null,
    key: "selection",
  });
  const [allData, setAllData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const [smeData, setSmeData] = React.useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const breadcrumbData = [
    {
      iconClass: regular("house"),
      link: "/",
    },
    {
      label: t("dashboard"),
    },
  ];

  const [viewRequest, setViewRequest] = React.useState(false);

  const handleChange = (e) => {
    setSearchTxt(e.target.value);
    var filtered = {};
    for (var key in allData.smeRequestDTOList) {
      if (
        allData.smeRequestDTOList[key][0].assignedName
          .toLowerCase()
          .includes(e.target.value.toLowerCase())
      )
        filtered[key] = allData.smeRequestDTOList[key];
    }
    setSmeData(filtered);
  };

  const dashboardData = (startDate, endDate) => {
    setAllData({});
    setSmeData([]);
    setIsLoading(true);
    let toDate = "";
    let fromDate = "";
    if (startDate !== null && endDate !== null) {
      toDate =
        selDtRange.endDate == null
          ? ""
          : moment(selDtRange.endDate).endOf("day").unix() * 1000;
      fromDate =
        selDtRange.startDate == null
          ? ""
          : moment(selDtRange.startDate).startOf("day").unix() * 1000;
    }

    instance
      .get(
        `${getApiUrl("adminDashboard")}?fromDate=${fromDate}&toDate=${toDate}`
      )
      .then(function (response) {
        if (response.data) {
          setAllData(response.data);
          setSmeData(response.data.smeRequestDTOList);
          setIsLoading(false);
          dispatch(saveSmeInfo(null));
        }
      })
      .catch(function (error) {
        appError("HomePage", error, dispatch);
      });
  };

  React.useEffect(() => {
    dashboardData();
  }, []);

  const handleDtSubmit = () => {
    dashboardData();
  };

  const handleDtClear = () => {
    setSelDtRange({
      ...selDtRange,
      startDate: null,
      endDate: null,
    });
    dashboardData(null, null);
  };

  React.useEffect(() => {}, [allData]);

  const handleDtRangeChange = (dt) => {
    setSelDtRange(dt.selection);
  };

  const handleModalToggle = () => {
    setModalStatus(!modalStatus);
  };

  const handleTaskStatus = () => {
    dashboardData();
  };

  const handleModalViewRequest = () => {
    setViewRequest(!viewRequest);
  };

  const handleError = () => chkError.location === "HomePage";

  return handleError() ? (
    <ErrorController data={chkError} />
  ) : (
    <>
      <Breadcrumb
        data={breadcrumbData}
        enableDateRange={true}
        selDtRange={selDtRange}
        handleDtRangeChange={handleDtRangeChange}
        handleDtSubmit={handleDtSubmit}
        handleDtClear={handleDtClear}
      />
      <div className="cm-content-wrappper cm-home-dash-content-wrapper pt-3 pb-5">
        <div className="cm-admin-home-dash-top-col d-flex align-items-center cm-home-active-ev-content">
          <div className="cm-home-active-events-wrapper cm-home-dash-widget col-6 cm-admin-pie-chart">
            <div className="cm-home-dash-widget-header bg-blue-gray-50 d-flex align-items-center justify-content-between py-3 px-4">
              <p className="my-0 cm-sm-txt fw-semibold text-blue-gray-700">
                Question Tags Usage
              </p>
            </div>
            {JSON.stringify(allData.questionTagMap) !== "{}" &&
            allData?.completeQuesCount !== 0 ? (
              <CustomPieChart
                data={allData.questionTagMap}
                totalQuestionCount={allData.completeQuesCount}
              />
            ) : (
              <div className="cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center bg-white">
                <div className="text-center px-4">
                  <EmptyIcon />
                  <p className="text-blue-700 mt-3 mb-0 fw-semibold">
                    Nothing to see!
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="col-6">
            <div className="cm-home-dash-top-cards d-flex align-items-center flex-wrap justify-content-between">
              <div className="cm-home-dash-item bg-white">
                <h3>
                  {allData.totalRequestCount !== null
                    ? allData?.totalRequestCount
                    : 0}
                </h3>
                <p className="my-0 text-blue-gray-700 fw-medium">
                  <FontAwesomeIcon
                    icon={regular("clipboard-list")}
                    className="text-blue-gray-700 me-2 cm-sm-txt"
                  />
                  Total Requests
                </p>
              </div>
              <div className="cm-home-dash-item bg-white">
                <h3>
                  {allData.completedRequestCount !== null
                    ? allData?.completedRequestCount
                    : 0}
                </h3>
                <p className="my-0 text-blue-gray-700 fw-medium">
                  <FontAwesomeIcon
                    icon={regular("clipboard-list-check")}
                    className="text-blue-gray-700 me-2 cm-sm-txt"
                  />
                  Closed Requests
                </p>
              </div>
              <div className="cm-home-dash-item bg-white">
                <h3>
                  {allData.inProgressRequestCount !== null
                    ? allData?.inProgressRequestCount
                    : 0}
                </h3>
                <p className="my-0 text-blue-gray-700 fw-medium">
                  <FontAwesomeIcon
                    icon={regular("clipboard-list")}
                    className="text-blue-gray-700 me-2 cm-sm-txt"
                  />
                  Active Requests
                </p>
              </div>
              <div className="cm-home-dash-item bg-white">
                <h3>
                  {allData.newRequestCount !== null
                    ? allData?.newRequestCount
                    : 0}
                </h3>
                <p className="my-0 text-blue-gray-700 fw-medium">
                  <FontAwesomeIcon
                    icon={regular("clipboard-list-check")}
                    className="text-blue-gray-700 me-2 cm-sm-txt"
                  />
                  New Requests
                </p>
              </div>
              <div className="cm-home-dash-item bg-white">
                <h3>
                  {allData.completeQuesCount !== null
                    ? allData?.completeQuesCount
                    : 0}
                </h3>
                <p className="my-0 text-blue-gray-700 fw-medium">
                  <FontAwesomeIcon
                    icon={regular("clipboard-list-check")}
                    className="text-blue-gray-700 me-2 cm-sm-txt"
                  />
                  Completed Questions
                </p>
              </div>
              <div className="cm-home-dash-item bg-white">
                <h3>
                  {allData.inProgressQuesCount !== null
                    ? allData?.inProgressQuesCount
                    : 0}
                </h3>
                <p className="my-0 text-blue-gray-700 fw-medium">
                  <FontAwesomeIcon
                    icon={regular("clipboard-list-check")}
                    className="text-blue-gray-700 me-2 cm-sm-txt"
                  />
                  In-Progress Questions
                </p>
              </div>
            </div>
          </div>
        </div>
        {!isLoading ? (
          <>
            <div className="cm-candidate-report-list-container mt-4">
              <>
                <ViewAllActiveRequestModal
                  openStatus={viewRequest}
                  closeStatus={handleModalViewRequest}
                ></ViewAllActiveRequestModal>
                <CreateOrEditAllTaskModalContainer
                  modalStatus={modalStatus}
                  handleModalToggle={handleModalToggle}
                  handleTaskStatus={handleTaskStatus}
                  actionType="create"
                  task=""
                />

                <div className="pt-4 px-5 cm-candidate-search-wrapper d-flex align-items-center justify-content-between flex-wrap mb-3">
                  <div className="cm-left-col">
                    <h5 className="my-0 text-blue-800 cm-mmc-font">
                      Available SME/Vendors
                    </h5>
                  </div>
                  <div className="cm-candidate-search-field position-relative">
                    <span
                      onClick={handleModalViewRequest}
                      id="viewRequestButton"
                      className="cm-add-quest-btn border border-blue-gray-300 bg-white text-blue-800 cm-sm-txt fw-medium cm-pointer"
                    >
                      <FontAwesomeIcon
                        icon={regular("list-check")}
                        className="pe-2"
                      />
                      View Requests
                    </span>
                    <span
                      onClick={handleModalToggle}
                      id="createRequestButton"
                      className="ms-2 cm-add-quest-btn border border-blue-gray-300 bg-white text-blue-800 cm-sm-txt fw-medium cm-pointer"
                    >
                      <FontAwesomeIcon
                        icon={regular("plus")}
                        className="pe-2"
                      />
                      Create Request
                    </span>
                    <input
                      type="text"
                      value={searchTxt}
                      onChange={handleChange}
                      name="search"
                      placeholder="Search by SME/Vendor name"
                      className="ms-2 cm-sm-txt text-blue-800"
                    />
                    <FontAwesomeIcon
                      icon={regular("search")}
                      className="position-absolute text-blue-gray-700"
                    />
                  </div>
                </div>

                <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50 text-center">
                  <div className="col-1">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      SME/Vendor Id
                    </p>
                  </div>
                  <div className="col-2">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      SME/Vendor Name
                    </p>
                  </div>
                  <div className="col-7 ">
                    <div className="row">
                      <div className="col-3">
                        <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                          Request Type
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                          Total Request
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                          Closed Request
                        </p>
                      </div>
                      <div className="col-3">
                        <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                          In-Progress Request
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-2" id="actionAdminDashboard">
                    <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                      Actions
                    </p>
                  </div>
                </div>
                {Object.keys(smeData).map((el) => (
                  <div
                    key={el}
                    className="cm-candidate-report-list-content bg-white"
                  >
                    <div className="d-flex mx-0">
                      <div className="col-1 cm-candidate-report-item py-3 px-3 d-flex align-items-center justify-content-center separate">
                        <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                          {smeData[el][0].assignedId == -1
                            ? "-"
                            : smeData[el][0].assignedId}
                        </p>
                      </div>
                      <div className="col-2 py-3 px-3 d-flex align-items-center justify-content-center separate">
                        <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                          {smeData[el][0].assignedName}
                        </p>
                      </div>
                      <div className="col-7 d-grid">
                        {smeData[el].map((user, index) => (
                          <div className="d-grid row" key={index}>
                            <div className="cm-candidate-report-item d-flex align-items-center text-center">
                              <div className="col-3">
                                {user.type === "ForCreation" && (
                                  <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                                    Creation
                                  </p>
                                )}
                                {user.type === "ForReview" && (
                                  <p className="my-0 cm-sm-txt fw-questionsWithChoicesAndAnswersmedium text-blue-800">
                                    Review
                                  </p>
                                )}
                              </div>
                              <div className="col-3">
                                <CustomToolTip
                                  title={
                                    <>
                                      <p className="mb-0 cm-sm-txt">
                                        Total Requests: {user.totalRequest}
                                      </p>
                                      <p className="mb-0 cm-sm-txt">
                                        Total Questions: {user.totalQuestion}
                                      </p>
                                    </>
                                  }
                                >
                                  <span className="my-0 cm-sm-txt fw-medium text-blue-800">
                                    {user.totalRequest} [{user.totalQuestion}]
                                  </span>
                                </CustomToolTip>
                              </div>
                              <div className="col-3">
                                <CustomToolTip
                                  title={
                                    <>
                                      <p className="mb-0 cm-sm-txt">
                                        Completed Requests: {user.completedRequest}
                                      </p>
                                      <p className="mb-0 cm-sm-txt">
                                        Completed Questions: {user.completedQuestion}
                                      </p>
                                    </>
                                  }
                                >
                                    <span className="my-0 cm-sm-txt fw-medium text-blue-800">
                                        {user.completedRequest}[
                                        {user.completedQuestion}]
                                    </span>
                                </CustomToolTip>
                              </div>
                              <div className="col-3">
                                <CustomToolTip
                                  title={
                                    <>
                                      <p className="mb-0 cm-sm-txt">
                                        In-Progress Requests: {user.inProgressRequest}
                                      </p>
                                      <p className="mb-0 cm-sm-txt">
                                        In-Progress Questions: {user.inProgressQuestion}
                                      </p>
                                    </>
                                  }
                                >
                                    <span className="my-0 cm-sm-txt fw-medium text-blue-800">
                                        {user.inProgressRequest}[
                                        {user.inProgressQuestion}]
                                    </span>
                                </CustomToolTip>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-2 py-5 px-4 d-flex align-items-center justify-content-center separate">
                        <CustomToolTip title={"Go to vendor dashboard"}>
                          <Link
                            to={`/vendor-dashboard/${smeData[el][0].assignedId}`}
                            className="p-2"
                          >
                            <FontAwesomeIcon
                              icon={regular("arrow-right")}
                              className="text-action-blue"
                            />
                          </Link>
                        </CustomToolTip>
                      </div>
                    </div>
                  </div>
                ))}
                {Object.keys(smeData).length==0 && (
                  <div className="cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center">
                    <div className="py-4 text-center px-4">
                      <EmptyIcon />
                      <p className="text-blue-700 mt-3 mb-0 fw-semibold">
                        Nothing to see!
                      </p>
                      <p
                        className="text-blue-gray-500 mt-2 mb-0 cm-sm-txt mt-2 mx-auto"
                        style={{ maxWidth: "368px" }}
                      >
                        You can start by creating a task.
                      </p>
                    </div>
                  </div>
                )}
              </>
            </div>
          </>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <AnimationDataLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminDashboardContainer;
