import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import "./i18n";

const theme = createTheme({ typography: { fontFamily: ["Noto Sans", "sans-serif"].join(",") } });
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={theme}>
        <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
