import React from "react";
import animationData from "../../assets/loaders/mmc_loader.json";
import AnimationComp from "../animation-comp/AnimationComp.component";

const AnimationDataLoader = ({height=100,width=100}) => {
    return (
        <div>
            <AnimationComp
                animationData={animationData}
                height={height}
                width={width}
            />
        </div>
    )
}

export default AnimationDataLoader