import { FETCH_ENV, LOGOUT } from "../actions/actions.types";

const INITIAL_STATE = null;

const envReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case FETCH_ENV:
            return action.payload;

        case LOGOUT:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default envReducer;