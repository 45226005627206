import React from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { appError } from '../../redux/actions/error.action';
import { getApiUrl } from '../../utils/apiUrls';
import { saveAs } from 'file-saver';
import instance from '../../utils/axios';
import './QuestionLibraryKnowledgeBased.css'
import QuestionCard from './QuestionCard.container';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import Select from '@mui/material/Select';
import { FormControl, InputLabel, MenuItem, FormHelperText } from '@mui/material';
import ViewReviewerModal from '../question-library/ViewReviewerModal.container';
import AnimationDataLoader from '../../components/Loaders/animationData';
import { ReactComponent as EmptyIcon } from "../../assets/images/home/empty-data.svg";




function QuestionLibraryKnowledgeBased() {

    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { taskId } = useParams();
    const [searchParams] = useSearchParams();
    // const getTaskid = searchParams.get('taskId');
    const getQuestid = searchParams.get('qId');
    const { state } = useLocation();
    const user = useSelector(state => state.user);
    const selectquestions = useSelector(state => state.checkbox);
    const [comment, setComment] = React.useState("");
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        revStatus: false,
        approveStatus: false,
        reviewerStatus: false,
        qId: null,
        searchDTOList: []
    });
    const [reviewModalStatus, setReviewModalStatus] = React.useState(false);
    const [reviewTimelineModalStatus, setReviewTimelineModalStatus] = React.useState(false);
    const [allData, setAllData] = React.useState(null);
    const [isAllDataLoading, setIsAllDataLoading] = React.useState(true);
    const [reload, setReload] = React.useState(false);
    const [timelineQuestionId, setTimeLineQuestionId] = React.useState(0);
    const [timelineQuestionStatus, setTimeLineQuestionStatus] = React.useState("");
    const [timelineAdminQuestionStatus, setTimelineAdminQuestionStatus] = React.useState("");
    const chkError = useSelector((state) => state.error);
    const [downloadQuestions, setDownloadQuestions] = React.useState([]);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [aStatus, setAStatus] = React.useState('Accept');
    const sme = useSelector(state => state.user?.sme);
    const userId = useSelector(state => state.user.userId);
    const handleDialogToggle = (qId, status) => {
        setDialogStatus({
            ...dialogStatus,
            status: !dialogStatus.status,
            qId: !!qId ? qId : null
        });
    }
    const [questionInstructionModalStatus, setQuestionInstructionModalStatus] = React.useState(false);

    const [subskillType, setSubskillType] = React.useState([]);
    const [questionList, setQuestionList] = React.useState([]);
    const [difficultyFilter, setDifficultyFilter] = React.useState("All");
    const [selectedSubskill, setSelectedSubskill] = React.useState("All");
    const [checkedQuestion, setCheckedQuestion] = React.useState([]);
    const [checkedQuestionMap, setCheckedQuestionMap] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);



    /* Can be used to implement future functionality

    const handleQuestionInstructionModalToggle = () => {
        setQuestionInstructionModalStatus(!questionInstructionModalStatus);
    }

    const navigateToQuestionCreationPage = () => {
        naviagte(`/question-details?reqId=${taskId}`, { state: allData })
    }

    var downQues = [];

    const handleDialogToggleRev = (qId, status) => {

        setDialogStatus({
            ...dialogStatus,
            revStatus: !dialogStatus.revStatus,
            qId: !!qId ? qId : null
        });
    }

    const handleDialogToggleApprove = (qId, status, adminStatus) => {
       
        if (!!adminStatus) {
            //console.log("reachd reached reached", adminStatus);
            setAStatus("Approve");
        }
        if (status !== 'InProgress' && status !== 'completed')
            setDialogStatus({
                ...dialogStatus,
                approveStatus: !dialogStatus.approveStatus,
                qId: !!qId ? qId : null
            })
    }

    const dialogActionFn = () => {
        deleteQuestion(dialogStatus.qId)
        setDialogStatus({
            ...dialogStatus,
            status: false,
            qId: null,
        })
    }

    const dialogActionFnReject = () => {
        approveRejectQuestion(dialogStatus.qId, "Rejected");
        setDialogStatus({
            ...dialogStatus,
            approveStatus: false,
            qId: null,
        })
    }

    const dialogActionFnRev = () => {
        SentForReview(dialogStatus.qId)
        setDialogStatus({
            ...dialogStatus,
            revStatus: false,
            qId: null,
        })
    }

    const dialogActionFnApprove = () => {
        if (aStatus === "Approve")
            approveRejectQuestion(dialogStatus.qId, "Approved");
        else
            approveRejectQuestion(dialogStatus.qId, "Accepted");
        setDialogStatus({
            ...dialogStatus,
            approveStatus: false,
            qId: null,
        })

    }

    const dialogActionFnApproveReviewer = () => {
        approveRejectQuestionReviewer(dialogStatus.qId, "Approved");
        if (comment.length > 0) {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: false,
                qId: dialogStatus.qId,
            })
        }
        else {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: true,
                qId: dialogStatus.qId,
            })
        }

    }

    const dialogActionFnRejectReviewer = () => {
        approveRejectQuestionReviewer(dialogStatus.qId, "Rejected");
        if (comment.length > 0) {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: false,
                qId: dialogStatus.qId,
            })
        }
        else {
            setDialogStatus({
                ...dialogStatus,
                reviewerStatus: true,
                qId: dialogStatus.qId,
            })
        }
    }

    const approveRejectQuestion = (qid, status) => {
        const dataToSend = { status: status, questionId: qid, comment: comment };
        instance.post(`${getApiUrl('approveCodelysisQuestion')}`, dataToSend).then(function (response) {
            if (response.data) {
                // for (var i = 0; i < data.length; i++) {
                //     if (data[i].questionId === qid)
                //         data[i].status = "sentForReview";
                // }
                reloadDashboard(false);
                // console.log(response.data);
                setComment("");
                //dashboardData();
                if (status === "Approved") {
                    dispatch(openSnackBar({
                        msg: "This question is now approved.",
                        type: 'success'
                    }))
                }
            }
            else {
                reloadDashboard(false);
                dispatch(openSnackBar({
                    msg: "This question is now rejected.",
                    type: 'success'
                }))
            }
        });

    }

    const approveRejectQuestionReviewer = (qid, status) => {
        if (comment.length > 0) {
            const dataToSend = { status: status, comment: comment, questionId: qid, assignedTo: user.userId };
            instance.post(`${getApiUrl('saveReviewerStatus')}`, dataToSend).then(function (response) {
                if (response.data) {
                    setComment("");
                    dashboardData();
                    dispatch(openSnackBar({
                        msg: "This question review is sent to admin.",
                        type: 'success'
                    }))
                } else {
                    // dispatch(openSnackBar({
                    //     msg: "The question review status is sent as rejected to admin.",
                    //     type: 'success'
                    // }))
                    reloadDashboard(false);
                }
            });
        }
        else {
            setErrorMsg(true);
        }
    }


    const handleReviewTimelineModalToggle = (id, status) => {
        setReviewTimelineModalStatus(!reviewTimelineModalStatus);
        setTimeLineQuestionId(id);
        setTimeLineQuestionStatus(status);
    }

    const approveRejectQuestionToggleReviewer = (qId, targetDateExceeded, isCodeAvailable) => {
        console.log(qId, targetDateExceeded, isCodeAvailable);
        if (!isCodeAvailable) {
            dispatch(openSnackBar({
                msg: "Please save a code before Approving or Rejecting a Question",
                type: 'error'
            }))
        }
        else if (targetDateExceeded) {
            dispatch(openSnackBar({
                msg: "Target data has Exceeded",
                type: 'error'
            }))
        } else {
            handleDialogToggleReviewer(qId);
        }
    }

    const handleDialogToggleReviewer = (qId) => {
        setDialogStatus({
            ...dialogStatus,
            reviewerStatus: !dialogStatus.reviewerStatus,
            qId: !!qId ? qId : null
        })
        setErrorMsg(false);
    }

    */

    // React.useState(() => {
    //     console.log(allData);
    // }, [allData])


    const reloadDashboard = (status) => {
        dashboardData(null);
    }


    const dashboardData = async (flag) => {
        if (user.role !== 'SME') {
            if (state === null && flag === null) {
                try {
                    const res = await instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${sme.userId}`);
                    if (!!res.data) {
                        setAllData(res.data);
                        setIsLoading(false);
                    }
                } catch (error) {
                    appError('QuestionLibrary', error, dispatch);
                }
            }
            else {
                instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${state.smeId}`)
                    .then(function (response) {
                        if (response.data) {
                            setAllData(response.data);
                            setIsLoading(false);
                        }
                    })
                    .catch(function (error) {
                        appError('QuestionLibrary', error, dispatch);
                    });
            }
        }
        else {
            if (state === null) {
                instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${userId}`)
                    .then(function (response) {
                        if (response.data) {
                            setAllData(response.data);
                            setIsLoading(false);
                        }
                    })
                    .catch(function (error) {
                        appError('QuestionLibrary', error, dispatch);
                    });
            }
            else {
                instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${user.userId}`)
                    .then(function (response) {
                        if (response.data) {
                            setAllData(response.data);
                            setIsLoading(false);
                        }
                    })
                    .catch(function (error) {
                        appError('QuestionLibrary', error, dispatch);
                    });
            }
        }
    }

    const requestType = state !== null ? 'review' : '';

    const [subskillTypeCount, setSubskillTypeCount] = React.useState({});
    let resetQuestionMap = {};

    React.useEffect(() => {
        allData === null && isAllDataLoading && dashboardData(allData);
        if (allData !== null) {
            setSubskillType([...new Set(allData.subSkills.map((element) => {
                return element.subskillName;
            }))])

            if (allData.questionList !== null) {
                const allSubskills = allData.questionList.map((element) => {
                    return element.questionTitle;
                    // if(element.questionId===getQuestid) return element;
                })

                setSubskillTypeCount(allSubskills.reduce(function (value, value2) {
                    return (
                        value[value2] ? ++value[value2] : (value[value2] = 1),
                        value
                    );
                }, {}))

                setQuestionList(allData.questionList);

                resetQuestionMap = {};

                for (let question in allData.questionList) {
                    const questionNum = allData.questionList[question].questionId;
                    resetQuestionMap = { ...resetQuestionMap, [questionNum]: false };
                }
                setCheckedQuestionMap(resetQuestionMap);
            }

        }
    }, [allData])

    const handleModalToggle = (id, status, adminStatus) => {
        setReviewModalStatus(!reviewModalStatus);
        setTimeLineQuestionId(id);
        setTimeLineQuestionStatus(status);
        setTimelineAdminQuestionStatus(adminStatus);
    }

    /* Can be used for future functionality addition

    const handleCheckBoxChange = (e) => {
        downQues.push(e.target.value);
        setDownloadQuestions(downQues);
        //console.log(downQues);
        if (selectedRows.includes(e.target.value)) {
            setSelectedRows(prevState => prevState.filter(el => el !== e.target.value))
        } else {
            setSelectedRows([...selectedRows, e.target.value])
        }
    }

    const loadQuestions = () => {
        if (!!allData.questionList) {
            return allData.questionList.map(el => {
                return <QuestionLibraryItem data={el} key={el.questionId} taskId={taskId} handleModalToggle={handleModalToggle} handleReviewTimelineModalToggle={handleReviewTimelineModalToggle} reloadDashboard={reloadDashboard} deleteQuestion={handleDialogToggle} SentForReview={handleDialogToggleRev} approveRejectQuestion={handleDialogToggleApprove} approveRejectQuestionReviewer={approveRejectQuestionToggleReviewer} requestType={allData.taskType} handleCheckBoxChange={handleCheckBoxChange} isChecked={selectedRows.includes(String(el.questionId))} />

            })
        }

        return (
            <div className='cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center'>
                <div className='py-4 text-center px-4'>
                    <EmptyIcon />
                    <p className='text-blue-700 mt-3 mb-0 fw-semibold'>Nothing to see!</p>
                    <p className='text-blue-gray-500 mt-2 mb-0 cm-sm-txt mt-2 mx-auto' style={{ 'maxWidth': '368px' }}>You can start by adding question.</p>
                </div>
            </div>
        )
    }

    const deleteQuestion = (qid) => {
        instance.get(`${getApiUrl('deleteCodelysisQuestion')}/${qid}`)
            .then(function (response) {
                if (response.data) {
                    reloadDashboard(false);
                    dispatch(openSnackBar({
                        msg: "This question is now deleted.",
                        type: 'success'
                    }))
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const SentForReview = (qid) => {
        instance.get(`${getApiUrl('codelysisQuestionSendForReview')}/${qid}`).then(function (response) {
            if (response.data) {
                // for (var i = 0; i < data.length; i++) {
                //     if (data[i].questionId === qid)
                //         data[i].status = "sentForReview";
                // }
                reloadDashboard(false);
                // console.log(response.data);
                dispatch(openSnackBar({
                    msg: "This question is now sent for review",
                    type: 'success'
                }))
            }

        })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleReportDownload = () => {

        saveAs(`${getApiUrl('downloadQuestions')}?questionIds=${selectedRows.join(",")}`, `Coding.xls`);
    }




    const handleComment = (e) => {
        setComment(e.target.value)
    }



    const handleError = () => chkError.location === 'QuestionLibrary'

    */

    const breadcrumbData = React.useMemo(() => ([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Question Library",
            link: '/'
        }
    ]), []);

    const handleSubskillClick = (currSubskill) => {
        if (allData.questionList !== null) {
            let tempQuestionList = allData.questionList.filter((question) => {
                return question.questionTitle === currSubskill;
            })
            setSelectedSubskill(currSubskill);
            setDifficultyFilter("All");
            setQuestionList(tempQuestionList);
        }

    }

    const handleAllSubskillClick = () => {
        setSelectedSubskill("All");
        setDifficultyFilter("All");
        setQuestionList(allData.questionList);
    }

    const handleDifficultyLevelChange = (event) => {
        if (allData.questionList !== null) {
            setDifficultyFilter(event.target.value);
            if (event.target.value !== "All") {
                let tempQuestionList = allData.questionList.filter((question) => {
                    if (selectedSubskill === "All") return question.difficultyLevel === event.target.value;
                    else return question.difficultyLevel === event.target.value && question.questionTitle === selectedSubskill;
                })
                setQuestionList(tempQuestionList);
            }
            else {
                let tempQuestionList = allData.questionList.filter((question) => {
                    if (selectedSubskill === "All") return true;
                    else return question.questionTitle === selectedSubskill;
                })
                setQuestionList(tempQuestionList);
            }
        }

    }


    return (
        <div className='scroll-hide'>
            <Breadcrumb data={breadcrumbData} backBtn={true} />
            <ViewReviewerModal t={t} reviewModalStatus={reviewModalStatus} handleModalToggle={handleModalToggle} timelineQuestionId={timelineQuestionId} dashboardData={dashboardData} timelineQuestionStatus={timelineQuestionStatus} timelineAdminQuestionStatus={timelineAdminQuestionStatus} isMultiReview={true} checkedQuestion={checkedQuestion} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} resetQuestionMap={resetQuestionMap} />


            <div className='d-flex scroll-hide' style={{ overflowY: "hidden", height: "78vh" }}>
                {/* <div className='w-30 left-container scroll-hide' >
                <div className="cm-quest-lib-top-cards d-flex align-items-center justify-content-center mx-2 my-3 ">
                    {allData !== null ? <div className='cm-quest-lib-top-item bg-white border' style={{minWidth: "200px"}}>
                        <span className='cm-gradient-txt cm-mmc-font cm-truncate-1' title={allData.topicName}>{allData.topicName}</span>
                        <p className='my-0 d-flex align-items-center fw-medium text-blue-gray-700 pt-2'><FontAwesomeIcon icon={regular("tag")} className="me-2 text-blue-gray-700" /> Topic</p>
                    </div> : null}
                    {allData !== null ? <div className='cm-quest-lib-top-item bg-white border' style={{minWidth: "200px"}}>
                        <span className='cm-gradient-txt cm-mmc-font'>{allData.totalQuestion}</span>
                        <p className='my-0 d-flex align-items-center fw-medium text-blue-gray-700 pt-2'><FontAwesomeIcon icon={regular("circle-question")} className="me-2 text-blue-gray-700" /> Question Count</p>
                    </div> : null}
                </div>

                {allData !== null && allData.taskType !== "ForReview" && <div>
                <div className='py-2 px-3 subskill-txt'>
                    Sub-Skills
                </div>

                <div className='skill-box' onClick={handleAllSubskillClick}>
                    <span>All Skills</span>
                    <span className='count-circle'>{allData !== null && allData.questionList !== null ? allData.questionList.length : 0}</span>
                </div>

                <div>
                     {
                        subskillType.map((value) => {
                            return <div className='skill-box' onClick={() => handleSubskillClick(value)}>
                                 <span>{value}</span>
                                 <span className='count-circle'>{value in subskillTypeCount ? subskillTypeCount[value] : 0}</span>
                            </div>
                        })
                     }
                </div>

                </div>}

                

            </div> */}

                <div className='w-100 right-container'>

                   { !location.state.singleReview && <div className='w-100 filter-bar'>
                        <div className='filter-options'>
                            <div className='my-3 mr-2 filter-style'>Filters</div>
                            <FormControl sx={{ m: 1, minWidth: 120, marginLeft: 5 }} size='small'>
                                <InputLabel id="demo-simple-select-helper-label">Difficulty</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={difficultyFilter}
                                    label="Age"
                                    onChange={handleDifficultyLevelChange}
                                >
                                    <MenuItem value="All">
                                        <em>All</em>
                                    </MenuItem>
                                    <MenuItem value={"Easy"}>Easy</MenuItem>
                                    <MenuItem value={"Medium"}>Medium</MenuItem>
                                    <MenuItem value={"Hard"}>Hard</MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        {/* {
                    // allData !== null && allData.taskType !== "ForReview" && <button className='review-btn' disabled={checkedQuestion.length === 0} onClick={() => handleModalToggle(0, "SentForReview", "Accepted")}>Send for Review</button>
                    allData !== null && allData.taskType !== "ForReview" && user.role!=="SME" && <button
                     className='review-btn' 
                     disabled={checkedQuestion.length === 0}
                     onClick={() => handleModalToggle(0, "SentForReview", "Accepted")
                     }
                     >
                        Send for Review
                    </button>
                    } */}
                    </div>}

                    <div className='mt-bar'>
                        {!isLoading ? ((allData !== null && allData.questionList !== null && questionList !== null && questionList.length !== 0) ? (questionList.map((question) => {

                            if (allData.taskType === "ForReview") {
                                // for multiple preview
                                if (selectquestions.length > 0) {
                                    if (selectquestions.includes(question.questionId)) {
                                        return <QuestionCard data={questionList} key={question.questionId} question={question} checkedQuestion={checkedQuestion} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} taskType={allData.taskType} multiple={true} />
                                    }
                                }
                                else if (selectquestions.length <= 0 && !location.state.singleReview) {
                                    return <QuestionCard data={questionList} key={question.questionId} question={question} checkedQuestion={checkedQuestion} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} taskType={allData.taskType} multiple={true} />
                                }
                                // for single preview
                                else if (question.questionId == getQuestid && location.state.singleReview) {
                                    return <QuestionCard data={questionList} key={question.questionId} question={question} checkedQuestion={checkedQuestion} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} taskType={allData.taskType} multiple={false} />
                                }

                            }
                            else {
                                if (question.questionId === getQuestid) {
                                    return <QuestionCard data={questionList} key={question.questionId} question={question} checkedQuestion={checkedQuestion} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} taskType={allData.taskType} multiple={false} />
                                }
                            }

                        })) : ( <div className="d-flex cm-empty-container justify-content-center">
                        <div className="text-center px-4">
                            <EmptyIcon />
                            <p className="text-blue-700 mt-3 mb-0 fw-semibold">
                                Nothing to see!
                            </p>
                            <p
                                className="text-blue-gray-500 mt-2 mb-0 cm-sm-txt mt-2 mx-auto"
                                style={{ maxWidth: "368px" }}
                            >
                                No questions to see.
                            </p>
                        </div>
                    </div>)) : (<AnimationDataLoader /> )
                        }
                        {allData?.questionList?.length === 0 && <div className='mx-4 my-4'>No questions added!</div>}

                    </div>
                </div>
            </div>

        </div>
        // </div>
    )
}

export default QuestionLibraryKnowledgeBased