import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';
import QuestionInstructionModal from '../question-library/QuestionInstructionModal.container';
import './QuestionCreation.styles.css';

const QuestionCreationSidebar = ({ t, handleSaveBtn, handleBackBtn, questStepData, isLoading, allTestcaseRun, compileTestCases, enableCases }) => {
    const [questionInstructionModalStatus, setQuestionInstructionModalStatus] = React.useState(false);

    const handleQuestionInstructionModalToggle = () => {
        setQuestionInstructionModalStatus(!questionInstructionModalStatus);
    }
    const progressSteps = [
        {
            label: "Add Question Details",
            value: 0
        },
        {
            label: "Add Testcases",
            value: 1
        },
        {
            label: "Add Solution and Preview",
            value: 2
        }
    ];

    const handleRadioChange = (e) => {
        // if(e.target.value !== eventProgress) setEventProgress(e.target.value);
    }

    const onSaveBtnClick = () => handleSaveBtn();

    const onBackBtnClick = () => handleBackBtn();

    return (
        <div className='cm-event-library-sidebar-wrapper'>
            <div className="cm-event-sidebar-widget cm-event-progress-wrap">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className='my-0 text-blue-gray-700 fw-semibold'>Question Creation Instruction</p>
                    <span className='text-blue-gray-700 cm-sm-txt cm-pointer'>
                        <FontAwesomeIcon icon={regular("circle-info")} onClick={handleQuestionInstructionModalToggle} />
                    </span>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p className='my-0 text-blue-gray-700 fw-semibold'>Current Progress</p>
                    <span className='text-blue-gray-400 cm-sm-txt'>{questStepData.activeStep + 1}/{progressSteps.length}</span>
                </div>
                <div className="cm-event-progress-steps mb-4">
                    <ul className='pt-3'>
                        {
                            progressSteps.map(el =>
                                <li className={`d-flex align-items-center py-2 px-3 position-relative ${el.value <= questStepData.activeStep ? 'active-step' : ''}`} key={el.value}>
                                    <input type="radio" checked={el.value <= questStepData.activeStep} value={el.value} onChange={handleRadioChange} name={`taskFilter-${el.value}`} id={el.value} />
                                    <label htmlFor={el.value} className="cm-sm-txt text-blue-800 fw-medium">{el.label}</label>
                                </li>)
                        }
                    </ul>
                </div>
                <div className="cm-event-progress-controls d-flex align-items-center">
                    <span className={`cm-sidebar-back-btn text-blue-800 fw-medium cm-sm-txt cm-pointer me-2 d-inline-block ${questStepData.activeStep === 3 ? 'cm-small-back-btn' : ''}`} onClick={onBackBtnClick}>Back</span>
                    <CustomButtonLoader
                        showLoadingState ={isLoading}
                        colorTheme= "blue"
                        icon={regular('angle-right')}
                        reverseIconDirection={false}
                        buttonLabel={questStepData.activeStep === 2 ? t("save_and_complete") : t("save_and_continue")}
                        buttonStyle={`cm-sidebar-back-btn px-12 py-2 cm-mar-left-icon ${questStepData.activeStep === 3 ? 'cm-small-back-btn' : ''}`}
                        handleOnClick={onSaveBtnClick}
                    />
                </div>
            </div>
            <QuestionInstructionModal modalStatus={questionInstructionModalStatus} handleModalToggle={handleQuestionInstructionModalToggle} navigateToQuestionCreationPage={null} />
        </div>

    )

}

export default QuestionCreationSidebar;
