import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const UserInput = (props) => {
  const [dropDownData, setDropDownData] = React.useState("Skill_type");
  const handleChange = (e) => {
    setDropDownData(e.target.value);
    props.dropDownData(e.target.value);
  };
  return (
    <div className="my-2 w-50 taxonomy-management_dd">
      <Select
        value={dropDownData}
        onChange={handleChange}
        fullWidth={true}
        sx={{ height: "40px", fontSize: "14px", textTransform: "capitalize" }}
        renderValue={(val) => {
          return dropDownData.replace("_", " ");
        }}
      >
        <MenuItem value="Skill">Skill</MenuItem>
        <MenuItem value="Job">Job</MenuItem>
        <MenuItem value="Skill_type">Skill type</MenuItem>
        {/* <MenuItem value="Trait">Trait</MenuItem> */}
        {/* <MenuItem value="Tool">Tool</MenuItem> */}
        <MenuItem value="Question">Question</MenuItem>
        <MenuItem value="Relation">Relation</MenuItem>
        <MenuItem value="Industry">Industry</MenuItem>
      </Select>
    </div>
  );
};
export default UserInput;
