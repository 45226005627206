import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import './CustomSelect.styles.css';

const CustomSelect = ({ currentVal, menuArr, handleMultiSelectChange, addSameVal, fieldName }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const inpRef = React.useRef(null);
    const open = Boolean(anchorEl);
    const handleAdd = (val) => {
        let valToSend = currentVal;
        if (addSameVal) {
            valToSend.push({ ...val, id: Date.now() })
        } else {
            // valToSend = currentVal.filter(el => el.value !== val.value);
            const getIndex = valToSend.findIndex(el => el.value === val.value);
            if (getIndex < 0) {
                valToSend.push(val);
            } else {
                valToSend.splice(getIndex, 1);
            }
        }

        handleMultiSelectChange(valToSend, fieldName);
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleRemove = (e, val) => {
        e.stopPropagation();
        let valToSend = [];
        if (addSameVal) {
            valToSend = currentVal.filter(el => el.id !== val.id);
        } else {
            valToSend = currentVal.filter(el => el.value !== val.value);
        }
        handleMultiSelectChange(valToSend, fieldName);
    }

    const filterValues = () => {
        const addedVal = currentVal.map(el => el.value);
        const filteredArr = menuArr.filter(el => !addedVal.includes(el.value))

        return filteredArr.map((el, index) => <MenuItem key={el.value + index} onClick={() => handleAdd(el)}>{el.label}</MenuItem>)
    }

    return (
        <div className='cm-custom-select'>
            <div className="cm-multi-select-inp form-control text-blue-800 fw-medium cm-sm-txt fw-medium" onClick={handleClick} ref={inpRef}>
                {
                    currentVal.map((el) => <span className={`cm-select-chip bg-gray-100 badge rounded-pill text-blue-800 fw-medium cm-sm-txt py-2 px-3`} key={addSameVal ? el.id : el.value}>{el.label} <FontAwesomeIcon icon={regular('times')} className="cm-blue-gray-700 cm-pointer ps-2" onClick={(e) => handleRemove(e, el)} /></span>)
                }
                <FontAwesomeIcon icon={regular("chevron-down")} className={`cm-select-arrow text-blue-800 position-absolute cm-rotate-transition ${open ? 'fa-rotate-180' : ''}`} />
            </div>
            <Menu
                id="multi-select"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: !!inpRef.current ? inpRef.current.offsetWidth : 150
                    },
                }}
            >
                {!addSameVal && currentVal.length > 0 ? filterValues() : menuArr.map((el, index) => <MenuItem key={el.value + index} onClick={() => handleAdd(el)}>{el.label}</MenuItem>)}
            </Menu>
        </div>
    )

}

export default CustomSelect;