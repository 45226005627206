import React from "react";
import { useDispatch } from "react-redux";
// import { Outlet } from "react-router-dom";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/header/header";
import { resetQuest } from "../../redux/actions/question.actions";

const LayoutContainer = ({ unAuth }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    React.useEffect(() => {
        const pathToExclude = ['/question-details', '/add-test-cases', '/add-solution']

        if(!pathToExclude.includes(location.pathname)) dispatch(resetQuest())
    }, [location]);

    React.useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return(
        <>
            <Header />
            <Outlet />
        </>
    )

}

export default LayoutContainer;