import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AnimationDataLoader from '../../components/Loaders/animationData';

import AceEditor from 'react-ace';
import * as ace from 'brace';
import 'brace/theme/monokai';
import 'brace/theme/tomorrow';
import 'brace/mode/javascript';
import 'brace/mode/java';
import 'brace/mode/c_cpp';
import 'brace/mode/python';
import 'brace/ext/language_tools';
import "ace-builds/src-noconflict/ext-language_tools";
import './QuestionCreation.styles.css';
import instance from '../../utils/axios';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { getApiUrl } from '../../utils/apiUrls';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { useDispatch, useSelector } from 'react-redux';
import { apiErrorHandler } from '../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import { appError } from '../../redux/actions/error.action';
import ErrorController from '../../pages/error/ErrorController.page';

const editorModes = {
    c: 'c_cpp',
    cpp: 'c_cpp',
    java: 'java',
    javascript: 'javascript',
    python: 'python'
}

const PreviewQuestionContainer = ({ questData, getQuestid, getReqId }) => {
    const dispatch = useDispatch();
    const {t}=useTranslation();
    const initial_state = {
        ...questData.addSolution
    }

    const editorRef = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [formVal, setFormVal] = React.useState(initial_state);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        qId: null,
    });
    const chkError = useSelector((state) => state.error);
    const [codeCompile, setCodeCompile] = React.useState(false);
    const [enableCases,setEnableCases]=React.useState(null);
    const [compileTestCases, setCompileTestCases]=React.useState([]);
    const [allTestcaseRun,setAllTestcaseRun]=React.useState(-1);
    const [compilationError,setCompilationError]=React.useState(null);

    const [editorOp, setEditorOp] = React.useState({
        darkMode: false,
    });

    const handleDialogToggle= (qId) => {
        setDialogStatus({
            ...dialogStatus,
            status: !dialogStatus.status,
            qId: !!qId ? qId : null
        }); 
    }

    const dialogActionFn = () => {
        grantAccessApi(dialogStatus.qId)
        setDialogStatus({
            ...dialogStatus,
            status: false,
            qId: null,
        })
    }

    const grantAccessApi = async(qId) => {
        try {
            const res = await instance.get(`${getApiUrl('requestForTestcaseData')}/${getQuestid}`);
            if(res.data) {
                dispatch(openSnackBar({
                    type: 'success',
                    msg: 'Testcase request has been sent to the Admin.'
                }))
            }


        } catch (error) {
            console.log('grantAccessApi error', error);
            appError('PreviewQuestion', error, dispatch);
        }
    }

    React.useEffect(() => {
        setFormVal(questData.addSolution)
        // handleEnableCases("disable");
    }, [questData])

    const handleFieldChange = (e, fieldName) => {
        // getSolutionValue({
        //     ...formVal,
        //     [fieldName]: {
        //         ...formVal[fieldName],
        //         value: e.target.value
        //     }
        // })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })
    }

    const handleSelectChange = (event, fieldName, arr) => {
        let val = {};

        val = arr[arr.findIndex(el => el.value === event.target.value)]
        //console.log('event', event.target.value);

        // getSolutionValue({
        //     ...formVal,
        //     [fieldName]: {
        //         ...formVal[fieldName],
        //         value: val
        //     }
        // })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })
    }

    const handleEditorChange = (val) => {
        // getSolutionValue({
        //     ...formVal,
        //     ['ansDescription']: {
        //         ...formVal['ansDescription'],
        //         value: val
        //     }
        // })

        setFormVal({
            ...formVal,
            ['ansDescription']: {
                ...formVal['ansDescription'],
                value: val
            }
        })

    }

    const checkSolution = () => {
        checkCodeCompile("enable");
    }

    const checkCodeCompile = async (state) => {
        try {
            setIsLoading(true);
            setEnableCases(state);
            // const isFormValid = handleAddSolutionValidation();

            // console.log('Valid?', isFormValid);

            // if(!isFormValid) {
            //     setIsLoading(false);
            //     return;
            // }

            const dataObj = questData.questDetails;

            const dataToSend = {
                codeLanguages: dataObj.codingLanguages.value.map(el => el.label),
                difficultyLevel:  dataObj.difficultyLevel.value.value,
                functionName: dataObj.functionName.value,
                inputSpecification: dataObj.addInputs.value.map(el => el.preText),
                inputType: dataObj.addInputs.value.map(el => el.value),
                outputSpecification: dataObj.addOutputs.value.map(el => el.preText),
                outputType: dataObj.addOutputs.value.map(el => el.value),
                questionText: dataObj.questionText.value,
                questionTitle: dataObj.questionTitle.value,
                tagIds: dataObj.questionTag.value.map(el => el.value),
                taskId: getReqId,
                topicName: dataObj.questionTopic.value,
                testCasesDTO: questData.addTestcases.data.map(el => ({
                    description: el.testcaseDesc.value,
                    inputValues: el.inputArr.map(innerEl => innerEl.value),
                    name: el.testcaseName.value,
                    outputValues: el.outputArr.map(innerEl => innerEl.value),
                    type: el.testcaseType.value.value,
                })),
                ansLangId: questData.addSolution.selLang.value.id,
                ansDescription: questData.addSolution.ansDescription.value,
                creatorStatus: "completed"
            }

            if(!!getQuestid) dataToSend.questionId = getQuestid;

            const res = await instance.post(`${getApiUrl('checkCodeCompilation')}`, dataToSend);;
            if(res.data){
                setIsLoading(false);
                if(!!res.data.status) {
                    let count=0;
                    setCompileTestCases(res.data.testcaseResponseDtoList);
                    if(res.data.status)
                    {
                
                    for(var i=0;i<res.data.testcaseResponseDtoList.length;i++) {
                        if(res.data.testcaseResponseDtoList[i].status) count=count+1;
                    }
                    
                    if(count===res.data.testcaseResponseDtoList.length) {
                        setAllTestcaseRun(1);
                        setEnableCases("enable");
                    }
                    else
                        setAllTestcaseRun(0);

                    // navigate(`/add-solution?reqId=${getReqId}&qId=${!!getQuestid ? getQuestid : res.data.id}`, { state: { fromAdd: true } });
                }
            }
            else{
                // setCompilationError(res.data.message);
            }
        }

        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleEditorTheme = () => {
        setEditorOp({ ...editorOp, darkMode: !editorOp.darkMode})
    }

    const handleError = () => chkError.location === 'PreviewQuestion'

    return (
        handleError() ? <ErrorController data={chkError} /> :
        <div className='cm-preview-quest-container m-4 p-3 bg-white'>
            {
                questData.questDetails !== null && questData.addTestcases.data !== null ? 
                <div className='cm-preview-quest-info'>
                    <p><strong>{questData.questDetails.questionTitle.value}</strong></p>
                    <div  className='cm-content cm-sm-txt' dangerouslySetInnerHTML={{__html: questData.questDetails.questionText.value}}></div>
                    <p className='pt-3 mb-2'><strong>Input Specification:</strong></p>
                    <div className='p-2 cm-io-spec-wrap mb-3' style={{backgroundColor: '#f6f6f6'}}>
                        {
                            questData.questDetails?.addInputs.value.map((el, index) => <p key={index} className="my-0 cm-sm-txt"><strong>input{index + 1} : </strong>{el.preText}</p>)
                        }
                    </div>

                    <p className='mb-2'><strong>Output Specification:</strong></p>
                    <div className='p-2 cm-io-spec-wrap mb-4' style={{backgroundColor: '#f6f6f6'}}>
                        {
                            questData.questDetails.addOutputs.value.map((el, index) => <p key={index} className="my-0 cm-sm-txt"><strong>output{index + 1} : </strong>{el.preText}</p>)
                        }
                    </div>
                    
                    {
                        questData.addTestcases.data.map((el, index) =>(
                            <div className='cm-test-case-item mt-4'>
                                <p className='mb-2'><strong>Example {index + 1}:</strong></p>
                                <div className='p-2 cm-io-spec-wrap mb-3' style={{backgroundColor: '#f6f6f6'}}>
                                    {
                                        el.inputArr.map((innerEl, innerInd) => <p key={innerInd} className="my-0 cm-sm-txt"><strong>input{innerInd + 1} : </strong>{innerEl.value}</p>)
                                    }
                                </div>
                                <div className='p-2 cm-io-spec-wrap mb-3' style={{backgroundColor: '#f6f6f6'}}>
                                    {
                                        el.outputArr.map((innerEl, innerInd) => <p key={innerInd} className="my-0 cm-sm-txt"><strong>output{innerInd + 1} : </strong>{innerEl.value}</p>)
                                    }
                                </div>
                                <div>
                                    <p className='mb-2'><strong>Explanation:</strong></p>
                                    <div className='cm-content cm-sm-txt' dangerouslySetInnerHTML={{__html: el.testcaseDesc.value}}></div>
                                </div>
                            </div>
                        ))
                    }
                    
                </div> :
                <div className="d-flex align-items-center justify-content-center">
                    <AnimationDataLoader />
                </div>
            }
        </div>
    )

}

export default PreviewQuestionContainer;