import React from 'react';
import TranslationMode from '../../containers/translation-mode/TranslationMode.container';



const TranslationModePage = () => {

    React.useEffect(() => {
        document.body.classList.add('overflow-hidden');
      
        return () => {
          document.body.classList.remove('overflow-hidden');
        };
      }, []);
      

    return(
        <div className="cm-content-wrappper">
            <TranslationMode />
        </div>
    )

}

export default TranslationModePage;