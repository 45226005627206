import React from "react";
import { getApiUrl } from "../../utils/apiUrls";
import { MenuItem } from "@mui/material";
import instance from "../../utils/axios";
import { apiErrorHandler } from "../../utils/errorHandler";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { saveAs } from "file-saver";
import "./DownloadBankStyles.css"


const DownloadBankFom = ({ t, modalData, handleBankModalToggle }) => {

    const [downloadBank, setDownloadBank] = React.useState({
        selectedLanguage: null,
        selectedLanguageValue: '',
        translatedlanguages: [],
        isDownloadTransalted: false,
        isLoading: false,
        isFlag: false,
        isOriginalLanguage: false,
        removeHTML: false
    })

    const [errorObj, setErrorObj] = React.useState({
        canDownlaod: { status: false, msg: null }
    })
    const [allLanguages, setAllLanguages] = React.useState({
        data: null,
    });
    const [translatedlanguages, setTranslatedlanguages] = React.useState(null);
    const dispatch = useDispatch();

    const handleChange = (key) => {
        if (key === 'isDownloadTransalted') {
            setDownloadBank({
                ...downloadBank,
                isDownloadTransalted: !downloadBank.isDownloadTransalted,
            })
        }
        else if(key === 'isFlags') {
            setDownloadBank({
                ...downloadBank,
                isFlag: !downloadBank.isFlag,
            })
        }
        else if(key === 'isOriginalLanguage') {
            setDownloadBank({
                ...downloadBank,
                isOriginalLanguage: !downloadBank.isOriginalLanguage,
            })
        }
        else if(key === 'removeHTML') {
            setDownloadBank({
                ...downloadBank,
                removeHTML: !downloadBank.removeHTML,
            })
        }
    }

    const handleLanguageChange = (key, value) => {
        let selectedLang = []
        if (key === 'selectedLanguage') {
            selectedLang = allLanguages.data.find(el => el.value === value);
            setDownloadBank({
                ...downloadBank,
                selectedLanguageValue: selectedLang.value,
                [key]: selectedLang
            })
        }
    }

    React.useEffect(() => {
        fetchAllLanguages();
    }, [])

    const handlevalidate = () => {
        const error = {}
        let isValid = true;
        if (!downloadBank.isDownloadTransalted) {
            const errobj = { status: true, msg: "Select one option" }
            error.canDownlaod = errobj;
            isValid = false
        }
        setErrorObj(error);
        return isValid;
    }
    const handledownloadTranslateBank = () => {
        // validation for futurte purpose
        // let isValid = handlevalidate();
        // if (!isValid) return;
        downloadQuestionBank();
    }

    const downloadQuestionBank = async () => {
        try {
            setDownloadBank({
                ...downloadBank,
                isLoading: true,
            })
            const res = await instance.get(`${getApiUrl("downloadReviewQuestions")}?bankId=${modalData.reviewBankId}&questionLanguage=${downloadBank?.selectedLanguageValue ? downloadBank.selectedLanguageValue : "BASE"}&isFlag=${downloadBank?.isFlag}&originalLanguage=${downloadBank?.isOriginalLanguage}&removeHTML=${downloadBank?.removeHTML}`, {
                responseType: "blob"
});
            setDownloadBank({
                ...downloadBank,
                isLoading: false,
            })
            if (res.data) {
                var filename = modalData.reviewBankName;
                if (downloadBank?.selectedLanguageValue) {
                    filename = filename + "_" + downloadBank.selectedLanguage.language;
                }
                saveAs(res.data, filename)
            }

        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const fetchAllLanguages = async () => {
        try {
            const res = await instance.get(getApiUrl("getLanguagesSupport"));
            setAllLanguages({
                data: res.data,
            });
            fetchTranslatedLanguages(res.data)

        } catch (error) {
            const errorObj = apiErrorHandler(error)
            dispatch(
                openSnackBar({
                    msg: t(errorObj.statusText),
                    type: "error"
                })
            )
        }
    }

    const fetchTranslatedLanguages = async (allLanguages) => {
        try {
            const res = await instance.get(`${getApiUrl('getAllTranslatedLanguages')}?bankId=${modalData.reviewBankId}`);
            if (res.data) {
                let translatedLang = [];
                res.data.forEach(el => {
                    if (el) {
                        const langObj = allLanguages.find(innerElement => el === innerElement.value);
                        translatedLang.push(langObj)
                    }
                });
                setTranslatedlanguages(translatedLang);
            }
        }
        catch (error) {
            const errorObj = apiErrorHandler(error)
            dispatch(
                openSnackBar({
                    msg: t(errorObj.statusText),
                    type: "error"
                })
            )
        }
    }

    return (
        <div>
            <div className='mt-3 px-4 mb-3 cm-create-task-form '>
                <div className='mb-3 d-flex align-items-center user-select-none'>
                    <input type="checkbox" name={t("download_translated_question")} id={t("download_translated_question")} className="transformed-checkBox" checked={downloadBank.isDownloadTransalted} onChange={() => handleChange('isDownloadTransalted')}  />
                    <label htmlFor={t("download_translated_question")} className='ps-2 cm-sm-txt fw-medium'>{t("download_translated_question")}</label>
                </div>
                {downloadBank.isDownloadTransalted &&
                    <div className="ms-4 position-relative">
                        <Select
                            id="language-select"
                            fullWidth={true}
                            displayEmpty={true}
                            disabled={!translatedlanguages?.length > 0}
                            value={downloadBank.selectedLanguage ? downloadBank.selectedLanguage : ''}
                            onChange={e => handleLanguageChange('selectedLanguage', e.target.value)}
                            renderValue={(selected) => {
                                if (!translatedlanguages?.length > 0) return <span className='text-blue-gray-400'>There are no translations available for this bank</span>
                                if (!selected) return <span className='text-blue-gray-400'>Add a Language</span>
                                return selected.language;
                            }}
                            className={!translatedlanguages?.length > 0 && `bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field`}
                            MenuProps={{
                                className: "cm-select-modal"
                            }}
                        >
                            {translatedlanguages?.map(el => <MenuItem value={el.value} key={el.value}> {el.language} </MenuItem>)}
                        </Select>
                        {translatedlanguages?.length > 0 && <div className='mt-2 d-flex align-items-center user-select-none'>
                            <input type="checkbox" name={t("question_in_original_language")} id={t("question_in_original_language")} style={{transform: 'scale(0.9)'}} checked={downloadBank.isOriginalLanguage} onChange={() => handleChange('isOriginalLanguage')} />
                            <label htmlFor={t("question_in_original_language")} className='ps-2 cm-xs-txt fw-medium'>{t("question_in_original_language")}</label>
                        </div>}
                    </div>
                }
                {errorObj.canDownlaod?.status && <span className='cm-xs-txt text-danger fw-medium pt-2'>{errorObj.canDownlaod.msg}</span>}
                <div className='mb-3 mt-3 d-flex align-items-center user-select-none'>
                    <input type="checkbox" name={t("remove_HTML_tags")} id={t("remove_HTML_tags")} className="transformed-checkBox" checked={downloadBank.removeHTML} onChange={() => handleChange('removeHTML')} />
                    <label htmlFor={t("remove_HTML_tags")} className='ps-2 cm-sm-txt fw-medium'>{t("remove_html_tags")}</label>
                </div>
                <div className='mb-3 d-flex align-items-center user-select-none'>
                    <input type="checkbox" name={t("download_question_flags")} id={t("download_question_flags")} className="transformed-checkBox" checked={downloadBank.isFlag} onChange={() => handleChange('isFlags')} />
                    <label htmlFor={t("download_question_flags")} className='ps-2 cm-sm-txt fw-medium'>{t("download_question_flags")}</label>
                </div>
                <div className="alert">
                    <label className="cm-xs-txt fw-medium">
                        In case no translation language is selected/available, the question(s) will be downloaded in their original language.
                    </label>
                </div>
            </div>

            <div className="cm-form-btn-group py-2  cm-create-modal-form-footer mt-2  d-flex align-items-center justify-content-end">
                <button onClick={handledownloadTranslateBank} className={`cm-icon-btn position-relative btn btn-primary bg-action-blue cm-sm-txt cm-btn cm-prim-btn d-flex align-items-center ${downloadBank.isLoading ? 'cm-disable-btn' : ''}`}>
                    <div>
                        <FontAwesomeIcon icon={regular('arrow-alt-down')} />
                        <span className=" ms-2 text-white">Download</span>
                    </div >
                </button>
            </div>
        </div>
    )
}
export default DownloadBankFom;