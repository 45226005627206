import { Collapse } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from "react";
import './LangResultItem.styles.css';

const LangResultItem = ({ data, handleCorrection, handleIgnoreWords, t }) => {
    const [expanded, setExpanded] = React.useState(false)

    const toggleAccordian = () => setExpanded(!expanded);

    const handleSuggestion = (word) => {
        handleCorrection({
            ...data,
            wordSelected: word
        })
    }

    const getErrType = (type) => {
        if (type === 'Grammar') {
            return 'Grammatical Error';
        } else if (type === 'Possible Typo') {
            return "Spelling Error";
        } else if (type === "Upper/Lowercase") {
            return "Capitalization Error";
        } else {
            return 'Error'
        }
    }

    const handleIgnoreWordsInText = () => {
        handleIgnoreWords(data.errorInText)
    }

    return (
        <div className={`cm-lang-result-item shadow${!expanded ? '-sm' : ''} bg-white rounded mb-3`}>
            <div className={`cm-lang-result-item-header d-flex align-items-center py-3 px-2 cm-sm-txt fw-medium cm-pointer ${expanded ? 'fw-semibold' : ''}`} onClick={toggleAccordian}>
                <div className="d-flex align-items-center" style={{ width: '100%' }}>
                    <div className="cm-red-dot me-2 bg-danger" />
                    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                        <span>{!expanded ? data.errorInText + ' - ' : ''} {getErrType(data.typeOfVoilation)}</span>
                        {expanded &&
                            <button className="bg-blue-500 text-white py-1 px-2 cm-sm-txt cm-pointer border-0 rounded align-items-center" onClick={handleIgnoreWordsInText}>
                                <FontAwesomeIcon icon={regular('minus-circle')} className="text-white me-1" />
                                <span>Ignore Suggestions</span>
                            </button>
                        }
                    </div>
                </div>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                <div className="py-2 px-2 border-top border-blue-gray-100">
                    <p className="mb-2 cm-sm-txt-txt fw-semibold cm-sm-txt">Suggestions:</p>
                    <ul className="d-flex flex-wrap align-items-center">
                        {
                            data.suggestions.map((el, ind) => <li key={el + ind} onClick={() => handleSuggestion(el)} className="bg-blue-500 text-white py-1 px-2 cm-sm-txt fw-medium cm-pointer">{el}</li>)
                        }
                    </ul>
                </div>
            </Collapse>
        </div>
    )
}

export default LangResultItem;