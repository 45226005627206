import React from "react"
import "./ViewTasksToAdmin.styles.css"

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import instance from '../../utils/axios';
import { getApiUrl } from '../../utils/apiUrls';
import { appError } from '../../redux/actions/error.action';
import { useDispatch, useSelector } from 'react-redux';
import { getDateAndTimeInFormat } from '../../utils/moments'
import ProgressControl from '../../components/status-control/ProgressControl.component';
import { ReactComponent as EmptyIcon } from '../../assets/images/home/empty-data.svg';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import CreateOrEditTaskModal from '../create-edit-task/CreateOrEditTaskModal.container';
import CreateOrEditAllTaskModalContainer from "../create-edit-all-task/CreateOrEditAllTaskModal.container";
import AnimationDataLoader from "../../components/Loaders/animationData";

const TaskListItem = () => {
    const dispatch = useDispatch();
    const [allData, setAllData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [deleteInProgress, setDeleteInProgress] = React.useState(false);
    const [editModalStatus, setModalStatus] = React.useState({
        status: false,
        task: null
    });

    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        taskId: null,
    });

    const handleDialogToggle = (taskId) => {
        setDialogStatus({
            status: !dialogStatus.status,
            taskId: !!taskId ? taskId : null,
        });
    }

    const handleEditModalToggle = (task) => {
        setModalStatus({
            status: !editModalStatus.status,
            task: !!task ? task : null,
        });
    }

    const handleTaskStatus = () => {
        getAllTasks();
    }

    const getAllTasks = () => {
        setAllData([]);
        setIsLoading(true);
        instance.get(`${getApiUrl('getAllTask')}`)
            .then(function (response) {
                if (response.data) {
                    setAllData(response.data);
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                appError('HomePage', error, dispatch);
            });
    }

    const dialogActionFn = () => {
        setDeleteInProgress(true);
        deleteTask(dialogStatus.taskId);
    }

    const deleteTask = async (taskId) => {
        try {
            const res = await instance.get(`${getApiUrl('deleteTask')}?taskId=${taskId}`);
            if (!!res.data) {
                getAllTasks();
                dispatch(openSnackBar({
                    msg: "Successfully deleted a request",
                    type: 'success'
                }))
            }
        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: errObj.statusText,
                type: 'error'
            }))
        } finally {
            setDialogStatus({
                status: false,
                taskId: null,
            })
            setDeleteInProgress(false);
        }
    }

    React.useEffect(() => {
        getAllTasks();
    }, [])

    return (
        <><div>
            {
                !isLoading ?
                    <>
                        <div className="cm-candidate-report-list-container mt-4">
                            <>
                                <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50 text-center">
                                    <div className='col-sm-1'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Request Id</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Topic</p>
                                    </div>
                                    <div className='col-sm-1'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Question Count</p>
                                    </div>
                                    <div className='col-sm-1'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Level</p>
                                    </div>
                                    <div className='col-sm-1'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Assigned By</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Assigned To</p>
                                    </div>
                                    <div className='col-sm-1'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Deadline</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Status</p>
                                    </div>
                                    <div className="col-sm-1">
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Action</p>
                                    </div>

                                </div>
                                <div className="cm-task-list-item">
                                    {allData.length !== 0 && <>{allData?.map((task, index) => (
                                        <div className="cm-candidate-report-list-content bg-white text-center" key={index}>
                                            <div className="cm-candidate-report-item d-flex align-items-center text-center">
                                                <div className='col-sm-1 '>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{task.taskId}</p>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800 cm-truncate-1' title={task.topicName}>{task.topicName}</p>
                                                </div>
                                                <div className='col-sm-1'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{task.questionCount}</p>
                                                </div>
                                                <div className='col-sm-1'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{task.difficultyLevel}</p>
                                                </div>
                                                <div className='col-sm-1'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{task.creatorName}</p>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{task.assignedName}</p>
                                                </div>
                                                <div className='col-sm-1'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>
                                                        {getDateAndTimeInFormat(task.targetDate)}
                                                    </p>
                                                </div>
                                                <div className='col-sm-2'>
                                                    <p className='my-0 cm-sm-txt fw-medium text-blue-800'><ProgressControl value={task.status} /></p>
                                                </div>
                                                <div className="col-sm-1">
                                                    <ul className='cm-quest-lib-actions d-flex align-items-center justify-content-center p-0 m-0'>
                                                        <li>
                                                            <CustomToolTip title={task.status === "YetToStart" ? 'Edit' : "Edit is disabled"}>
                                                                <span
                                                                    className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${task.status !== "YetToStart" ? 'cm-sidebar-btn-disabled' : ''}`} onClick={() => task.status === "YetToStart" ? handleEditModalToggle(task) : null}>
                                                                    <FontAwesomeIcon icon={regular("pen-to-square")} />
                                                                </span>
                                                            </CustomToolTip>
                                                        </li>
                                                        <li className="d-flex align-items-center">
                                                            <CustomToolTip title={task.status === "YetToStart" ? 'Delete' : "Delete is disabled"}>
                                                                <span className={`text-action-blue cm-sm-txt fw-medium px-3 cm-pointer d-flex align-items-center ${task.status !== "YetToStart" ? 'cm-sidebar-btn-disabled' : ''}`} onClick={() => task.status === "YetToStart" ? handleDialogToggle(task.taskId) : null}>
                                                                    <FontAwesomeIcon icon={regular("trash")} /></span>
                                                            </CustomToolTip>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>))}
                                    </>}
                                    {allData.length == 0 && <div className='cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center'>
                                        <div className='py-4 text-center px-4'>
                                            <EmptyIcon />
                                            <p className='text-blue-700 mt-3 mb-0 fw-semibold'>Nothing to see!</p>
                                        </div>
                                    </div>}
                                </div>
                            </>
                        </div>
                    </>
                    : <div className="d-flex justify-content-center mt-3">
                        <AnimationDataLoader />
                    </div>
            }
            <CustomDialog
                dialogHeading="Delete Task"
                cancelBtnLabel="No, Don't delete"
                actionBtnLabel={!deleteInProgress ? <span>Delete Task</span> : <span>Deleting</span>}
                actionBtnBg="bg-danger"
                cancelFn={handleDialogToggle}
                actionFn={dialogActionFn}
                dialogStatus={dialogStatus.status}
                disableButton={deleteInProgress}>
                <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to delete the selected Task?</p>
            </CustomDialog>
            {editModalStatus.task !== null && <CreateOrEditAllTaskModalContainer modalStatus={editModalStatus.status} handleModalToggle={handleEditModalToggle} taskCreated={true} handleTaskStatus={handleTaskStatus} actionType='edit' taskData={editModalStatus.task} />}
        </div>
        </>
    );
}

export default TaskListItem;