
import React from 'react'

import instance from '../../utils/axios';
// import Header from '../header/header';
import { useDispatch, useSelector } from 'react-redux';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import '../create-task/CreateTask.styles.css';
import AddTagModal from '../../containers/add-tags/AddTagModal.container';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from '../../utils/apiUrls';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { ReactComponent as EmptyIcon } from '../../assets/images/home/empty-data.svg';
import { appError } from '../../redux/actions/error.action';
import ErrorController from '../error/ErrorController.page';
import AnimationDataLoader from '../../components/Loaders/animationData';

export default function QuestionTags() {
    const [allTags, setAllTags] = React.useState([]);
    const [tagName, setTagName] = React.useState("");
    const [addTag, setAddTag] = React.useState(false);
    //const [editTag, setEditTag] = React.useState(false);
    const [editInput, setEditInput] = React.useState();
    const [addTagInput, setAddTagInput] = React.useState("");
    const [tagId, setTagId] = React.useState(0);
    const [editError, setEditError] = React.useState("");
    const [openAddTag, setOpenAddTag] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [actionType, setActionType] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const chkError = useSelector((state) => state.error);


    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [anchorElTag, setAnchorElTag] = React.useState({
        anchor: null,
        tag: null
    });

    const [editTag, setEditTag] = React.useState(null);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        tagId: null,
    });

    const handleDialogToggle = (tagId) => {
        setDialogStatus({
            status: !dialogStatus.status,
            tagId: !!tagId ? tagId : null,
        });

        if (anchorElTag.anchor) setAnchorElTag({ anchor: null, user: null })

    }

    const dialogActionFn = () => {
        deleteTag(dialogStatus.tagId);
        setDialogStatus({
            status: false,
            tagId: null,
        })
    }

    const deleteTag = async (tagId) => {
        try {
            const res = await instance.get(`${getApiUrl('deleteTag')}?id=${tagId}`);
            if (!!res.data) getAllTags();
        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))

        }
    }

    const [modalStatus, setModalStatus] = React.useState(false);

    const handleModalToggle = (type, tag) => {
        if (type === 'edit') {
            // console.log('user here', user);
            setEditTag(tag);
            setModalStatus(!modalStatus);
            return;
        }
        setModalStatus(!modalStatus);
        setEditTag(null);
    }


    const getAllTags = () => {
        instance.get(`${getApiUrl('getAllTags')}`)
            .then(function (response) {
                if (response.data) {
                    //const filteredArr = response.data.filter(el => !el.active);
                    setAllTags(response.data);
                    setIsLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                appError('QuestionTags', error, dispatch);
            });
    }

    React.useEffect(() => {
        getAllTags();
    }, [])

    const handleAddTags = () => {
        setAddTag(true);
    }

    const handleOpen = (id) => {
        setTagId(id);
        setOpen(true);

    }
    const handleClose = () => setOpen(false);

    const handleCloseAddTag = () => setOpenAddTag(false);

    const handleSubmitTag = () => {
        setAddTag(false);
        setOpenAddTag(false);
        const addTagData = { "tagName": addTagInput };
        instance.post(`${getApiUrl('addTag')}`, addTagData)
            .then(function (response) {
                if (response.data) {
                    getAllTags();
                }
            })
            .catch(function (error) {
            });
    }

    // const handleSubmitEditInput = () => {
    //     const editTagData = { "tagName": editInput };
    //     instance.post(`${process.env.REACT_APP_BASE_URL}/cms/v1/editTag?id=${tagId}`, editTagData)
    //         .then(function (response) {
    //             if (response.data.status) {
    //                 setOpen(false);
    //                 getAllTags();
    //             }
    //             else {
    //                 setEditError(response.data.message);
    //             }
    //         })
    //         .catch(function (error) {

    //         });
    // }


    // const deleteTag = () => {
    //     if (tagId !== 0) {
    //         instance.get(`${process.env.REACT_APP_BASE_URL}/cms/v1/deleteTag?id=${tagId}`)
    //             .then(function (response) {
    //                 if (response.data.status) {
    //                     getAllTags();
    //                 }
    //             })
    //             .catch(function (error) {
    //             });
    //     }
    // }

    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Question Tags",
            link: '/'
        }
    ]

    const handleError = () => chkError.location === 'QuestionTags'

    return (
        handleError() ? <ErrorController data={chkError} /> : <>
            <div className="body">
                <Breadcrumb data={breadcrumbData} />
                {!isLoading && <div className="cm-candidate-report-list-container">
                    <>
                        <div className='pt-4 px-5 cm-candidate-search-wrapper d-flex align-items-center justify-content-between flex-wrap mb-3'>
                            <div className="cm-left-col">
                                <h5 className='my-0 text-blue-800 cm-mmc-font'>Available Tags</h5>
                            </div>
                        </div>

                        <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50">
                            <div className='col-sm-2'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Tag Id</p>
                            </div>
                            <div className='col-sm-2'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Tag Name</p>
                            </div>
                            <div className='col-sm-2'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Created By</p>
                            </div>
                            <div className='col-sm-2'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Created On</p>
                            </div>
                            <div className='col-sm-2'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Question Count</p>
                            </div>
                            <div className='col-sm-2'>
                                <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Action</p>
                            </div>
                        </div>
                        {allTags.length !== 0 && <>{allTags.map((tag, index) => (
                            <div key={index} className="cm-candidate-report-list-content bg-white">
                                <div className="cm-candidate-report-item d-flex align-items-center">
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{tag.tagId}</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{tag.tagName}</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{tag.createdBy}</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{tag.createdOn}</p>
                                    </div>
                                    <div className='col-sm-2'>
                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{tag.questionCount}</p>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className='col-sm-4'>
                                            <ul className='cm-quest-lib-actions d-flex align-items-center justify-content-center p-0 m-0'>
                                                <li>
                                                    <CustomToolTip title={'Edit'}>
                                                        <span onClick={() => handleModalToggle('edit', tag)} className='text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer'>
                                                            <FontAwesomeIcon icon={regular("pen-to-square")} />
                                                        </span>
                                                    </CustomToolTip>
                                                </li>
                                                <li className="d-flex align-items-center">
                                                    <CustomToolTip title={'Delete'}>
                                                        <span className='text-action-blue cm-sm-txt fw-medium px-3 cm-pointer d-flex align-items-center' onClick={() => handleDialogToggle(tag.tagId)}><FontAwesomeIcon icon={regular("trash")} /></span>
                                                    </CustomToolTip>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <div className="d-flex text-center">
                                            <div className="button" onClick={() => {
                                                setTagName(tag.tagName);
                                                setTagId(tag.tagId);
                                                setActionType("");
                                                setEditInput(tag.tagName);
                                                setOpen(true);
                                            }}>
                                                <i className="fa fa-lg fa-pencil-square-o ms-3 me-3 mt-1" aria-hidden="true" ></i>
                                            </div>
                                            <div className="button" onClick={() => {
                                                setActionType("delete");
                                                console.log(actionType);
                                                setTagId(tag.tagId);

                                            }}>
                                                <i className="fa fa-lg fa-trash mt-1"></i>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        ))}</>}

                        {allTags.length === 0 && <div className='cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center'>
                            <div className='py-4 text-center px-4'>
                                <EmptyIcon />
                                <p className='text-blue-700 mt-3 mb-0 fw-semibold'>Nothing to see!</p>
                                <p className='text-blue-gray-500 mt-2 mb-0 cm-sm-txt mt-2 mx-auto' style={{ maxWidth: '368px' }}>You can start by adding question tags.</p>
                            </div>
                        </div>}



                    </>

                    {/* {candReport.data.length === 0 && candReport.pageNum === 0 ? null : <CandidateReportPagination t={t} handlePagination={handlePagination} candidateData={candReport} /> } */}
                </div>}
                {isLoading &&
                    <div className="d-flex justify-content-center">
                        <AnimationDataLoader />
                    </div>
                }
                {/* {addTag && (
                    <form>
                        <div className="d-flex row mb-3" >
                            <div className="col-lg-3"></div>
                            <div className='col-lg-2'>
                                <div className="mt-3 ms-5" > Add Tag Name</div>
                            </div>
                            <div className='col-lg-4 mt-2'>
                                <div className="d-flex">
                                    <input type="text" onChange={(e) => setAddTagInput(e.target.value)} className="form-control" required />
                                    <div className="btn btn-success mt-1 ms-3" id={questionTagsCSS.submitTag} onClick={handleSubmitTag}>Submit</div>
                                </div>
                            </div>
                            <div className="col-lg-3"></div>
                        </div>
                    </form>
                )} */}

                <div className="cm-form-btn-group pt-2 d-flex align-items-center">
                    <button onClick={handleModalToggle} className={`bg-action-blue fw-medium text-white cm-pointer border mt-2`} style={{ 'marginLeft': '48%' }}>
                        <span className="text-white">Add Tag</span>
                        <FontAwesomeIcon icon={regular('angle-right')} className="ps-2" />
                    </button>
                </div>

                <AddTagModal handleModalToggle={handleModalToggle} modalStatus={modalStatus} t={t} getAllTags={getAllTags} editTag={editTag} />
                <CustomDialog
                    dialogHeading="Delete Tag"
                    cancelBtnLabel="No, Don't delete"
                    actionBtnLabel={<span>Delete Tag</span>}
                    actionBtnBg="bg-danger"
                    cancelFn={handleDialogToggle}
                    actionFn={dialogActionFn}
                    dialogStatus={dialogStatus.status}
                >
                    <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to delete the selected tag?</p>
                </CustomDialog>
            </div>
        </>
    )
}