import React from 'react';
import './Reviewertem.styles.css';
import { getInitials } from '../../utils/helperFn';
import { getDateAndTimeInFormat } from '../../utils/moments'

const ReviewTimelineItem = ({ data }) => {
    return (
        <div className={`cm-modal-task-item d-flex flex-wrap align-items-center py-3 px-4`}>
            <div className="cm-left-col">
                <div className="cm-name-avtar d-flex align-items-center justify-content-center fw-semibold">
                    {getInitials(data.firstName + " " + data.lastName)}
                </div>
            </div>
            <div className="cm-right-col ms-4">
                <p className='my-0 text-blue-800 fw-medium d-flex align-items-center'>{data.firstName + " " + data.lastName}</p>
                <span className='cm-xs-txt fw-medium text-blue-gray-300'>{getDateAndTimeInFormat(data.commentedOn, "MMMM Do YYYY [at] h:mm a")}</span>
            </div>
            <div className="cm-content mt-2">
                <p className='fw-medium text-blue-800 cm-sm-txt cm-comment-data'>{data.comment}</p>
            </div>
        </div>
    )

}

export default ReviewTimelineItem;