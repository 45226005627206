import React from 'react';
import AdminDashboardContainer from '../../containers/home/AdminDashboard.container';
import MetaComp from '../../components/meta-comp/MetaComp.component';
import './HomePage.styles.css';

const HomePage = () => {
    return <>
        <MetaComp title="Home - Mercer | Mettl"/>
        <AdminDashboardContainer />
        </>
}

export default HomePage;