import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import { getQuestionDetails } from '../../redux/actions/question.actions';
import PreviewKnowledgeBasedQuestionContainer from '../../containers/question-creation/PreviewKnowledgeBasedQuestion.Container';

const PreviewKnowledgeBasedQuestionPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const getReqId = searchParams.get('reqId');
    const getQuestid = searchParams.get('qId');
    const questData = useSelector(state => state.question);

    const breadcrumbData = React.useMemo(() => ([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Preview Question",
            link: '/'
        }
    ]), []);

    React.useEffect(() => {
        if(!!getReqId && !!getQuestid) {
            dispatch(getQuestionDetails(getQuestid, 1,true));
        }
    }, []);

    return(
        <div className='cm-cms-container'>
            <Breadcrumb data={breadcrumbData} backBtn={true} />
            <PreviewKnowledgeBasedQuestionContainer  getReqId={getReqId} getQuestid={getQuestid} />
            <Outlet />
        </div>
    )
}

export default PreviewKnowledgeBasedQuestionPage;