import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ReactComponent as Logo } from '../../assets/images/global/mercer-mettl_logo.svg';
import './Header.styles.css';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/user.actions';
// import { adjustFontSize } from '../../redux/actions/appSettings.actions';
import { getInitials } from '../../utils/helperFn';
import { handleLogOut } from '../../redux/actions/user.actions';
import DownloadReportForm from '../download-report-form/DownloadReportForm.component';
import { getMomentInstance } from '../../utils/moments'
import instance from '../../utils/axios';
import { getApiUrl } from '../../utils/apiUrls';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { apiErrorHandler } from '../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyIcon } from '../../assets/images/home/empty-data.svg';


const textResizeOptions = [
    {
        label: 'A+',
        value: 'large'
    },
    {
        label: 'A',
        value: 'normal'
    },
    {
        label: 'A-',
        value: 'small'
    },
];

const notifArr = [
    // {
    //     id: 1,
    //     type: 'comment',
    //     msg: 'Jon Doe (Reviewer) added comment on (Question Title)',
    //     createdAt: 1676901164,
    // },
    // {
    //     id: 2,
    //     type: 'reviewer',
    //     msg: 'Jon Doe (Reviewer) approved/rejected (Reviewer Status) the (Question Title)',
    //     createdAt: 1676901164,
    // },
    // {
    //     id: 3,
    //     type: 'sme',
    //     msg: 'Jean Doe(SME) has sent {Question Title} for review',
    //     createdAt: 1676901164,
    // }
]

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();
    const user = useSelector(state => state.user);
    // const textResizeState = useSelector(state => state.appSettings.textSize);
    const [notifData, setNotifData] = React.useState(null);
    const [reportDialogStatus, setReportDialogStatus] = React.useState(false);

    const handleReportDialogToggle = (e) => {
        e.preventDefault();
        setReportDialogStatus(!reportDialogStatus);
    }

    const handleLogoutFn = () => {
        dispatch(handleLogOut());
        handleCloseUserMenu();
    }

    const pages = [
        {
            label: t("view_profile"),
            iconClass: regular('user'),
            clickFn: () => {
                navigate('/profile');
                handleCloseUserMenu();
            }
        },
        {
            label: t('logout'),
            iconClass: regular('right-from-bracket'),
            clickFn: handleLogoutFn
        }];

    // const [user, setUser] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [anchorElNotif, setAnchorElNotif] = React.useState(null);
    const [anchorElResizer, setAnchorElResizer] = React.useState({
        el: null,
        active: 'normal'
    });

    //Add class to body for Text Resizing
    // React.useEffect(() => {
    //     if(textResizeState === 'large') {
    //         document.body.classList.add('txt-size-lg');
    //         document.body.classList.remove('txt-size-sm');
    //     } else if(textResizeState === 'small') {
    //         document.body.classList.add('txt-size-sm');
    //         document.body.classList.remove('txt-size-lg');
    //     } else {
    //         document.body.classList.remove('txt-size-sm');
    //         document.body.classList.remove('txt-size-lg');
    //     }

    // }, [textResizeState])

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenNotifMenu = (event) => {
        getAllNotif();
        setAnchorElNotif(event.currentTarget);
    };

    const handleCloseNotifMenu = () => {
        setNotifData(null);
        setAnchorElNotif(null);
    };

    const handleOpenResizer = (event) => {
        setAnchorElResizer({
            ...anchorElResizer,
            el: event.currentTarget
        });
    };

    const handleCloseResizer = () => {
        setAnchorElResizer({
            ...anchorElResizer,
            el: null
        });
    };

    // const loadTextResizeOptions = () => {
    //     return textResizeOptions.map(el => (
    //     <li className={`text-center cm-pointer ${el.value === textResizeState ? 'bg-action-1 text-white border-action-1' : 'text-blue-800'}`} key={el.value} onClick={() => dispatch(adjustFontSize(el.value))}>
    //         <span>{el.label}</span>
    //     </li>))
    // }

    const handleNotifClick = async (id, taskId) => {
        try {
            const res = await instance.get(`${getApiUrl("markNotificationAsDisplayed")}/${id}`);

            if (res.data) {
                handleCloseNotifMenu();
                navigate(`/question-library/${taskId}`)
            }
        } catch (error) {

            console.log('handleNotifClick err', error);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const getIcon = (notifType) => {
        if (notifType === "ADD_COMMENT") {
            return regular("message")
        } else if (notifType === "QUESTION_REVIEWED") {
            return regular("user-magnifying-glass")
        } else {
            return regular("user-tie")
        }
    }

    const getMsg = (notif) => {
        switch (notif.eventType) {
            case 'ADD_COMMENT': return `SME(${notif.userName}) has added a comment!`;
            case 'QUESTION_CREATED': return `SME(${notif.userName}) has created a question!`;
            case 'QUESTION_REVIEWED': return `REVIEWER(${notif.userName}) has reviewed the question!`;
            default: return 'Notification'
        }
    }

    const loadNotifications = () => {
        return notifData.map(el => {
            return (
                <MenuItem key={el.id} onClick={() => handleNotifClick(el.id, el.taskId)} className="cm-notif-item d-flex">
                    <span className='cm-notif-type bg-blue-gray-700 d-flex align-items-center justify-content-center'>
                        <FontAwesomeIcon icon={getIcon(el.eventType)} className="text-white cm-sm-txt" />
                    </span>
                    <div className='ps-3 cm-content'>
                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{getMsg(el)}</p>
                        <span className='text-gray-700 cm-xs-txt'>{getMomentInstance(el.createdOn).fromNow()}</span>
                    </div>
                </MenuItem>
            )
        })
    }

    const getAllNotif = async () => {
        try {
            const res = await instance.get(getApiUrl('getAllNotifications'));
            setNotifData(res.data);

        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
            console.log('Get all notif err', error);
        }
    }

    return (
        <header className={`cm-header-container bg-primary d-flex justify-content-between align-items-center py-3 px-4 ${user !== null && user?.isMfaAuthenticated ? 'cm-user-header' : ''}`}>
            <div className="cm-header-logo px-3 d-flex align-items-center">
                <Link to="/">
                    <Logo className='d-block' />
                </Link>
                {
                    user && user.isMfaAuthenticated ?
                        <>
                            <div className='cm-header-logo-separator bg-blue-gray-700 my-0 ms-4 d-block' />
                            <ul className="navbar-nav flex-row">
                                <li>
                                    <NavLink
                                        to="/"
                                        style={({ isActive }) =>
                                            isActive ? { backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)" } : { color: "var(--bs-white)" }
                                        }
                                        className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center d-block"
                                    >
                                        {t('home')}
                                    </NavLink>
                                </li>
                                {user?.role !== "SME" && <li><NavLink
                                    to="/all-users"
                                    style={({ isActive }) =>
                                        isActive ? { backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)" } : { color: "var(--bs-white)" }
                                    }
                                    className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center d-block"
                                >
                                    {t('all_users')}
                                </NavLink></li>}
                                {user?.role !== "SME" && <li><NavLink
                                    to="/question-tags"
                                    style={({ isActive }) =>
                                        isActive ? { backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)" } : { color: "var(--bs-white)" }
                                    }
                                    className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center d-block"
                                >
                                    {t('question_tags')}
                                </NavLink></li>}
                                {user?.role !== "SME" && <li><NavLink
                                    to="/skill-dashboard"
                                    style={({ isActive }) =>
                                        isActive ? { backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)" } : { color: "var(--bs-white)" }
                                    }
                                    className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center d-block"
                                >
                                    {t('skill_dashboard')}
                                </NavLink></li>}
                                {user?.role !== "SME" && <li><NavLink
                                    to="/taxonomy-management"
                                    style={({ isActive }) =>
                                        isActive ? { backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)" } : { color: "var(--bs-white)" }
                                    }
                                    className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center d-block"
                                >
                                    {t('taxonomy-management')}
                                </NavLink></li>}
                                {<li><NavLink
                                    to="/my-bank"
                                    style={({ isActive }) =>
                                        isActive ? { backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)" } : { color: "var(--bs-white)" }
                                    }
                                    className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center d-block"
                                >
                                    {t('my-bank')}
                                </NavLink></li>}
                                {user?.role === "ADMIN" && <li className='cm-menu-has-children'>
                                    <a
                                        href="#"
                                        style={['/download-question-bank', '/hierarchy'].includes(location.pathname) ? {
                                            backgroundColor: "var(--bs-blue-800)", color: "var(--bs-white)"
                                        } : { color: "var(--bs-white)" }
                                        }
                                        className="py-2 px-1 cm-sm-txt fw-semibold cm-btn-radius ms-3 text-center text-white d-block"
                                    >
                                        {t('tools')}
                                    </a>
                                    <ul className='position-absolute bg-white'>
                                        <li>
                                            <NavLink
                                                to="/download-question-bank"
                                                style={({ isActive }) =>
                                                    isActive ? { color: "var(--bs-action-blue) !important" } : { color: "var(--bs-blue-800)" }
                                                }
                                                className="py-10 px-2 cm-sm-txt fw-medium cm-btn-radius d-block"
                                            >
                                                {t('download_question_bank')}
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                to="/hierarchy"
                                                style={({ isActive }) =>
                                                    isActive ? { color: "var(--bs-action-blue) !important" } : { color: "var(--bs-blue-800)" }
                                                }
                                                className="py-10 px-2 cm-sm-txt fw-medium cm-btn-radius d-block"
                                            >
                                                Hierarchy
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>}
                            </ul>
                        </>
                        : null
                }
            </div>
            {user !== null && user.isMfaAuthenticated && <div className="cm-header-op text-white d-flex align-items-center px-3">
                {/* <span className='cm-txt-resizer bg-blue-800 d-flex align-items-center justify-content-center cm-pointer' onClick={handleOpenResizer}>
                    <FontAwesomeIcon icon={regular('font')} />
                </span>
                <Menu
                    sx={{ mt: '0', ml: '-36px' }}
                    id="txt-resizer"
                    anchorEl={anchorElResizer.el}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElResizer.el)}
                    onClose={handleCloseResizer}
                    MenuListProps={{ disablePadding: true }}
                    elevation={0}
                    PaperProps={{  
                        style: {  
                            width: 174,  
                        },
                    }}
                >
                    <li><p className='my-0 cm-xs-txt text-blue-gray-700 fw-semibold text-uppercase'>{t('adjust_text_size')}</p></li>
                    {loadTextResizeOptions()}
                </Menu> */}

                <span className='d-flex align-items-center justify-content-center cm-pointer me-1' onClick={handleOpenNotifMenu}>
                    <FontAwesomeIcon icon={regular('bell')} />
                </span>
                <Menu
                    sx={{ mt: '32px' }}
                    id="notif-menu"
                    anchorEl={anchorElNotif}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElNotif)}
                    onClose={handleCloseNotifMenu}
                    MenuListProps={{ disablePadding: true }}
                    elevation={0}
                    PaperProps={{
                        style: {
                            width: 400,
                        },
                    }}
                >
                    {!!notifData ? loadNotifications() : <div className='cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center border-0'>
                        <div className='py-4 text-center px-4'>
                            <EmptyIcon />
                            <p className='text-blue-700 mt-3 mb-0 fw-semibold'>There are no notifications to see.</p>
                        </div>
                    </div>}
                </Menu>

                {/* {user !== null && <a href="https://support.mettl.com" target="_blank" rel='noreferrer' className="me-1 cm-pointer text-white">
                    <FontAwesomeIcon icon={regular('circle-question')} />
                </a>} */}
                {
                    user ?
                        <>
                            <div className='cm-header-logo-separator bg-blue-gray-700 my-0 ms-4 d-block' />
                            <div className="cm-user-avatar-wrap ms-3 cm-pointer d-flex align-items-center" onClick={handleOpenUserMenu}>
                                <div className="d-flex me-2">
                                    <p className='cm-xs-txt my-0 fw-semibold cm-pointer text-uppercase'>{user.firstName}</p>
                                    <p className='cm-xs-txt my-0 fw-semibold cm-pointer text-uppercase ms-1'>{!!user.lastName ? user.lastName : ''}</p>
                                </div>
                                <div className="cm-user-avatar bg-purple rounded-circle d-flex align-items-center justify-content-center d-flex">
                                    <p className='cm-xs-txt my-0 fw-semibold cm-pointer text-uppercase'>{getInitials(`${user.firstName} ${!!user.lastName ? user.lastName : ''}`)}</p>
                                </div>
                                <FontAwesomeIcon icon={solid('caret-down')} className="cm-xs-txt ms-2" />
                            </div>
                            <Menu
                                sx={{ mt: '58px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                                MenuListProps={{ disablePadding: true }}
                                elevation={0}
                                PaperProps={{
                                    style: {
                                        width: 220,
                                    },
                                }}
                            >
                                <li><p className='p-2 my-0 cm-xs-txt text-blue-gray-700 fw-semibold text-uppercase'>{t('account_actions')}</p></li>
                                {pages.map((page) => (
                                    <MenuItem key={page.label} onClick={!!page.clickFn ? page.clickFn : handleCloseUserMenu}>
                                        <FontAwesomeIcon icon={page.iconClass} className="text-blue-gray-500" />
                                        <span className='ms-3 cm-sm-txt fw-medium text-blue-800'>{page.label}</span>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                        : null
                }

            </div>}
        </header>
    )
}

export default Header;
