import { useState, useEffect } from "react";

const useWindowSize = () => {
  const [data, setData] = useState({
    isMobile: window.innerWidth < 768,
    screenWidth: window.innerWidth
  });

  return data;
};

export default useWindowSize;