import * as React from "react";
import { ReactComponent as EmptyIcon } from "../../assets/images/home/empty-data.svg";

const NothingToSee = () => {
  return (
    <div className="container">
      <div className="nothingToSee d-flex align-items-center justify-content-center pb-5">
        <EmptyIcon />
        <p className="text-blue-700 mt-3 fw-semibold">Nothing to see!</p>
      </div>
    </div>
  );
};
export default NothingToSee;
