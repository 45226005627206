import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, } from 'react-redux';
import { Outlet, } from 'react-router-dom';
import Breadcrumb from '../components/breadcrumb/Breadcrumb.component';
import LanguageToolModal from '../containers/question-library/LanguageToolModal.container';

const TestPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [langModal, setLangModal] = React.useState({
        status: false,
        qId: 1,
        reqId: 1
    })

    const breadcrumbData = React.useMemo(() => ([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Dashboard",
            link: '/'
        }
    ]), []);

    const handleLangModalToggle = () => {
        setLangModal({
            status: !langModal.status,
            qId: !langModal.status ? 1 : null,
            reqId: !langModal.status ? 1 : null
        })
    }

    // console.log('langModal', langModal)

    return(
        <div className='cm-cms-container'>
            <Breadcrumb data={breadcrumbData} />
            
            <button className='btn btn-primary' onClick={handleLangModalToggle}>Open</button>
            <LanguageToolModal t={t} modalStatus={langModal.status} questId={langModal.qId} reqId={langModal.reqId} handleModalToggle={handleLangModalToggle} />
            <Outlet />
        </div>
    )
}

export default TestPage;