import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CustomToolTip from '../custom-tooltip/CustomTooltip.component';
import CustomButtonLoader from '../global/CustomButtonLoader.component';

const ChangeExpiredPasswordForm = ({ t, user }) => {
    const initial_val = {
        password: {
            value: '',
            isRequired: true,
            msg: null
        },
        passwordVisible: false,
        newPassword: {
            value: '',
            isRequired: true,
            msg: null
        },
        newPasswordVisible: false,
        passwordConfirm: {
            value: '',
            isRequired: true,
            msg: null
        },
        passwordConfirmVisible: false,
        validOptions: {
            requiredFields: ['password', 'newPassword', 'passwordConfirm'],
            errorFields: [],
        },
    }


    const [isLoading, setIsLoading] = React.useState(false);

    const [formVal, setFormVal] = React.useState(initial_val);

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value,
            }
        })
    }
    const handleValidation = () => {
    }
    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleValidation();
    }
    const handleCancel = () => {

    }
    const handlePassVisiblity = (fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: !formVal[fieldName]
        })
    }

    return (
        <form onSubmit={handleFormSubmit} className="cm-profile-form cm-reset-pass-form mt-4">

            <div className="row">
                <div className="form-group mb-3">
                    <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("current_password")}</label>
                    <div className="cm-start-icon position-relative">
                        <FontAwesomeIcon icon={regular('key')} className="text-blue-gray-700 position-absolute" />
                        <input type={formVal.passwordVisible ? 'text' : 'password'} className={`form-control text-blue-800 fw-medium`} id="password" value={formVal.password.value} placeholder={t("password_placeholder")} onChange={(e) => handleFieldChange(e, 'password')} />
                        <div className='cm-mult-icon-wrap d-flex'>
                            <FontAwesomeIcon icon={formVal.passwordVisible ? regular('eye-slash') : regular('eye')} className={`form-text text-blue-gray-500 cm-pass-visiblility-changer cm-pointer`} onClick={() => handlePassVisiblity('passwordVisible')} />
                            <CustomToolTip
                                title={
                                    <>
                                        <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                        <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                            <li>{t("pass_point_1")}</li>
                                            <li>{t("pass_point_2")}</li>
                                            <li>{t("pass_point_3")}</li>
                                            <li>{t("pass_point_4")}</li>
                                        </ul>
                                    </>
                                }
                            >
                                <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 cm-pass-info-icon`} />
                            </CustomToolTip>
                        </div>
                    </div>
                    {formVal.password.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.password.msg}</span>}
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3">
                    <label htmlFor="newPassword" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("new_password")}</label>
                    <div className="cm-start-icon position-relative">
                        <FontAwesomeIcon icon={regular('key')} className="text-blue-gray-700 position-absolute" />
                        <input type={formVal.newPasswordVisible ? 'text' : 'password'} className={`form-control text-blue-800 fw-medium`} id="newPassword" value={formVal.newPassword.value} placeholder={t("password_placeholder")} onChange={(e) => handleFieldChange(e, 'newPassword')} />
                        <div className='cm-mult-icon-wrap d-flex'>
                            <FontAwesomeIcon icon={formVal.newPasswordVisible ? regular('paste') : regular('eye')} className={`form-text text-blue-gray-500 cm-pass-visiblility-changer cm-pointer`} onClick={() => handlePassVisiblity('newPasswordVisible')} />
                            <CustomToolTip
                                title={
                                    <>
                                        <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                        <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                            <li>{t("pass_point_1")}</li>
                                            <li>{t("pass_point_2")}</li>
                                            <li>{t("pass_point_3")}</li>
                                            <li>{t("pass_point_4")}</li>
                                        </ul>
                                    </>
                                }
                            >
                                <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 cm-pass-info-icon`} />
                            </CustomToolTip>
                        </div>
                    </div>
                    {formVal.newPassword.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.newPassword.msg}</span>}
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3">
                    <label htmlFor="password-confirm" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("reenter_new_password")}</label>
                    <div className="cm-start-icon position-relative">
                        <FontAwesomeIcon icon={regular('key')} className="text-blue-gray-700 position-absolute" />
                        <input type={formVal.passwordConfirmVisible ? 'text' : 'password'} className="form-control text-blue-800 fw-medium" id="password-confirm" value={formVal.passwordConfirm.value} placeholder={t("password_placeholder")} onChange={(e) => handleFieldChange(e, 'passwordConfirm')} />
                        <div className='cm-mult-icon-wrap d-flex'>
                            <FontAwesomeIcon icon={formVal.passwordConfirmVisible ? regular('eye-slash') : regular('eye')} className={`form-text text-blue-gray-500 cm-pass-visiblility-changer cm-pointer`} onClick={() => handlePassVisiblity('passwordConfirmVisible')} />
                            <CustomToolTip
                                title={
                                    <>
                                        <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                        <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                            <li>{t("pass_point_1")}</li>
                                            <li>{t("pass_point_2")}</li>
                                            <li>{t("pass_point_3")}</li>
                                            <li>{t("pass_point_4")}</li>
                                        </ul>
                                    </>
                                }
                            >
                                <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 cm-pass-info-icon`} />
                            </CustomToolTip>
                        </div>
                    </div>
                    {formVal.passwordConfirm.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.passwordConfirm.msg}</span>}
                </div>
            </div>
            <div className="row">
                <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                    <CustomButtonLoader
                        showLoadingState ={isLoading}
                        colorTheme= "blue"
                        icon={regular('angle-right')}
                        reverseIconDirection={false}
                        buttonLabel={t("save_changes")}
                        buttonStyle={"px-12 py-10 cm-mar-left-icon"}
                        handleOnClick={handleFormSubmit}
                    />

                    <span className="btn btn-primary cm-sm-txt cm-btn cm-trans-btn ms-3 text-blue-800" onClick={handleCancel}>{t("cancel")}</span>
                </div>
            </div>
        </form >
    )

}

export default ChangeExpiredPasswordForm;