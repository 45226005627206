// import { useTranslation } from 'react-i18next';

const ProgressControl = ({value}) => {
    // const { t } = useTranslation();

    const progressObj = {
        "yettostart": {
            // value: t('yet_to_start'),
            value: "Yet to Start",
            activeClass: 'cm-yet-to-start'
        },
        "inprogress": {
            // value: t('in_progress'),
            value: "In Progress",
            activeClass: 'cm-in-progress'
        },
        "completed": {
            // value: t('completed'),
            value: "Completed",
            activeClass: 'cm-completed'
        },
        "rejected": {
            value: "Rejected",
            activeClass: 'cm-expired'
        },
        "sentforreview":
        {
            value:"Sent For Review",
            activeClass:'cm-yet-to-start'
        },
        "approved": {
            // value: t('completed'),
            value: "Approved",
            activeClass: 'cm-completed'
        },
        "accepted": {
            // value: t('completed'),
            value: "Accepted",
            activeClass: 'cm-completed'
        },
        "notapplicable":
        {
          value:"N/A",
          activeClass:'cm-yet-to-start'
        },
        "plagiarised":
        {
                value:"Plag",
                activeClass:'cm-expired'
              
        },
        "nonplagiarised":
        {
                value:"Non-Plag",
                activeClass:'cm-completed'
              
        },
        "readyforadmin":
        {
            value:"Ready For Admin",
                activeClass:'cm-completed'

        },

        "default": {
            value,
            activeClass: 'cm-yet-to-start'
        }
        // "active": {
        //     value: t('active'),
        //     activeClass: 'cm-active'
        // },
        // "draft": {
        //     value: t('draft'),
        //     activeClass: 'cm-draft'
        // },
        // "completed": {
        //     value: t('completed'),
        //     activeClass: 'cm-draft'
        // },
    }

    return !!progressObj[value.toLowerCase()] ? <span className={`cm-candidate-pill cm-sm-txt fw-medium ${progressObj[value.toLowerCase()].activeClass}`}>{progressObj[value.toLowerCase()].value}</span> : <span className={`cm-candidate-pill cm-sm-txt fw-medium ${progressObj["default"].activeClass}`}>{progressObj["default"].value}</span>;
}

export default ProgressControl;