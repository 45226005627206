import React from 'react';
import LoginForm from '../../components/auth/LoginForm.component';
import authBg from '../../assets/images/auth/BG.jpg';
import footerLogo from '../../assets/images/auth/mercer-mettl-footer_logo.svg';

import './Auth.styles.css';
import { Trans } from 'react-i18next';
import MetaComp from '../../components/meta-comp/MetaComp.component';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChangeExpiredPasswordForm from '../../components/auth/ChangePasswordForm';
const ChangePasswordPage = () => {
    const user = useSelector(state => state.user);
    const { t } = useTranslation();
    return(
        <main className='cm-page-container cm-auth-page-container d-flex row mx-0'>
            <MetaComp title="Login - Mercer | Mettl"/>
            <div className='col-sm-12 col-md-6 cm-md-12 cm-auth-content-wrap d-flex align-items-center'>
                <div className='cm-auth-content-inner col-12'>
                    <div className="cm-txt-wrap">
                        <h2 className='h2 text-blue-gray-700 cm-fs-24 cm-mmc-font'>
                            Welcome to
                            <span className='d-block h1 text-primary fw-bold cm-fs-40'>
                                Mettl CMS 2.0
                            </span>
                        </h2>
                        <h4 className='h4 text-blue-800'>Change Password</h4>
                    </div>

                    <hr className='cm-auth-hr bg-blue-gray-300' />

                   <ChangeExpiredPasswordForm t={t} user={user}/>

                    <div className="cm-auth-footer align-items-center d-none d-lg-flex d-md-flex">
                        <p className='my-0 pe-3 fw-medium'>Powered By: </p>
                        <img src={footerLogo} alt="Mercer Mettl" className='d-block' />
                    </div>
                </div>
            </div>
            <div className='col-sm-6 py-8 cm-auth-bg-container position-relative d-none d-lg-block d-md-block w-md-50' style={{ backgroundImage: `url('${authBg}')` }}>
                
            </div>
        </main>
    )

}

export default ChangePasswordPage;