import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link } from "react-router-dom";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import LanguageTranslateModal from "./LanguageTranslateModal";
import "./BankBox.css"
import { Menu, MenuItem } from "@mui/material";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { getInitials } from "../../utils/helperFn";



const BankBox = ({ index, data, role, handleModalToggle, handleDialogToggle, downloadQuestionBank }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const [modalData, setModalData] = React.useState({
    status: false,
    bankId: null
  })
  const handleTranslateToggle = (bankId = null) => {
    setModalData({
      status: !modalData.status,
      bankId
    });
  }

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div className="cm-bank-box-container bg-white d-flex flex-column justify-content-between">
      <div className="border-bottom border-blue-gray-100 py-2 px-3 d-flex align-items-center justify-content-between">
        <div>
          <FontAwesomeIcon icon={regular("folder-open")} className="text-blue-gray-700" />
          <CustomToolTip title={data.reviewBankName}>
            <Link to={`/my-bank/${index}`} ><span className='ps-2 text-action-blue cm-sm-txt fw-medium cm-text-truncate'>{data.reviewBankName}</span></Link>
          </CustomToolTip>
        </div>
        <CustomToolTip title="Settings">
          <FontAwesomeIcon
            icon={regular("ellipsis")}
            className="text-action-blue cm-sm-txt cm-pointer"
            onClick={handleOpenUserMenu}
          />
        </CustomToolTip>
      </div>
      <div className="cm-bank-body px-2 mt-2 mx-2">

        <p className="cm-xs-txt text-blue-gray-700 mb-1 d-flex justify-content-between align-items-center">
          <span className="pe-2">Number of questions :</span>
          <span>{data.questionCount}</span>
        </p>
        {role === "ADMIN" && <>
          <p className="cm-xs-txt text-blue-gray-700 mb-1 d-flex justify-content-between align-items-center">
            <span className="pe-2">Created By :</span>
            <span>{data.createdBy}</span>
          </p>
          <p className="cm-xs-txt text-blue-gray-700 mb-1 d-flex justify-content-between align-items-center">
            <span className="pe-2">Created On :</span>
            <span>{moment(data.createdOn).format("DD MMM YYYY [at] hh:mm A")}</span>
          </p>
          <p className="cm-xs-txt text-blue-gray-700 mb-1 d-flex justify-content-between align-items-center">
            <span className="pe-2">Assigned to :</span>
            <span className="d-flex">
              {data.assignedTo.map((userData, i) => {
                return <CustomToolTip key={i} title={<>{userData.name}  <br />  {userData.emailId}</>}>
                  <div className="d-flex cm-bank-avatar rounded-circle align-items-center justify-content-center" style={{ backgroundColor: `#${userData.colour}` }} >
                    <p className='cm-xs-txt my-0 text-white fw-semibold cm-pointer text-uppercase'>{getInitials(userData.name)}</p>
                  </div>
                </CustomToolTip>
              })}
            </span>
          </p>
        </>}
        {role === "SME" && <>
          <p className="cm-xs-txt text-blue-gray-700 mb-1 d-flex justify-content-between align-items-center">
            <span className="pe-2">Assigned By :</span>
            <span>{data.assignedBy}</span>
          </p>
          <p className="cm-xs-txt text-blue-gray-700 mb-1 d-flex justify-content-between align-items-center">
            <span className="pe-2">Assigned On :</span>
            <span>{moment(data.assignedOn).format("DD MMM YYYY [at] hh:mm A")}</span>
          </p>
        </>}
      </div>
      <div className="d-flex align-items-center border-top border-blue-gray-100 pt-2 cm-bank-footer my-2 d-flex align-items-center justify-content-between px-3">
        <div>
          {role === "ADMIN" && <CustomToolTip title="Reassign/Assign Bank">
            <FontAwesomeIcon
              icon={regular("user-plus")}
              className={`${data.questionCount ? 'cm-pointer text-action-blue' : 'text-blue-gray-500'} me-3`}
              onClick={() => {
                if (data.questionCount) {
                  handleModalToggle("ASSIGN_BANK", { reviewBankId: data.reviewBankId, title: "Assign Bank" }); handleCloseUserMenu()
                }
              }}
            />
          </CustomToolTip>}
          <CustomToolTip title="Download Question Bank">
            <FontAwesomeIcon
              icon={regular("arrow-down-to-line")}
              onClick={() => {
                if ((role === "SME" && data.reviewBankPermissionDTO.canDownloadQuestion && data.questionCount) || (role === "ADMIN" && data.questionCount)) {
                  // downloadQuestionBank(data.reviewBankId, data.reviewBankName)
                  handleModalToggle("DOWNLOAD_BANK", { reviewBankId: data.reviewBankId, title: "Download Question Bank", reviewBankName: data.reviewBankName }); handleCloseUserMenu()
                }
              }}
              className={`${(role === "SME" && data.reviewBankPermissionDTO.canDownloadQuestion && data.questionCount) || (role === "ADMIN" && data.questionCount) ? 'cm-pointer text-action-blue' : 'text-blue-gray-500'} me-3`}
            />
          </CustomToolTip>
          <CustomToolTip title="Open in Translation Mode">
            <FontAwesomeIcon
              icon={regular("language")}
              className={`${data.questionCount ? 'cm-pointer text-action-blue' : 'text-blue-gray-500'} me-3`}
              onClick={() => {
                if (data.questionCount) {
                  (role === "SME" ? navigate(`/translation-mode/${data.reviewBankId}`, { state: { translationMode: 'Self Translate' } }) : handleTranslateToggle(data.reviewBankId))
                }
              }
              }
            />
          </CustomToolTip>
          {role === 'ADMIN' &&
            <CustomToolTip title="Analyze Questions">
              <FontAwesomeIcon
                icon={regular("magnifying-glass-chart")}
                className="text-blue-gray-500 me-3"
              />
            </CustomToolTip>
          }
          {role === "ADMIN" &&
            <CustomToolTip title="Check Plagiarism">
              <FontAwesomeIcon
                icon={regular("file-magnifying-glass")}
                className="text-blue-gray-500 me-3"
              />
            </CustomToolTip>
          }
        </div>


        <CustomToolTip title="Go to the Bank">
          <Link to={`/my-bank/${index}`}>
            <FontAwesomeIcon
              icon={regular("arrow-right")}
              className="text-action-blue"
            />
          </Link>
        </CustomToolTip>

        <Menu
          sx={{ mt: '32px' }}
          id="more-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseUserMenu}
          MenuListProps={{ disablePadding: true }}
          PopoverClasses={{
            className: "cm-select-modal cm-select-inside-modal",
          }}
          elevation={0}
          PaperProps={{
            style: {
              // width: 220,  
            },
          }}
          disableAutoFocus={true}
        >
          <MenuItem disabled={(role === "ADMIN" && data.assignedCount === 0)} className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleModalToggle("VIEW_ASSIGNMENT", { reviewBankId: data.reviewBankId, title: "View Assignment", assignedCount: data.assignedCount }); handleCloseUserMenu() }} autoFocus={false}>View Assignment</MenuItem>
          {role === "ADMIN" && <div>
            <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleModalToggle("RENAME_BANK", { reviewBankId: data.reviewBankId, title: "Rename Bank", bankName: data.reviewBankName, isRoot: true, isRenameBank: true }); handleCloseUserMenu() }} autoFocus={false}>Rename Bank</MenuItem>
            <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleDialogToggle({ reviewBankId: data.reviewBankId, bankName: data.reviewBankName }); handleCloseUserMenu() }} autoFocus={false}>Delete Bank</MenuItem>
            {
              data?.isFromOldCms === true && 
              <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleModalToggle("OLD_CMS_CONFIGURATION", { reviewBankId: data.reviewBankId, title: "Old Cms Configuration", bankName: data.reviewBankName, managerEmail: data.oldCmsManagerEmail, assignedUsersEmail: data.oldCmsAssignedUsersEmail }); handleCloseUserMenu() }} autoFocus={false}>Old Cms Configuration</MenuItem>
            }
          </div>}
        </Menu>

      </div>
      <LanguageTranslateModal modalData={modalData} handleTranslateToggle={handleTranslateToggle} />
    </div>
  )
}

export default BankBox;