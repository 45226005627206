import MainTaxonomyManagement from "../../containers/taxonomy-management/MainTaxonomyManagement.container";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import "../../containers/taxonomy-management/TaxonomyManagement.styles.scss";

const TaxonomyManagement = () => {
  const breadcrumbData = [
    {
      iconClass: regular("house"),
      link: "/",
    },
    {
      label: "Taxonomy Management",
      link: "/",
    },
  ];
  return (
    <div className="bg-white taxonomy">
      <Breadcrumb data={breadcrumbData} />
      <div className="pt-3 pb-2 d-flex align-items-center fw-medium cm-mmc-font text-blue-800">
        <h5 className="m-0 ms-3 ps-4 py-2">Taxonomy Management</h5>
      </div>
      <MainTaxonomyManagement />
    </div>
  );
};

export default TaxonomyManagement;
