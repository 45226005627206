import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/login/login";
import React from 'react';
import './custom.scss';
import 'font-awesome/css/font-awesome.min.css';
import NotFoundPage from './pages/not-found-page/not-found-page';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import QuestionCreation from './pages/questionCreation/questionCreation';
import AllUsers from './pages/allUsers/allUsers';
import QuestionTags from './pages/questionTags/questionTags';
import AuthRoute from './components/authRoute/authRoute';
import QuestionLibrary from './pages/questionLibrary/questionLibrary';
import CreateQuestion from './pages/create-question/create-question';
import './App.css';
import LayoutContainer from "./containers/layout/Layout.container";
import HomePage from "./pages/home/Home.page";
import AdminSmeDashboard from "./pages/home/AdminSmeDashboard.page";
import CustomSnackbar from "./components/snackbar/Snackbar.component";
import AddQuestionDetailsPage from "./pages/question-creation-pages/AddQuestionDetails.page";
import AddTestCasesPage from "./pages/question-creation-pages/AddTestcases.page";
import AddSolutionPreview from "./pages/question-creation-pages/AddSolutionPreview.page";
import LoginPage from "./pages/auth/Login.page";
import ResetPasswordPage from "./pages/auth/ResetPassword.page";
import ProfilePage from "./pages/profile/Profile.page";
import { useDispatch, useSelector } from 'react-redux';
import PreviewQuestionPage from "./pages/question-creation-pages/PreviewQuestion.page";
import ReviewSolutionCode from "./pages/review-solution/ReviewSolutionCode.page";
import SetPasswordPage from "./pages/auth/SetPassword.page";
import PreviewQuestionReviewerPage from "./pages/question-creation-pages/PreviewQuestionReviewer.page";
import TestPage from "./pages/Test.page";
import { DefaultConfig } from "./config/defaultConfig";
import axios from "axios";
import { fetchEnv } from "./redux/actions/env.action";
import SkillDashboard from "./pages/skill-dashboard/SkillDashboard.page";
import TaxonomyManagement from "./pages/taxonomy-management/TaxonomyManagement.page";
import QuestionLibraryKnowledgeBased from "./containers/question-library-knowledgeBased/QuestionLibraryKnowledgeBased.container";
import CreateKnowledgeBasedQuestion from "./pages/create-question/create-knowledgeBased-question";
import PreviewKnowledgeBasedQuestionPage from "./pages/question-creation-pages/PreviewKnowledgeBasedQuestion";
import CodeSnippet from "./questionCreation/codeSnippet";
import ChangePasswordPage from "./pages/auth/ChangePassword.Page";
import OtpPage from "./pages/auth/OtpPage";
import MyBank from "./pages/my-bank/MyBank.page";
import BankDashboard from "./containers/my-bank/BankDashboard";
import TranslationModePage from "./pages/translation-mode/TranslationMode.page";
import DowloadQuestionBankPage from "./pages/tools/DowloadQuestionBank.page";
import HierarchyPage from "./pages/tools/Hierarchy.page";
import ViewQuestion from "./pages/viewQuestion/ViewQuestion";
function App() {
  const user = useSelector(state => state.user);
  const env = useSelector(state => state.env);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(true);

  let configToUse = DefaultConfig;

  const fetchConfig = async () => {
    try {
      const res = await axios.get(`/env`);

      if (!!res.data) {
        configToUse = res.data;

        dispatch(fetchEnv(configToUse));
        setIsLoading(false)
      }
    } catch (error) {
      dispatch(fetchEnv(configToUse));
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    fetchConfig();

    // eslint-disable-next-line
  }, [])
  return !isLoading ? (
    <>
      <CustomSnackbar />
      <Router>

        <Routes>
          {!!env && !env?.production && <Route path="/corporate/question/codesnippet" element={<CodeSnippet />}/>}
          <Route path="/" element={<LayoutContainer />}>
            {/* {!user?.isMfaAuthenticated && <Route index element={<ProtectedRoute><OtpPage/></ProtectedRoute>} />} */}
            {user?.role !== "SME" &&  <Route index element={<ProtectedRoute><HomePage /></ProtectedRoute>} />}
            {user?.role === "SME" && <Route index element={<ProtectedRoute><AdminSmeDashboard /></ProtectedRoute>} />}

            <Route path="/login" element={<AuthRoute><LoginPage /></AuthRoute>} />
            <Route path="/change-password" element={<ProtectedRoute><ChangePasswordPage/></ProtectedRoute>} />
            <Route path="forgot-password" element={<AuthRoute><ResetPasswordPage /></AuthRoute>} />
            <Route path="reset-password" element={<AuthRoute><SetPasswordPage /></AuthRoute>} />

            <Route path="/mfa-verify" element={user!==null && !user.isMfaAuthenticated ? <OtpPage/>:<NotFoundPage />} />
            <Route exact path="vendor-dashboard/:vid" element={<ProtectedRoute><AdminSmeDashboard /></ProtectedRoute>} />
            <Route exact path="question-library/:taskId" element={<ProtectedRoute><QuestionLibrary /></ProtectedRoute>} />

            <Route exact path="review-knowledgeBased-question/:taskId" element={<ProtectedRoute><QuestionLibraryKnowledgeBased /></ProtectedRoute>} />
           
            <Route path="/questionCreation" element={<ProtectedRoute><QuestionCreation /></ProtectedRoute>} />

            <Route path="/all-users" element={<ProtectedRoute><AllUsers /></ProtectedRoute>} />

            <Route path="/question-tags" element={<ProtectedRoute><QuestionTags /></ProtectedRoute>} />
            <Route path="/createQuestion/question-details/:taskId" element={<ProtectedRoute><CreateQuestion /></ProtectedRoute>} />
            <Route path="/question-details" element={<ProtectedRoute><AddQuestionDetailsPage /></ProtectedRoute>} />

            <Route path="create-question" element={<ProtectedRoute><CreateKnowledgeBasedQuestion /></ProtectedRoute>} />
            <Route path="/reviewQuestion/editQuestion/:qId" element={<ProtectedRoute><CreateKnowledgeBasedQuestion /></ProtectedRoute>} />
            <Route path="/add-test-cases" element={<ProtectedRoute><AddTestCasesPage /></ProtectedRoute>} />
            <Route path="/add-solution" element={<ProtectedRoute><AddSolutionPreview /></ProtectedRoute>} />
            <Route path="/preview-question" element={<ProtectedRoute><PreviewQuestionPage /></ProtectedRoute>} />

            <Route path="/preview-knowledgeBased-question" element={<ProtectedRoute><PreviewKnowledgeBasedQuestionPage /></ProtectedRoute>} />
            

            <Route path="/review-question" element={<ProtectedRoute><PreviewQuestionReviewerPage /></ProtectedRoute>} />
            <Route path="/reviewSolution/:questionId" element={<ProtectedRoute><ReviewSolutionCode /></ProtectedRoute>} />

            <Route path="/skill-dashboard" element={<ProtectedRoute><SkillDashboard /></ProtectedRoute>} />

            <Route path="/taxonomy-management/" element={<ProtectedRoute><TaxonomyManagement /></ProtectedRoute>} />
            <Route path="/my-bank/" element={<ProtectedRoute><MyBank /></ProtectedRoute>} />
            <Route path="/my-bank/:bankId" element={<ProtectedRoute><BankDashboard/></ProtectedRoute>} />
            <Route exact path="profile" element={<ProtectedRoute><ProfilePage /></ProtectedRoute>} />
            <Route exact path="test" element={<ProtectedRoute><TestPage /></ProtectedRoute>} />
            <Route exact path="/translation-mode/:bankId" element={<TranslationModePage />} />

            <Route exact path="/download-question-bank" element={<ProtectedRoute><DowloadQuestionBankPage /></ProtectedRoute>} />
            <Route exact path="/hierarchy" element={<ProtectedRoute><HierarchyPage /></ProtectedRoute>} />
            <Route exact path="/viewQuestion" element={<ProtectedRoute><ViewQuestion /></ProtectedRoute>} />


            
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  ) : null;
}

export default App;
