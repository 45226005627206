import { SNACK_CLOSE, SNACK_OPEN } from "../actions/actions.types";

const INITIAL_STATE = {
    type: '',
    status: false,
    msg: null
}

const snackbarReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case SNACK_OPEN:
            return {
                ...action.payload,
                status: true
            }
        case SNACK_CLOSE:
            return INITIAL_STATE
        default:
            return state;
    }
}

export default snackbarReducer;