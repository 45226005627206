import React from 'react';
import MetaComp from '../../components/meta-comp/MetaComp.component';
import SmeDashboardContainer from '../../containers/home/SmeDashboard.container';
import './HomePage.styles.css';

const AdminSmeDashboard = () => {
    return <>
        <MetaComp title="Home - Mercer | Mettl"/>
        <SmeDashboardContainer />
    </>
}

export default AdminSmeDashboard;