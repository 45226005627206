import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TaxonomyManagement.styles.scss";
import downloadIcon from "../../assets/images/global/ms_excel.svg";
import DropDown from "./DropDown.container";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import UploadEntity from "./UploadEntity.container";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { getApiUrlSkill } from "../../utils/apiUrls";


const MainTaxonomyManagement = () => {
  const [dropDownData, setDropDownData] = React.useState("skill_type");
  const dispatch = useDispatch();
  const handleReportDownload = () => {
    try {
      var queryApi = dropDownData + "DownloadUrl";
      saveAs(`${getApiUrlSkill(queryApi)}`, `Coding.xls`);
    } catch {
      dispatch(
        openSnackBar({
          msg: "Server error!",
          type: "error",
        })
      );
    }
  };

  return (
    <div className="container m-0 p-0 ms-4 taxonomy-management">
      <div className="ms-3 my-1">
        <div className="cm-sm-txt text-blue-800 mb-2 fw-semibold">
          1. Select entity
        </div>
        <div>
          <DropDown
            dropDownData={(dropDownData) => {
              setDropDownData(dropDownData.toLowerCase());
            }}
          />
        </div>
        <div className="cm-sm-txt text-blue-800 mt-3 mb-2 fw-semibold">
          2. Download
        </div>
        <div
          className="bg-blue-gray-50 d-flex justify-content-between taxonomy-management_head mb-3 py-2 cm-btn-radius cm-pointer"
          onClick={handleReportDownload}
        >
          <div className="cm-xs-txt d-flex container-fluid">
            <img src={downloadIcon} alt="logo" />
            <div className="ps-1 mx-0 ms-1 my-auto">
              Download the excel to enter more details for the skill taxonomy.
            </div>
          </div>
          <div className="taxonomy-management_icon-sty me-3 my-auto">
            <CustomToolTip title="Download Report">
              <span className="text-blue-gray-700">
                <FontAwesomeIcon icon={regular("circle-down")} />
              </span>
            </CustomToolTip>
          </div>
        </div>
      </div>
      <div className="ms-3 my-2">
        <div className="cm-sm-txt text-blue-800 mb-2 fw-semibold">
          3. Upload
        </div>
        <UploadEntity dropDownData={dropDownData} />
      </div>
    </div>
  );
};
export default MainTaxonomyManagement;
