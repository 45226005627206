import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import PreviewQuestionReviewer from '../../containers/question-creation/PreviewQuestionReviewer.container';
import { getQuestionDetails, getQuestionDetailsAdmin } from '../../redux/actions/question.actions';

const PreviewQuestionReviewerPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const getReqId = searchParams.get('reqId');
    const getQuestid = searchParams.get('qId');
    const questData = useSelector(state => state.question);

    const breadcrumbData = React.useMemo(() => ([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Preview Question",
            link: '/'
        }
    ]), []);

    React.useEffect(() => {
        if(!!getReqId && !!getQuestid) {
            dispatch(user.role === 'ADMIN' ? getQuestionDetailsAdmin(getQuestid) : getQuestionDetails(getQuestid, 1, true));
        }
    }, []);

    return(
        <div className='cm-cms-container'>
            <Breadcrumb data={breadcrumbData} backBtn={true} />
            <PreviewQuestionReviewer user={user} questData={questData} getReqId={getReqId} getQuestid={getQuestid} />
            <Outlet />
        </div>
    )
}

export default PreviewQuestionReviewerPage;