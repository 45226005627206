import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './QuestionCreation.styles.css';
import instance from '../../utils/axios';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import AnimationDataLoader from '../../components/Loaders/animationData';
import CustomSelect from '../../components/custom-select/CustomSelect.component';
import TextEditor from '../../components/text-editor/TextEditor.component';
import { arraysEqual } from '../../utils/helperFn';

const difficultyLevels = [
    {
        value: "Easy",
        label: "Easy"
    },
    {
        value: 'Medium',
        label: "Medium"
    },
    {
        value: 'Difficult',
        label: "Difficult"
    },
];

export const inputDataType = [
    { value: 'int', label: 'int', preText: 'An integer value, representing ', msg: null, placeholder: 'E.g. 1' },
    { value: 'int[]', label: 'int[]', preText: 'An integer array, representing ', msg: null, placeholder: 'E.g. {1,2,3,4,...}' },
    { value: 'double', label: 'double', preText: 'A double value, representing ', msg: null, placeholder: 'E.g. 1.56' },
    { value: 'double[]', label: 'double[]', preText: 'A double array, representing ', msg: null, placeholder: 'E.g. {1.67,2.09,3.54,4.67,...}' },
    { value: 'string', label: 'string', preText: 'A string value, representing ', msg: null, placeholder: 'E.g. Lorem Ipsum' },
    { value: 'string[]', label: 'string[]', preText: 'A string array, representing ', msg: null, placeholder: 'E.g. {"Lorem Ipsum 1","Lorem Ipsum 2",...}' },
    { value: 'int[-]', label: 'int[][]', preText: 'A 2D integer array, representing ', msg: null, placeholder: 'E.g. {{1,2,3},{4,5,6},...}' }];

export const outputDataType = [
    { value: 'int', label: 'int', preText: 'Return an integer value ', msg: null, placeholder: 'E.g. 1' },
    { value: 'int[]', label: 'int[]', preText: 'Return an integer array ', msg: null, placeholder: 'E.g. {1,2,3,4,...}' },
    { value: 'double', label: 'double', preText: 'Return a double value ', msg: null, placeholder: 'E.g. 1.56' },
    { value: 'double[]', label: 'double[]', preText: 'Return a double array ', msg: null, placeholder: 'E.g. {1.67,2.09,3.54,4.67,...}' },
    { value: 'string', label: 'string', preText: 'Return a string value ', msg: null, placeholder: 'E.g. Lorem Ipsum' },
    { value: 'string[]', label: 'string[]', preText: 'Return a string array ', msg: null, placeholder: 'E.g. {"Lorem Ipsum 1","Lorem Ipsum 2",...}' }];



const editorConfiguration = {
    toolbar: ['heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'sourceEditing',
        // 'wproofreader'
    ]
};

const QuestionDetailsForm = ({ getQuestDetailsValue, questStepData }) => {
    const initial_state = {
        ...questStepData.questDetails
    }

    const [formVal, setFormVal] = React.useState(initial_state);
    const [allTags, setAllTags] = React.useState(null);
    const [codingLanguages, setCodingLanguages] = React.useState([]);
    const [ioWarn, setIoWarn] = React.useState({
        addInput: false,
        addOutput: false
    })

    React.useEffect(() => {
        getAllTags();
        getCodingLanguages();
    }, []);

    React.useEffect(() => {
        if (questStepData.matchSpecs.addInputs.length > 0 && questStepData.matchSpecs.addOutputs.length > 0) {
            const isInpEqual = arraysEqual(formVal.addInputs.value.map(el => el.value), questStepData.matchSpecs.addInputs.map(el => el.value));
            const isOutEqual = arraysEqual(formVal.addOutputs.value.map(el => el.value), questStepData.matchSpecs.addOutputs.map(el => el.value));

            setIoWarn({
                addInput: !isInpEqual,
                addOutput: !isOutEqual
            });
        }
    }, [questStepData])

    // React.useEffect(() => {
    //     if(!!allTags && !!codingLanguages) setFormVal(questStepData.questDetails);
    // }, [allTags, codingLanguages])

    React.useEffect(() => {
        if (!!allTags && !!codingLanguages) {
            setFormVal(questStepData.questDetails);
        }
    }, [allTags, codingLanguages, questStepData])

    const handleChange = (newValue, fieldName) => {
        // getQuestDetailsValue({
        //     ...formVal,
        //     [fieldName]: {
        //         ...formVal[fieldName],
        //         value: newValue
        //     }
        // })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: newValue
            }
        })
    };

    const handleFieldChange = (e, fieldName) => {
        getQuestDetailsValue({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })
    }

    const handleRepeatFieldChange = (e, index, fieldName) => {
        const getCurrentArr = formVal[fieldName].value;

        getCurrentArr[index].preText = e.target.value;

        getQuestDetailsValue({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: getCurrentArr
            }
        })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: getCurrentArr
            }
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // console.log('handleFormSubmit', formVal);
        //handleValidation();
    }

    const handleSelectChange = (event, fieldName, arr) => {
        let val = {};

        val = arr[arr.findIndex(el => el.value === event.target.value)]
        //console.log('event', event.target.value);

        getQuestDetailsValue({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })
    }

    const handleMultiSelectChange = (val, fieldName) => {
        getQuestDetailsValue({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })
    }

    const handeTxtEditorChange = (val) => {
        getQuestDetailsValue({
            ...formVal,
            questionText: {
                ...formVal['questionText'],
                value: val
            }
        })

        setFormVal({
            ...formVal,
            questionText: {
                ...formVal['questionText'],
                value: val
            }
        })
    }

    const getAllTags = async () => {
        try {
            const res = await instance.get(`${getApiUrl("getAllTags")}`);

            setAllTags(!!res.data && res.data.length > 0 ? res.data.map(el => ({ label: el.tagName, value: el.tagId })) : []);

        } catch (error) {
            const msgObj = apiErrorHandler(error);
            console.log('getAllTags', error)

            // dispatch({
            //     type: SNACK_OPEN,
            //     payload: {
            //         msg: t(msgObj.statusText),
            //         type: 'error'
            //     }
            // })
        }
    }

    const getCodingLanguages = async () => {
        try {
            const res = await instance.get(`${getApiUrl("getCodeLanguage")}`);

            setCodingLanguages(!!res.data && res.data.length > 0 ? res.data.map(el => ({ label: el.codeName, value: el.codeName, id: el.codeId })) : []);

        } catch (error) {
            const msgObj = apiErrorHandler(error);
            console.log('getAllTags', error)

            // dispatch({
            //     type: SNACK_OPEN,
            //     payload: {
            //         msg: t(msgObj.statusText),
            //         type: 'error'
            //     }
            // })
        }
    }

    // console.log('formval', formVal, questStepData);

    return (
        <div className='cm-event-library-content-wrapper bg-blue-gray-50 mb-5'>
            {
                allTags !== null && codingLanguages !== null ?
                    <form className="cm-event-details-form cm-login-form mx-5" onSubmit={handleFormSubmit}>
                        <div className="row pt-4">
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="questionTopic" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Question Topic</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id="questionTopic" value={formVal.questionTopic.value} onChange={(e) => handleFieldChange(e, 'questionTopic')} readOnly disabled />
                                    {formVal.questionTopic.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.eventName.msg}</span>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="difficultyLevel" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Difficulty Level</label>
                                    <div className="position-relative">
                                        <Select
                                            labelId="difficultyLevelLabel"
                                            id="difficultyLevel"
                                            disabled={true}
                                            value={formVal.difficultyLevel.value?.value}
                                            onChange={(e) => handleSelectChange(e, 'difficultyLevel', difficultyLevels)}
                                            fullWidth={true}
                                            className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                        >
                                            {
                                                difficultyLevels.map(el => <MenuItem value={el.value} key={el.value} className=" fw-medium cm-sm-txt text-blue-800">{el.label}</MenuItem>)
                                            }
                                        </Select>
                                        <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow text-blue-800 position-absolute" />
                                    </div>
                                    {formVal.difficultyLevel.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.difficultyLevel.msg}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="questionTag" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Question Tag</label>
                                    <div className="position-relative">
                                        <CustomSelect
                                            menuArr={allTags}
                                            currentVal={formVal.questionTag.value}
                                            addSameVal={false}
                                            handleMultiSelectChange={handleMultiSelectChange}
                                            fieldName="questionTag" />
                                    </div>
                                    {formVal.questionTag.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.questionTag.msg}</span>}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label htmlFor="functionName" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Function Name</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id="functionName" value={formVal.functionName.value} onChange={(e) => handleFieldChange(e, 'functionName')} />
                                    {formVal.functionName.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.functionName.msg}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="questionTitle" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Question Title</label>
                            <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id="questionTitle" value={formVal.questionTitle.value} onChange={(e) => handleFieldChange(e, 'questionTitle')} />
                            {formVal.questionTitle.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.questionTitle.msg}</span>}
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="questionText" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Question Text</label>
                            <TextEditor 
                                data={formVal.questionText.value}  
                                handeTxtEditorChange={handeTxtEditorChange}
                                id="questionText"
                            />
                            {formVal.questionText.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.questionText.msg}</span>}
                        </div>


                        <div className="form-group mb-3">
                            <div className="form-group mb-3">
                                <label htmlFor="codingLanguages" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Coding Languages</label>
                                <div className="position-relative">
                                    <CustomSelect
                                        menuArr={codingLanguages}
                                        currentVal={formVal.codingLanguages.value}
                                        addSameVal={false}
                                        handleMultiSelectChange={handleMultiSelectChange}
                                        fieldName="codingLanguages" />
                                </div>
                                {formVal.codingLanguages.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.codingLanguages.msg}</span>}
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="addInputs" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Add Inputs</label>
                            <div className="position-relative">
                                <CustomSelect
                                    menuArr={inputDataType}
                                    currentVal={formVal.addInputs.value}
                                    addSameVal={true}
                                    handleMultiSelectChange={handleMultiSelectChange}
                                    fieldName="addInputs" />
                            </div>
                            {formVal.addInputs.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.addInputs.msg}</span>}
                            {ioWarn.addInput && <span className='cm-xs-txt text-orange fw-medium pt-2'>Change/Re-ordering of inputs would lead to deletion of testcases.</span>}
                        </div>
                        <div className="row">
                            {
                                formVal.addInputs.value.map((el, index) => {
                                    return (
                                        <div className='col-lg-6' key={el.id}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="functionName" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Input{index + 1} Specification</label>
                                                <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" value={formVal.addInputs.value[index].preText} onChange={(e) => handleRepeatFieldChange(e, index, 'addInputs')} />
                                                {el.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{el.msg}</span>}
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>

                        <div className="form-group mb-3">
                            <label htmlFor="addOutputs" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Add Outputs</label>
                            <div className="position-relative">
                                <CustomSelect
                                    menuArr={outputDataType}
                                    currentVal={formVal.addOutputs.value}
                                    addSameVal={true}
                                    handleMultiSelectChange={handleMultiSelectChange}
                                    fieldName="addOutputs" />
                            </div>
                            {formVal.addOutputs.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.addOutputs.msg}</span>}
                            {ioWarn.addOutput && <span className='cm-xs-txt text-orange fw-medium pt-2'>Change/Re-ordering of outputs would lead to deletion of testcases.</span>}
                        </div>

                        <div className="row">
                            {
                                formVal.addOutputs.value.map((el, index) => {
                                    return (
                                        <div className='col-lg-6' key={el.id}>
                                            <div className="form-group mb-3">
                                                <label htmlFor="functionName" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Output{index + 1} Specification</label>
                                                <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" value={formVal.addOutputs.value[index].preText} onChange={(e) => handleRepeatFieldChange(e, index, 'addOutputs')} />
                                                {el.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{el.msg}</span>}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </form>
                    :
                    <div className='p-4 d-flex align-items-center justify-content-center'>
                        <AnimationDataLoader />
                    </div>
            }
        </div>
    )

}

export default QuestionDetailsForm;