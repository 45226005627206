import React from 'react'
import './create-question.styles.css';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import QuestionDetails from './question-details';
import { Container } from '@mui/system';
import AddTestcases from './addTestcases';
import instance from '../../utils/axios';
import QuestionSettings from './questionSettings';
import { Link,params, useParams, useLocation} from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import { useNavigate } from 'react-router-dom';

export default function CreateQuestion() {
    const [eventStepData, setEventStepData] = React.useState({ activeStep: 0 });
    const isLoading = true;
    const [quesDetails, setQuestionDetails] = React.useState({});
    const navigate=useNavigate();
    const [allTestCaseDetails, setAllTestCaseDetails] = React.useState({});
    const [ansDesc, setAnswerDesc] = React.useState("");
    const{taskId}=useParams();
   const {state } = useLocation();

    const progressSteps = [
        {
            label: "Question Details",
            value: 0
        },
        {
            label: "Add Testcases",
            value: 1
        },
        {
            label: "Add Solution and Preview",
            value: 2
        }
    ];

    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Dashboard",
            link: '/'
        }
    ]

    const onSaveBtnClick = async () => {
        if (eventStepData.activeStep == 0) {
            const dataToSend = {
                difficultyLevel: quesDetails.difficultyLevel.value,
                questionTitle: quesDetails.questionTitle,
                questionText: quesDetails.questionText,
                codeLanguages: quesDetails.codeLanguages.map(el => el.value),
                functionName: quesDetails.functionName,
                topicName: quesDetails.topicName,
                inputType: quesDetails.inputType.map(el => el.value),
                outputType: quesDetails.outputType.map(el => el.value),
                inputSpecification: quesDetails.inputSpecification,
                outputSpecification: quesDetails.outputSpecification,
                taskId: taskId,
                tagIds: quesDetails.tagIds,
                testcaseDetails: [],
                ansDescription: ''
            }
            instance.post(`${process.env.REACT_APP_BASE_URL}/cms/v1/save-codelysis-question`, dataToSend)
                .then(function (response) {
                    if (response.data) {
                        navigate(`/editQuestion/add-testcases/${response.data.id}`);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    const onBackBtnClick = () => {
        setEventStepData({ activeStep: eventStepData.activeStep - 1 });
    }

    const handleRadioChange = () => {
    }

    const saveQuestDetails = (data) => {
        setQuestionDetails(data);
    }

    const saveAllTestCasesDetails = (data) => {
        setAllTestCaseDetails(data);
    }

    const saveAnsDescription = (data) => {
        setAnswerDesc(data);
    }

    const finishCreation = (data) => {
        const dataToSend = {
            difficultyLevel: quesDetails.difficultyLevel.value,
            questionTitle: quesDetails.questionTitle,
            questionText: quesDetails.questionText,
            codeLanguages: quesDetails.codeLanguages.map(el => el.value),
            functionName: quesDetails.functionName,
            topicName: quesDetails.topicName,
            inputType: quesDetails.inputType.map(el => el.value),
            outputType: quesDetails.outputType.map(el => el.value),
            inputSpecification: quesDetails.inputSpecification.map(el => el.firstInp + " " + el.secondInp),
            outputSpecification: quesDetails.outputSpecification.map(el => el.firstInp + " " + el.secondInp),
            questionId: 1,
            taskId: 1,
            tagIds: quesDetails.tagIds,
            testcasesDTO: allTestCaseDetails,
            ansDescription: ansDesc,
            creatorStatus: "Completed"
        }
        instance.post(`${process.env.REACT_APP_BASE_URL}/cms/v1/update-codelysis-question`, dataToSend)
            .then(function (response) {
                if (response.data) {
                    setEventStepData({ activeStep: eventStepData.activeStep + 1 });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getContainer = (step) => {
        switch (step) {

            case 0: return (<QuestionDetails saveQuestDetails={saveQuestDetails} state={state} step={eventStepData.activeStep}></QuestionDetails>);
            case 1: return (
                <><AddTestcases totalInputs={quesDetails.inputType} totalOutputs={quesDetails.outputType} saveAllTestCasesDetails={saveAllTestCasesDetails}></AddTestcases></>);
            case 2: return (<><QuestionSettings saveAnsDescription={saveAnsDescription}></QuestionSettings></>);
            default: return "Unknown Step";
        }
    }

    return (
        <div className="body">
            <Breadcrumb data={breadcrumbData} />
            <div className='row'>
                <div className="col-lg-3">
                    <div className='cm-event-library-sidebar-wrapper'>
                        <div className="cm-event-sidebar-widget cm-event-progress-wrap">
                            <div className="d-flex justify-content-between align-items-center">
                                <p className='my-0 text-blue-gray-700 fw-semibold'>Current Progress</p>
                                <span className='text-blue-gray-400 cm-sm-txt'>{eventStepData.activeStep + 1}/3</span>
                            </div>

                            <div className="cm-event-progress-steps mb-4">
                                <ul className='pt-3'>
                                    {
                                        progressSteps.map(el =>
                                            <li className={`d-flex align-items-center py-2 px-3 position-relative ${el.value <= eventStepData.activeStep ? 'active-step' : ''}`} key={el.value}>
                                                <input type="radio" checked={el.value <= eventStepData.activeStep} value={el.value} onChange={handleRadioChange} name={`taskFilter-${el.value}`} id={el.value} />
                                                <label htmlFor={el.value} className="cm-sm-txt text-blue-800 fw-medium">{el.label}</label>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className="cm-event-progress-controls d-flex align-items-center">
                                <span className={`cm-sidebar-back-btn text-blue-800 fw-medium cm-sm-txt cm-pointer ${eventStepData.activeStep === 2 ? 'cm-small-back-btn' : ''}`} onClick={onBackBtnClick}>Back</span>
                                <span className={`ms-2 cm-sidebar-save-btn bg-action-blue text-white d-flex align-items-center justify-content-center cm-pointer`} onClick={onSaveBtnClick}>
                                    <span className='fw-medium cm-sm-txt'>{eventStepData.activeStep === 2 ? "Save Step" : "Save and Continue"}</span>
                                    <FontAwesomeIcon icon={regular("angle-right")} className="ps-2" />
                                </span>
                            </div>
                            {eventStepData.activeStep == 2 &&
                                <button className="btn bg-action-blue text-white mt-3 ms-5 ps-2" onClick={finishCreation}>
                                    Finish
                                </button>}
                            <div className="cm-event-library-sidebar-divider" />
                        </div>
                    </div>
                </div>
                <div className='col-lg-8 ps-4' style={{ 'margin-top': '90px' }} >
                    <p className='my-0 text-blue-800 fw-semibold txt-size-lg mt-3 mb-3'><h3> Coding question creation</h3></p>
                    <Container className="bg-white"> {getContainer(eventStepData.activeStep)}</Container>

                </div>
            </div>

        </div>
    )
}
