import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Graph from "react-graph-vis";
import "./Network.styles.css";

export default function NetworkGraph({
  networkGraphData,
  filterEntityRecordArray,
  inputVal,
}) {
  const [loading, setLoading] = React.useState(false);
  const [isFs, setIsFs] = React.useState(false);
  const graphRef = React.useRef(null);
  const [node, setNode] = React.useState([]);
  const [filterEdge, setFilterEdge] = React.useState([]);
  const [filterNode, setFilterNode] = React.useState([]);
  const [edge, setEdge] = React.useState([]);
  const [networkInstance, setNetworkInstance] = React.useState(null);
  React.useEffect(() => {
    setNode(networkGraphData.node);
    setEdge(networkGraphData.edge);
  }, []);
  React.useEffect(() => {
    if (loading) {
      if (filterEntityRecordArray.length > 0) {
        var tempfilternode = filterNode;
        filterEntityRecordArray.forEach((e) => {
          tempfilternode = tempfilternode.filter((el) => el.nodeType !== e);
        });

        let directData = {
          nodes: tempfilternode,
          edges: filterEdge,
        };
        networkInstance.setData(directData);
      } else {
        let directData = {
          nodes: filterNode,
          edges: filterEdge,
        };
        networkInstance.setData(directData);
      }
    }
  }, [filterEntityRecordArray, loading, filterNode, filterEdge]);
  const getData = () => {
    let startNodes = networkGraphData.node.filter((e) => {
      if (networkGraphData.startId.includes(e.id)) {
        return e;
      }
    });

    return {
      nodes: startNodes,
      edges: [],
    };
  };

  const options = {
    physics: {
      stabilization: {
        enabled: true,
      },
    },
    interaction: {
      hover: true,
      navigationButtons: true,
    },
    height: `${window.innerHeight - 200}px`,
    nodes: {
      shape: "circle",
      font: {
        // font of text inside node
        size: 10,
        face: "Noto Sans",
        color: "white",
      },
      chosen: false, // on hover no changes in node
      scaling: {
        label: {
          min: 40,
          max: 50,
        },
      },
    },
    edges: {
      // widthConstraint: { minimum: 90, maximum: 170 },
      arrows: {
        to: {
          enabled: true,
        },
      },
      font: {
        align: "middle",
        background:"#ffff00"
      },
    },
  };

  const events = {
    select: function (event) {
      var { nodes, edges } = event;
    },
    click: function (event) {
      var { nodes, edges } = event;
      let params = { nodes, edges };
      let tempData = this.body.data;
      let edgesN = edge;
      let nodesN = node;
      setLoading(true);
      if (params.nodes.length > 0) {
        var nodeId = params.nodes[0];
        var nodess = [];

        var directEdges = edgesN.filter(function (item) {
          if (item.from === nodeId) {
            nodess.push(item.to);
          } else {
            if (item.to === nodeId) {
              nodess.push(item.from);
            }
          }
          return item.from === nodeId || item.to === nodeId;
        });

        nodess.push(nodeId);

        var directNodes = nodesN.filter(function (item) {
          return nodess.find((id) => {
            return item.id === id;
          });
        });
        setFilterNode(directNodes);
        setFilterEdge(directEdges);

        // console.log("Click", directData);
        var newArray = [];
        var directData = {};

        networkInstance.setData(directData);
        if (filterEntityRecordArray.length > 0) {
          filterEntityRecordArray.forEach((e) => {
            newArray = directNodes.filter((el) => el.nodeType !== e);
            directData = {
              nodes: newArray,
              edges: directEdges,
            };
          });
        } else {
          directData = {
            nodes: directNodes,
            edges: directEdges,
          };
        }
        networkInstance.setData(directData);
      } else {
        networkInstance.setData(tempData);
      }
    },
  };

  const handleFs = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setIsFs(false);
      setTimeout(() => {
        networkInstance.setSize("100%", `${window.innerHeight - 200}px`);
        networkInstance.redraw();
      }, 1000);
    } else {
      graphRef?.current?.requestFullscreen();
      setIsFs(true);
      setTimeout(() => {
        networkInstance.setSize(window.innerWidth, window.innerHeight);
        networkInstance.redraw();
      }, 1000);
    }
  };

  return (
    <div
      ref={graphRef}
      id="graph"
      className="bg-white container-graph position-relative ms-3"
    >
      <span onClick={handleFs} className="bg-white cm-graph-fs">
        <FontAwesomeIcon
          icon={isFs ? solid("compress") : solid("expand")}
          className="text-action-blue"
        />
      </span>
      <Graph
        graph={getData()}
        options={options}
        events={events}
        getNetwork={(network) => {
          //  if you want access to vis.js network api you can set the state in a parent component using this property
          setNetworkInstance(network);
        }}
      />
    </div>
  );
}
