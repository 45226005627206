import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import quesCreation from './questionCreation.module.css';
import Select from "react-select";
import { Form, useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';

const editorConfiguration = {
  toolbar: ['heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    '|',
    'outdent',
    'indent',
    '|',
    'imageUpload',
    'blockQuote',
    'insertTable',
    'mediaEmbed',
    'undo',
    'redo',
    'sourceEditing',
    // 'wproofreader'
  ]
};


const steps = ['Question Properties', 'Add Testcases', 'Question Settings'];
const language = [
  { value: "Javascript", label: "Javascript" },
  { value: "Python", label: "Python" },
  { value: "Java", label: "Java" },
  { value: "CPP", label: "CPP" }
];

const tags = [
  { value: 'Arrays', label: 'Arrays' },
  { value: 'Strings', label: 'Strings' },
  { value: 'Bit Manipulation', label: 'Bit Manipulation' },
  { value: 'Searching and Sorting', label: 'Searching and Sorting' }
];

const levels = [
  { value: 'Easy', label: 'Easy' },
  { value: 'Medium', label: 'Medium' },
  { value: 'Difficult', label: 'Difficult' }
]

const datatype = [{ value: 'int', label: 'int' },
{ value: 'int[]', label: 'int[]' },
{ value: 'String', label: 'String' },
{ value: 'String[]', label: 'String[]' },
{ value: 'boolean', label: 'boolean' }
]

const testcaseType = [{ value: 'Default Testcase', label: 'Default Testcase' },
{ value: 'Basic Testcase', label: 'Basic Testcase' },
{ value: 'Necessary Testcase', label: 'Necessary Testcase' },
{ value: 'Corner Testcase', label: 'Corner Testcase' },
{ value: 'Time Complexity Testcase', label: 'Time Complexity Testcase' }];

export default function QuestionForms() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [counter, setCounter] = React.useState(0);
  const methods = useForm();
  var datatype2 = [];

  const [languages, setLanguages] = React.useState();
  const [datatypes, setDatatypes] = React.useState();
  const [testcaseTypes, setTestcaseTypes] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState("none");
  const [title, setTitle] = React.useState('');
  const [tag, setTag] = React.useState("Arrays");
  const [functionName, setFunctionName] = React.useState("");
  const [topic, setTopic] = React.useState("");
  const [level, setLevel] = React.useState("Easy");
  const [questionData, setQuestionData] = React.useState({ questionTag: tag, difficultyLevel: level });

  const [formVal, setFormVal] = React.useState({
    questionTag: '',
    title: '',
    diffLevel: ''
  })

  const isStepOptional = (step) => {
    return step === 1;
  };

  var handleQuestionData = () => {
    setQuestionData({ questionTag: tag, difficultyLevel: level });
  }

  var handleChangeLevel = (selectedOption) => {
    setLevel(selectedOption.value);
    setQuestionData({ ...questionData, ['difficultyLevel']: selectedOption.value })
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    handleQuestionData();
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  var handleTitle = (selectedOption) => {
    setTitle(selectedOption);
  }

  const repeatSpec = () => {
    const n = 5;
    return [...Array(n)].map((e, i) => <input type="text" id="new" className="form-control" required />);
  }



  var handleChangeLang = (selectedOption) => {
    setLanguages(selectedOption.value);
  };

  var handleChangeDatatype = (selectedOption) => {
    setDatatypes(selectedOption.value);
    datatype2.push(selectedOption[selectedOption.length - 1].value);
    setCounter(counter + 1);
    repeatSpec();
  };


  var handleChangeTag = (selectedOption) => {
    setTag(selectedOption.value);
    setQuestionData({ ...questionData, ['questionTag']: selectedOption.value })
  }

  const [inputList, setInputList] = React.useState([[{ firstName: '', lastName: '' }]]);

  const handleAddClick = () => {
    setInputList([...inputList, { firstName: '', lastName: '' }]);
  }


  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  }

  const handleTagChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setTag(e.target.value);
  }

  // const onChange = (e, fieldName) => {
  //   setFormVal({
  //     ...formVal,
  //     [fieldName]: e.target.value,
  //   })
  // }

  // console.log('title', title);

  const QuestionDetails = () => {

    // Editor.create(document.querySelector('#ckeditor5-editor'))
    //   .then(editor => {
    //     window.WEBSPELLCHECKER.init({
    //       container: editor.ui._editableElements.get('main')
    //     });
    //   });

    return (

      <>
        <div className="mt-5">
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex" >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5">Question Tag</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <Select name="" options={tags} value={tags.filter(function (option) {
                    return option.value === tag;
                  })} onChange={handleChangeTag} />
                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>

          <div className="form-outline mb-4 ms-5">
            <div className="d-flex" >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5">Difficulty Level</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <Select options={levels} value={levels.filter(function (option) {
                    return option.value === level;
                  })} onChange={handleChangeLevel} />
                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex " >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5" id="plagField">Question Title</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <input type="text" name="title" value={title} className="form-control form-control-lg mt-2" onChange={(e) => setTitle(e.target.value)} required />
                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex">
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5" >Question Text</div>
                </div>
                <div className='col-lg-5' id="quesText">
                {/* <CKEditor
                    editor={Editor}
                    // plugins={[ WProofreader]}
                    config={editorConfiguration}
                    // wproofreader={{
                    //   lang: 'en_US', // sets the default language
                    //   serviceId: 'elKOVRMuinnOAAh', // required for the Cloud version only
                    //   srcUrl: 'https://svc.webspellchecker.net/spellcheck31/wscbundle/wscbundle.js'
                    // }}
                    data="<p>Hello from CKEditor 5!</p>"
                  /> */}

                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex " >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5">Coding Languages</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <Select isMulti onChange={handleChangeLang} options={language} />
                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex " >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5">Input Type</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <Select isMulti onChange={handleChangeDatatype} options={datatype} />
                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          {Array.from(Array(counter)).map((c, index) => {
            return (<div key={index}>
              <div className="form-outline mb-4 ms-5">
                <div className="d-flex row" >
                  <div className="col-lg-1"></div>
                  <div className='col-lg-3'>
                    <div className="mt-4 ms-5" > Add Input{index + 1} Specification {datatype2}</div>
                  </div>
                  <div className='col-lg-7'>
                    <div className="d-flex row" >
                      <div className="col-lg-4 ">
                        <input type="text" className="form-control" required />
                      </div>
                      <div className="col-lg-7">
                        <input type="text" className="form-control" required />
                      </div>
                      <div className="col-lg-1"></div>
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            </div>);
          })}
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex " >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5" >Output Type</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <Select isMulti onChange={handleChangeLang} options={datatype} />
                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex row" >
              <div className="col-lg-1"></div>
              <div className='col-lg-3'>
                <div className="mt-4 ms-5" > Add Output Specification </div>
              </div>
              <div className='col-lg-5'>
                <div className="d-flex row" >
                  <div className="col-lg-6 ">
                    <input type="text" className="form-control" required />
                  </div>
                  <div className="col-lg-6">
                    <input type="text" className="form-control" required />
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex " >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5" >Function Name</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <input type="text" value={functionName} className="form-control form-control-lg mt-2" onChange={(e) => setFunctionName(e.target.value)} required />

                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
          <div className="form-outline mb-4 ms-5">
            <div className="d-flex" >
              <div className="row" style={{ width: '100%' }}>
                <div className='col-lg-1'></div>
                <div className="col-lg-3">
                  <div className="mt-4 ms-5" >Question Topic</div>
                </div>
                <div className={`mt-3 ${quesCreation.col}`}>
                  <input type="text" value={topic} className="form-control form-control-lg mt-2" onChange={(e) => setTopic(e.target.value)} required />

                </div>
                <div className='col-lg-1'></div>
              </div>
            </div>
          </div>
        </div>

      </>)
  }

  const onSubmitStep = (data) => (
    console.log(data)
  )

  const AddTestcases = () => {
    return (<>{
      inputList.map((x, i) => {
        return (
          <div key={i}>
            <div className="form-outline mb-4 ms-5" >
              <div className="d-flex " >
                <div className="row" style={{ width: '100%' }}>
                  <div className='col-lg-1'></div>
                  <div className="col-lg-3">
                    <div className="mt-4 ms-5" >Testcase Type</div>
                  </div>
                  <div className={`mt-3 ${quesCreation.col}`}>
                    <Select options={testcaseType} onChange={e => handleInputChange(e, i)} />
                  </div>
                  <div className='col-lg-1'></div>
                </div>
              </div>
            </div>
            <div className="form-outline mb-4 ms-5">
              <div className="d-flex" >
                <div className="row" style={{ width: '100%' }}>
                  <div className='col-lg-1'></div>
                  <div className="col-lg-3">
                    <div className="mt-4 ms-5" >TestCase Title</div>
                  </div>
                  <div className={`mt-3 ${quesCreation.col}`}>
                    <input type="text" className="form-control form-control-lg mt-2" onChange={e => handleInputChange(e, i)} required />
                  </div>
                  <div className='col-lg-1'></div>
                </div>
              </div>
            </div>
            <div className="form-outline mb-4 ms-5">
              <div className="d-flex" >
                <div className="row" style={{ width: '100%' }}>
                  <div className='col-lg-1'></div>
                  <div className="col-lg-3">
                    <div className="mt-4 ms-5">TestCase Description</div>
                  </div>
                  <div className='col-lg-5'>
                  </div>
                  <div className='col-lg-1'></div>
                </div>
              </div>
            </div>
            <div className="form-outline mb-4 ms-5">
              <div className="d-flex" >
                <div className="row" style={{ width: '100%' }}>
                  <div className='col-lg-1'></div>
                  <div className="col-lg-3">
                    <div className="mt-4 ms-5" >Input Value</div>
                  </div>
                  <div className={`mt-3 ${quesCreation.col}`}>
                    <input type="text" className="form-control form-control-lg mt-2" onChange={e => handleInputChange(e, i)} required />
                  </div>
                  <div className='col-lg-1'></div>
                </div>
              </div>
            </div>
            <div className="form-outline mb-4 ms-5">
              <div className="d-flex" >
                <div className="row" style={{ width: '100%' }}>
                  <div className='col-lg-1'></div>
                  <div className="col-lg-3">
                    <div className="mt-4 ms-5">Output Value</div>
                  </div>
                  <div className={`mt-3 ${quesCreation.col}`}>
                    <input type="text" className="form-control form-control-lg mt-2" onChange={e => handleInputChange(e, i)} required />
                  </div>
                  <div className='col-lg-1'></div>
                </div>
              </div>
            </div>
          </div>)
      })}
      <div className={`btn ${quesCreation.btnMargin}`} onClick={handleAddClick}>Add Testcases</div></>
    )
  }

  const QuestionSettings = () => {
    return (<><div className="form-outline mb-4 ms-5">
      <div className="d-flex" >
        <div className="row" style={{ width: '100%' }}>
          <div className='col-lg-1'></div>
          <div className="col-lg-3">
            <div className="mt-4 ms-5">Add Solution</div>
          </div>
          <div className='col-lg-5'>
          </div>
          <div className='col-lg-1'></div>
        </div>
      </div>
    </div>
      <div className={`btn ${quesCreation.btnMargin}`} onClick={handleAddClick}>Question Preview</div></>)
  }



  function getStepContent(step) {
    switch (step) {
      case 0: return (<QuestionDetails></QuestionDetails>);
      case 1: return (<><AddTestcases></AddTestcases></>);
      case 2: return (<QuestionSettings />);
      default: return "Unknown Step";
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>

          <div className="mt-5" style={{ height: 'auto' }}>
            <FormProvider {...methods}>
              <form id="form3" onSubmit={methods.handleSubmit(onSubmitStep)}>
                {getStepContent(activeStep)}
              </form></FormProvider></div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pb: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button form="form3" onClick={handleNext} type="submit">
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>

          </Box>

        </React.Fragment>
      )}
    </Box>
  );
}