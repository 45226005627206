import { RESET_QUEST, GET_QUEST_DETAILS, UPDATE_QUEST_DETAILS, UPDATE_QUEST_DETAILS_BY_KEY, UPDATE_TEST_CASE_DETAILS, ADD_TEST_CASE, REMOVE_TEST_CASE } from "../actions/actions.types";

const RESET_STATE = {
    questDetails: {
        questionTopic: {
            value: '',
            msg: null,
        },
        difficultyLevel: {
            value: {value: '', label: ''},
            msg: null
        },
        questionTag: {
            value: [],
            msg: null,
        },
        functionName: {
            value: '',
            msg: null
        },
        questionTitle: {
            value: '',
            msg: null
        },
        questionText: {
            value: '',
            msg: null
        },
        codingLanguages: {
            value: [],
            msg: null
        },
        addInputs: {
            value: [],
            msg: null
        },
        addOutputs: {
            value: [],
            msg: null
        }
    },
    addTestcases: {
        data: null,
    },
    addSolution: {
        avLanguages: {
            value: [{ value: '', label: '' }],
            msg: null
        },
        selLang: {
            value: {label: '', value: ''},
            msg: null
        },
        ansDescription: {
            value: '',
            msg: null
        }
    },
    matchSpecs: {
        addInputs: [],
        addOutputs: [],
    }
}

const INITIAL_STATE = {
    questDetails: {
        questionTopic: {
            value: '',
            msg: null,
        },
        difficultyLevel: {
            value: {value: '', label: ''},
            msg: null
        },
        questionTag: {
            value: [],
            msg: null,
        },
        functionName: {
            value: '',
            msg: null
        },
        questionTitle: {
            value: '',
            msg: null
        },
        questionText: {
            value: '',
            msg: null
        },
        codingLanguages: {
            value: [],
            msg: null
        },
        addInputs: {
            value: [],
            msg: null
        },
        addOutputs: {
            value: [],
            msg: null
        }
    },
    addTestcases: {
        data: null,
    },
    addSolution: {
        avLanguages: {
            value: [{ value: '', label: '' }],
            msg: null
        },
        selLang: {
            value: {label: '', value: ''},
            msg: null
        },
        ansDescription: {
            value: '',
            msg: null
        }
    },
    matchSpecs: {
        addInputs: [],
        addOutputs: [],
    }
}

const questionReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case GET_QUEST_DETAILS:
            return {
                ...state,
                ...action.payload,
            }

        case UPDATE_QUEST_DETAILS:
            return {
                ...state,
                ...action.payload
            }

        case UPDATE_TEST_CASE_DETAILS:
            return {
                ...state,
                addTestcases: {
                    ...state.addTestcases,
                    data: [
                        ...action.payload,
                    ]
                }
            }

        case ADD_TEST_CASE:
            return {
                ...state,
                addTestcases: {
                    ...state.addTestcases,
                    data: [
                        ...state.addTestcases.data,
                        action.payload
                    ]
                }
            }

        case REMOVE_TEST_CASE:
                const testcaseData = state.addTestcases.data.filter(el => el.id !== action.payload);
    
                return {
                    ...state,
                    addTestcases: {
                        ...state.addTestcases,
                        data: testcaseData
                    }
                }

        case UPDATE_QUEST_DETAILS_BY_KEY:
                return {
                    ...state,
                    [action.payload.key]: {
                        ...state[action.payload.key],
                        ...action.payload.data
                    }
                }

        case RESET_QUEST:
            return {
                questDetails: {
                    questionTopic: {
                        value: '',
                        msg: null,
                    },
                    difficultyLevel: {
                        value: {value: '', label: ''},
                        msg: null
                    },
                    questionTag: {
                        value: [],
                        msg: null,
                    },
                    functionName: {
                        value: '',
                        msg: null
                    },
                    questionTitle: {
                        value: '',
                        msg: null
                    },
                    questionText: {
                        value: '',
                        msg: null
                    },
                    codingLanguages: {
                        value: [],
                        msg: null
                    },
                    addInputs: {
                        value: [],
                        msg: null
                    },
                    addOutputs: {
                        value: [],
                        msg: null
                    }
                },
                addTestcases: {
                    data: null,
                },
                addSolution: {
                    avLanguages: {
                        value: [{ value: '', label: '' }],
                        msg: null
                    },
                    selLang: {
                        value: {label: '', value: ''},
                        msg: null
                    },
                    ansDescription: {
                        value: '',
                        msg: null
                    }
                },
                matchSpecs: {
                    addInputs: [],
                    addOutputs: [],
                }
            }
            
        default:
            return state;
    }
}

export default questionReducer;