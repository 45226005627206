const localConfig = {
    "production": false,
    "BASE_URL": "http://localhost:8090",
    "TEST_ENV": "xyz",
    "WEB_URL": "http://localhost:3000",
    "SOURCE": "local",
    "API_URL": "/cms/v1",
    "PLAGIARISM_URL": "http://localhost:8080/external/v1/runOnText",
    "SKILL_URL": "/skill-taxonomy/v1",
    "REACT_APP_OAUTH_ISSUER": "dev-5710201.okta.com",
    "REACT_APP_OAUTH_CLIENTID": "0oagmjvwp5bFyuc9q5d7",
    "REACT_APP_OAUTH_REDIRECT_URI":
        "http%3A%2F%2Flocalhost%3A8090%2Fcms%2Flogin%2Fcallback",
}
export const DefaultConfig = localConfig;