import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import { checkPassword } from '../../utils/helperFn';
import CustomToolTip from '../custom-tooltip/CustomTooltip.component';
import instance from '../../utils/axios';
import CustomButtonLoader from '../global/CustomButtonLoader.component';

const ChangePasswordForm = ({ t, user }) => {
    const initial_val = {
        email: {
            value: user.userEmail,
            isRequired: true,
            msg: null
        },
        password: {
            value: '',
            isRequired: true,
            msg: null
        },
        passwordVisible: false,
        newPassword: {
            value: '',
            isRequired: true,
            msg: null
        },
        newPasswordVisible: false,
        passwordConfirm: {
            value: '',
            isRequired: true,
            msg: null
        },
        passwordConfirmVisible: false,
        isChangingPass: false,
        validOptions: {
            requiredFields: ['email', 'password', 'newPassword', 'passwordConfirm'],
            errorFields: [],
        },
    }
    
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);

    const [formVal, setFormVal] = React.useState(initial_val);

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value,
            }
        })
    }

    const changePassCall = async (data) => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token');

            const res = await instance.post(getApiUrl('changePassword'), data);
    
            if(res.data.status) {
                setIsLoading(false);
                dispatch(openSnackBar(
                    {
                    msg: t(res.data.message),
                    type: 'success'
                }));

                // dispatch(logout());
            } else {
                throw Error(res.data);
            }
        } catch (err) {
            setIsLoading(false);
            const errObj = apiErrorHandler(err);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    } 

    const getReqMsg = (fieldName) => {
        if(fieldName === 'password') {
            return t('change_pass_current_password_required');
        } else if(fieldName === 'newPassword' || fieldName === 'passwordConfirm') {
            return t('sign_up_password_required');
        } else {
            return t('field_required');
        }
    }

    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = formVal;

        formVal.validOptions.requiredFields.forEach(el => {
            if(currentFormVal[el].value === '') {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                //Password Validation
                if(el === 'password') {
                    const isValid = checkPassword(currentFormVal.password.value);
                    currentFormVal.password.msg = !isValid ? t("valid_password") : null;
                    return; 
                }

                if(el === 'newPassword') {
                    const isValid = checkPassword(currentFormVal.newPassword.value);
                    currentFormVal.newPassword.msg = !isValid ? t("valid_password") : null;
                    return;
                }

                if(el === 'passwordConfirm') {
                    const isValid = checkPassword(currentFormVal.passwordConfirm.value);
                    currentFormVal.passwordConfirm.msg = !isValid ? t("valid_password") : null;
                }

                //Match Passwords
                if(el === 'passwordConfirm') {
                    currentFormVal.passwordConfirm.msg = currentFormVal.newPassword.value !== currentFormVal.passwordConfirm.value ? t('passwords_not_match_change_password') : null;
                    return;
                }

                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if(formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if(isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
            !isLoading && encProcess();

        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        formVal.isChangingPass && handleValidation();
    }

    const handleChangePassword = () => {
        setFormVal({
            ...initial_val,
            isChangingPass: !formVal.isChangingPass
        })
    }

    const encProcess = async () => {
        try {
            setIsLoading(true);
            const getKey = await instance.get(getApiUrl('getSk'));
            if(!!getKey.data) {
                setIsLoading(false);
                const JSEncrypt = window.JSEncrypt;

                //RSA Public Key
                const rsaKey = getKey.data.key;
                const passToEncrypt = formVal.password.value;
                const newpassToEncrypt = formVal.newPassword.value;
                const confirmPassToEncrypt = formVal.passwordConfirm.value;
                const skid = getKey.data.id;

                //encrypt AES key with RSA public key
                var rsaEncrypt = new JSEncrypt({default_key_size: 2048});
                rsaEncrypt.setPublicKey(rsaKey);
                var rsaEncryptedAesKey = rsaEncrypt.encrypt(passToEncrypt.toString());
                var rsaEncryptedAesKey2 = rsaEncrypt.encrypt(newpassToEncrypt.toString());
                var rsaEncryptedAesKey3 = rsaEncrypt.encrypt(confirmPassToEncrypt.toString());

                var encryptedTransaction = { 
                    skid,
                    //email: formVal.email.value,
                    oldPassword: rsaEncryptedAesKey.toString(),
                    password: rsaEncryptedAesKey2.toString(), 
                    confirmPassword: rsaEncryptedAesKey3.toString(), 
                };

                changePassCall(encryptedTransaction)
            }

        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handlePassVisiblity = (fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: !formVal[fieldName]
        })
    }

    return(
        <form onSubmit={handleFormSubmit} className="cm-profile-form cm-reset-pass-form mt-4">
            <div className="row">
                <div className="col-lg-4">
                    <div className="form-group mb-3">
                        <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("email_id")}</label>
                        <div className="cm-start-icon position-relative">
                            <FontAwesomeIcon icon={regular("envelope")} className="text-blue-gray-700 position-absolute" />
                            <input type="email" className="form-control text-blue-gray-700 fw-medium bg-blue-gray-50" id="email" value={formVal.email.value} placeholder={t("login_email_placeholder")} onChange={(e) => handleFieldChange(e, 'email')} disabled={true} />
                        </div>
                        {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
                    </div>
                </div>
                <div className="col-lg-4" />
                <div className="col-lg-4" />
            </div>


            <Accordion expanded={formVal.isChangingPass}>
                <div className="row">
                    <div className="col-lg-4">
                        <div className="form-group mb-3">
                            <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("current_password")}</label>
                            <div className="cm-start-icon position-relative">
                                <FontAwesomeIcon icon={regular('key')}  className="text-blue-gray-700 position-absolute" />
                                <input type={formVal.passwordVisible ? 'text': 'password'} className={`form-control text-blue-800 fw-medium ${!formVal.isChangingPass ? 'bg-blue-gray-50' : ''}`} id="password" value={formVal.password.value} placeholder={formVal.isChangingPass ? t("password_placeholder") : "**********"} onChange={(e) => handleFieldChange(e, 'password')} disabled={!formVal.isChangingPass} />
                                <div className='cm-mult-icon-wrap d-flex'>
                                    <FontAwesomeIcon icon={formVal.passwordVisible ? regular('eye-slash') : regular('eye')}  className={`form-text text-blue-gray-500 cm-pass-visiblility-changer cm-pointer`} onClick={() => handlePassVisiblity('passwordVisible')} />
                                    <CustomToolTip 
                                        title={
                                            <>
                                                <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                                <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                                    <li>{t("pass_point_1")}</li>
                                                    <li>{t("pass_point_2")}</li>
                                                    <li>{t("pass_point_3")}</li>
                                                    <li>{t("pass_point_4")}</li>
                                                </ul>
                                            </>
                                        }
                                    >
                                        <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 cm-pass-info-icon`} />
                                    </CustomToolTip>
                                </div>
                            </div>
                            {formVal.password.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.password.msg}</span>}
                        </div>
                    </div>
                    <div className="col-lg-4">
                       {!formVal.isChangingPass && <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                            <span className="btn btn-primary cm-sm-txt cm-btn cm-trans-btn text-blue-800" onClick={handleChangePassword}>{t("change_password")}</span>
                        </div>}
                    </div>
                    <div className="col-lg-4" />
                </div>

                <AccordionDetails>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                                <label htmlFor="newPassword" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("new_password")}</label>
                                <div className="cm-start-icon position-relative">
                                    <FontAwesomeIcon icon={regular('key')}  className="text-blue-gray-700 position-absolute" />
                                    <input type={formVal.newPasswordVisible ? 'text': 'password'} className={`form-control text-blue-800 fw-medium`} id="newPassword" value={formVal.newPassword.value} placeholder={t("password_placeholder")} onChange={(e) => handleFieldChange(e, 'newPassword')} />
                                    <div className='cm-mult-icon-wrap d-flex'>
                                        <FontAwesomeIcon icon={formVal.newPasswordVisible ? regular('eye-slash') : regular('eye')}  className={`form-text text-blue-gray-500 cm-pass-visiblility-changer cm-pointer`} onClick={() => handlePassVisiblity('newPasswordVisible')} />
                                        <CustomToolTip 
                                            title={
                                                <>
                                                    <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                                    <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                                        <li>{t("pass_point_1")}</li>
                                                        <li>{t("pass_point_2")}</li>
                                                        <li>{t("pass_point_3")}</li>
                                                        <li>{t("pass_point_4")}</li>
                                                    </ul>
                                                </>
                                            }
                                        >
                                            <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 cm-pass-info-icon`} />
                                        </CustomToolTip>
                                    </div>
                                </div>
                                {formVal.newPassword.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.newPassword.msg}</span>}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="form-group mb-3">
                                    <label htmlFor="password-confirm" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("reenter_new_password")}</label>
                                    <div className="cm-start-icon position-relative">
                                        <FontAwesomeIcon icon={regular('key')}  className="text-blue-gray-700 position-absolute" />
                                        <input type={formVal.passwordConfirmVisible ? 'text': 'password'} className="form-control text-blue-800 fw-medium" id="password-confirm" value={formVal.passwordConfirm.value} placeholder={t("password_placeholder")} onChange={(e) => handleFieldChange(e, 'passwordConfirm')} />
                                        <div className='cm-mult-icon-wrap d-flex'>
                                            <FontAwesomeIcon icon={formVal.passwordConfirmVisible ? regular('eye-slash') : regular('eye')}  className={`form-text text-blue-gray-500 cm-pass-visiblility-changer cm-pointer`} onClick={() => handlePassVisiblity('passwordConfirmVisible')} />
                                            <CustomToolTip 
                                                title={
                                                    <>
                                                        <span className='my-0 cm-sm-txt fw-medium'>{t("your_password_should_be")}</span>
                                                        <ul className='cm-tooltip-pass-info mt-3 mb-0 ps-3 cm-sm-txt fw-medium'>
                                                            <li>{t("pass_point_1")}</li>
                                                            <li>{t("pass_point_2")}</li>
                                                            <li>{t("pass_point_3")}</li>
                                                            <li>{t("pass_point_4")}</li>
                                                        </ul>
                                                    </>
                                                }
                                            >
                                                <FontAwesomeIcon icon={regular("question-circle")} className={`form-text text-blue-gray-500 cm-pass-info-icon`} />
                                            </CustomToolTip>
                                        </div>
                                    </div>
                                    {formVal.passwordConfirm.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.passwordConfirm.msg}</span>}
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="cm-form-btn-group mt-4 pt-2 position-relative d-flex align-items-center">
                                <CustomButtonLoader
                                    showLoadingState ={isLoading}
                                    colorTheme= "blue"
                                    icon={regular('angle-right')}
                                    reverseIconDirection={false}
                                    buttonLabel={t("save_changes")}
                                    buttonStyle={"px-12 py-10 cm-mar-left-icon"}
                                    handleOnClick={handleFormSubmit}
                                />

                                <span className="btn btn-primary cm-sm-txt cm-btn cm-trans-btn ms-3 text-blue-800" onClick={handleChangePassword}>{t("cancel")}</span>
                            </div>
                            
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </form>
    )

}

export default ChangePasswordForm;