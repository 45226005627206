import * as React from 'react';
import { MenuItem, Modal, Select } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { TextareaAutosize } from '@mui/material';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import "./CreateBankModal.css";
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';

const CreateBankModal = ({ modalStatus, handleModalToggle, getAllBanks, role }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialState = {
        bankName: "",
        bankDescription: ""
    }
    const [formVal, setFormVal] = React.useState(initialState)
    const [nameError, setNameError] = React.useState("")
    const [descriptionNameError, setDescriptionNameError] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false);

    const [createNewBank, setCreateNewBank] = React.useState(false);
    const [fetchFromTask, setFetchFromTask] = React.useState(false);
    const [fetchedCompletedTasks, setFetchedCompletedTasks] = React.useState(false);
    const [completedTaskOptions, setCompletedTaskOptions] = React.useState([]);
    const [completedTaskSelected, setCompletedTaskSelected] = React.useState();

    const user = useSelector(state => state.user);

    const loadSelectOptions = (list) => list.map((el) => (
    <MenuItem  value={el} key={el.taskId}>
        <div className=' d-flex flex-column'>
            <span className='fw-medium text-blue-800'>{el.skillName}</span>
            <span className='cm-xs-txt text-blue-gray-700'>{"task id : " + el.taskId}</span>
        </div>
    </MenuItem>
    ));

    const handleFormChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: e.target.value
        })

    }

    const fetchAllCompletedTasks = async () => {
        try {
            const res = await instance.get(getApiUrl('getAllCompletedTasks'));
            const completedTasks = res.data?.map((task) => {
                return { skillName: task.topicName, taskId: task.taskId };
            })

            setCompletedTaskOptions(completedTasks);
            setFetchedCompletedTasks(true);
        } catch (error) {
            const errorObj = apiErrorHandler(error)
            dispatch(
                openSnackBar({
                    msg: t(errorObj.statusText),
                    type: "error"
                })
            )
        }
    }

    React.useEffect(() => {
        setNameError("");
        setDescriptionNameError("");
        setFormVal(initialState);
        setCreateNewBank(false);
        setFetchFromTask(false);
        setCompletedTaskSelected(null);
    }, [modalStatus])


    React.useEffect(() => {
        if (modalStatus && !fetchedCompletedTasks && user?.role==="ADMIN") {
            fetchAllCompletedTasks();
        }
    }, [modalStatus])

    

    const handleValidation = (dataTosend) => {
        let nameMsg = "Bank name should only contian a-z/A-Z/0-9 characters";
        let emptyBankName = "Bank name should not be empty"
        let descriptionMsg = "Bank description should not be empty"
        let isvaild = true;
        var regexPattern = new RegExp("^[a-zA-Z0-9_\\-. ]*$")
        if (dataTosend.reviewBankName.length <= 0 || !regexPattern.test(dataTosend.reviewBankName)) {
            setNameError(dataTosend.reviewBankName.length <= 0 ? emptyBankName : nameMsg)
            isvaild = false
        }
        else {
            setNameError("");
        }
        if (!dataTosend.reviewBankDesc.length) {
            isvaild = false
            setDescriptionNameError(dataTosend.reviewBankDesc ? "" : descriptionMsg)
        }
        return isvaild;
    }

    const handleCreateNewBank = async () => {
        try {
            const dataToSend = {
                reviewBankName: formVal.bankName,
                reviewBankDesc: formVal.bankDescription
            }
            const isVaild = handleValidation(dataToSend);
            if (!isVaild) return;
            const res = await instance.post(getApiUrl('saveRootBank'), dataToSend);
            if (res.data.status) getAllBanks();
            dispatch(
                openSnackBar({
                    type: res.data.status ? "success" : "error",
                    msg: res.data.message,
                })
            );
        }

        catch (error) {
            const errorObj = apiErrorHandler(error)
            dispatch(
                openSnackBar({
                    msg: t(errorObj.statusText),
                    type: "error"
                })
            )
        }
        handleModalToggle();
    }

    const handleBankChange = (type) => {
        if (type === "new_bank") {
            setFetchFromTask(false);
            setCreateNewBank(true);
        }
        else if (type === "from_task") {
            setCreateNewBank(false);
            setFetchFromTask(true);
        }
    }

    const handleSelectChange = (e) => {
        setCompletedTaskSelected(e.target.value);
        console.log("Event is : ", e.target);
    }

    const handleFetchFromTask = async () => {
          try {
            const dataToSend = {
                reviewBankName: completedTaskSelected.skillName,
                reviewBankDesc: "This bank is created from Completed task"
            }
            const isVaild = handleValidation(dataToSend);
            if (!isVaild) return;
            const res = await instance.post(`${getApiUrl('saveBankFromTask')}?taskId=${completedTaskSelected.taskId}`, dataToSend);
            if (res.data.status) getAllBanks();
            dispatch(
                openSnackBar({
                    type: res.data.status ? "success" : "error",
                    msg: res.data.message,
                })
            );
          } catch(error) {
            const errorObj = apiErrorHandler(error)
            dispatch(
                openSnackBar({
                    msg: t(errorObj.statusText),
                    type: "error"
                })
            )
          }
          handleModalToggle();
    }




    return (
        <div>
            <Modal open={modalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
                <div className='cm-create-bank-form-container cm-add-bank-form-container bg-white my-3'>
                    <div className='cm-create-modal-form-header py-2 px-4 d-flex align-items-center justify-content-between'>
                        <h5 className='text-blue-800 my-0'>Create New Bank</h5>
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalToggle} />
                    </div>
                    <div className="mt-2 mb-2 cm-create-task-form px-4 pt-2 pb-2 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-center">
                            <input type="radio" id="create_new_bank" name="new_bank" onChange={() => handleBankChange("new_bank")} checked={createNewBank} style={{ cursor: 'pointer' }} />
                            <label htmlFor="create_new_bank" className='px-2 text-blue-gray-700 cm-md-txt fw-medium'>Create new</label>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <input type="radio" id="fetch_from_task" name="from_task" onChange={() => handleBankChange("from_task")} checked={fetchFromTask} style={{ cursor: 'pointer' }} />
                            <label htmlFor="fetch_from_task" className='px-2 text-blue-gray-700 cm-md-txt fw-medium'>Fetch from Task</label>
                        </div>
                    </div>

                    {createNewBank &&
                        <div className="mt-1 cm-create-task-form px-4 pb-4 ">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="tagName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("bank_name")} :</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt" value={formVal.bankName} placeholder={t("bank_name_placeholder")} onChange={(e) => { handleFormChange(e, 'bankName') }} />
                                </div>
                                {nameError !== null && <span className='cm-xs-txt text-danger fw-medium pt-1'>{nameError} </span>}
                            </div>
                            <div className="mt-3 col-sm-12">
                                <div className="form-group ">
                                    <label htmlFor="tagName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("bank_description")} :</label>
                                    <TextareaAutosize
                                        maxRows={12}
                                        minRows={4}
                                        className="form-control text-blue-800 fw-medium cm-sm-txt"
                                        aria-label="maximum height"
                                        placeholder={t("bank_description_placeholder")}
                                        style={{
                                            "overflowY": "auto",
                                            "resize": "none",
                                        }}
                                        value={formVal.bankDescription}
                                        onChange={(e) => { handleFormChange(e, 'bankDescription') }}
                                    />
                                </div>
                            </div>
                            {descriptionNameError !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{descriptionNameError} </span>}

                        </div>
                    }

                    {fetchFromTask &&
                        <div className="cm-create-task-form px-4 ">
                            <div className="mt-3 mb-4 col-sm-12">
                                <div className="form-group">
                                    <label htmlFor="completed-task" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Completed task : </label>
                                    <Select
                                        id="completed-task"
                                        value={completedTaskSelected}
                                        onChange={val => handleSelectChange(val)}
                                    >
                                            {loadSelectOptions(completedTaskOptions)}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="cm-form-btn-group py-2  cm-create-modal-form-footer d-flex align-items-center justify-content-end">
                        {(createNewBank || fetchFromTask) &&
                            <CustomButtonLoader
                                showLoadingState ={isLoading}
                                colorTheme= "blue"
                                icon={regular('angle-right')}
                                reverseIconDirection={false}
                                buttonLabel={t("create_bank")}
                                buttonStyle={`px-12 py-10 cm-mar-left-icon`}
                                handleOnClick={createNewBank ? handleCreateNewBank : handleFetchFromTask}
                            />
                        }
                    </div>

                </div>
            </Modal>
        </div>
    )
}
export default CreateBankModal;