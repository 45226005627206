import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AnimationDataLoader from '../../components/Loaders/animationData';
import ReactResizeDetector from 'react-resize-detector';

import AceEditor from 'react-ace';
import * as ace from 'brace';
import 'brace/theme/monokai';
import 'brace/theme/tomorrow';
import 'brace/mode/javascript';
import 'brace/mode/java';
import 'brace/mode/c_cpp';
import 'brace/mode/python';
import 'brace/ext/language_tools';
import "ace-builds/src-noconflict/ext-language_tools";
import './QuestionCreation.styles.css';
import instance from '../../utils/axios';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { getApiUrl } from '../../utils/apiUrls';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { useDispatch, useSelector } from 'react-redux';
import { apiErrorHandler } from '../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import { appError } from '../../redux/actions/error.action';
import ErrorController from '../../pages/error/ErrorController.page';

const editorModes = {
    c: 'c_cpp',
    cpp: 'c_cpp',
    java: 'java',
    javascript: 'javascript',
    python: 'python'
}

const roles = [
    { ansDescription: '', assignedName: "Creator", assignedTo: "creator" }
]


const PreviewQuestionReviewer = ({ questData, getQuestid, getReqId, user }) => {
    const dispatch = useDispatch();
    const initial_state = {
        ...questData.addSolution
    }

    const editorRef = React.useRef(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [roleSel, setRoleSel] = React.useState(roles[0]);
    // const [roles, setRoles] = React.useState([]);
    const [formVal, setFormVal] = React.useState(initial_state);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        qId: null,
    });
    const { t } = useTranslation();
    const [codeEditor, setCodeEditor] = React.useState(false);;
    const chkError = useSelector((state) => state.error);
    const [editorDimension, setEditorDimension] = React.useState({
        editorWidth: 'auto',
        editorHeight: 500
    })
    const [codeCompile, setCodeCompile] = React.useState(false);
    const [enableCases, setEnableCases] = React.useState(null);
    const [compileTestCases, setCompileTestCases] = React.useState([]);
    const [allTestcaseRun, setAllTestcaseRun] = React.useState(-1);
    const [compilationError, setCompilationError] = React.useState(null);
    const [isRunning, setIsRunning] = React.useState(false);
    const [permission, setPermission] = React.useState(false);

    const [editorOp, setEditorOp] = React.useState({
        darkMode: false,
    });

    // console.log("in preview question reviewer", questData);


    React.useEffect(() => {
        setTimeout(() => { setCodeEditor(true) }, 1000)
    }, [])

    const handleDialogToggle = (qId) => {
        setDialogStatus({
            ...dialogStatus,
            status: !dialogStatus.status,
            qId: !!qId ? qId : null
        });
    }

    const dialogActionFn = () => {
        grantAccessApi(dialogStatus.qId)
        setDialogStatus({
            ...dialogStatus,
            status: false,
            qId: null,
        })
    }

    const grantAccessApi = async (qId) => {
        try {
            const res = await instance.get(`${getApiUrl('requestForTestcaseData')}/${getQuestid}`);
            if (res.data) {

                dispatch(openSnackBar({
                    type: 'success',
                    msg: 'Testcase request has been sent to the Admin.'
                }))

            }
        } catch (error) {
            console.log('grantAccessApi error', error);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const requestPermisssionStatus = (qid) => {
        instance.get(`${getApiUrl('getQuestionReviewerMapping')}/${qid}`)
            .then(function (response) {
                if (response.data) {
                    //setState({...state,data:response.data})
                    setPermission(response.data.showTestcase);
                }
                ;
            })
            .catch(function (error) {
                console.log(error);
                appError('PreviewQuestionReviewer', error, dispatch);
            });
    }

    React.useEffect(() => {
        setFormVal(questData.addSolution);
    }, [questData])

    React.useEffect(() => {
        requestPermisssionStatus(getQuestid);
    }, []);



    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })
    }

    const handleSelectChange = async (event, fieldName, arr) => {
        let val = {};

        val = arr[arr.findIndex(el => el.value === event.target.value)]
        ////console.log('event', event.target.value);

        //console.log(ansLang.value);
        //console.log("event",event.target.value);
        const ansLang = questData.addSolution.selLang.value;

        if (ansLang.value === event.target.value) {

            setFormVal({
                ...formVal,
                [fieldName]: {
                    ...formVal[fieldName],
                    value: val
                },
                ["ansDescription"]: {
                    ...formVal['ansDescription'],
                    value: questData.addSolution.ansDescription.value
                }
            })
        }
        else {
            const res = await instance.get(`${getApiUrl('getCodingTemplate')}?questionId=${getQuestid}&codeLanguage=${event.target.value.toLowerCase()}`);
            setFormVal({
                ...formVal,
                [fieldName]: {
                    ...formVal[fieldName],
                    value: val
                },
                ["ansDescription"]: {
                    ...formVal['ansDescription'],
                    value: res.data
                }
            })
        }

    }

    const handleBasicSelectChange = (e) => {
        const getEl = formVal.reviewerDTOList.value.filter(el => el.assignedTo === e.target.value);

        const getLangInd = formVal.avLanguages.value.findIndex(el => el.id === getEl[0].ansLangId);

        setRoleSel(getEl[0]);
        setFormVal({
            ...formVal,
            ansDescription: {
                ...formVal.ansDescription,
                value: getEl[0].ansDescription,
            },
            selLang: {
                ...formVal.selLang,
                value: formVal.avLanguages.value[getLangInd],
            }
        })
    }

    const handleEditorChange = (val) => {
        setFormVal({
            ...formVal,
            ['ansDescription']: {
                ...formVal['ansDescription'],
                value: val
            }
        })

    }

    const checkSolution = () => {
        checkCodeCompile("enable");
    }

    // console.log('Formval', formVal)

    const checkCodeCompile = async (state) => {
        try {
            setIsLoading(true);
            setEnableCases(state);
            // const isFormValid = handleAddSolutionValidation();

            // console.log('Valid?', isFormValid);

            // if(!isFormValid) {
            //     setIsLoading(false);
            //     return;
            // }
            if (!formVal.selLang.value.value) {
                dispatch(openSnackBar({
                    type: 'error',
                    msg: 'Please select coding language'
                }))

                return;
            }

            if (!formVal.ansDescription.value) {
                dispatch(openSnackBar({
                    type: 'error',
                    msg: 'Editor field cannot be empty.'
                }))

                return;
            }


            const dataObj = questData.questDetails;

            const dataToSend = {
                codeLanguages: dataObj.codingLanguages.value.map(el => el.label),
                ansLangId: formVal.selLang.value.id,
                ansDescription: formVal.ansDescription.value,
            }

            // console.log('Ans', dataToSend)

            if (!!getQuestid) dataToSend.questionId = getQuestid;
            setIsRunning(true);
            const res = await instance.post(`${getApiUrl("checkCodeCompilation")}`, dataToSend);
            if (res.data) {
                setIsRunning(false);
                if (!!res.data.status) {
                    setCompilationError(null);
                    let count = 0;
                    setCompileTestCases(res.data.testcaseResponseDtoList);

                    if (res.data.status) {
                        for (var i = 0; i < res.data.testcaseResponseDtoList.length; i++) {
                            if (res.data.testcaseResponseDtoList[i].status) count = count + 1;
                        }


                        if (count === res.data.testcaseResponseDtoList.length) {
                            setAllTestcaseRun(1);
                            setEnableCases("enable");
                        }
                        else
                            setAllTestcaseRun(0);

                        // navigate(`/add-solution?reqId=${getReqId}&qId=${!!getQuestid ? getQuestid : res.data.id}`, { state: { fromAdd: true } });
                    }
                }
                else {
                    setCompilationError(res.data.message);
                }
            }

        } catch (error) {
            setIsLoading(false);
            console.log('error', error);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleEditorTheme = () => {
        setEditorOp({ ...editorOp, darkMode: !editorOp.darkMode })
    }

    const handleSaveCode = async () => {
        try {
            setIsLoading(true);

            const dataObj = questData.questDetails;

            const dataToSend = {
                codeLanguages: dataObj.codingLanguages.value.map(el => el.label),
                ansLangId: formVal.selLang.value.id,
                ansDescription: formVal.ansDescription.value,
            }

            if (!!getQuestid) dataToSend.questionId = getQuestid;

            const res = await instance.post(`${getApiUrl('saveReviewerAnswer')}`, dataToSend);
            if (res.data.status) {
                dispatch(openSnackBar({
                    type: 'success',
                    msg: 'Your code has been saved.'
                }))
            }


        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleError = () => chkError.location === 'PreviewQuestionReviewer'

    return (
        <>
        {
          
            handleError() ? <ErrorController data={chkError} /> :
            <div className='cm-preview-quest-container m-4 p-3 bg-white'>
                <div className="row">
                    <div className="col-sm-6">
                        {
                            (questData.questDetails !== null && questData.addTestcases.data !== null) ?

                                <div className='cm-preview-quest-info cm-review-quest-info'>
                                    <p><strong>{questData.questDetails.questionTitle.value}</strong></p>
                                    <div className='cm-content cm-sm-txt' dangerouslySetInnerHTML={{ __html: questData.questDetails.questionText.value }}></div>
                                    <p className='pt-3 mb-2'><strong>Input Specification:</strong></p>
                                    <div className='p-2 cm-io-spec-wrap mb-3 ' style={{ backgroundColor: '#f6f6f6' }}>
                                        {
                                            questData.questDetails?.addInputs.value.map((el, index) => <p key={index} className="my-0 cm-sm-txt"><strong>input{index + 1} : </strong>{el.preText}</p>)
                                        }
                                    </div>

                                    <p className='mb-2'><strong>Output Specification:</strong></p>
                                    <div className='p-2 cm-io-spec-wrap mb-4' style={{ backgroundColor: '#f6f6f6' }}>
                                        {
                                            questData.questDetails.addOutputs.value.map((el, index) => 
                                            <p key={index} className="my-0 cm-sm-txt">
                                                <strong>output{index + 1} : </strong>
                                                {el.preText}
                                            </p>
                                            )
                                        }
                                    </div>

                                    {
                                        questData.addTestcases.data.map((el, index) => (
                                            <div className='cm-test-case-item mt-4'>
                                                <p className='mb-2'><strong>Example {index + 1}:</strong></p>
                                                <div className='p-2 cm-io-spec-wrap mb-3 text-break' style={{ backgroundColor: '#f6f6f6' }}>
                                                    {
                                                        el.inputArr.map((innerEl, innerInd) => <p key={innerInd} className="my-0 cm-sm-txt"><strong>input{innerInd + 1} : </strong>{innerEl.value}</p>)
                                                    }
                                                </div>
                                                <div className='p-2 cm-io-spec-wrap mb-3' style={{ backgroundColor: '#f6f6f6' }}>
                                                    {
                                                        el.outputArr.map((innerEl, innerInd) => <p key={innerInd} className="my-0 cm-sm-txt"><strong>output{innerInd + 1} : </strong>{innerEl.value}</p>)
                                                    }
                                                </div>
                                                <div>
                                                    <p className='mb-2'><strong>Explanation:</strong></p>
                                                    <div className='cm-content cm-sm-txt' dangerouslySetInnerHTML={{ __html: el.testcaseDesc.value }}></div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div> :
                                <div className="d-flex align-items-center justify-content-center">
                                    <AnimationDataLoader />
                                </div>
                        }
                    </div>
                    <div className="cm-editor-wrap px-0 col-sm-6">
                        {
                            questData.questDetails !== null && questData.addTestcases.data !== null ?
                                <>
                                    <div className='cm-code-editor-header border border-blue-gray-300 d-flex align-items-center justify-content-between'>
                                        <div className="cm-left-col d-flex" >
                                            <div className="cm-lang-sel col-sm-4 position-relative">
                                                {user.role !== 'ADMIN' ? <>
                                                <Select
                                                    labelId="avLanguagesLabel"
                                                    id="avLanguages"
                                                    displayEmpty
                                                    value={formVal.selLang.value?.value}
                                                    onChange={(e) => handleSelectChange(e, 'selLang', formVal?.avLanguages.value)}
                                                    fullWidth={true}
                                                    renderValue={(val) => {
                                                        if (val === '') {
                                                            return <span className='text-blue-gray-400'>Select Language</span>;
                                                        }

                                                        return val;
                                                    }}
                                                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                                    
                                                    >
                                                    {
                                                        formVal?.avLanguages.value.map(el => <MenuItem value={el.value} key={el.value} className="fw-medium cm-sm-txt text-blue-800">{el.label}</MenuItem>)
                                                    }
                                                </Select>
                                                    <FontAwesomeIcon 
                                                    icon={solid("angle-down")} 
                                                    className="cm-sel-arrow-icon text-blue-gray-700 position-absolute" 
                                                    /> 
                                                    </>
                                                       : 
                                                       <div className=" px-4 mt-2 bg-white cm-sm-txt fw-medium text-blue-800">
                                                        {formVal.selLang.value?.value}
                                                       </div>
                                                    }

                                            </div>
                                            {/* for reviwer dropdown  */}
                                            {user.role === 'ADMIN' && !!formVal.reviewerDTOList && <div className="ms-2 cm-lang-sel col-sm-3 position-relative" style={{ 'borderLeft': '1px solid #b0d9ea' }}>
                                                <Select
                                                    labelId="avRolesLabel"
                                                    id="avRoles"
                                                    value={roleSel.assignedTo}
                                                    onChange={handleBasicSelectChange}
                                                    fullWidth={true}
                                                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field">
                                                    {
                                                        formVal.reviewerDTOList.value.map(el => 
                                                        <MenuItem 
                                                        value={el.assignedTo} 
                                                        key={el.assignedTo} 
                                                        className="fw-medium cm-sm-txt text-blue-800"
                                                        >
                                                            {el.assignedName}
                                                        </MenuItem>
                                                        )
                                                    }
                                                </Select>
                                                <FontAwesomeIcon icon={solid("angle-down")} className="cm-sel-arrow-icon text-blue-gray-700 position-absolute" />
                                            </div>}
                                        </div>
                                        <div className="cm-other-op d-flex align-items-center justify-content-end">
                                            {user.role !== 'ADMIN' && <span className="cm-editor-compile-btn cm-pointer p-2 text-white bg-action-blue fw-medium cm-sm-txt me-3" onClick={handleSaveCode}>
                                                Save Code
                                            </span>}
                                            <span className="cm-editor-compile-btn cm-pointer p-2 text-white bg-action-blue fw-medium cm-sm-txt me-3" onClick={checkSolution}>
                                                Compile and Run
                                            </span>
                                            {user.role !== 'ADMIN' && <span className="cm-editor-compile-btn cm-pointer p-2 text-action-blue fw-medium cm-sm-txt me-3" onClick={() => handleDialogToggle(getQuestid)}>
                                                Request Testcase
                                            </span>}
                                            <span className="cm-edtior-theme-control cm-pointer p-2" onClick={handleEditorTheme}>
                                                <FontAwesomeIcon icon={editorOp.darkMode ? regular("sun-bright") : regular("moon")} className="text-blue-gray-700" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="resizable">
                                            <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => setEditorDimension({ editorWidth: w, editorHeight: h })} />
                                            {
                                                codeEditor && <AceEditor
                                                    mode={editorModes[formVal.selLang.value.value?.toLowerCase()]}
                                                    setOptions={{
                                                        enableBasicAutocompletion: true,
                                                        enableLiveAutocompletion: true,
                                                        // enableSnippets: true
                                                    }}
                                                    onChange={handleEditorChange}
                                                    value={formVal.ansDescription.value}
                                                    theme={editorOp.darkMode ? 'monokai' : 'tomorrow'}
                                                    showPrintMargin={true}
                                                    showGutter={true}
                                                    highlightActiveLine={true}
                                                    ref={editorRef}
                                                    className={`${editorOp.fullScreen ? 'cm-full-screen' : ''}`}
                                                    readOnly={user.role === 'ADMIN'}
                                                    width={editorDimension.editorWidth}
                                                    height={editorDimension.editorHeight}
                                                />
                                            }
                                        </div>
                                        {formVal.ansDescription.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.ansDescription.msg}</span>}
                                    </div>
                                    {
                                        compilationError == null && compileTestCases !== null &&
                                        <>{!isRunning && compileTestCases.map((x, i) => {
                                            return (
                                                <div className={`mb-4 text-break ${x.inputValues === null && 'cm-acc-disabled'}`}>
                                                    <Accordion defaultExpanded={x.inputValues != null && i == 0 ? true : false}>
                                                        <AccordionSummary
                                                            expandIcon={x.inputValues != null && <FontAwesomeIcon icon={regular('chevron-down')} className="text-blue-800" />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header" className='bg-gray-100'>
                                                            <div className="d-flex text-break">
                                                                {!x.status && <FontAwesomeIcon style={{ 'color': 'red' }} icon={regular('circle-xmark')} className="pt-1" />}
                                                                {x.status && <FontAwesomeIcon style={{ 'color': 'green' }} icon={regular('circle-check')} className="pt-1" />}
                                                                <div className='my-0 ps-2 text-blue-800 cm-mmc-font'>{x.name}</div>
                                                            </div>
                                                        </AccordionSummary>
                                                        {x.inputValues != null && <AccordionDetails>
                                                            <hr role="presentation" className="mx-4 w-25 text-blue-gray-500 lineWidth"></hr>
                                                            <p className="mb-0 text-blue-800 font-weight-500">
                                                                <FontAwesomeIcon icon={regular("code")} /> TEST CASE INFORMATION
                                                            </p>
                                                            <p className="px-4 mb-0 mt-2 text-blue-gray-700">Input</p>
                                                            <div className="px-4 mb-0 text-blue-800 pt-0 question-description-text">
                                                                <p>{x.inputValues.join("\r\n")}</p>
                                                            </div>
                                                            <p className="px-4 mb-0 mt-2 text-blue-gray-700">Expected Output</p>
                                                            <div className="px-4 mb-0 text-blue-800 pt-0 question-description-text">
                                                                <p>{x.outputValues.join("\r\n")}</p>
                                                            </div>
                                                            <p className="px-4 mb-0 mt-2 text-blue-gray-700">Actual Output</p>
                                                            <div className="px-4 mb-0 text-blue-800 pt-0 question-description-text">
                                                                <p>{x.outputResult}</p>
                                                            </div>
                                                            {/* <label className="cm-sm-txt text-blue-800 fw-medium text-break">TEST CASE INFORMATION</label> */}
                                                            {/* {!!x.inputValues && <div className="cm-sm-text text-blue-800">Input:{x.inputValues.join(', ')}</div>} */}
                                                            {/* {!!x.outputValues && <div className="cm-sm-text text-blue-800">Expected Output:{x.outputValues.join(', ')}</div>} */}
                                                            {/* <div className="cm-sm-text text-blue-800">Actual Output:{x.outputResult}</div> */}
                                                        </AccordionDetails>}
                                                    </Accordion>
                                                </div>
                                            )
                                        })}
                                        </>}

                                    {compilationError !== null &&
                                        <Accordion defaultExpanded={true} style={{ 'width': 'calc(100% - 10px', 'maxWidth': '70vw' }}>
                                            <AccordionSummary
                                                expandIcon={<FontAwesomeIcon icon={regular('plus')} className="text-blue-800" />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                className='bg-gray-100'>
                                                <div className="d-flex">
                                                    <FontAwesomeIcon style={{ 'color': 'red' }} icon={regular('circle-xmark')} className="pt-1" />
                                                    <div className='my-0 ps-2 text-blue-800 cm-mmc-font'>Code Compilation Failed</div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="cm-sm-text text-blue-800">{compilationError}</div>
                                            </AccordionDetails>
                                        </Accordion>
                                    }

                                    {isRunning &&
                                        <div className="d-flex justify-content-center">
                                            <AnimationDataLoader />
                                        </div>
                                    }
                                    <CustomDialog
                                        dialogHeading="Access Testcases"
                                        cancelBtnLabel="Cancel"
                                        actionBtnLabel={<span>Request Access</span>}
                                        cancelFn={handleDialogToggle}
                                        actionFn={dialogActionFn}
                                        dialogStatus={dialogStatus.status}>
                                        <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to access Testcases?</p>
                                    </CustomDialog>
                                </>
                                : null
                        }
                    </div>
                </div>
            </div>
            
        }
        </>
       
    )

}

export default PreviewQuestionReviewer;