import { errorHandler } from "../../utils/errorHandler";
import { APP_ERROR } from "./actions.types";

export const appError = (location, err, dispatch) => {
    dispatch({
        type: APP_ERROR,
        payload: {
            location,
            err: errorHandler(err)
        }
    })
}