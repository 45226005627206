import React from 'react';
import {Helmet} from "react-helmet";

const MetaComp = ({ title }) => {

    return(
        <Helmet>
            {!!title && <title>{title}</title>}
        </Helmet>
    )
}

MetaComp.defaultProps = {
    title: ''
}

export default MetaComp;