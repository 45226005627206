import axios from "axios";

const instance = axios.create({
  withCredentials: true
})

// const instance_excel=axios.create({
//   withCredentials:true,
//   responseType:'blob'
// })

// export default instance;

// import axios from "axios";
// import { getApiUrl } from "./apiUrls";

// const instance = axios.create();

// // Request interceptor for API calls
// // instance.interceptors.request.use(
// //     async config => {
// //         //const token = getToken();
// //         const token = localStorage.getItem('token');
// //         config.headers = { 
// //             'Authorization': `Bearer ${token}`,
// //         }
// //         return config;
// //     },
// //     error => {
// //       Promise.reject(error)
// // });

// // const refreshAccessToken = async () => {
// //     try {
// //         const token = localStorage.getItem('token');

// //         if(!!token) {
// //             const res = await axios.get(getApiUrl('refreshToken'), {
// //                 headers: {
// //                     "Authorization" : `Bearer ${token}`,
// //                 }
// //             });

// //             //Set
// //             // cookies.set('token', res.data.jwtToken, { path: '/', expires: new Date(moment(decodedData.exp * 1000).format()) });
// //             // cookies.set('exp', moment(decodedData.exp * 1000).format() , { path: '/', expires: new Date(moment(decodedData.exp * 1000).format()) });
// //             localStorage.setItem('token', res.data.jwtToken);

// //             return res.data.jwtToken;
// //         }
// //     } catch (error) {
// //         localStorage.clear();
// //         window.location.href = '/login';
// //         return error;
// //     }
// // }

// // Response interceptor for API calls
instance.interceptors.response.use((response) => {  return response; }, async function (error) {
    const originalRequest = error.config;
    console.log('Interceptor', error);
    if (!!error.response && error.response.status === 401 && !originalRequest._retry) {
      // originalRequest._retry = true;
      // const access_token = await refreshAccessToken();
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      localStorage.clear();
      window.location.href = '/login';
      return instance(originalRequest);
    }
    return Promise.reject(error);
  });

export default instance;
