import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import { getQuestionDetails } from '../../redux/actions/question.actions';
import ReviewSolutionContainer from '../../containers/review-solution/ReviewSolution.container';

const ReviewSolutionCode = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const getReqId = searchParams.get('reqId');
    const getQuestid = searchParams.get('qId');

    const questData = useSelector(state => state.question);

    const breadcrumbData = React.useMemo(() => ([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Dashboard",
            link: '/'
        }
    ]), []);

    React.useEffect(() => {
        if(!!getReqId && !!getQuestid) {
            dispatch(getQuestionDetails(getQuestid, 1))
        }
    }, [])

    return(
        <div className='cm-cms-container'>
            <Breadcrumb data={breadcrumbData} />
            <ReviewSolutionContainer questData={questData}></ReviewSolutionContainer>
            <Outlet />
        </div>
    )
}

export default ReviewSolutionCode;