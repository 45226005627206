import * as React from "react";

const SkillInfoTable = ({ tableDataDTO }) => {
  return (
    <div className="container-fluid mx-1 taxonomy-management">
      <div className="d-flex border-bottom py-2">
        <div className="col-1 text-blue-800 cm-sm-txt fw-semibold d-flex ">
          <p className="m-0 pt-2">Skills:</p>
        </div>
        <div className="col d-flex flex-wrap">
          {tableDataDTO.skills.length > 0 &&
            tableDataDTO.skills.map((e) => (
              <span
                key={e}
                className="table-field table-field cm-xs-txt fw-medium px-3 py-2 mx-2 my-1 badge rounded-pill border bg-white text-action-blue border-blue-gray-500"
              >
                {e}
              </span>
            ))}
          {tableDataDTO.skills.length === 0 && (
            <span className="cm-xs-txt py-2 mx-4 my-1">
              No data found
            </span>
          )}
        </div>
      </div>
      <div className="d-flex border-bottom py-3">
        <div className="col-1 text-blue-800 cm-sm-txt fw-semibold">
          <p className="m-0 pt-2"> Similar Skill:</p>
        </div>
        <div className="col d-flex flex-wrap">
          {tableDataDTO.similarSkill.length > 0 &&
            tableDataDTO.similarSkill.map((e) => (
              <span
                key={e}
                className="table-field cm-xs-txt fw-medium px-3 py-2 mx-2 my-1 badge rounded-pill border bg-white text-action-blue border-blue-gray-500"
              >
                {e}
              </span>
            ))}
          {tableDataDTO.similarSkill.length === 0 && (
            <span className="cm-xs-txt py-2 mx-4 my-1">
              No data found
            </span>
          )}
        </div>
      </div>
      <div className="d-flex border-bottom py-3">
        <div className="col-1 text-blue-800 cm-sm-txt fw-semibold">
          <p className="m-0 pt-2"> Industry:</p>
        </div>
        <div className="col d-flex flex-wrap">
          {tableDataDTO.industry.length > 0 &&
            tableDataDTO.industry.map((e) => (
              <span
                key={e}
                className="table-field cm-xs-txt fw-medium px-3 py-2 mx-2 my-1 badge rounded-pill border bg-white text-action-blue border-blue-gray-500"
              >
                {e}
              </span>
            ))}
          {tableDataDTO.industry.length === 0 && (
            <span className="cm-xs-txt py-2 mx-4 my-1">
              No data found
            </span>
          )}
        </div>
      </div>
      <div className="d-flex border-bottom py-3">
        <div className="col-1 text-blue-800 cm-sm-txt fw-semibold">
          <p className="m-0 pt-2"> Jobs:</p>
        </div>
        <div className="col-11 d-flex flex-wrap">
          {tableDataDTO.job.length > 0 &&
            tableDataDTO.job.map((e) => (
              <span
                key={e}
                className="table-field cm-xs-txt fw-medium px-3 py-2 mx-2 my-1 badge rounded-pill border bg-white text-action-blue border-blue-gray-500"
              >
                {e}
              </span>
            ))}
          {tableDataDTO.job.length === 0 && (
            <span className="cm-xs-txt py-2 mx-4 my-1">
              No data found
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default SkillInfoTable;
