import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const CustomInput = (props) => {
  return (
    <div
      className={`${props.parentclass} cm-inp-field ${props.haserror ? "cm-error-input" : ""
        } position-relative w-100`}
      style={props.style}
    >
      <input
        type={!!props.type ? props.type : "text"}
        placeholder={!!props.placeholder ? props.placeholder : ""}
        className={`px-3 d-block py-2 lh-sm border cm-sm-txt fw-medium cm-btn-radius border ${props.haserror
            ? "border-danger text-danger"
            : `border-blue-gray-300 ${!!props.disabled ? "text-blue-gray-700" : "text-blue-800"
            }`
          } ${props.inputclass}`}
        value={![null, undefined, ""].includes(props.value) ? props.value : ""}
        onChange={props.onChange}
        {...props?.inputProps}
        onWheel={(e) => e.target.blur()}
        ref={input => props?.focus && setTimeout(() => input && input.focus(), 100)}
      />
      {!!props.haserror && (
        <FontAwesomeIcon
          icon={regular("info-circle")}
          className={`text-danger position-absolute ${props.iconClass}`}
        />
      )}
      {!!props.msg && (
        <span className="text-danger cm-xs-txt fw-medium pt-2 d-inline-block">
          {props.msg}
        </span>
      )}
    </div>
  );
};

export default CustomInput;
