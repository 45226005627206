import React from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { appError } from '../../redux/actions/error.action';
import { getApiUrl } from '../../utils/apiUrls';
import { saveAs } from 'file-saver';
import instance from '../../utils/axios';
import '../question-library-knowledgeBased/QuestionLibraryKnowledgeBased.css'
import PreviewQuestionCard from '../question-library-knowledgeBased/PreviewQuestionCard';
import AnimationDataLoader from '../../components/Loaders/animationData';




function PreviewKnowledgeBasedQuestionContainer({getReqId, getQuestid}) {

    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { qId } = useParams();
    const { state } = useLocation();
    const user = useSelector(state => state.user);
    const [comment, setComment] = React.useState("");
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        revStatus: false,
        approveStatus: false,
        reviewerStatus: false,
        qId: null,
        searchDTOList: []
    });
    const [reviewModalStatus, setReviewModalStatus] = React.useState(false);
    const [reviewTimelineModalStatus, setReviewTimelineModalStatus] = React.useState(false);
    const [allData, setAllData] = React.useState(null);
    const [isAllDataLoading, setIsAllDataLoading] = React.useState(true);
    const [downloadQuestions, setDownloadQuestions] = React.useState([]);
    const [errorMsg, setErrorMsg] = React.useState(false);
    const [aStatus, setAStatus] = React.useState('Accept');
    const sme = useSelector(state => state.user?.sme);
    const userId = useSelector(state => state.user.userId);
    const handleDialogToggle = (qId, status) => {
        setDialogStatus({
            ...dialogStatus,
            status: !dialogStatus.status,
            qId: !!qId ? qId : null
        });
    }
 
    


   const QuestionData = async (flag) => {
    
        try {
            setIsAllDataLoading(false);
            const res = await instance.get(`${getApiUrl('getKnowledgeBasedQuestionForPreview')}/${getQuestid}`);
            
            if (!!res.data) {
                setAllData(res.data);
            }
            
        } catch (error) {
            console.log(error);
            appError('QuestionLibrary', error, dispatch);
        }
    }

    React.useEffect(() => {
    QuestionData();
    },[]);

  return (
    <div className='scroll-hide'>
        <div className='d-flex scroll-hide' style={{overflowY: "hidden", height: "78vh"}}>
            <div className='w-100 right-container' >
                <div className='mt-bar'>
                    {allData !== null ?
                     <PreviewQuestionCard  question={allData} />
                    //  <McqQuestionCard  key={getQuestid} question={question} checkedQuestion={checkedQuestion} setCheckedQuestion={setCheckedQuestion} checkedQuestionMap={checkedQuestionMap} setCheckedQuestionMap={setCheckedQuestionMap} taskType={allData.taskType} multiple={false} />
                     :  
                  <div className="d-flex align-items-center justify-content-center">
                     <AnimationDataLoader />
                 </div>}       
                </div>
            </div>
        </div>
    </div>
    // </div>
  )
}

export default PreviewKnowledgeBasedQuestionContainer;