import { store } from "../redux/store";

const endPoint = {
    login: 'login',
    logout: 'logout',
    getSk: 'getSk',
    createUser: 'createUser',
    editUser: "editUser",
    resetPassword: "resetPassword",
    changePassword: "changePassword",
    getUser: 'get-user',
    forgotPassword: "forgotPassword",
    getCodelysisQuestionData: 'getCodelysisQuestionData',
    getAllTags: "getAllTags",
    getCodeLanguage: "get-code-language",
    saveCodelysisQuestion: "save-codelysis-question",
    updateCodelysisQuestion: "update-codelysis-question",
    requestForTestcaseData: "reviewQuestion/requestForTestcaseData",
    getCodelysisQuestionForPreview: "getCodelysisQuestionForPreview",
    getCodelysisQuestionForAdminPreview: "getCodelysisQuestionForAdminPreview",
    saveReviewerAnswer: "reviewQuestion/saveReviewerAnswer",
    disableEnableUser: 'disableEnableUser',
    addTag: "addTag",
    editTag: "editTag",
    deleteTag: "deleteTag",
    getAllNotifications: "getAllNotifications",
    markNotificationAsDisplayed: "markNotificationAsDisplayed",
    languageEvaluator: "language-evaluator",
    getQuestionReviewerMapping: "reviewQuestion",
    adminDashboard: "admin/dashboard",
    checkCodeCompilation: "checkCodeCompilation",
    smeDashboard: "sme/dashboard",
    getSmeData: "get-sme-data",
    createTask: "task/create",
    getAllUsers: "getAllUsers",
    sendRequestNotifyMail: "sendRequestNotifyMail",
    getCodelysisQuestionByTaskId: "getCodelysisQuestionByTaskId",
    codelysisQuestionSendForReview: "codelysisQuestion/sendForReview",
    deleteCodelysisQuestion: "deleteCodelysisQuestion",
    getCodelysisQuestionCommentData: "getCodelysisQuestionCommentData",
    saveCodelysisQuestionComment: "saveCodelysisQuestionComment",
    saveQuestionReviewerMapping: "reviewQuestion/allocate",
    grantTestcasePermission: "reviewQuestion/grantTestcasePermission",
    getReviewerList: "getReviewerList",
    downloadQuestions: "downloadQuestions",
    downloadMultipleChoiceQuestions:"downloadMultipleChoiceQuestions",
    extendReviewerTargetDate: "reviewQuestion/extendReviewerTargetDate",
    approveCodelysisQuestion: "approveCodelysisQuestion",
    decryptId: "decryptId",
    getCodingTemplate: "getCodingTemplate",
    getAllTask: "task/getAll",
    deleteTask: "task/delete",
    editTask: "task/edit",
    deleteQuestionReviewerMapping: "reviewQuestion/deallocate",
    saveReviewReportAdminStatus: "reviewQuestion/adminReview",
    saveReviewerStatus: "reviewQuestion/reviewerReview",
    extendSMETaskTargetDate:"task/extend-sme-task-deadline",
    createMultipleChoiceQuestion:"create-multipleChoice-question",
    updateMultipleChoiceQuestion:"update-multipleChoice-question",
    getKnowledgeBasedQuestionForPreview:"getKnowledgeBasedQuestionForPreview",
    updateAnswerConflict:"update-answer-conflict-status",
    setReviewerAnswer:"set-reviewer-answer",
    reviewerAnswer:"get-reviewer-answer",
    reviewQuestion:"reviewQuestion",
    getAllSkills:"getAll-skills",
    getAllSubSkills:"getAll-Sub-skills",
    validateOTP:"validate-otp",
    getAllBanks:"getAllRootBanks",
    getAllQuestionsFromBank:"getAllBankQuestions",
    getBankAllSkills:"getAllSkillData",
    saveRootBank:"saveRootBank",
    getLanguagesSupport: "getLanguages-support",
    getAllBankQuestions: "getAllBankQuestions",
    getAllTranslatedQuestions: "getAllTranslatedQuestions",
    saveReviewTranslatedQuestion: "save-review-translated-question",
    getAllTeams:"getAllTeams",
    uploadMultipleChoicefile:"upload-multi-choice-file",
    downloadQuestiontemplate:"download-question-template",
    renameRootBank: "renameRootBank",
    deleteRootBank: "deleteRootBank",
    assignBank: "assignBank",
    deleteBankAssignment: "deleteBankAssignment",
    getAllBankAssignment: "getBankAssignment",
    getFlags: "getFlags",
    getAllComments: "getAllComments",
    saveComment: "saveComment",
    downloadReviewQuestions: "download-questions",
    getAllTranslatedLanguages:"getAllTranslationLanguage",
    editReviewQuestion: 'editReviewQuestion',
    uploadFile:"upload-file",
    deleteBank : 'deleteBank',
    renameBank : 'renameBank',
    addBankOrTopic:'addGroupOrTopic',
    changeReviewStatus : 'changeReviewStatus',
    resolveComment : 'resolveComment',
    getAllCompletedTasks : 'getAllCompletedTasks',
    saveBankFromTask : 'saveBankFromCompletedTask',
    deleteQuestions: 'deleteQuestions',
    mettlDataCenterList: "mettlDataCenterList",
    loginToMettl: "loginToMettl",
    checkPermission: "checkPermission",
    validateOTPForMettl: "validateOTP",
    fetchAssessments: "fetchAssessments",
    fetchBanks: "fetchBanks",
    downloadQuestionToExcel: "downloadQuestionToExcel",
    resendOTP: "resendOTP",
    createHierarchyToMettl: "createHierarchyToMettl",
    getAllVersions: 'getAllVersions',
    saveLongAnswerQuestion: 'createLongAnswerTypeQuestion',
    updateLongAnswerQuestion: 'updateLongAnswerTypeQuestion'
}

const endPointSkill = {
    searchData: 'searchData',

    relationUploadUrl: 'upload/relation_upload',
    skillUploadUrl: 'upload/skill/bulk',
    skill_typeUploadUrl: 'upload/skillType/bulk',
    jobUploadUrl: 'upload/business/bulk',
    questionUploadUrl: 'upload/question/bulk',
    industryUploadUrl: 'upload/industry/bulk',
    traitUploadUrl: 'upload/trait/bulk',
    toolUploadUrl: 'upload/tool/bulk',

    relationDownloadUrl: 'download/template?entityName=Relation',
    skillDownloadUrl: 'download/template?entityName=Skill',
    skill_typeDownloadUrl: 'download/template?entityName=Skill+Type',
    jobDownloadUrl: 'download/template?entityName=Job',
    questionDownloadUrl: 'download/template?entityName=Question',
    industryDownloadUrl: 'download/template?entityName=Industry',
    traitDownloadUrl: 'download/template?entityName=Trait',
    toolDownloadUrl: 'download/template?entityName=Tool',

    suggestionData: 'suggestionData',
}

export const getApiUrl = (key, direct = false) => {
    const envState = store.getState().env;
    const mainApiUrl = `${envState.BASE_URL}${direct ? '/' : envState.API_URL + '/'}`;
    // console.log('apiUrl', mainApiUrl)
    return `${mainApiUrl}${endPoint[key]}`
}

export const getApiUrlSkill = (key, direct = false) => {
    const envState = store.getState().env;
    // console.log('apiUrl', mainApiUrl)
    const mainApiUrl = `${envState.BASE_URL}${direct ? '/' : envState.SKILL_URL + '/'}`;
    return `${mainApiUrl}${endPointSkill[key]}`
}

