import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({children}) => {
    const user = useSelector(state => state.user);

    return(
        user !== null && user.isMfaAuthenticated ?  children  : <Navigate to="/login" replace={true} /> 
    )

}

export default ProtectedRoute;