import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useLocation } from 'react-router-dom';
import QuestionInstructionModal from '../question-library/QuestionInstructionModal.container';
import './QuestionCreation.styles.css';
import ProgressControl from "../../components/status-control/ProgressControl.component";
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';
import { MenuItem, Select } from '@mui/material';

const McqQuestionCreationSidebar = ({ t, handleSaveBtn, handleBackBtn, activeStep, isLoading, stickyTopVal, handleSelectChange, selectedDifficultyLevel }) => {
    const [questionInstructionModalStatus, setQuestionInstructionModalStatus] = React.useState(false);
    const location = useLocation();
    const handleQuestionInstructionModalToggle = () => {
        setQuestionInstructionModalStatus(!questionInstructionModalStatus);
    }
    const progressSteps = [
        {
            label: "Add Question Details",
            value: 0
        }
    ];

    const handleRadioChange = (e) => {
        // if(e.target.value !== eventProgress) setEventProgress(e.target.value);
    }

    const onSaveBtnClick = () => handleSaveBtn();

    const onBackBtnClick = () => handleBackBtn();

    const loadSelectOptions = (list) => list.map((el, index) => (
        <MenuItem value={el} key={index}>
            <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{el}</span>
        </MenuItem>
    ));


    return (
        <div className='cm-event-library-sidebar-wrapper' style={(stickyTopVal ? { top: stickyTopVal } : {})}>
            <div className="cm-event-sidebar-widget cm-event-progress-wrap">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <p className='my-0 text-blue-gray-700 fw-semibold'>Question Creation Instruction</p>
                    <span className='text-blue-gray-700 cm-sm-txt cm-pointer'>
                        <FontAwesomeIcon icon={regular("circle-info")} onClick={handleQuestionInstructionModalToggle} />
                    </span>
                </div>

                <div className="d-flex flex-wrap space-between align-items-center">
                    <div className="d-flex cm-candidate-report-list-container cm-candidate-report-item py-0 my-0 mb-2 cm-sm-txt fw-medium text-blue-800">
                        {
                            (location.state?.isBank) ?
                                <Select
                                    id="difficulty_level_select"
                                    value={selectedDifficultyLevel}
                                    onChange={e => handleSelectChange(e)}
                                    renderValue={(selected) => {
                                        return <span className='cm-sm-txt fw-medium  text-blue-gray-700'>{selected}</span>;
                                    }}
                                    className='difficulty_level_select-box'
                                >
                                    {loadSelectOptions(['EASY', 'MEDIUM', 'DIFFICULT'])}
                                </Select>
                                :
                                <ProgressControl value={(location.state?.isEdit || location.state?.isBank) ? location.state.data?.difficultyLevel : location.state?.currDifficulty} />
                        }
                    </div>
                    <div className=" d-flex cm-candidate-report-list-container cm-candidate-report-item py-0 my-0 mb-2 ms-2 cm-sm-txt fw-medium text-blue-800" >
                        <ProgressControl value={(location.state?.isEdit || location.state?.isBank) ? location.state.questionType : location.state.currQuestionType} />
                    </div>
                    <div className="d-flex cm-candidate-report-list-container cm-candidate-report-item py-0 my-0 mb-2 ms-2 cm-xs-txt fw-medium text-blue-800">
                        <ProgressControl value={location.state?.isBank ? location.state.data.bankName : location.state?.isEdit ? location.state.data.topicName : location.state.subskillName} />
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <p className='my-0 text-blue-gray-700 fw-semibold'>Current Progress</p>
                    <span className='text-blue-gray-400 cm-sm-txt'>{activeStep + 1}/{progressSteps.length}</span>
                </div>
                <div className="cm-event-progress-steps mb-4">
                    <ul className='pt-3'>
                        {
                            progressSteps.map(el =>
                                <li className={`d-flex align-items-center py-2 px-3 position-relative ${el.value <= activeStep ? 'active-step' : ''}`} key={el.value}>
                                    <input type="radio" checked={el.value <= activeStep} value={el.value} onChange={handleRadioChange} name={`taskFilter-${el.value}`} id={el.value} />
                                    <label htmlFor={el.value} className="cm-sm-txt text-blue-800 fw-medium">{el.label}</label>
                                </li>)
                        }
                    </ul>

                </div>

                <div className="d-flex flex-column">
                    <div className="cm-event-progress-controls d-flex align-items-center mb-2" >
                        <span className={`cm-sidebar-back-btn text-blue-800 fw-medium cm-sm-txt cm-pointer cm-small-back-btn me-2 d-inline-block`} onClick={onBackBtnClick}>{t("back")}</span>
                        <CustomButtonLoader
                            showLoadingState ={isLoading}
                            colorTheme= "blue"
                            icon={regular('angle-right')}
                            reverseIconDirection={false}
                            buttonLabel={t("save_and_complete")}
                            buttonStyle={"cm-sidebar-save-btn px-12 py-2 cm-mar-left-icon"}
                            handleOnClick={onSaveBtnClick}
                        />
                    </div>
                </div>


            </div>
            <QuestionInstructionModal modalStatus={questionInstructionModalStatus} handleModalToggle={handleQuestionInstructionModalToggle} navigateToQuestionCreationPage={null} />
        </div>

    )

}

export default McqQuestionCreationSidebar;
