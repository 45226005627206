import React from "react";
import { useLottie } from "lottie-react";

const AnimationComp = ({ width, height, animationData, color }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const { View } = useLottie(defaultOptions);

  return (
    <div
      className={`cm-anim-comp ${color}`}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      {View}
    </div>
  );
};

export default AnimationComp;
