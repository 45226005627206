import * as React from "react";
import axios from "axios";
import uploadIcon from "../../assets/images/global/upload.png";
import { getApiUrlSkill } from "../../utils/apiUrls";
import { useDispatch } from "react-redux";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import CircularProgress from "@mui/material/CircularProgress";
import { apiErrorHandler } from "../../utils/errorHandler";
import instance from '../../utils/axios';

const UploadEntity = (props) => {
  const dispatch = useDispatch();
  const [activeLoader, setActiveLoader] = React.useState(false);
  const [dropDownData, setDropDownData] = React.useState("skill_type");
  const fileInpRef=React.useRef(null);
  React.useEffect(() => {
    if (!!props.dropDownData) {
      setDropDownData(props.dropDownData);
    }
  }, [props.dropDownData]);
  const handleDropFile = (e) => {
    setActiveLoader(true);
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (
      droppedFile.type === "application/vnd.ms-excel" ||
      droppedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      droppedFile.type === "csv"
    ) {
      handleOnSubmit(droppedFile);
    } else {
      dispatch(
        openSnackBar({
          msg: "Selected incorrect file type!",
          type: "error",
        })
      );
    }
  };

  const handleSelectedFile = (e) => {
    handleOnSubmit(e.target.files[0]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  
  const handleOnSubmit = async (fileToUpload) => {
    setActiveLoader(true);

    const formData = new FormData();
    formData.append("file", fileToUpload);

    try {
      if (!(!!dropDownData && dropDownData !== ""))
        throw Error("Dropdown data is not available");
      const response = await instance({
        method: "post",
        url: `${getApiUrlSkill(dropDownData + "UploadUrl")}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      setActiveLoader(false);
      dispatch(
        openSnackBar({
          msg:
            "Entity uploaded successfully : " +
            response.data[0] +
            " & Unsuccessful uploads : " +
            response.data[1],
          type: "success",
        })
      );
      fileInpRef.current.value="";
    } catch (error) {
      var errorMessage = apiErrorHandler(error);
      errorMessage = errorMessage.statusText;
      setActiveLoader(false);
      fileInpRef.current.value="";
      dispatch(
        openSnackBar({
          msg: !!errorMessage ? errorMessage : "Internal Server Error",
          type: "error",
        })
      );
    }
  };
  return (
    <div
      className="d-flex flex-column cm-xs-txt bg-blue-gray-50 taxonomy-management_head cm-btn-radius mx-auto"
      onDrop={(e) => handleDropFile(e)}
      onDragOver={(e) => handleDragOver(e)}
    >
      <img
        className="mx-auto mt-4 pt-2 mb-4"
        src={uploadIcon}
        alt="logo"
        width="140px"
      />
      <p className="mb-3 mx-auto">Drag and drop files to upload</p>
      <p className="mx-auto mb-3">or</p>
      <div className="mb-4 mx-auto position-relative">
        <input
          className="d-none"
          type="file"
          ref={fileInpRef}
          id="fileInput"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={handleSelectedFile}
        />
        <label
          htmlFor="fileInput"
          className="btn btn-success bg-action-blue cm-xs-txt"
        >
          Choose a file and upload it
        </label>
        {activeLoader ? (
          <div className="cm-loader-sty">
            <CircularProgress size={24} />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default UploadEntity;
