import React from 'react';
import CustomErrorPage from './CustomError.page';
import ServerErrorPage from './ServerError.page';

const ErrorController = ({data}) => {
    if(data.err.code === 500) {
        return <ServerErrorPage />
    } else {
        return <CustomErrorPage errorCode={data.err.code} errMsg={data.err.statusText} />
    }

}

export default ErrorController;