import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthRoute = ({children}) => {
    const user = useSelector(state => state.user);

    return(
        user==null ? children : user.isMfaAuthenticated ? <Navigate to="/" replace={true} /> : <Navigate to="/mfa-verify" replace={true} /> 
        // user == null && !user?.isMfaAuthenticated ? (user?.isMfaAuthenticated) ? children : <Navigate to="/mfa-verify" replace={true} /> : <Navigate to="/" replace={true} />
    )

}

export default AuthRoute;