import React from "react";
import { ReactComponent as EmptyTaskIcon } from '../../assets/images/task/empty_task_modal.svg';
import { ReactComponent as EmptySearchIcon } from '../../assets/images/task/empty_search.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const NoResultsPage=({ t, searchActive, resetFn, searchTerm, noDataHead, noDataSubHead, txtClasses })=>{
    return (<div className='cm-empty-task-container py-5 text-center cm-empty-wrapper mt-2 bg-blue-gray-50 d-flex flex-column justify-content-center align-items-center py-5 text-center col-12'>
        {searchActive ? <EmptySearchIcon /> : <EmptyTaskIcon />}
        <p className='fw-semibold  mb-2 text-blue-700'>{searchActive ? `${t('nothing_found_for')} ${searchTerm}` : noDataHead ? noDataHead : t('nothing_to_see')}</p>
        <div className='d-flex justify-content-center'>
            {searchActive ? 
            <span className='mt-2 d-inline-block text-blue-800 cm-reset-search-btn border border-blue-gray-300 cm-pointer cm-light-hover' onClick={resetFn}>
                <span className="cm-sm-txt fw-medium bg-white">Clear Search</span>
                <FontAwesomeIcon icon={regular("angle-right")} className="text-blue-800 ps-2" />
            </span>
            : 
            noDataSubHead ? <p className="fw-medium cm-sm-txt text-blue-gray-500 mb-0">{noDataSubHead}</p> : ''
            }
        </div>
    </div>)
}
export default NoResultsPage;