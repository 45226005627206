import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, AccordionDetails, AccordionSummary, Collapse, } from '@mui/material';
import React from 'react';

const HierarchyTree = ({ treeState, t }) => {
    const [expandedObj, setExpandedbj] = React.useState({ root: true });

    React.useEffect(() => {
        const newObj = {};

        for(let key in treeState.branch) {
            if(treeState.branch[key].length > 0)  newObj[key] = true;
        }

        setExpandedbj({ ...expandedObj, ...newObj })
    }, [treeState])

    const renderBranch = () => {
        const struct = [];

        for(let key in treeState.branch) {
            struct.push(<div>
                {treeState.branch[key].length > 0 ? <p onClick={() => handleAccodianToggle(key)} className="p-2 rounded bg-white text-blue-800 fw-medium cm-sm-txt border border-blue-gray-100 mb-2 cm-pointer d-flex align-items-center user-select-none"><div className='bg-blue-500 text-white rounded px-2 me-2'><FontAwesomeIcon icon={expandedObj[key] ? regular("angle-up") : regular("angle-down")} /></div>{key}</p> : <p className={`p-2 rounded bg-white text-blue-800 fw-medium cm-sm-txt border border-blue-gray-100 mb-2`}>{key}</p>}
                
                {treeState.branch[key].length > 0 ? <Collapse in={expandedObj[key]} timeout="auto" unmountOnExit style={{ width: '100%' }} className="ps-3">
                    {treeState.branch[key].map(el => <p className='p-10 rounded bg-white text-blue-800 fw-medium cm-sm-txt border border-blue-gray-100 mb-2' key={el}>{el}</p>)}
                </Collapse> : null}
            </div>)
        }

        return struct;
    }

    const handleAccodianToggle = (key) => {
        setExpandedbj({ ...expandedObj, [key]: !expandedObj[key] })
    }

    const handleAccodianControl = (type='collapse') => {
        const obj = { ...expandedObj };

        for(let key in expandedObj) {
            obj[key] = type !== "collapse"
        }

        setExpandedbj(obj);
    }

    return(
        <div>
            <div className='d-flex align-items-center mb-4'>
                <span className='bg-action-blue text-white px-3 py-2 rounded cm-sm-txt fw-medium d-inline-block me-3 cm-pointer' onClick={() => handleAccodianControl("collapse")}>{t("collapse_all")}</span>
                <span className='bg-action-blue text-white px-3 py-2 rounded cm-sm-txt fw-medium d-inline-block me-3 cm-pointer' onClick={() => handleAccodianControl("expand")}>{t("expand_all")}</span>
            </div>
            <p onClick={() => handleAccodianToggle('root')} className='p-10 rounded bg-white text-blue-800 fw-medium cm-sm-txt border border-blue-gray-100 mb-2 cm-pointer d-flex align-items-center user-select-none'><div className='bg-blue-500 text-white rounded px-2 me-2'><FontAwesomeIcon icon={expandedObj.root ? regular("angle-up") : regular("angle-down")} /></div>{treeState.root}</p>
            <Collapse in={expandedObj.root} timeout="auto" unmountOnExit style={{ width: '100%' }} className="ps-3">
                {renderBranch()}
            </Collapse>
        </div>
    )

}

export default HierarchyTree;