import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import instance from '../../utils/axios';
import { getApiUrl } from '../../utils/apiUrls';
import { useNavigate } from 'react-router-dom';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { faFlag, faXmark } from '@fortawesome/free-solid-svg-icons';
import { TextareaAutosize } from '@mui/material';
import Select from 'react-select';
import MenuItem from '@mui/material/MenuItem';
import { Select as Muiselect } from '@mui/material';

function QuestionCard({ data, question, checkedQuestion, setCheckedQuestion, checkedQuestionMap, setCheckedQuestionMap, taskType, multiple }) {

  const navigate = useNavigate();
  const correctAnswer = question.multipleChoiceAnswerDescription?.correctChoiceIndex;
  const correctAnswerChoiceIndices = question.multipleChoiceAnswerDescription?.correctChoiceIndices;

  const user = useSelector(state => state.user);

  const dispatch = useDispatch();

  const [reviewerAnswer, setReviewerAnswer] = React.useState([]);
  const [isreviewerSavedAnswer, setIsreviewerSavedAnswer] = React.useState(false);

  // changes
  const [flagOptions, setFlagOptions] = React.useState(true);
  const [selectedFlag, setSelectedFlag] = React.useState('');

  const [dialogStatus, setDialogStatus] = React.useState({
    status: false,
    revStatus: false,
    approveStatus: false,
    reviewerStatus: false,
    qId: null,
    searchDTOList: [],
  });

  const initialState = {
    selectFor: "",
    flagselcted: "",
    comments: null,
    isSelected: false,
    isRejected: false,
}

const [approveForm, setApproveForm] = React.useState(initialState)

  const [errorMsg, setErrorMsg] = React.useState(false);
  const [comment, setComment] = React.useState("");
  const selectOptions = React.useMemo(() => ([
    { label: "Approve", value: "Approve"},
    { label: "Reject", value: 'reject' }
]), []);


  // changes
  const options = [
    { value: 'Technically inaccurate', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#E74C3C' }} /> Technically inaccurate</> },
    { value: 'Ambiguous question text', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#647C55' }} /> Ambiguous question text</> },
    { value: 'Irrelevant to the skill', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#3498DB' }} /> Irrelevant to the skill</> },
    { value: 'Irrelevant to the sub-skill/topic', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#FF9900' }} /> Irrelevant to the sub-skill/topic</> },
    { value: 'None of the answer options are correct', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#DA3C78' }} /> None of the answer options are correct</> },
    { value: 'More than one correct answer options', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#39D5FF' }} /> More than one correct answer options</> },
    { value: 'Level of difficulty of the question is higher than expected', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#F1C40F' }} /> Level of difficulty of the question is higher than expected</> },
    { value: 'Level of difficulty of the question is lower than expected', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#9B59B6' }} /> Level of difficulty of the question is lower than expected</> },
    { value: 'Code snippets are not properly formatted/indented', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#E67E22' }} /> Code snippets are not properly formatted/indented</> },
    { value: 'Question is plagiarized', label: <><FontAwesomeIcon icon={faFlag} style={{ color: '#B3421B' }} /> Question is plagiarized</> },
  ];

  const handleApproveChange = (e, key) => {
    setApproveForm({
        ...approveForm,
        [key]: e.target.value,
        isSelected: true,
    })
}
const loadSelectOptions = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)

  useEffect(() => {
    const getReviewerAnswer = async () => {
      try {
        const res = await instance.get(`${getApiUrl('reviewerAnswer')}?qId=${question.questionId}`);
        if (res.data.answer) {
          setReviewerAnswer(res.data.answer);
          setIsreviewerSavedAnswer(true);
        }
      } catch (err) {
        console.log("error occured while getting reviewer ans", err);
      }
    }


    if(question.questionType==="MCQ" || question.questionType==="MCA") getReviewerAnswer();
  }, [])

  const handleCheckClick = (event, questionId) => {
    const isChecked = event.target.checked;
    setCheckedQuestionMap({
      ...checkedQuestionMap,
      [questionId]: !checkedQuestionMap[questionId]
    })
    if (isChecked) {
      setCheckedQuestion([...checkedQuestion, questionId]);
    }
    else {
      setCheckedQuestion(checkedQuestion.filter((element) => {
        return element !== questionId;
      }))
    }

  }


  const handleChange = (index) => {
    if (question.questionType === "MCA") {
      if (reviewerAnswer.includes(index + 1)) {
        const changedMcaAnswer = reviewerAnswer.filter((item => item !== index + 1));
        setReviewerAnswer(changedMcaAnswer);
      }
      else {
        const changedanswer = [...reviewerAnswer, index + 1];
        setReviewerAnswer(changedanswer)
      }
    }
    else {
      const singleAnswer = [index + 1];
      setReviewerAnswer(singleAnswer);
    }
  }

  const handleClick = async (questionId) => {
    let reviewerChoiceIndices = {
      answer: reviewerAnswer,
    }
    try {
      const res = await instance.post(`${getApiUrl('setReviewerAnswer')}?qId=${questionId}`, reviewerChoiceIndices);
      dispatch(openSnackBar({
        msg: "Reviewer Option saved",
        type: 'success'
      }))
      setIsreviewerSavedAnswer(true);

    } catch (err) {
      console.log("Error occurred while saving reviewer answer", err);
    }
    let conflict = false;
    if (question.questionType === "MCA") {
      if (correctAnswerChoiceIndices.length != reviewerAnswer.length) conflict = true;
      else {
        reviewerAnswer.sort();
        correctAnswerChoiceIndices.sort();
        for (let i = 0; i < correctAnswerChoiceIndices.length; i++) {
          if (reviewerAnswer[i] != correctAnswerChoiceIndices[i]) {
            conflict = true;
          }
        }
      }
    }
    if (question.questionType === "MCQ" ? reviewerAnswer[0] !== correctAnswer : conflict) {
      try {
        const res = await instance.post(`${getApiUrl('updateAnswerConflict')}?qId=${questionId}`);
      } catch (err) {
        console.log("Error occurred while updating Answer conflict to the DataBase");
      }
    }
  }

  const approveRejectQuestionToggleReviewer = (qId, targetDateExceeded, isCodeAvailable) => {
    if (reviewerAnswer.length <= 0 && (question.questionType==="MCQ" || question.questionType==="MCA")) {
      dispatch(openSnackBar({
        msg: "Please save option before Approving or Rejecting a Question",
        type: 'error'
      }))
    }
    else if (targetDateExceeded) {
      dispatch(openSnackBar({
        msg: "Target data has Exceeded",
        type: 'error'
      }))
    } else {
      handleDialogToggleReviewer(qId);
    }
  }

  const handleDialogToggleReviewer = (qId) => {
    setDialogStatus({
      ...dialogStatus,
      reviewerStatus: !dialogStatus.reviewerStatus,
      qId: !!qId ? qId : null
    })
    setErrorMsg(false);
  }


  const approveRejectQuestionReviewer = (qid, status, flag) => {
    if (comment.length > 0) {
      const dataToSend = { status: status, comment: comment, questionId: [qid], assignedTo: user.userId, flagStatus: flag };
      instance.post(`${getApiUrl('saveReviewerStatus')}`, dataToSend).then(function (response) {
        if (response.data) {
          setComment("");
          // dashboardData();
          dispatch(openSnackBar({
            msg: "This question review is sent to admin.",
            type: 'success'
          }))
        } else {
          // dispatch(openSnackBar({
          //     msg: "The question review status is sent as rejected to admin.",
          //     type: 'success'
          // }))
          // reloadDashboard(false);
        }
      });
    }
    else {
      setErrorMsg(true);
    }
  }

  const dialogActionFnApproveReviewer = () => {

    approveRejectQuestionReviewer(dialogStatus.qId, "Approved");
    if (comment.length > 0) {
      setDialogStatus({
        ...dialogStatus,
        reviewerStatus: false,
        qId: dialogStatus.qId,
      })
    }
    else {
      setDialogStatus({
        ...dialogStatus,
        reviewerStatus: true,
        qId: dialogStatus.qId,
      })
    }
  }

  const dialogActionFnRejectReviewer = () => {
    approveRejectQuestionReviewer(dialogStatus.qId, "Rejected", selectedFlag);
    if (comment.length > 0) {
      setDialogStatus({
        ...dialogStatus,
        reviewerStatus: false,
        qId: dialogStatus.qId,
        // flagStatus: selectedFlag
      })
    }
    else {
      setDialogStatus({
        ...dialogStatus,
        reviewerStatus: true,
        qId: dialogStatus.qId,
      })
    }
  }

  const handleComment = (e) => {
    setComment(e.target.value)
  }

  const handleSelectShow = () => {
    setFlagOptions(false);
  }

  const handleSelectClose = () => {
    setFlagOptions(true);
    setSelectedFlag('');
  }

  const handleSelectChange = (selected) => {
    setSelectedFlag(selected.value);
  }

  return (
    <div className='question-card' style={{ backgroundColor: "white" }}>
      {/* <span
                        // className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                        //   data.creatorStatus === "SentForReview"
                        //     ? data.adminStatus === null ||
                        //       data.reviewerStatus !== "InProgress"
                        //       ? ""
                        //       : "cm-sidebar-btn-disabled"
                        //     : "cm-sidebar-btn-disabled"
                        // } `}
                        onClick={() =>
                          approveRejectQuestion(
                            data.questionId,
                            data.creatorStatus,
                            data.adminStatus
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={regular("comments-question-check")}
                        />
        </span> */}

      <CustomDialog
        dialogHeading="Approve or Reject Question"
        cancelBtnLabel="Cancel"
        actionBtnLabel={(approveForm.selectFor === 'Approve') ? <span>Approve</span> : false}
        actionBtnLabel2={(approveForm.selectFor === 'reject') ? <span>Reject</span> : false}
        actionBtnBg="bg-action-blue"
        actionBtnBg2="bg-danger"
        cancelFn={handleDialogToggleReviewer}
        actionFn={dialogActionFnApproveReviewer}
        actionFn2={dialogActionFnRejectReviewer}
        dialogStatus={dialogStatus.reviewerStatus}
        isReviewer="true"
        selectedFlag={approveForm.selectFor === 'reject' ? selectedFlag : false}
        handleSelectClose={handleSelectClose}
        isComment={comment}
      >
        {/* <p className='text-danger fw-semibold txt-sm my-0 pb-2'>Please save a code before Approving or Rejecting a Question</p> */}
        {errorMsg && <p className='text-danger fw-semibold txt-sm my-0 pb-2'>Review report is required</p>}
        <div className='cm-create-task-form px-3 pb-2'>
          <label htmlFor="Accept/Reject" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Select :</label>
          <Muiselect
            id="request-select mb-2"
            value={approveForm.selectFor}
            onChange={val => handleApproveChange(val, 'selectFor')}
          >
            {loadSelectOptions(selectOptions)}
          </Muiselect>
        </div>
        {approveForm.selectFor === 'reject' &&

          <div className="cm-create-task-for px-3 pb-2" >
            <label htmlFor="Accept/Reject" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Choose a Flag</label>
            <Select
              className='optionsDropDown'
              options={options}
              placeholder="---Select a Flag---"
              onChange={handleSelectChange}
            />
          </div>
        }

        {(approveForm.isSelected) &&
          <div className='px-3'>
            <p className='text-blue-gray-500 cm-sm-txt fw-medium mb-1 ms-1'>Review report</p>
            <div className='d-flex'>
              <TextareaAutosize
                maxRows={12}
                minRows={4}
                className="text-blue-800 cm-sm-txt p-2"
                aria-label="maximum height"
                placeholder={selectedFlag !== '' ? "State reasons for raising the flag" : "Write your comment"}
                value={comment}
                style={{
                  'width': '750px', 'border': '1px solid var(--bs-blue-gray-100)', '&:hover': {
                    borderColor: '#FFFFFF',
                    borderWidth: 2
                  },
                }}
                onChange={handleComment}
              />
            </div>
          </div>}
      </CustomDialog>

      <div className='ques-id'>
        {/* <input type='checkbox' checked={checkedQuestionMap[question.questionId]} className='check-box' onChange={(e) => handleCheckClick(e, question.questionId)}/> */}
        {/* <input type='checkbox' hidden={user.role==="SME" ? true : false} checked={checkedQuestionMap[question.questionId]} className='check-box' onChange={(e) => handleCheckClick(e, question.questionId)}/> */}
        {/* <input type='checkbox' hidden checked={checkedQuestionMap[question.questionId]} className='check-box' onChange={(e) => handleCheckClick(e, question.questionId)}/> */}
        <span className='ques-id-txt inline-block'>Question Id : {question.questionId}</span>
        {user.role === "SME" &&
          <CustomToolTip title={"Approve/Reject Question"}>
            <span
              className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer 
                      ${question.reviewerStatus !== "InProgress"
                  ? "cm-sidebar-btn-disabled"
                  : ""
                } ${question.targetDateExceeded
                  ? "cm-sidebar-btn-disabled"
                  : ""
                }`
              }
              style={{ fontSize: '20px' }}
              onClick={
                () => {
                  approveRejectQuestionToggleReviewer(
                    question.questionId,
                    question.targetDateExceeded,
                    true
                  )
                }
              }
            >
              <FontAwesomeIcon
                icon={regular("comments-question-check")}
              />
            </span>
          </CustomToolTip>
        }
      </div>

      <div dangerouslySetInnerHTML={{ __html: question.questionText }}></div>

      <div className='my-3'>
        {question.multipleChoiceAnswerDescription?.choices.map((element, index) => {
          return (<div className='my-2 answer-text'>
            {question.questionType === "MCQ" ?
              <input className='inline-block mt-1'
                type="radio"
                value={index}
                name={'answerOptions' + question.questionId}
                checked={(taskType !== "ForCreation" && user.role === "SME") ? reviewerAnswer[0] === index + 1 : correctAnswer === index + 1}
                onChange={() => handleChange(index)}
              />
              :
              <input className='inline-block mt-1'
                type="checkbox"
                value={index}
                name={'answerOptions' + question.questionId}
                checked={(taskType !== "ForCreation" && user.role === "SME") ? reviewerAnswer.includes(index + 1) : correctAnswerChoiceIndices.includes(index + 1)}
                onChange={() => handleChange(index)}
              />
            }
            <span className='inline-block mx-2' >{element.choiceText !== null ? element.choiceText.replace(/<\/?[^>]+>/gi, ' ') : ""}</span>
          </div>)
        })}
      </div>

      <div className='mcqCardConatinerBottom'>
        <div className='mcqCardConatinerBottomTags'>
          <span className='mcq-tags'>{question.difficultyLevel}</span>
          <span className='mcq-tags'>{question.questionTitle}</span>
          {(user.role === "SME") && <span className='mcq-tags'>{question.questionType}</span>}
          {(user.role === "ADMIN") && <span className='mcq-tags'>{question.creatorStatus}</span>}
        </div>

        {user.role === "SME" && (question.questionType==="MCQ" || question.questionType==="MCA") &&
          <button
            className='reviewerChoiceSendBtn'
            onClick={() => handleClick(question.questionId)}
            disabled={question.reviewerStatus !== "InProgress"}>Save</button>}
      </div>
    </div>
  )
}

export default QuestionCard