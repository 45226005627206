import * as React from 'react';
import { Modal } from '@mui/material';

const QuestionInstructionModal = ({ modalStatus, handleModalToggle, navigateToQuestionCreationPage }) => {

    return (
        <div className='p-0'>
            <Modal open={modalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
                <div className='cm-question-creation-guidline-container bg-white my-3'>
                    <div className='cm-question-creation-guidline-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h4 className='text-blue-800 my-0'>Question Creation Guidlines</h4>
                    </div>
                    <div className="cm-question-creation-guidline-data px-4 py-4">
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Problem statement
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>The problem statement should contain all the logical parameters of the question.</li>
                                <li>We accept mainly scenario-based questions.</li>
                                <li>Keep the problem statement concise and readable by using short sentences and
                                    paragraphs.</li>
                                <li>Logical and important aspects of the questions should be present in the problem
                                    statement.</li>
                                <li>Bold all the data variables used in the problem statement once. E.g., "You are given an
                                    integer A.......".</li>
                                <li>The last statement of the problem statement should always be a return statement. For
                                    example, “Your task is to help Bruce find and return how far he is from the nearest corner seat. Return 0, if he is in the corner seat.” OR “Your task is to find and return an integer value, representing........”.</li>
                                <li>If the question holds a condition to return 0 or -1, please mention it after the “find and return” statement.</li>
                                <li>Return modulo 1e9+7 statement should be present after the "find and return" statement, if the question requires.</li>
                            </ul>
                            <div className='mb-4 mx-4'>
                                <h6 className='text-blue-700'>
                                    Notes
                                </h6>
                                <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                    <li>Note should be written just beneath the problem statement as this "Note:"</li>
                                    <li>Note has points which are necessary to solve the question but not important to the problem statement.</li>
                                    <li>If we have one point, we write in front of the note heading. If we have multiple points, we use bullets always and write beneath the note heading.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Function Details
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>Function name should be relevant to the question, please do not use generic names like solve, solution, etc.</li>
                                <li>Follow the camel casing as the naming convention for the function naming. E.g. findNumber</li>
                            </ul>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Input specifications
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>For example,
                                    <div className='ms-4'>
                                        input1: A string value, representing Bruce's newly assigned desk number.<br />
                                        input2: A string array, containing all the office desk numbers.
                                    </div>
                                </li>
                                <li>Please maintain the colon spacing as above.</li>
                                <li>Please use “, representing/, containing” as a defining statement.</li>
                            </ul>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Output specifications
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>Output specification should be exactly same as the problem statement's last line.
                                </li>
                                <li>Please maintain the colon spacing as above.</li>
                                <li>Please use “, representing/, containing” as a defining statement.</li>
                            </ul>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Testcases specifications
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>1D Arrays will be in curly braces "{ }" and elements in the array should be separated by commas, as shown in the image.</li>
                                <li>2D Arrays will be in double curly braces as {"{{ a, b }, { c, d }}"}.</li>
                                <li>String will be defined normally without enclosing in double quotes.</li>
                                <li>There should be two deafult testcases</li>
                            </ul>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Testcase Explanation
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>Explanations of the examples should have a starting statement explaining about the inputs taken in that particular example</li>
                                <li>The last statement should be as “Hence, X is returned as output.", where X represents the output value and X should be in bold.</li>
                                <li>Explanations of both the examples should be understandable, explaining the process and have initial and final statements.</li>
                            </ul>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Test case parameters
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>Basic test cases should have 30-60 elements in 1D array.</li>
                                <li>Necessary test cases should have 60-100 elements in 1D array</li>
                                <li>Corner test cases should check all the bug prone conditions.</li>
                                <li>Time complexity test cases should have 500-1000 elements in 1D array. 500 elements are compulsory.</li>
                                <li>For Basic questions the Time Complexity test cases should be 250-350 elements in 1D array. 250 elements are compulsory.</li>
                                <li>2D array size should be 26x26 elements maximum and -99999 {'<='} value of each array element {'<='} 99999.</li>
                                <li>Test case Size for Strings {'->'} The combined length for all the strings should not exceed 89,000.</li>
                                <li>Try to avoid output for test cases as 0 or 1 or true or false or -1 or some repeating value.</li>
                                <li>Follow test case order too (2 default, 4 basic,2 necessary, 2 corner, 2 Time complexity)</li>
                            </ul>
                        </div>
                        <div className='mb-4 mx-2'>
                            <h5 className='text-blue-700'>
                                Generalized points
                            </h5>
                            <ul className='text-blue-800 cm-sm-txt mx-4 mt-3'>
                                <li>Return 0 or -1 statement should be mentioned both in problem statement and output specification.</li>
                                <li>Maintain colon spacing as defined everywhere.</li>
                                <li>Try to avoid output for test cases as 0 or 1 or true or false or -1 or some repeating value, just to avoid hard coded return output program.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="cm-question-creation-guidline-footer">
                        <div className="cm-form-btn-group pt-2 d-flex align-items-center">
                            {!!navigateToQuestionCreationPage && <button onClick={navigateToQuestionCreationPage} className="bg-action-blue fw-medium text-white cm-pointer border border-action-blue me-3">
                                <span className="text-white">Proceed</span>
                            </button>}
                            <button onClick={handleModalToggle} className={`bg-white fw-medium text-action-blue cm-pointer border border-action-blue`}>
                                <span>Dismiss</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}

export default QuestionInstructionModal;