import { Checkbox, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';
import ToolsLoginFormContainer from './ToolsLoginForm.container';
import './DownloadQuestionBank.styles.css';
import AceEditor from 'react-ace';
import HierarchyTree from './HierarchyTree.container';
import { splitLines } from '../../utils/helperFn';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';


const HierarchyContainer = ({ t }) => {
    const dispatch = useDispatch();
    const [hierarchyData, setHierarchyData] = React.useState({ data: null, token: null, loginExpand: true, errMsg: null, modalStatus: false, modalData: { language: null, questionDiscovery: false, productCategory: null, pricingCategory: null } });
    const [formVal, setFormVal] = React.useState({ parentBank: '', topics: null, showHierarchy: false, isLoading: false, });
    const [treeState, setTreeState] = React.useState(null);

    const toggleAccordian = () => setHierarchyData({ ...hierarchyData, loginExpand: !hierarchyData.loginExpand })

    const handleFieldChange = (key, value) => {
        setFormVal({ ...formVal, [key]: value })
    }

    const createTree = () => {
        const treeData = {};

        const linesArr = splitLines(formVal.topics);

        linesArr.forEach(el => {
            var topic1 = el.substring(0, el.lastIndexOf("-")).trim();
            var topic2 = el.substring(el.lastIndexOf("-") + 1, el.length).trim();

            if(topic2 && (["application", "analysis", "theory", "concept", "essential"].includes(topic2.toLowerCase()))) {
                if(treeData[topic1]) {
                    if(!treeData[topic1].includes(el))treeData[topic1].push(el);
                } else {
                    treeData[topic1] = [el];
                }
            } else if(el) {
                treeData[el] = []
            }

            setTreeState({ root: formVal.parentBank, branch: treeData })
            // getTopicTagData();
        })
    }

    const handleGetHierarchy = (e) => {
        e.preventDefault();
        if(formVal.parentBank && formVal.topics) createTree();
    }

    const getTopicTagData = (data) => {
        if(data.accountType === "admin") setHierarchyData({ ...hierarchyData, data: data.mettlAdminMetaData, token: data.token, modalStatus: true })
        else { 
            createHierarchy(true, data.token);
            setHierarchyData({ ...hierarchyData, token: data.token,})
        }
    }

    const handleDialogToggle= (hasErrMsg) => {
        setHierarchyData({ ...hierarchyData, modalStatus: !hierarchyData.modalStatus, modalData: { language: null, questionDiscovery: false, productCategory: null, pricingCategory: null }, ...(hasErrMsg ? { errMsg: null } : {}) })
    }

    const handleDialogClose= (hasErrMsg) => {
        setHierarchyData({ ...hierarchyData, modalStatus: false,})
    }

    const createHierarchy = async(skipTopic, token) => {
        try {
            setFormVal({ ...formVal, isLoading: true })
            const hierarchyDataObj = {title: treeState.root, nodes: []};

            if(treeState.branch) {
                for(let key in treeState.branch) {
                    const obj = { title: key, nodes: treeState.branch[key].map(el => ({ title: el, nodes: [] })) }
                    hierarchyDataObj.nodes.push(obj);
                }
            }

            const dataToSend = {
                token: skipTopic ? token : hierarchyData.token,
                hierarchyData: hierarchyDataObj,
                tm: skipTopic ? {} : { skill_id: 0, ...hierarchyData.modalData }
            }

            const res = await instance.post(getApiUrl("createHierarchyToMettl"), dataToSend);

            if(res.data.status) {
                dispatch(openSnackBar({
                    msg: res.data.message,
                    type: 'success'
                }));

                setFormVal({ ...formVal, isLoading: false })

                if(hierarchyData.errMsg) {
                    handleDialogToggle(hierarchyData.errMsg);
                } else {
                    handleDialogClose();
                }
            } else {
                if(res.data.message) setHierarchyData({ ...hierarchyData, errMsg: res.data.message });
                throw Error(res.data.message ? res.data.message : 'Something went wrong. Please try again.')
            }
        } catch (error) {
            setFormVal({ ...formVal, isLoading: false });
            handleDialogToggle();
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleTopicFieldChange = (key, value, listName) => {
        let dataToSet = null;
        if (listName) {
            dataToSet = hierarchyData.data[listName].find(el => el.value === value);
        } else { dataToSet = value }

        setHierarchyData({
            ...hierarchyData,
            modalData: { ...hierarchyData.modalData, [key]: dataToSet }
        })
    }

    const loadSelectOptions = React.useCallback((data, fieldName) => data && data.map(el => <MenuItem value={el.value} key={el.id + '-' + fieldName}>{el.name}</MenuItem>), [hierarchyData.data]);

    return(
        <div className='py-4 cm-download-quest-bank-container px-32 d-flex row mx-2'>
            <div className='col-sm-7'>
                <div className='bg-white border border-blue-gray-100 bg-white mb-4'>
                    <p className='mb-0 px-4 py-2 cm-sm-txt fw-semibold text-blue-gray-700 border-bottom border-blue-gray-100'>{t("enter_details")}</p>
                    <form onSubmit={handleGetHierarchy} className='pt-3 px-4 pb-4'>
                        <div className="form-group mb-3">
                            <label htmlFor="parent-bank" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("parent_bank")}</label>
                            <div className="cm-icon-field position-relative">
                                <input type="text" className="form-control text-blue-800 cm-sm-txt fw-medium border-blue-gray-100" id="parent-bank" value={formVal.parentBank} onChange={(e) => handleFieldChange('parentBank', e.target.value)} />
                            </div>
                        </div>
                        <div className="form-group mb-3">
                            <label htmlFor="parent-bank" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("topics")}</label>
                            <div className="cm-icon-field position-relative">
                                <AceEditor
                                    mode={null}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        showPrintMargin: false
                                    }}
                                    onChange={(val) => handleFieldChange("topics", val)}
                                    value={formVal.topics}
                                    width={'100%'}
                                    height={'300px'}
                                    className="border border-blue-gray-100"
                                />
                            </div>
                        </div>

                        <input type="submit" className={`px-4 rounded py-10 cm-sm-txt fw-medium text-white ${formVal.parentBank && formVal.topics ? 'bg-action-blue cm-pointer' : 'cm-action-disabled-bg cm-pointer-none'} d-inline-block border-0`} onClick={handleGetHierarchy} value={t("get_hierarchy")} />
                    </form>
                </div>

                {hierarchyData.errMsg !== null && <span className='cm-sm-txt text-danger fw-medium pb-3 d-block'>{hierarchyData.errMsg}</span>}

                {treeState && formVal.parentBank && formVal.topics && <ToolsLoginFormContainer permissionName="createHierarchy" t={t} fetchData={getTopicTagData} loginExpand={hierarchyData.loginExpand} toggleAccordian={toggleAccordian} downloadFrom={true} btnLabel={t("make_hierarchy")} />}
            </div>

            {treeState && <div className='col-sm-5'><HierarchyTree treeState={treeState} t={t} /></div>}

            <CustomDialog 
                dialogHeading={t("topic_tag")} 
                cancelBtnLabel={t("cancel")}
                actionBtnLabel={<span>{t("save")}</span>} 
                actionBtnBg={(hierarchyData.modalData.language && hierarchyData.modalData.pricingCategory && hierarchyData.modalData.productCategory) && !(formVal.isLoading) ? 'bg-action-blue' : 'cm-action-disabled-bg cm-pointer-none'}
                cancelFn = {() => handleDialogToggle()} 
                actionFn= {() => createHierarchy()} 
                dialogStatus={hierarchyData.modalStatus}
            >
                <div className='mb-3'>
                    <label className='pb-2 text-blue-gray-700 cm-sm-txt pb-2 fw-medium'>{t("product_category")}</label>
                    <div className="position-relative">
                        <Select
                            fullWidth={true}
                            displayEmpty={true}
                            value={hierarchyData.modalData.productCategory ? hierarchyData.modalData.productCategory.value : ''}
                            onChange={e => handleTopicFieldChange('productCategory', e.target.value, 'productCategoryList')}
                            className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                            MenuProps={{
                                className: "cm-select-modal"
                            }}
                        >
                            {loadSelectOptions(hierarchyData.data?.productCategoryList)}
                        </Select>
                        <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow cm-sm-txt text-blue-800 position-absolute" />
                    </div>
                </div>
                <div className='mb-3'>
                    <label className='pb-2 text-blue-gray-700 cm-sm-txt pb-2 fw-medium'>{t("pricing_category")}</label>
                    <div className="position-relative">
                        <Select
                            fullWidth={true}
                            displayEmpty={true}
                            value={hierarchyData.modalData.pricingCategory ? hierarchyData.modalData.pricingCategory.value : ''}
                            onChange={e => handleTopicFieldChange('pricingCategory', e.target.value, 'pricingCategoryList')}
                            className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                            MenuProps={{
                                className: "cm-select-modal"
                            }}
                        >
                            {loadSelectOptions(hierarchyData.data?.pricingCategoryList)}
                        </Select>
                        <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow cm-sm-txt text-blue-800 position-absolute" />
                    </div>
                </div>
                <div className='mb-3'>
                    <label className='pb-2 text-blue-gray-700 cm-sm-txt pb-2 fw-medium'>{t("language")}</label>
                    <div className="position-relative">
                        <Select
                            fullWidth={true}
                            displayEmpty={true}
                            value={hierarchyData.modalData.language ? hierarchyData.modalData.language.value : ''}
                            onChange={e => handleTopicFieldChange('language', e.target.value, 'languageList')}
                            className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                            MenuProps={{
                                className: "cm-select-modal"
                            }}
                        >
                            {loadSelectOptions(hierarchyData.data?.languageList)}
                        </Select>
                        <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow cm-sm-txt text-blue-800 position-absolute" />
                    </div>
                </div>
                <div className="form-group mb-3 d-flex align-items-center">
                    <Checkbox
                        size="small"
                        style={{ height: "30px", padding: "10px" }}
                        checked={hierarchyData.modalData.questionDiscovery}
                        onChange={(e) => handleTopicFieldChange("questionDiscovery", !hierarchyData.modalData.questionDiscovery)}

                    />
                    <span className='cm-sm-txt text-blue-800 fw-medium'>{t("show_in_question_discovery")}</span>
                </div>
            </CustomDialog>
        </div>
    )

}

export default HierarchyContainer;