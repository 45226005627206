import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackBar } from '../../redux/actions/snackbar.actions';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
  

const CustomSnackbar = () => {
    const snackState = useSelector(state => state.snack);
    const dispatch = useDispatch();
    
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        dispatch(closeSnackBar());
      };

    return(
        <Snackbar open={snackState.status} autoHideDuration={6000} sx={{ marginTop: '59px' }}  onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={snackState.type} sx={{ width: '100%' }}>
                {snackState.msg}
            </Alert>
        </Snackbar>
        
    )

}

export default CustomSnackbar;