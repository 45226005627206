import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './NumberedPagination.styles.css';

const NumberedPagination = ({handlePagination, totalPages, currentPage, pagesToShow, showNextPrevButtons}) => {
    const [sliderData, setSliderData] = React.useState({
        startNum: 1,
    })

    const renderPages = () => {
        let pagesStruct = [];

        const maxPagesToShow = totalPages < pagesToShow ? totalPages+1 : (sliderData.startNum + pagesToShow);

        for(let i = sliderData.startNum; i < maxPagesToShow; i++ ) {
            pagesStruct.push(<span onClick={() => handlePagination(i)} className={i === currentPage ? `active cm-pointer-none` : 'cm-pointer'} key={i} >{i}</span>)
        }

        return pagesStruct;
    }

    const handleArrowClick = (type) => {
        let newStartNum = sliderData.startNum;
        if(type === "prev") {
            newStartNum = sliderData.startNum - pagesToShow;
             if(newStartNum !== 1 && (newStartNum) < 1) {
                 newStartNum = 1;
            }
        }

        if(type === "next") {
            //Check if pages available
            newStartNum = sliderData.startNum + pagesToShow;
            if((newStartNum + pagesToShow) > totalPages) {
                newStartNum = newStartNum - (newStartNum + pagesToShow - totalPages) + 1;
            }
        }

        setSliderData({ ...sliderData, startNum: newStartNum });
    }

    return(
        <>
            {showNextPrevButtons && <div className={`cm-pagination-wrap d-flex align-items-center user-select-none me-3 ${currentPage > 1 ? 'cm-pointer' : 'cm-pointer-none'}`} style={{ opacity: currentPage > 1 ? '1' : '0.6' }} onClick={() => {
                handlePagination("prev");
                if((currentPage - 1) % pagesToShow === 0) handleArrowClick("prev")
            }}>
                <span className='px-3'>Previous</span>
            </div>}
            <div className="cm-pagination-wrap d-flex align-items-center user-select-none">
                <span onClick={() => handleArrowClick("prev")} className={sliderData.startNum === 1 ? 'cm-pointer-none text-blue-gray-300 border-blue-gray-300' : ''}><FontAwesomeIcon icon={regular("angle-left")} /></span>
                <div className="cm-number-wrap d-flex align-items-center mx-2">{renderPages()}</div>
                <span onClick={() => handleArrowClick("next")} className={(sliderData.startNum + pagesToShow - 1) === totalPages ? 'cm-pointer-none text-blue-gray-300 border-blue-gray-300' : ''}><FontAwesomeIcon icon={regular("angle-right")} /></span>
            </div>
            {showNextPrevButtons && <div className={`cm-pagination-wrap d-flex align-items-center user-select-none ms-3 ${currentPage < totalPages ? 'cm-pointer' : 'cm-pointer-none'}`} style={{ opacity: currentPage < totalPages ? '1' : '0.6' }}>
                <span className='px-3' onClick={() => {
                    handlePagination("next");

                    if(currentPage % pagesToShow === 0) handleArrowClick("next")
                }}>Next</span>
            </div>}
        </>
    )

}

NumberedPagination.defaultProps = {
    pagesToShow: 10
}

export default NumberedPagination;