import React from 'react';
import instance from '../../utils/axios';
import allUsersCSS from './allUsers.module.css';
import { useDispatch, useSelector } from 'react-redux';
import './allUsers.styles.css';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import AddUserModal from '../../containers/add-users/AddUserModal.container';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from '@mui/material';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { searchFilter } from '../../utils/helperFn';
import { appError } from '../../redux/actions/error.action';
import ErrorController from '../error/ErrorController.page';




export default function AllUsers() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [anchorElUser, setAnchorElUser] = React.useState({
        anchor: null,
        user: null
    });
    // const [allUsers, setAllUsers] = React.useState([]);
    const [allUsers, setAllUsers] = React.useState({
        data: [],
        searchActive: false,
        searchResult: []
    });
    const [editUser, setEditUser] = React.useState(null);
    const [smeData,setSmeData]=React.useState([]);
    const [searchTxt, setSearchTxt] = React.useState('');
    const chkError = useSelector((state) => state.error);
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        userInfo: null,
    });

    const handleDialogToggle= () => {
        setDialogStatus({
            status: !dialogStatus.status,
            userInfo: !!anchorElUser.user ? {userId: anchorElUser.user.userId, isActive: !anchorElUser.user.isActive} : null,
        });

        if(anchorElUser.anchor) setAnchorElUser({ anchor: null, user: null })

    }

    const dialogActionFn = () => {
        disableEnableUser(dialogStatus.userInfo);
        setDialogStatus({
            status: false,
            userInfo: null,
        })
    }

    const disableEnableUser = async (userInfo) => {
        try {
            const res = await instance.post(`${getApiUrl('disableEnableUser')}`, userInfo);
            if(!!res.data) getAllUsers();
        } catch (error) {

            console.log('disableUser err', error);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))

        }
    }

    const getAllUsers = () => {
        instance.get(`${getApiUrl('getAllUsers')}`)
            .then(function (response) {
                if (response.data) {
                    setAllUsers({
                        ...allUsers,
                        data: response.data.map(el => ({
                            ...el,
                            name: el.firstName + " " + el.lastName,
                        }))
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                appError('AllUsers', error, dispatch);
            })
    }

    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "All Users",
            link:'/'
        }
    ]

    React.useEffect(() => {
        getAllUsers();
    }, [])

    const handleOpenUserMenu = (event, user=null) => {
        setAnchorElUser({ anchor: event.currentTarget, user });
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser({ anchor: null, user: null });
    };

    const [modalStatus, setModalStatus] = React.useState(false);

    const handleModalToggle = (type, user) => {
        if(type === 'edit') {
            // console.log('user here', user);
            handleCloseUserMenu();
            setEditUser(user);
            setModalStatus(!modalStatus);
            return;
        }
        setModalStatus(!modalStatus);
        setEditUser(null);
    }

    const handleChange = (e) => {
        setAllUsers({
            ...allUsers,
            searchActive: e.target.value !== '',
            searchResult: searchFilter(allUsers.data, e.target.value, ['name', 'accountType','emailId'])
        })
        setSearchTxt(e.target.value)
       
    }

    const handleError = () => chkError.location === 'AllUsers'

    const loadUsers = (arr) => {
        return (
            arr.map((user, index) => {
                return (
                    <div key={user.userId} className={`cm-candidate-report-item d-flex align-items-center ${!user.isActive ? 'bg-gray-50' : 'bg-white' }`}>
                        <div className='col-sm-1'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.userId}</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.firstName} {user.lastName}</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.emailId}</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.accountType}</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.createdAt}</p>
                        </div>
                        <div className='col-sm-1'>
                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.createdBy}</p>
                        </div>
                        <div className='col-sm-1'>
                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>{user.isActive.toString()}</p>
                        </div>
                        <div className="col-sm-1">
                            <FontAwesomeIcon icon={regular("ellipsis-vertical")} className="text-blue-800 p-2 cm-pointer" onClick={(e) => handleOpenUserMenu(e, user)} />
                        </div>
                    </div>
                
            )})
        )
    }


    return (
        handleError() ? <ErrorController data={chkError} /> :<>
            <div className="body">
            <Breadcrumb data={breadcrumbData}/>
            <div className="cm-candidate-report-list-container">
                <>
                    <div className='pt-4 px-5 cm-candidate-search-wrapper d-flex align-items-center justify-content-between flex-wrap mb-3'>
                        <div className="cm-left-col">
                            <h5 className='my-0 text-blue-800 cm-mmc-font'>Available Users</h5>
                        </div>
                        <div className='cm-candidate-search-field position-relative'>
                                            <input type="text" value={searchTxt} onChange={handleChange} name="search" placeholder='Search by name/email' className='cm-sm-txt text-blue-800' />
                                            <FontAwesomeIcon icon={regular("search")} className="position-absolute text-blue-gray-700" />
                                        </div>
                    </div>

                    <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50">
                        <div className='col-sm-1'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>User Id</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Name</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Email</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Account Type</p>
                        </div>
                        <div className='col-sm-2'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Created On</p>
                        </div>
                        <div className='col-sm-1'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Created By</p>
                        </div>
                        <div className='col-sm-1'>
                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Active</p>
                        </div>
                        <div className='col-sm-1'>
                        </div>
                    </div>
                    <div className="cm-candidate-report-list-content bg-white">
                        {loadUsers(allUsers.searchActive ? allUsers.searchResult : allUsers.data)}
                    </div>
                    <Menu
                        sx={{ mt: '32px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser.anchor}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser.anchor)}
                        onClose={handleCloseUserMenu}
                        MenuListProps={{ disablePadding: true }}
                        elevation={0}
                        PaperProps={{  
                            style: {  
                                width: 220,  
                            },
                        }}
                    >
                        <li><p className='p-2 my-0 cm-xs-txt text-blue-gray-700 fw-semibold text-uppercase'>{t('account_actions')}</p></li>
                        <MenuItem onClick={() => handleModalToggle('edit', anchorElUser.user)}>
                            <FontAwesomeIcon icon={regular("edit")} className="text-blue-gray-500"/>
                            <span className='ms-3 cm-sm-txt fw-medium text-blue-800'>Edit User</span>
                        </MenuItem>
                        <MenuItem onClick={handleDialogToggle}>
                            <FontAwesomeIcon icon={regular("trash")} className="text-blue-gray-500"/>
                            <span className='ms-3 cm-sm-txt fw-medium text-blue-800'>{!!anchorElUser.user && anchorElUser.user.isActive ? 'Disable' : 'Enable'} User</span>
                        </MenuItem>
                    </Menu>
                    <CustomDialog 
                        dialogHeading={dialogStatus?.userInfo?.isActive ? 'Enable User' : `Disable User`}
                        cancelBtnLabel={`No, Don't ${dialogStatus?.userInfo?.isActive ? 'Enable' : `Disable`}`} 
                        actionBtnLabel={<span>{dialogStatus?.userInfo?.isActive ? 'Enable User' : `Disable User`}</span>} 
                        actionBtnBg={dialogStatus?.userInfo?.isActive ? 'bg-action-blue' : "bg-danger"}
                        cancelFn = {handleDialogToggle} 
                        actionFn= {dialogActionFn} 
                        dialogStatus={dialogStatus.status}
                    >
                        <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to {dialogStatus?.userInfo?.isActive ? 'enable' : 'disable'} the selected user?</p>
                    </CustomDialog>
                </>

                {/* {candReport.data.length === 0 && candReport.pageNum === 0 ? null : <CandidateReportPagination t={t} handlePagination={handlePagination} candidateData={candReport} /> } */}
            </div>
            <AddUserModal handleModalToggle={handleModalToggle} modalStatus={modalStatus} t={t} getAllUsers={getAllUsers} editUser={editUser} />
            <div className="btn btn-success ms-5 mt-4 bg-action-blue" id={allUsersCSS.addUser} onClick={handleModalToggle}>Add User</div>

                {/* <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" className="text-blue-800">
                                Fill Details to Add User
                            </Typography>
                            <form className="ps-3 mt-3">
                                <div className="mb-3">
                                    <div className="form-group mb-3">
                                        <label htmlFor="email" className='pb-2 labelText'>Account Type</label>
                                        <Select options={accountTypes} value={accountTypes.filter(function (option) {
                                            return option.value === accountType;
                                        })} onChange={handleAccountType} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label className='pb-2 labelText'>First Name</label>
                                            <input type="text" name="firstName" className="form-control text-blue-800 fw-medium cm-box" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />

                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group mb-3">
                                            <label htmlFor="lastName" className='pb-2 labelText'>Last Name</label>
                                            <input type="text" name="lastName" value={lastName} className="form-control text-blue-800 fw-medium cm-box" onChange={(e) => setLastName(e.target.value)} required />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className='pb-2 labelText'>Email Address</label>
                                    <input type="email" name="email" value={email} className="form-control text-blue-800 fw-medium cm-box-lg" onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className='pb-2 labelText'>Contact Number</label>
                                    <input type="text" name="contact" value={contact} className="form-control text-blue-800 fw-medium cm-box-lg" onChange={(e) => setContact(e.target.value)} required />
                                </div>
                                <div className="form-outline mb-4 d-flex">
                                    <label className="me-3 mt-3 text-blue-800">Rates for Creation</label>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className='pb-2 labelText'>Easy</label>
                                    <input className="form-control text-blue-800 fw-medium cm-box-lg" type="text" name="rates" value={easyRate} onChange={(e) => setEasyRate(e.target.value)} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className='pb-2 labelText'>Medium</label>
                                    <input className="form-control text-blue-800 fw-medium cm-box-lg" type="text" name="rates" value={mediumRate} onChange={(e) => setMediumRate(e.target.value)} required />
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="email" className='pb-2 labelText'>Difficult</label>
                                    <input className="form-control text-blue-800 fw-medium cm-box-lg" type="rates" name="text" value={difficultyRate} onChange={(e) => setDifficultyRate(e.target.value)} required />
                                </div>
                                <div className="btn btn-success ms-5" style={{ 'position': 'relative', 'left': '200px', 'background-color': '#1976d2', 'color': 'white' }} type="button" onClick={handleSubmit}>Add
                                </div>
                            </form>
                        </Box>
                    </Modal>
                </div> */}
            </div>      
        </>
         
    )
}
