import { SETROWS, DISSELECTALL, DISETROWS } from "./actions.types";

export const setRows = (data) => {
    let payload = [];

    if (Array.isArray(data)) {
        payload = data;
    } else {
        payload.push(data);
    }
    return {
        type: SETROWS,
        payload: payload
    }
}
export const diSet = (data) => ({
    type: DISETROWS,
    payload: data
})

export const disselectALl = (data) => ({
    type: DISSELECTALL
})
