import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import './CustomDialog.styles.css';
import CircularProgress from '@mui/material/CircularProgress';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={regular("times")} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomDialog({ dialogHeading, children, cancelBtnLabel, actionBtnLabel, cancelFn, actionFn, dialogStatus, actionBtnBg, style, actionBtnLabel2, actionFn2, actionBtnBg2, comment, disableButton, isReviewer, selectedFlag, isComment, handleSelectClose, actionBtn }) {

  const handleClickOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    cancelFn(false)
    if (handleSelectClose) handleSelectClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogStatus}
        className="cm-dialag-container"
        style={style}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialogHeading}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {children}
        </DialogContent>
        <DialogActions>
          {!!cancelBtnLabel ? <Button disableRipple={true} onClick={handleClose} className="cm-dialog-cancel-btn text-action-blue cm-sm-txt fw-medium">
            {cancelBtnLabel}
          </Button> : null}
          {!!actionBtn ? actionBtn : null}
          {!actionBtn && comment !== '' && !!actionBtnLabel && <Button
            disableRipple={true}
            onClick={actionFn}
            className={`cm-dialog-action-btn border-action-blue ${actionBtnBg} text-white cm-sm-txt fw-medium `}
            disabled={isReviewer === "true" ? ((!selectedFlag && isComment != '') ? false : true) : (selectedFlag ? true : false)}
          >
            {actionBtnLabel}
            {disableButton ? <CircularProgress style={{ marginLeft: "5px", height: "15px", width: "15px" }} /> : null}
          </Button>}
          {comment !== '' && !!actionBtnLabel2 && <Button
            disableRipple={true}
            onClick={actionFn2}
            className={`cm-dialog-cancel-btn border-danger text-white cm-sm-txt fw-medium ${actionBtnBg2}`}
            disabled={(isReviewer === "true") ? ((selectedFlag && isComment != '') ? false : true) : ((selectedFlag) ? false : true)}
          >
            {actionBtnLabel2}
          </Button>
          }
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

CustomDialog.defaultProps = {
  dialogHeading: "",
  actionBtnBg: 'bg-action-blue',
  style: {},
  disableButton: false
}