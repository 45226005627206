import React from 'react';
import { replaceAll } from '../../containers/question-library/LanguageToolModal.container';
import { Popover } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { decode } from 'html-entities';
import { CKEditor } from 'ckeditor4-react';

const charLimit = 100000;

const LangTextEditor = ({ data, editorOptions, handelTxtEditorChange, result, handleCorrection }) => {
    const [editorInstance, setEditorInstance] = React.useState("");
    const [isFocus, setIsFocus] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState({
        top: null,
        left: null,
        status: false,
        data: null
    })


    React.useEffect(() => {
        if (!!editorInstance) {
            //Register span
            editorInstance.model.schema.register('span', {
                inheritAllFrom: '$block',
                allowIn: ['paragraph'],
                allowAttributes: ['id', 'class', 'data-pos']
            });
            editorInstance.conversion.elementToElement({ model: 'span', view: 'span' });
            editorInstance.conversion.attributeToAttribute({ model: 'class', view: 'class' });
            editorInstance.conversion.attributeToAttribute({ model: { name: 'span', key: 'id' }, view: 'id' });
            editorInstance.conversion.attributeToAttribute({ model: { name: 'span', key: 'data-pos' }, view: 'data-pos' });
        }
    }, [editorInstance]);

    React.useEffect(() => {
        if (result.length > 0) {
            const view = editorInstance.editing.view;
            const viewDocument = view.document;
            // view.addObserver(ClickObserver);

            editorInstance.listenTo(viewDocument, 'click', (evt, data) => {
                if (data.domTarget.nodeName === 'SPAN') {
                    const getPos = data.domTarget.dataset.pos.split(',');
                    const getData = result.filter(el => el.errorStart === Number(getPos[0]) && el.errorEnd === Number(getPos[1]));
                    setErrorModal({
                        status: true,
                        top: data.domTarget.getBoundingClientRect().top + data.domTarget.offsetHeight + 5,
                        left: data.domTarget.getBoundingClientRect().left,
                        data: getData[0]
                    })
                }

            });
        }

    }, [result]);

    const handleErrModalClose = () => {
        setErrorModal({
            top: null,
            left: null,
            status: false,
            data: null
        })
    }

    const onSuggestionClick = (wordSelected) => {
        handleCorrection({ ...errorModal.data, wordSelected });
        handleErrModalClose();
    }

    const getErrType = (type) => {
        if (type === 'Grammar') {
            return 'Grammatical Error';
        } else if (type === 'Possible Typo') {
            return "Spelling Error";
        } else if (type === "Upper/Lowercase") {
            return "Capitalization Error";
        } else {
            return 'Error'
        }
    }


    return (
        <div className="cm-txt-editor-container">
            <CKEditor
                // editor={Editor}
                data={data}
                config={editorOptions}
                onReady={(editor) => {
                    setEditorInstance(editor)
                }}
                onChange={(event, editor) => {
                    let data = editor.getData();

                    if (!!editorInstance) {
                        if (editorInstance.model.document.selection.anchor.parent.name === 'span') {
                            const getSpanEl = editorInstance.model.document.selection.anchor.parent;
                            data = replaceAll(data, `&nbsp;</span>`, `</span>&nbsp;`);
                            setTimeout(() => {
                                editorInstance.model.change(writer => {
                                    writer.setSelection(writer.createPositionAt(getSpanEl, 'end'));
                                });
                            }, 2000)
                            isFocus && handelTxtEditorChange(data);
                            // handelTxtEditorChange(decode(data));
                            return;
                        }
                    }

                    isFocus && handelTxtEditorChange(data);
                    // handelTxtEditorChange(decode(data))
                }}
                onBlur={(event, editor) => {
                    // setIsFocus(false)
                }}
                onFocus={(event, editor) => {
                    setIsFocus(true)
                }}
            />
            {!!errorModal.data &&
                <Popover
                    id={"lang-error"}
                    open={errorModal.status}
                    anchorReference="anchorPosition"
                    onClose={handleErrModalClose}
                    anchorPosition={{ top: errorModal.top, left: errorModal.left }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {!!errorModal.data ?
                        <div className='cm-err-editor-modal'>
                            <div className="cm-err-editor-header d-flex align-items-center justify-content-between p-2">
                                <p className='my-0 cm-sm-txt fw-medium d-flex align-items-center'><div className="cm-red-dot me-2 bg-danger"></div> {getErrType(errorModal.data.typeOfVoilation)}</p>
                                <FontAwesomeIcon icon={regular("times")} className="cm-sm--txt px-2 py-1 text-gray-200 cm-pointer" onClick={handleErrModalClose} />
                            </div>
                            <div className="cm-err-editor-content border-top border-blue-gray-100 px-2 py-3">
                                <p className='cm-xs-txt my-0'>{errorModal.data.desciptionOfVoilation}.</p>
                            </div>
                            <div className="cm-err-editor-footer d-flex align-items-center flex-wrap border-top border-blue-gray-100 py-2 px-1">
                                {errorModal.data.suggestions.map(el => <span key={el} className="d-inline-block bg-blue-500 text-white py-1 px-2 cm-xs-txt fw-medium cm-pointer" onClick={() => onSuggestionClick(el)}>{el}</span>)}
                            </div>
                        </div>
                        :
                        null
                    }
                </Popover>
            }
        </div>
    )
}

LangTextEditor.defaultProps = {
    data: '',
    editorOptions: {
        // toolbar: []
        toolbar: ['heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo',
            'sourceEditing',
        ]
    }
}

export default LangTextEditor