import React from "react";
import AnimationComp from "../animation-comp/AnimationComp.component";
import animationData from "../../assets/loaders/loader.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const colorBucket = {
  hoverBlue: {
    parentClass: "border-action-blue text-action-blue",
    loadingFalseClass: "cm-action-hover bg-white text-action-blue",
    loadingTrueClass: "bg-action-blue",
    disabled:
      "cm-action-disabled border-blue-gray-300 bg-white cm-save-schedule-btn",
  },
  blue: {
    parentClass: "bg-action-blue border border-action-blue text-white",
    disabled:
      "cm-action-disabled-bg border-blue-gray-300 text-white",
  },
  white: {
    parentClass: "bg-white border border-gray-300 text-blue-800",
  },
};
const CustomButtonLoader = ({
  showLoadingState, // loading state, false
  colorTheme, // blue,white
  reverseIconDirection, // boolean
  icon,
  buttonLabel,
  buttonStyle,
  handleOnClick,
  disabled,
  buttonProps
}) => {
  const modalButtonRef = React.useRef(null);

  if (disabled) {
    return (
      <button
        key={buttonLabel}
        autoFocus={false}
        className={`d-flex cm-btn-radius fw-medium cm-sm-txt border cm-pointer-none ${
          colorBucket[colorTheme].disabled
        } ${!!buttonStyle ? buttonStyle : ""}
      `}
        ref={modalButtonRef}
        style={{
          width: modalButtonRef?.current?.getBoundingClientRect().width + "px",
          height:
            modalButtonRef?.current?.getBoundingClientRect().height + "px",
        }}
      >
        <span className={`d-flex ${reverseIconDirection ? "" : ""}`}>
          {buttonLabel}
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className="my-auto fw-bolder cm-mar-icon"
            />
          )}
        </span>
      </button>
    );
  }

  return (
    <button
      autoFocus={false}
      className={`d-flex cm-btn-radius fw-medium cm-sm-txt border cm-loader-btn ${
        !!buttonStyle ? buttonStyle : ""
      } ${
        colorBucket[colorTheme].hasOwnProperty("loadingFalseClass") &&
        showLoadingState
          ? colorBucket[colorTheme].loadingTrueClass
          : colorBucket[colorTheme].loadingFalseClass
      } ${!!colorTheme && colorBucket[colorTheme].parentClass} 
       ${showLoadingState ? "justify-content-center align-items-center cm-btn-loading" : ""}`}
      ref={modalButtonRef}
      style={{
        width: showLoadingState
          ? modalButtonRef?.current?.getBoundingClientRect().width + "px"
          : "auto",
        height: modalButtonRef?.current?.getBoundingClientRect().height + "px",
      }}
      onClick={!showLoadingState ? handleOnClick : null}
      {...(buttonProps ? buttonProps : {})}
    >
      {!showLoadingState ? (
        <span className={`d-flex ${reverseIconDirection ? "" : ""}`}>
          {buttonLabel}
          {!!icon && (
            <FontAwesomeIcon
              icon={icon}
              className="my-auto fw-bolder cm-mar-icon"
            />
          )}
        </span>
      ) : (
        <AnimationComp
          animationData={animationData}
          width={'100%'}
          color="text-blue-800"
        />
      )}
    </button>
  );
};

export default CustomButtonLoader;
