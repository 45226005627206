import * as React from 'react';
import { Modal } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import "./UploadBankQuestions.css"
import AnimationDataLoader from '../../components/Loaders/animationData';
import { getApiUrl } from '../../utils/apiUrls';
import taskbtn from "../../assets/images/global/empty_task_modal.svg"
import bulbImg from "../../assets/images/global/bulb.svg"
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';
import { useRef } from 'react';
import { saveAs } from 'file-saver';
import { Menu, MenuItem, Select } from '@mui/material';

const UploadBankQuestions = ({ uploadQuestionsModalStatus, handleModalToggle, bankId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const initialState = {
        bankName: "",
        bankDescription: ""
    }
    const [initalState, setInitialState] = React.useState(true)
    const [isUploadmsg, setIsUploadmsg] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false);
    const fileInputRef = useRef(null);
    const [errorMessage, setErrorMessage] = React.useState("")
    const [downloadUrl, setDownloadUrl] = React.useState("")
    const [fileName, setFileName] = React.useState("")
    const [errorStatus, setErrorStatus] = React.useState("")
    const [isUploadSuccess, setIsUploadSuccess] = React.useState(false)
    const [errorQuestionCount, setErrorQuestionCount] = React.useState(10)
    const [successfullUploadQuestions, setSuccessfullUploadQuestions] = React.useState()
    const [isDropFile, setIsDropFile] = React.useState(false);

    const [selectedLanguage, setSelectedLanguage] = React.useState({});
    const [languageOptions, setLanguageOptions] = React.useState([]);
    const [fetchedAllLanguages, setFetchedAllLanguages] = React.useState(false);

    const fileUpload = async (file) => {
        try {
            const res = await instance({
                method: "post",
                url: `${getApiUrl("uploadMultipleChoicefile")}?bankId=${bankId}&questionLanguage=${selectedLanguage.value}`,
                data: file,
                headers: { "Content-Type": "multipart/form-data" },
            });
            if (res.status) {
                setIsUploadSuccess(true);
                setSuccessfullUploadQuestions(res.data.size)
            }
        }

        catch (error) {
            setIsUploadmsg(true);
            setErrorStatus(error.response.status)
            if (error.response.status === 400) {
                let errobj = error.response?.data;
                setErrorMessage(errobj.message)
                setDownloadUrl(errobj.errorUrl)
            }
            else {
                setIsUploadmsg(false);
                setInitialState(true);
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            }
        }
        setIsLoading(false);

    }

    const handleFileChosen = (file) => {
        setInitialState(false)
        setIsLoading(true);
        const fileFormatRegex = /\.(xls|xlsx)$/;
        if (!fileFormatRegex.test(file.name)) {
            setFileName("")
            dispatch(openSnackBar({
                msg: t("upload_error_message"),
                type: 'error'
            }))
            setIsLoading(false);
            setInitialState(true);
        }

        else {
            setInitialState(false);
            setFileName(file.name)
            const formData = new FormData();
            formData.append('file', file);
            fileUpload(formData)
        }

    };


    const handleDownloadTemplate = async () => {
        const res = await instance.get(`${getApiUrl("downloadQuestiontemplate")}`, {
            responseType: 'blob',
        });
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        saveAs(blob, "Mettl-Bulk-Upload-Template-General-Questions.xls");
        handleModalToggle();
    }

    const handleOpenFileUploader = () => {
        fileInputRef.current.click();
    }


    const handleDropFile = (e) => {
        e.preventDefault();
        setIsDropFile(true)
        const droppedFile = e.dataTransfer.files[0];
        handleFileChosen(droppedFile);
    }
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const fetchAllLanguages = async () => {
        try {
            const res = await instance.get(getApiUrl("getLanguagesSupport"));
            setLanguageOptions(res.data);
        } catch (error) {
            console.log("Error", error)
        }
    }

    React.useEffect(() => {
        fetchAllLanguages();
    }, [fetchedAllLanguages])

    React.useEffect(() => {
        if (isUploadSuccess) {
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
    }, [isUploadSuccess])

    React.useEffect(() => {
        setIsLoading(false)
        setIsUploadmsg(false)
        setIsUploadSuccess(false);
        setInitialState(true);
        setIsDropFile(false);
    }, [uploadQuestionsModalStatus])


    const loadSelectMenuItem = () => {
        return languageOptions?.map(el => (
            <MenuItem value={el} key={el.value}>{el.language}</MenuItem>
        ));
    }
    const handleLanguageSelectChange = (e) => {
        setSelectedLanguage(e.target.value);
    }

    return (
        <div>
            <Modal open={uploadQuestionsModalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
                <div className='cm-upload-bank-questions-container bg-white my-3'>
                    <div className='cm-upload-bank-questions-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h5 className='text-blue-800 my-0'>{t("upload_questions")}</h5>
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalToggle} />
                    </div>
                    <div className='w-100 ps-3 pe-3 d-flex align-items-center justify-content-around mt-3'>
                        <label className='text-blue-gray-700 cm-sm-txt fw-medium'>Select a Language to upload question</label>
                        <div className="w-40 position-relative">
                            <Select
                                id="lang-select"
                                fullWidth={true}
                                displayEmpty={true}
                                value={selectedLanguage}
                                onChange={e => handleLanguageSelectChange(e)}
                                renderValue={(selected) => {
                                    if (!selected.language) return <span className='text-blue-gray-400'>Select Language</span>

                                    return selected.language;
                                }}
                                style={{ height: '30px' }}
                                className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                MenuProps={{
                                    className: "cm-select-modal"
                                }}
                            >
                                {loadSelectMenuItem()}
                            </Select>
                            <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow cm-sm-txt text-blue-800 position-absolute" />
                        </div>
                    </div>
                    <div className='cm-upload-bank-questions-body'>
                        {initalState && selectedLanguage && selectedLanguage.language &&
                            <div
                                className=' d-flex justify-content-center align-items-center cm-event-fileupload  bg-blue-gray-50 border-blue-gray-300 '>
                                <div onDragOver={handleDragOver} onDrop={handleDropFile}
                                    className='d-flex justify-content-center align-items-center cm-event-drag-drop'>
                                    <>
                                        <img src={taskbtn} alt="" height="35" />
                                        <label className='mb-2 text-blue-gray-700 cm-sm-txt fw-medium' >{t("upload_bank_questions")} </label>
                                    </>
                                </div>
                                <span className="line">
                                    <h2>or</h2>
                                </span>
                                <div className='d-flex justify-content-center align-items-center cm-event-upload-option'>
                                    <input type="file" ref={fileInputRef} disabled={isDropFile} onChange={e => handleFileChosen(e.target.files[0])} accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden={true} />
                                    {/* <img  className="cm-pointer" onClick={handleOpenFileUploader} src={uploadIMg} alt="" height="30"/> */}
                                    <span
                                        onClick={handleOpenFileUploader}
                                        id="createRequestButton"
                                        className=" mx-2 mt-2 cm-add-bank-btn border border-gray-300 bg-white text-blue-800 cm-sm-txt fw-medium cm-pointer "
                                    >
                                        Browse files
                                    </span>
                                    <label className='mt-1 text-blue-gray-700 cm-sm-txt fw-medium'  > Upload from your device</label>
                                </div>
                            </div>
                        }
                        {isLoading &&
                            <div className=" cm-event-fileupload  d-flex align-items-center justify-content-center mt-3">
                                <AnimationDataLoader />
                                <label className=' text-blue-gray-700 cm-xs-txt fw-medium' >{t("uploading")}</label>
                            </div>
                        }
                        {(isUploadmsg || isUploadSuccess) &&
                            <div className=' d-flex justify-content-center align-items-center cm-event-fileupload labelwrap bg-blue-gray-50 border-blue-gray-300 '>
                                <img src={bulbImg} alt="" height="35" />
                                {isUploadmsg &&
                                    <>
                                        <label className='ms-2 cm-sm-txt text-danger fw-medium pt-2' ><FontAwesomeIcon className="cm-sm-txt text-danger" icon={regular("exclamation-circle")} /> {downloadUrl === null ? errorMessage : `Error occured while uploading questions`}</label>
                                        {downloadUrl !== null &&
                                            <>
                                                <a href={downloadUrl} target="_blank" className=' cm-txt text-action-blue fw-medium pt-3' >
                                                    <FontAwesomeIcon className="mx-2 cm-txt text-action-blue " icon={regular("square-exclamation")} />
                                                    {t("download_error_report")}
                                                </a>
                                                <label className='cm-xs-txt text-blue-gray-700 fw-medium pt-2' >{t("bulk_upload_error_msg")}</label>
                                            </>
                                        }
                                    </>
                                }
                                {isUploadSuccess &&
                                    <label className='mt-2 cm-sm-txt text-blue-gray-700 fw-medium pt-2' >
                                        {`${successfullUploadQuestions} questions uploaded Successfully`}
                                    </label>
                                }
                            </div>
                        }
                    </div>
                    <div className="cm-form-btn-group py-2  cm-create-modal-form-footer d-flex align-items-center justify-content-end mt-3">
                        <button onClick={handleDownloadTemplate} className={`ms-2 cm-icon-btn cm-uploadquestion-btn position-relative btn btn-primary bg-action-blue cm-sm-txt  d-flex align-items-center ${fileName === "" ? 'cm-disable-btn' : ''}`}>
                            <FontAwesomeIcon icon={regular('arrow-alt-down')} className="ps-2" />
                            <span className=" ms-2 text-white">{t("download_template")}</span>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
export default UploadBankQuestions;