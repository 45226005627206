import React from "react";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../components/custom-dialog/CustomDialog.component";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { apiErrorHandler } from "../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import { getApiUrl } from "../../utils/apiUrls";
import instance from "../../utils/axios";
import BankSettingModal from "../../pages/my-bank/BankSettingModal.component";


const BankDashboardSidebar = ({ allSkills, handleSkillChange, bankId, bankName, questionCount, isSKillsLoading, handleChangeBankHierarchy }) => {

  const user = useSelector((state) => state.user);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [deleteDialog, setDeleteDialog] = React.useState({
    status: false,
    data: null
  });


  const [subSkillSelected, setSubSkillSelected] = React.useState({});

  const [modalData, setModalData] = React.useState({
    type: null,
    data: null
  })

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };



  const handleDialogToggle = (data) => {
    setDeleteDialog({
      ...deleteDialog,
      status: !deleteDialog.status,
      data: !!data ? data : null
    })
  }

  const dialogActionFn = () => {
    handleDelete(deleteDialog.data.reviewBankId);
    handleDialogToggle();
  }

  const handleDelete = async (reviewBankId) => {
    try {
      const res = await instance.get(`${getApiUrl('deleteBank')}?bankId=${reviewBankId}&rootBankId=${bankId}`);
      if (res.data.status) {
        dispatch(openSnackBar({
          msg: res.data.message,
          type: 'success'
        }));
        handleSkillChange(bankId, 0);
        handleChangeBankHierarchy()
      }
    } catch (error) {
      const errObj = apiErrorHandler(error);
      dispatch(openSnackBar({
        msg: t(errObj.statusText),
        type: 'error'
      }))
    }
  }

  const handleSettingClick = (e, data) => {
    e.stopPropagation();
    handleOpenUserMenu(e);
    setSubSkillSelected(data);
  }

  const renderTreeItems = (items) => {
    if (items.length == 0) {
      return <div key="stub" />
    } else {
      return items.map((item, index) => (
        <TreeItem key={index} itemId={item.reviewBankId.toString()} label={
          <>
            <span className="cm-event-bank-label d-flex align-items-center justify-content-between justify-space-between cm-sm-txt"
              onClick={(e) => { handlelabelClick(e, item.reviewBankId) }}>{item.reviewBankName}
              <span>
                <span className="text-center px-1 cm-question-count fw-medium d-inline-block bg-action-blue text-white py-1 ms-2">{item.questionCount}</span>
                {<span className="ms-1 mt-1">
                  <CustomToolTip title="Settings">
                    <FontAwesomeIcon
                      icon={regular("ellipsis")}
                      className="text-action-blue cm-sm-txt cm-pointer"
                      onClick={(e) => handleSettingClick(e, { id: item.reviewBankId, name: item.reviewBankName, isTopic: item.isTopic, parentReviewBankId: item.reviewBankId })}
                    />
                  </CustomToolTip>
                </span>}
              </span>
            </span>
          </>
        }>
          {(!item.isTopic) && renderTreeItems(getChildren(item.reviewBankId))}
        </TreeItem>
      ));
    }
  };

  const getChildren = (parentId) => {
    return allSkills.filter((bank) => bank.parentReviewBankId == parentId);
  };
  const handlelabelClick = (e, bankId) => {
    e.stopPropagation();
    handleSkillChange(bankId, 0)
  }

  const loadReviewBanks = () => {
    return (
      <TreeItem itemId={bankId.toString()} label={
        <span className="cm-event-bank-label  d-flex align-items-center justify-content-between cm-sm-txt" onClick={(e) => { handlelabelClick(e, bankId) }}>{bankName}
          <span>
            <span className="text-center px-1 fw-medium cm-question-count d-inline-block bg-action-blue text-white py-1">{questionCount}</span>
            {<span className="ms-1 mt-1">
              <CustomToolTip title="Settings">
                <FontAwesomeIcon
                  icon={regular("ellipsis")}
                  className="text-action-blue cm-sm-txt cm-pointer"
                  onClick={(e) => handleSettingClick(e, {
                    id: bankId, name: bankName, isTopic: false, parentReviewBankId: bankId
                  })}
                />
              </CustomToolTip>
            </span>}
          </span>
        </span>
      } >
        {allSkills.length === 1 ? <TreeItem itemId="test"></TreeItem> : renderTreeItems(getChildren(bankId))}
      </TreeItem>
    )
  }

  const handleBankModalToggle = (type, data = null) => {
    let dataToSet = {
      type: type ? type : null,
      data
    };

    if (type === "RENAME_BANK_CLOSE") {
      dataToSet = { type: null, data: null };

    }

    setModalData(dataToSet)
  }


  return (
    <>
      <div className='cm-event-Bank-sidebar'>
        <div className=" ms-4 pb-4 cm-event-progress-wrap">
          <p className='my-0 pb-2 text-blue-gray-700 fw-semibold'>Bank Hierarchy</p>
          {!isSKillsLoading && <SimpleTreeView
            aria-label="gmail"
            defaultExpandedItems={['pickers']}
            sx={{ overflowY: 'auto', minHeight: 224, flexGrow: 1, maxWidth: "calc(100% - 20px)", wordBreak: 'break-all' }}
            className="cm-sm-txt text-blue-800"
          >
            {loadReviewBanks()}
          </SimpleTreeView>}
        </div>
        <Menu
          sx={{ mt: '32px' }}
          id="more-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleCloseUserMenu}
          MenuListProps={{ disablePadding: true }}
          PopoverClasses={{
            className: "cm-select-modal cm-select-inside-modal",
          }}
          elevation={0}
          PaperProps={{
            style: {
              // width: 220,  
            },
          }}
          disableAutoFocus={true}
        >
          {user?.role === "ADMIN" &&
            <div>
              {bankId != subSkillSelected.id && <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleBankModalToggle("RENAME_BANK", { rootBankId: bankId, reviewBankId: subSkillSelected.id, title: "Rename Bank", bankName: subSkillSelected.name, isRenameBank: true }); handleCloseUserMenu() }} autoFocus={false}>Rename Bank</MenuItem>}
              {!subSkillSelected.isTopic && <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleBankModalToggle("CREATE_GROUP", { rootBankId: bankId, parentReviewBankId: subSkillSelected.parentReviewBankId, title: "Create Group", bankName: "", isRenameBank: false, isTopic: false }); handleCloseUserMenu() }} autoFocus={false}>Create Group</MenuItem>}
              {!subSkillSelected.isTopic && <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleBankModalToggle("CREATE_TOPIC", { rootBankId: bankId, parentReviewBankId: subSkillSelected.parentReviewBankId, title: "Create Topic", bankName: "", isRenameBank: false, isTopic: true }); handleCloseUserMenu() }} autoFocus={false}>Create Topic</MenuItem>}
              {bankId != subSkillSelected.id && <MenuItem className="text-blue-800 cm-sm-txt fw-medium" onClick={() => { handleDialogToggle({ reviewBankId: subSkillSelected.id, bankName: subSkillSelected.name }); handleCloseUserMenu() }} autoFocus={false}>Delete Bank</MenuItem>}
            </div>
          }
        </Menu>

        {
          <>
            <CustomDialog
              dialogHeading="Delete Question Bank"
              cancelBtnLabel="No Close Panel"
              actionBtnBg="bg-danger"
              actionBtnLabel={<span>Delete Bank</span>}
              cancelFn={handleDialogToggle}
              actionFn={dialogActionFn}
              dialogStatus={deleteDialog.status}
            >
              <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to delete <span className="fw-semibold">"{deleteDialog?.data?.bankName}"</span>?</p>
            </CustomDialog>
            <BankSettingModal role={user?.role} handleBankModalToggle={(type, data) => handleBankModalToggle(typeof type === 'string' ? type : null, data ? data : null)} modalData={modalData} isRoot={false} handlerefreshAllBanks={handleChangeBankHierarchy} />
          </>
        }
      </div>
    </>
  )
}
export default BankDashboardSidebar;