import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ReactComponent as EmptyIcon } from '../../assets/images/home/empty-data.svg';
import axios from 'axios';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { apiErrorHandler } from '../../utils/errorHandler';
import { Modal } from '@mui/material';
import AnimationDataLoader from '../../components/Loaders/animationData';

const PlagiarismModal = ({ modalStatus, questId, quesText, handleModalToggle }) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch();
    const env = useSelector(state => state.env);

    const [plagData, setPlagData] = React.useState({
        searchResult: [],
        plagStatus: false,
        highestPlagPercentage: -1,
        highestPlagLink: ""
    });

    const handleModalClose = (event, reason) => {
        if (reason === "backdropClick") {
            return false;
        }

        if (reason === "escapeKeyDown") {
            return false;
        }

        setPlagData({
            searchResult: [],
            plagStatus: false,
            highestPlagPercentage: -1,
            highestPlagLink: ""
        })
        handleModalToggle();
    }

    React.useEffect(() => {
        questId !== null && modalStatus && checkPlagiarism();
    }, [modalStatus])

    const checkPlagiarism = () => {
        setIsLoading(true);
        dispatch(openSnackBar({
            type: 'info',
            msg: 'Plagiarism check is running. Kindly wait for sometime and do not refresh the page for atleast 2 minutes.'
        }));
        const dataToSend = { body: quesText };
        axios.post(`${env.PLAGIARISM_URL}`, dataToSend)
            .then(function (response) {
                setIsLoading(false);
                if (response.data) {
                    let plagSearchResult = response.data.searchResultDTOList;
                    plagSearchResult.sort((a, b) => a.plagPercentage < b.plagPercentage ? 1 : -1);
                    setPlagData({
                        searchResult: plagSearchResult,
                        plagStatus: response.data.plagStatus,
                        highestPlagPercentage: response.data.highestPlagPercentage,
                        highestPlagLink: response.data.highestPlagLink
                    })
                    dispatch(openSnackBar({
                        type: 'success',
                        msg: 'Plagiarism check successfully completed.'
                    }))
                }
            })
            .catch(function (error) {
                setIsLoading(false);
                console.log(error);
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: errObj.message,
                    type: 'error'
                }))
            })
    }

    return (
        <div className='p-0'>
            <Modal open={modalStatus} onClose={handleModalClose} disableAutoFocus={true}>
                <div className='cm-plagiarism-container bg-white my-3'>
                    <div className='cm-plagiarism-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h4 className='text-blue-800 my-0'>Plagiarism Report</h4>
                        {!isLoading && <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalClose} />}
                    </div>
                    <div className="cm-plagiarism-data px-4 pb-4">
                        <div className="cm-candidate-report-list-container mt-4">
                            {isLoading ? <div className="text-center">
                                Please wait while we check plagiarism on the question
                                <div className="d-flex justify-content-center">
                                    <AnimationDataLoader />
                                </div>
                            </div>
                                : <>
                                    {plagData.searchResult.length === 0 && plagData.highestPlagPercentage <= 0 &&
                                        <div className='mb-4 text-center'>
                                            <p className='text-danger mt-3 mb-0 fw-semibold'>{plagData.highestPlagPercentage < 0 ? 'Unable to Check Plagiarism' : 'No Plagiarism found'} for the given Question text.</p>
                                        </div>
                                    }
                                    <div className="cm-candidate-report-list-header d-flex align-items-center bg-blue-gray-50">
                                        <div className='col-sm-2'>
                                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>S.No.</p>
                                        </div>
                                        <div className='col-sm-8'>
                                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Link</p>
                                        </div>
                                        <div className='col-sm-2'>
                                            <p className='my-0 cm-sm-txt fw-medium text-blue-gray-700'>Plag Percentage</p>
                                        </div>
                                    </div>
                                    {plagData.searchResult.length !== 0 && <>
                                        {plagData.searchResult?.map((searchData, index) => (
                                            <div className="cm-candidate-report-list-content bg-white" key={index}>
                                                <div className="cm-candidate-report-item d-flex align-items-center">
                                                    <div className='col-sm-2 d-flex'>
                                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>
                                                            {index + 1}
                                                        </p>
                                                    </div>
                                                    <div className='col-sm-8 text-break'>
                                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800'>
                                                            <a target='_blank' href={searchData.link}>{searchData.link}</a>
                                                        </p>
                                                    </div>
                                                    <div className='col-sm-2'>
                                                        <p className='my-0 cm-sm-txt fw-medium text-blue-800 cm-truncate-1'>{Math.floor(searchData.plagPercentage * 100) / 100}</p>
                                                    </div>
                                                </div>
                                            </div>

                                        ))}</>
                                    }
                                    {plagData.searchResult.length === 0 &&
                                        <div className='cm-home-dash-widget-content cm-empty-home-dash-widget d-flex align-items-center justify-content-center'>
                                            <div className='py-4 text-center px-4'>
                                                <EmptyIcon />
                                                <p className='text-blue-700 mt-3 mb-0 fw-semibold'>Nothing to see!</p>
                                            </div>
                                        </div>}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}

export default PlagiarismModal;