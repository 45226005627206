import MainSkillDashboard from "../../containers/skill-dashboard/MainSkillDashboard.container";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb.component";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
const SkillManagementTool = () => {
  const breadcrumbData = [
    {
      iconClass: regular("house"),
      link: "/",
    },
    {
      label: "Skill Dashboard",
      link: "/",
    },
  ];
  return (
    <>
      <Breadcrumb data={breadcrumbData} />
      <MainSkillDashboard />
    </>
  );
};

export default SkillManagementTool;
