import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProfileContainer.styles.css';
import { ReactComponent as ProfileBannerIcon } from '../../assets/images/profile/icon.svg';
import  ProfileBanner from '../../assets/images/profile/Banner.jpg';
import { useSelector } from 'react-redux';
import ChangePasswordForm from '../../components/auth/ChangePassword.component';

const ProfileContainer = ({ t }) => {
     
    const user = useSelector(state => state.user);

    return(
        <div className='cm-profile-wrapper d-md-flex'>
            <div className="cm-profile-sidebar pt-5 pb-3 col-md-3">
                <p className='fw-semibold cm-sm-txt'>{t("table_of_content")}</p>
                <p className='cm-sm-txt bg-blue-gray-50 cm-profile-sidebar-sh'>{t("overview")}</p>
                <ul>
                    <li className='d-flex align-items-center'>
                        <FontAwesomeIcon icon={regular('envelope')} className="text-gray-500" />
                        <span className='cm-sm-txt text-blue-800'>{t("personal_details")}</span>
                    </li>
                </ul>
            </div>
            <div className="cm-profile-content-wrap col-md-9 bg-blue-gray-50">
                <div className="cm-profile-content-wrap-header bg-white">
                    <div className="cm-profile-banner position-relative">
                        {/* <ProfileBanner /> */}
                        <img src={ProfileBanner} alt="Profile" />
                        <span className='cm-banner-user-icon d-flex align-items-center justify-content-center'>
                            <ProfileBannerIcon />
                        </span>
                    </div>
                    <div className="cm-profile-info">
                        <h4 className='fw-bold text-blue-700'>{user.firstName} {user.lastName}</h4>
                        <p className='d-flex align-items-center text-blue-gray-500 cm-sm-txt my-0'>
                            <FontAwesomeIcon icon={regular("envelope")} />
                            <span className='ps-2'>{user.userEmail}</span>
                        </p>
                    </div>
                </div>
                <div className="cm-personal-details-wrap">
                    <p className='cm-personal-details-head cm-sm-txt text-blue-gray-500 my-0'>{t("personal_details")}</p>
                    <ChangePasswordForm t={t} user={user} />
                </div>
            </div>
        </div>
    )

}

export default ProfileContainer;