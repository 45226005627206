import * as React from 'react';
import { Modal } from '@mui/material';
import "./QuestionAddModal.style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {useNavigate} from "react-router-dom"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




const QuestionAddModal = ({ modalStatus, handleModalToggle, navigateToQuestionCreationPage, allData, taskId }) => {

    const navigate = useNavigate();

    const navigateToQuestionCreation = (currDifficulty, subskillName,currskillQuestionType) => {
        navigate(`/create-question?reqId=${taskId}`, { state: {allData:allData , taskId:taskId, currDifficulty: currDifficulty, subskillName: subskillName,currQuestionType:currskillQuestionType}} )
    }

    return (
        <div className='p-0' >
            <Modal open={modalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
                <div className='cm-question-creation-guidline-container bg-white my-3'>
                    <div className='cm-question-creation-guidline-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h4 className='text-blue-800 my-0'>Select Question for Creation</h4>
                    </div>
                    


                    <TableContainer className='' component={Paper}>
                        <Table sx={{ minWidth: "50%" }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell align='center'>Subskill</TableCell>
                                <TableCell align='center'>Difficulty</TableCell>
                                <TableCell align='center'>Question Type</TableCell>
                                <TableCell align='center'>Question Count</TableCell>
                                <TableCell align='center'>Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {allData.subSkills.map((currSkill) => {
                                        return (<TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell align='center' className='my-0  text-blue-800' component="th" scope="row">
                                                {currSkill.subskillName}
                                            </TableCell>
                                            <TableCell align='center' className='my-0  text-blue-800' component="th" scope="row">
                                                {currSkill.difficulty}
                                            </TableCell>
                                            <TableCell align='center' className='my-0  text-blue-800'>{currSkill.questionType}</TableCell>
                                            <TableCell align='center' className='my-0  text-blue-800' >{currSkill.questionCount}</TableCell>
                                            <TableCell align='center' className='my-0 cm-sm-txt text-blue-800' ><FontAwesomeIcon onClick={() => navigateToQuestionCreation(currSkill.difficulty, currSkill.subskillName,currSkill.questionType)} className='text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer' icon={regular("arrow-right")} /> </TableCell>
                                        </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                                
                    <div className="cm-question-creation-guidline-footer">
                        <div className="cm-form-btn-group pt-2 d-flex align-items-center">
                            {/* {!!navigateToQuestionCreationPage && <button onClick={navigateToQuestionCreationPage} className="bg-action-blue fw-medium text-white cm-pointer border border-action-blue me-3">
                                <span className="text-white">Proceed</span>
                            </button>} */}
                            <button onClick={handleModalToggle} className={`bg-white fw-medium text-action-blue cm-pointer border border-action-blue`}>
                                <span>Dismiss</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}

export default QuestionAddModal;