import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from '@mui/material/Select';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { Collapse, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import OTPInput from '../../components/global/otp-input';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { apiErrorHandler } from '../../utils/errorHandler';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';

const initial_state = {
    email: {
        value: '',
        msg: null
    },
    password: {
        value: '',
        msg: null
    },
    passwordVisible: false,
    dataCenter: { value: "", msg: null },
    validOptions: {
        requiredFields: ['email', 'password'],
    },
};

const ToolsLoginFormContainer = ({ t, fetchData, loginExpand, toggleAccordian, downloadFrom, btnLabel, permissionName }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const [loginData, setLoginData] = React.useState({
        dataCenter: [],
        token: null,
        permission: null,
        otpModalData: {
            status: false,
            emailOtp: false,
            otp: '',
            isLoading: false,
        }
    });

    const [formVal, setFormVal] = React.useState(initial_state);

    const fetchDataCenter = async() => {
        try {
            const res = await instance.get(getApiUrl("mettlDataCenterList"));

            if(res.data.length > 0) {
                const filteredDataCenter = res.data.filter(el => el.visibility === "visible")
                setLoginData({ ...loginData, dataCenter: filteredDataCenter })
                setFormVal({ ...formVal, dataCenter: { value: filteredDataCenter[0], msg: null } })
            }

        } catch (error) {
            
        }
    }

    React.useEffect(() => {
        if(loginData.dataCenter.length === 0) { 
            fetchDataCenter()
        }
    }, [loginData])

    const handleFieldChange = (e, fieldName) => {

        let value = e.target.value;

        if(fieldName === "dataCenter") {
            value = loginData.dataCenter.find(el => el.host === e.target.value);
        }

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value
            }
        })
    }

    const handlePassVisiblity = (fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: !formVal[fieldName]
        })
    }

    const getReqMsg = (fieldName) => {
        if (fieldName === 'email') {
            return t('email_required');
        } else if (fieldName === 'password') {
            return t('password_required');
        } else {
            return t('field_required')
        }
    }

    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = formVal;

        formVal.validOptions.requiredFields.forEach(el => {
            if (currentFormVal[el].value === '') {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                //Email Validation
                if (el === 'email') {
                    // eslint-disable-next-line
                    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                    currentFormVal.email.msg = !regex.test(currentFormVal.email.value) ? t("valid_email_address") : null;
                    return;
                }

                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if (formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if (isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            !isLoading && encProcess()
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }
    }

    const handleFormSubmit = (e) => {
        e?.preventDefault();
        handleValidation();
    }

    const checkPermission = async(token) => {
        try {
            const res = await instance.post(getApiUrl("checkPermission"), { token, permissionName });

            if(res.data.status) {
                fetchData(permissionName === "downloadQuestionBank" ? token : {...res.data, token});
                setIsLoading(false);
            } else {
                throw Error(!!res.data.message ? res.data.message : 'Something went wrong. Please try again.')
            }
        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleLogin = async (dataToSend) => {
        try {
            setIsLoading(true);

            const res = await instance.post(getApiUrl('loginToMettl'), dataToSend);

            if (res.data.status && res.data.token) {
                let loginDataToSet = { ...loginData, token: res.data.token }
                

                if(res.data.status) {
                    if(res.data?.isOTPRequired) {
                        loginDataToSet = { ...loginDataToSet, permission: res.data, otpModalData: { ...loginData.otpModalData, emailOtp: !res.data.authenticatorAppOtpConfigured, status: true, }}
                    } else {
                        checkPermission(res.data.token)
                    }
                    
                    setLoginData(loginDataToSet)
                } else {
                    throw Error(!!res.data.message ? res.data.message : 'Something went wrong. Please try again.')
                }
            } else {
                throw Error(!!res.data.message ? res.data.message : 'Something went wrong. Please try again.')
            }

        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const encProcess = async () => {
        try {
            setIsLoading(true);
            const getKey = await instance.get(getApiUrl('getSk'));
            if (!!getKey.data) {
                setIsLoading(false);
                const JSEncrypt = window.JSEncrypt;

                //RSA Public Key
                const rsaKey = getKey.data.key;
                const passToEncrypt = formVal.password.value;
                const skid = getKey.data.id;

                //encrypt AES key with RSA public key
                var rsaEncrypt = new JSEncrypt({ default_key_size: 2048 });
                rsaEncrypt.setPublicKey(rsaKey);
                var rsaEncryptedAesKey = rsaEncrypt.encrypt(passToEncrypt.toString());

                var encryptedTransaction = { username: formVal.email.value, password: rsaEncryptedAesKey.toString(), skid, host: formVal.dataCenter?.value?.host };

                handleLogin(encryptedTransaction);
            }

        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const loadSelectOptions = React.useCallback(() => loginData.dataCenter.length > 0 && loginData.dataCenter.map(el => <MenuItem value={el.host} key={el.value}>{el.region} ({el.host})</MenuItem>), [loginData.dataCenter]);

    const handleDialogToggle= (isLoading=false) => {
        setLoginData({ ...loginData, otpModalData: { ...loginData.otpModalData, status: !loginData.otpModalData.status, isLoading }})
    }

    const resendOtp = async() => {
        try {
            const res = await instance.post(getApiUrl("resendOTP"), { token: loginData.permission.token });

            if(res.data.status) {
                dispatch(openSnackBar({
                    msg: "OTP has been sent successfully!",
                    type: 'success'
                }))
            } else {
                throw Error(!!res.data.message ? res.data.message : 'Something went wrong. Please try again.')
            }

        } catch (error) {
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const validateOtp = async() => {
        try {
            setLoginData({ ...loginData, otpModalData: { ...loginData.otpModalData, isLoading: true } });

            const res = await instance.post(getApiUrl("validateOTPForMettl"), { otp: loginData.otpModalData.otp, authenticatorAppOtpConfigured: !loginData.otpModalData.emailOtp, token: loginData.permission.token });

            if(res.data.status) {
                handleDialogToggle();
                checkPermission(loginData.permission.token)
            } else {
                throw Error(!!res.data.message ? res.data.message : 'Something went wrong. Please try again.')
            }

        } catch (error) {
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }
    
    const dialogActionFn = () => {
        validateOtp();
    }

    const toggleOtpViewer = () => {
        if(!loginData.otpModalData.emailOtp) resendOtp();

        setLoginData({
            ...loginData,
            otpModalData: {
                ...loginData.otpModalData,
                emailOtp: !loginData.otpModalData.emailOtp
            }
        })
    }

    return(
        <div className='bg-white border border-blue-gray-100 bg-white user-select-none'>
            <p onClick={downloadFrom ? toggleAccordian : null} className='mb-0 px-4 py-2 cm-sm-txt fw-semibold text-blue-gray-700 border-bottom border-blue-gray-100 cm-pointer d-flex align-items-center justify-content-between'>{t("enter_account_details")} <FontAwesomeIcon icon={loginExpand ? regular("angle-up") : regular("angle-down")} className="text-blue-gray-700 fw-medium" /></p>
            <Collapse in={downloadFrom ? loginExpand : false} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                <form onSubmit={handleFormSubmit} className='pt-3 px-4 pb-4'>
                    <div className="form-group mb-3">
                        <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("login_email_label")}</label>
                        <div className="cm-icon-field position-relative">
                            <input type="email" disabled={loginData.token} className="form-control text-blue-800 cm-sm-txt fw-medium" id="email" value={formVal.email.value} placeholder="abc@email.com" onChange={(e) => handleFieldChange(e, 'email')} />
                        </div>
                        {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("password")}</label>
                        <div className="cm-icon-field position-relative cm-inp-field">
                            <input disabled={loginData.token} type={formVal.passwordVisible ? 'text' : 'password'} className="form-control text-blue-800 cm-sm-txt fw-medium border-blue-gray-300" style={{ boxShadow: "none" }} id="password" value={formVal.password.value} placeholder="Enter password here" onChange={(e) => handleFieldChange(e, 'password')} />
                            <FontAwesomeIcon icon={formVal.passwordVisible ? regular('eye-slash') : regular('eye')} className={`text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} onClick={() => handlePassVisiblity('passwordVisible')} />
                        </div>
                        {formVal.password.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.password.msg}</span>}
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <CustomButtonLoader
                            showLoadingState ={isLoading}
                            colorTheme= "blue"
                            reverseIconDirection={false}
                            buttonLabel={btnLabel}
                            buttonStyle={"px-12 py-2 cm-mar-left-icon"}
                            handleOnClick={handleFormSubmit}
                            disabled={loginData.token}
                        />

                        <div className='d-flex align-items-center'>
                            <span className='cm-sm-txt fw-medium text-blue-gray-700 pe-2'>{t("data_center")}:</span>
                            <div className="position-relative">
                                <Select
                                    id="language-select"
                                    fullWidth={true}
                                    value={formVal.dataCenter.value?.host ? formVal.dataCenter.value?.host : ''}
                                    onChange={e => handleFieldChange(e, 'dataCenter')}
                                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                    MenuProps={{
                                        className: "cm-select-modal"
                                    }}
                                    disabled={loginData.token}
                                >
                                    {loadSelectOptions()}
                                </Select>
                                <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow cm-sm-txt text-blue-800 position-absolute" />
                            </div>
                        </div>
                    </div>
                </form>
            </Collapse>

            <CustomDialog 
                dialogHeading={t("enter_otp")} 
                cancelBtnLabel={t("cancel")}
                actionBtn={<CustomButtonLoader
                    showLoadingState ={loginData.otpModalData.isLoading}
                    colorTheme= "blue"
                    reverseIconDirection={false}
                    buttonLabel={t("send")}
                    buttonStyle={"cm-dialog-action-btn d-inline-block cm-mar-left-icon"}
                    handleOnClick={() => dialogActionFn()}
                    disabled={!(loginData.otpModalData.otp && loginData.otpModalData.otp?.length > 0)}
                />}
                cancelFn = {() => handleDialogToggle()} 
                dialogStatus={loginData.otpModalData.status}
            >
                <p className='my-0 cm-sm-txt text-blue-800'>{loginData.otpModalData.emailOtp ? 'OTP has been sent to your associated Email Id' : 'Enter the code generated on your Authenticator App'}</p>
                <OTPInput
                    autoFocus
                    isNumberInput
                    length={6}
                    className="otpContainer d-flex align-items-center justify-content-between py-3"
                    inputClassName="otpInput"
                    onChangeOTP={(otp) => setLoginData({ ...loginData, otpModalData: { ...loginData.otpModalData, otp } })}
                />
                {loginData.permission?.authenticatorAppOtpConfigured && !loginData.otpModalData.emailOtp ? <span className='my-0 cm-sm-txt text-action-blue fw-medium cm-pointer' onClick={toggleOtpViewer}>Send OTP to email</span>: <p className='my-0 cm-sm-txt text-blue-gray-700 fw-medium cm-pointer'>Didn't receive OTP? <span className='my-0 cm-sm-txt text-action-blue fw-medium cm-pointer' onClick={resendOtp}>Resend</span></p>}
            </CustomDialog>
        </div>
    )

}

export default ToolsLoginFormContainer;