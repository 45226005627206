import React from 'react';
import authBg from '../../assets/images/auth/BG.jpg';
import footerLogo from '../../assets/images/auth/mercer-mettl-footer_logo.svg';

import './Auth.styles.css';
import ResetPasswordForm from '../../components/auth/ResetPasswordForm.component';
import { useTranslation } from 'react-i18next';
import MetaComp from '../../components/meta-comp/MetaComp.component';

const ResetPasswordPage = () => {
    const { t } = useTranslation();

    return(
        <main className='cm-page-container cm-auth-page-container d-flex row mx-0'>
            <MetaComp title="Reset Password - Mercer | Mettl"/>
            <div className='col-sm-12 col-md-6 cm-md-12 cm-auth-content-wrap cm-resetpass-content-wrap d-flex align-items-center'>
                <div className='cm-auth-content-inner col-12'>
                    <div className="cm-txt-wrap">
                        <h2 className='h2 text-blue-gray-700  cm-fs-24 cm-mmc-font'>{t("need_help")} <span className='d-block h1 text-primary fw-bold cm-fs-40'>{t("reset_your_password")}</span></h2>
                        <h4 className='h4 text-blue-800'>{t("please_enter_emailId")}</h4>
                    </div>

                    <hr className='cm-auth-hr bg-blue-gray-300' />

                    <ResetPasswordForm t={t} />

                    <div className="cm-auth-footer align-items-center d-none d-lg-flex d-md-flex">
                        <p className='my-0 pe-3 fw-medium'>{t("powered_by")}: </p>
                        <img src={footerLogo} alt="Mercer Mettl" className='d-block' />
                    </div>
                </div>
            </div>
            <div className='col-sm-6 py-8 cm-auth-bg-container position-relative d-none d-lg-block d-md-block w-md-50' style={{ backgroundImage: `url('${authBg}')` }} />
        </main>
    )

}

export default ResetPasswordPage;