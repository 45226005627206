import React, { Component } from 'react'
// import Header from "../header/header"
import QuestionForms from './QuestionForms';
import quesCreation from './questionCreation.module.css';


export class QuestionCreation extends Component {
  render() {
    return (
      <>
        <div className={quesCreation.background}>
          <div className="sticky-top">
            {/* <Header /> */}
            <div role="presentation" className={quesCreation.borderBreadcrumb}>
              <nav className={quesCreation.breadcrumbDivider}
                aria-label="breadcrumb">
                <ol className="breadcrumb pt-2 ms-3">
                  <li className="breadcrumb-item"><i className="fa fa-lg fa-home" aria-hidden="true"></i></li>
                  <li className="breadcrumb-item link" aria-current="page">Dashboard</li>
                  <li className="breadcrumb-item link" aria-current="page">
                    Question Creation</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container card d-flex mb-5 mt-5">
            <div className="mt-5">
              <QuestionForms />
            </div>

          </div>
        </div>
      </>
    )
  }
}

export default QuestionCreation
