import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import CustomToolTip from "../custom-tooltip/CustomTooltip.component";
import ProgressControl from "../status-control/ProgressControl.component";
import { Link } from "react-router-dom";
import instance from "../../utils/axios";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { useDispatch, useSelector } from "react-redux";
import "./QuestionLibraryComponent.styles.css";
import { Menu } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { TextField } from "@mui/material";
import LanguageToolModal from "../../containers/question-library/LanguageToolModal.container";
import PlagiarismModal from "../../containers/question-library/PlagiarismModal.container";
import { useTranslation } from "react-i18next";
import { apiErrorHandler } from "../../utils/errorHandler";
import { getApiUrl } from "../../utils/apiUrls";
import { format } from "date-fns";
import { faCheckCircle, faCheckDouble, faSackDollar, faXmarkCircle } from "@fortawesome/pro-thin-svg-icons";
import { faInfoCircle, faMessageCheck, faMessageXmark, faSack, faSackXmark, faThumbsDown, faThumbsUp, faUserXmark } from "@fortawesome/pro-solid-svg-icons";
import { faFaceThinking } from "@fortawesome/pro-light-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { setRows,disselectALl } from "../../redux/actions/checkbox.actions";

const QuestionLibraryItem = ({
  
  data,
  taskId,
  handleModalToggle,
  handleReviewTimelineModalToggle,
  reloadDashboard,
  deleteQuestion,
  SentForReview,
  approveRejectQuestion,
  approveRejectQuestionReviewer,
  requestType,
  isChecked,
  handleCheckBoxChange,
  questionType,
}) => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const [reload, setReload] = React.useState(false);
  const user = useSelector((state) => state.user);
  // const flagState = useSelector((state) => state.flag);
  const selectquestions = useSelector(state => state.checkbox);
  const [anchorElNotif, setAnchorElNotif] = React.useState(null);
  const [formVal, setFormVal] = React.useState({ targetDate: "" });
  const [value, setValue] = React.useState(null);
  const [selected,setSelected] = React.useState([]);
  const [LToSend, setDataToSend] = React.useState({
    targetDate: "",
    assignedTo: 0,
    questionId: 0,
  });
  const handleOpenNotifMenu = (event) => {
    setAnchorElNotif(event.currentTarget);
  };
  const env = useSelector((state) => state.env);
  const handleCloseNotifMenu = () => {
    setValue("");
    setAnchorElNotif(null);
    reloadDashboard(false);
  };
  const [langModal, setLangModal] = React.useState({
    status: false,
    qId: data.questionId,
    reqId: taskId,
  });
  const [plagModal, setPlagModal] = React.useState({
    status: false,
    qId: data.questionId,
    qText: data.questionText,
  });
  const { t } = useTranslation();

  React.useEffect(() => {
    if (reload) reloadDashboard(false);
  }, [reload]);

  const handleReviewTimeline = (id, status) => {
    handleReviewTimelineModalToggle(id, status);
  };

  const handleModal = (id, status, adminStatus) => {
    handleModalToggle(id, status, adminStatus);
  };

  const handleDtChange = (newValue) => {
    setValue(newValue);
    setFormVal({
      ...formVal,
      ["targetDate"]: Number(moment(newValue._d).endOf("day").unix() + "999"),
    });
  };

  const getMapping = (qId) => {
    instance
      .get(`${getApiUrl("getQuestionReviewerMapping")}/${qId}`)
      .then(function (response) {
        if (response.data) {
          //setState({...state,data:response.data})
          //setDataToSend(...dataToSend,{targetDate:formVal.targetDate, questionId:qId, assignedTo:response.data[response.data.length-1].assignedTo});
          extendDeadline(
            response.data[response.data.length - 1].assignedTo,
            formVal.targetDate,
            qId
          );
        }
      })
      .catch(function (error) {
        const errObj = apiErrorHandler(error);
        dispatch(
          openSnackBar({
            msg: t(errObj.statusText),
            type: "error",
          })
        );
        console.log(error);
      });
  };

  const extendDeadline = (assignedTo, targetDate, questionId) => {
    const dataToSend = { assignedTo, targetDate, questionId };
    instance
      .post(`${getApiUrl("extendReviewerTargetDate")}`, dataToSend)
      .then(function (response) {
        if (response.data) {
          dispatch(
            openSnackBar({
              type: "success",
              msg: "Deadline has been extended.",
            })
          );
          handleCloseNotifMenu();
        }
      })
      .catch(function (error) {
        console.log(error);
        const errObj = apiErrorHandler(error);
        dispatch(
          openSnackBar({
            msg: t(errObj.statusText),
            type: "error",
          })
        );
      });
  };

  const handlePlagModalToggle = (qId, qText) => {
    setPlagModal({
      status: !plagModal.status,
      qId: !plagModal.status ? qId : null,
      qText: !plagModal.status ? qText : null,
    });
  };

  const handleLangModalToggle = (qId, status) => {
    if (status !== "InProgress" && status !== "completed") {
      setLangModal({
        status: !langModal.status,
        qId: !langModal.status ? qId : null,
        reqId: !langModal.status ? taskId : null,
      });
    }
  };

  return (
    <div
      key={data.questionId}
      className="cm-candidate-report-item d-flex align-items-center flex-wrap"
    >
     
     {
            // user.role !== "SME" &&
            //data.creatorStatus === "ReadyForAdmin" &&
             (
              <input div className='col-sm-1' style={{width :"3.5%"}}
                type="checkbox"
                key={data.questionId}
                value={data.questionId}
                checked={selectquestions.includes(data.questionId)}
                onChange={handleCheckBoxChange}
              />
            )}

      <div className="col-sm-1">

        <div className="d-flex">
          {/* {user.role !== "SME" &&
            requestType !== "ForReview" &&
            data.creatorStatus === "ReadyForAdmin" && (
              <input
                type="checkbox"
                value={data.questionId}
                checked={isChecked}
                onChange={handleCheckBoxChange}
              />
            )} */}
        
          <p
            id="questionLibrary"
            className={`my-2 cm-sm-txt fw-medium text-blue-800 ${
              user.role !== "SME" &&
              requestType !== "ForReview" &&
              data.creatorStatus !== "InProgress"
                ? "ps-3"
                : "ps-4 ms-1"
            }`}
          >
            {data.questionId}
          </p>
        </div>

      </div>

      <div className="col-sm-2">
        <p className="my-0 cm-sm-txt fw-medium text-blue-800">

          {data.creatorStatus !== null ? (
            <ProgressControl value={data.creatorStatus} />
          ) : (
            "-"
          )}
        </p>
      </div>

      <div className="col-sm-1">
        <p className="my-0 cm-sm-txt fw-medium text-blue-800">
          {data.questionTitle}
        </p>
      </div>

 {/* changes */}
      <div className="col-sm-2">
      {/* <p className = "my-0 cm-sm-txt fw-medium text-blue-800"> */}
        {data.adminStatus !== null && (
          <>
          <ProgressControl value={data.adminStatus} />
          {
            
            user.role==="SME" && requestType === "ForCreation" && data.adminStatus === "Rejected" && 
            
            <CustomToolTip title={`${data.adminFlagStatus}`}>
              <span
                className={`mt-5 ms-1 cm-pointer`}
              >
                           <FontAwesomeIcon 
                             icon={faFlag}
                             style={{fontSize:'14px',color:'#FF0000'}}
                          /> 
                               
              </span>
            </CustomToolTip>

          }
        </>
        )}
        {/* </p> */}

        {data.adminStatus === null && <ProgressControl value="notApplicable" />}
      </div>
 {/* changes */}
      <div className="col-sm-2">
      {/* <p className = "my-0 cm-sm-txt fw-medium text-blue-800"> */}
        {data.reviewerStatus !== null && (
          <div>
   
                <ProgressControl value={data.reviewerStatus} />   
               
               {                        
                 // requestType !== "ForReview"&& data.reviewerStatus !== "InProgress" &&
                user.role === "ADMIN" && data.reviewerStatus !== "InProgress" &&
    
                // <CustomToolTip 
                // title={ data.answerConflictFlagStatus ? 
                //         "Conflict of answer between creator and reviewer" 
                //       : 
                //          data.reviewerFlagStatus ?
                //             `${data.reviewerFlagStatus}`
                //           :
                //             `${data.adminReviewerStatus}` === "Approved" ?
                //                  'Review accepted by admin' 
                //               : 
                //                  data.adminReviewerStatus === "Rejected" ? 'Review Rejected by Admin' : 'Admin Review on Report Pending'
                //  }
                //  >
                //   <span
                //     className={`mt-5 ms-1 cm-pointer`}
                //   >
                //     {(data.reviewerStatus === "Rejected" || data.answerConflictFlagStatus) ? 
                //                <FontAwesomeIcon 
                //                  icon={faFlag}
                //                 //  className='flagIcon'
                //                  style={{fontSize:'14px',color:'#FF0000'}}
                //                // onClick={handleSelectShow}
                //               /> 
                //                  :
                //                  <FontAwesomeIcon 
                //                  icon={data.adminReviewerStatus === "Rejected" ? faThumbsDown : data.adminReviewerStatus === "Approved" ? faThumbsUp : faFaceThinking} 
                //                  className={`${data.adminReviewerStatus === "Rejected" ? 'text-danger' : data.adminReviewerStatus === "Approved" ? 'text-success' : 'text-primary'}`}
                //                  /> 
                //     }
                                   
                //   </span>
                // </CustomToolTip>
                
                 <>
                 {data.answerConflictFlagStatus && 
                 <CustomToolTip title={ "Conflict of answer between creator and reviewer" } >
                   <span className={`mt-5 ms-1 cm-pointer`} >
                              <FontAwesomeIcon 
                                  icon={faFlag}
                                  className='flag'
                                  // style={{fontSize:'14px',color:'#FF0000'}}
                                // onClick={handleSelectShow}
                               />            
                   </span>
                 </CustomToolTip>
                 }

                 {data.reviewerFlagStatus && 
                 <CustomToolTip title={ `${data.reviewerFlagStatus}` } >
                 <span className={`mt-5 ms-1 cm-pointer`} >
                            <FontAwesomeIcon 
                                icon={faFlag}
                                className='flag'
                                // style={{fontSize:'14px',color:'#FF0000'}}
                              // onClick={handleSelectShow}
                             />            
                 </span>
               </CustomToolTip>
                 }
                 
                  </>
                } 

                 {
                  user.role === "SME" && requestType === "ForReview" && data.reviewerStatus !== "InProgress" &&
                  <CustomToolTip title={`${data.adminReviewerStatus}` === "Approved" ?
                                   'Review Report accepted by admin' 
                                : 
                                   data.adminReviewerStatus === "Rejected" ? 'Review Report Rejected by Admin' : 'Admin Review on Report Pending' } >
                   <span className={`mt-5 ms-1 cm-pointer`} >
                                 <FontAwesomeIcon 
                                 icon={data.adminReviewerStatus === "Rejected" ? faThumbsDown : data.adminReviewerStatus === "Approved" ? faThumbsUp : faFaceThinking} 
                                 className={`${data.adminReviewerStatus === "Rejected" ? 'text-danger' : data.adminReviewerStatus === "Approved" ? 'text-success' : 'text-primary'}`}
                                 style={{margin:'0 5px'}}
                                 />           
                   </span>
                 </CustomToolTip>

                 }
     
                    
          </div>
        )}
     {/* </p> */}

        {data.reviewerStatus === null && (
          <ProgressControl value="notApplicable" />
        )}
      </div>

      <div className="col-sm-3 d-flex align-items-center">
        <ul className="cm-quest-lib-actions d-flex align-items-center justify-content-center p-0 m-0">
          <li>
            <CustomToolTip title={"View Comments"}>
              <span
                className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer `}
                onClick={() =>
                  handleReviewTimeline(data.questionId, data.creatorStatus)
                }
              >
                <FontAwesomeIcon icon={regular("comment")} />
              </span>
            </CustomToolTip>
          </li>

    {/* changes        */}
          {requestType === "ForCreation" && (
            <>
              {user.role === "SME" && (
                <>
                  <li>
                    <CustomToolTip title={"Edit"}>

                      {questionType!== null && questionType === "MCQ" ||  questionType === "MCA" || questionType === "Long Answer" ? 
                        <Link
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus === "SentForReview" ||
                          data.creatorStatus === "ReadyForAdmin"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        to={  `/create-question?reqId=${taskId}`}
                        state={{taskId:taskId, isEdit: true, data:data, questionType: questionType}}
                        
                      >
                        <FontAwesomeIcon icon={regular("pen-to-square")} />
                      </Link>
                      :
                      <Link
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus === "SentForReview" ||
                          data.creatorStatus === "ReadyForAdmin"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        to={`/question-details?reqId=${taskId}&qId=${data.questionId}`}
                      >
                        <FontAwesomeIcon icon={regular("pen-to-square")} />
                      </Link>
                      }
                      
                      {/* <Link
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus === "SentForReview" ||
                          data.creatorStatus === "ReadyForAdmin"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        to={`/question-details?reqId=${taskId}&qId=${data.questionId}`}
                      >
                        <FontAwesomeIcon icon={regular("pen-to-square")} />
                      </Link> */}
                    </CustomToolTip>

                    {/* <Link to ={{
                        pathname: "/CreateEventUser", 
                        state: { 
                            bucket_id: !this.state.selected_bucket.id ? this.state.recent_bucket : this.state.selected_bucket, 
                            new_id: this.state.newID, 
                            anotherValue: this.state.anotherValue 
                        }
                      }}  */}

                  </li>
                  <li>
                    <CustomToolTip title={"Delete"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus === "SentForReview" ||
                          data.creatorStatus === "ReadyForAdmin"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        onClick={() =>
                          deleteQuestion(data.questionId, data.creatorStatus)
                        }
                      >
                        <FontAwesomeIcon icon={regular("trash")} />
                      </span>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Send for review"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus !== "completed"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        } `}
                        onClick={() =>
                          SentForReview(data.questionId, data.creatorStatus)
                        }
                      >
                        <FontAwesomeIcon icon={regular("share")} />
                      </span>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Preview Question"}>
                      <Link
                        to={(questionType==="Codelysis") ? `/preview-question?reqId=${taskId}&qId=${data.questionId}` : `/preview-knowledgeBased-question?qId=${data.questionId}` }
                        className="text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer "
                      >
                        <FontAwesomeIcon icon={regular("eye")} />
                      </Link>
                    </CustomToolTip>
                  </li>
                </>
              )}
              
              {user.role === "ADMIN" && (
                <>
                  <li>
                    <CustomToolTip title={"Allocate"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus === "SentForReview" &&
                          data.adminStatus === "Accepted"
                            ? ""
                            : "cm-sidebar-btn-disabled"
                        }`}
                        onClick={() =>
                          handleModal(
                            data.questionId,
                            data.creatorStatus,
                            data.adminStatus
                          )
                        }
                      >
                        <FontAwesomeIcon icon={regular("link")} />
                      </span>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Preview Question"}>
                      <Link
                        to={questionType==="Codelysis" ? `/review-question?reqId=${taskId}&qId=${data.questionId}`  :  `/preview-knowledgeBased-question?qId=${data.questionId}`}
                        className="text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer"
                      >
                        <FontAwesomeIcon icon={regular("eye")} />
                      </Link>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip
                      title={
                        data.creatorStatus === "SentForReview"
                          ? data.adminStatus === null
                            ? "Accept/Reject Question"
                            : "Approve/Reject Question"
                          : "Accept/Approve/Reject Question"
                      }
                    >
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.creatorStatus === "SentForReview"
                            ? (data.adminStatus === null || data.reviewerStatus !== "InProgress")
                                  ? ""
                                  : "cm-sidebar-btn-disabled"
                            : "cm-sidebar-btn-disabled"
                        } `}
                        onClick={() =>
                          approveRejectQuestion(
                            data.questionId,
                            data.creatorStatus,
                            data.adminStatus
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={regular("comments-question-check")}
                        />
                      </span>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Check Plagiarism"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer `}
                        onClick={() =>
                          handlePlagModalToggle(
                            data.questionId,
                            data.questionText
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={regular("file-magnifying-glass")}
                        />
                      </span>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Check grammer"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer  ${
                          data.creatorStatus !== "SentForReview"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        onClick={() =>
                          handleLangModalToggle(
                            data.questionId,
                            data.creatorStatus
                          )
                        }
                      >
                        <FontAwesomeIcon icon={regular("language")} />
                      </span>
                    </CustomToolTip>
                  </li>
                </>
              )}

            </>
          )}

          {requestType === "ForReview" && (
            <>
              {user.role === "SME" && (
                <>
                  <li>
                  {questionType === "Codelysis" &&
                      (<CustomToolTip title={"Review and Run Solution"}>
                        {!data.targetDateExceeded &&
                          data.reviewerStatus === "InProgress" ? (
                          <Link
                            to={`/review-question?reqId=${taskId}&qId=${data.questionId}`}
                            className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer`}
                          >
                            <FontAwesomeIcon icon={regular("eye")} />
                          </Link>
                        ) : (
                          <span
                            className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 cm-pointer cm-sidebar-btn-disabled`}
                          >
                            <FontAwesomeIcon icon={regular("eye")} />
                          </span>
                        )}
                      </CustomToolTip>
                      )}
                    {questionType != "Codelysis" &&
                      (<CustomToolTip title={"Review"}>
                        {!data.targetDateExceeded &&
                          data.reviewerStatus === "InProgress" ? (
                          <Link
                            to={`/review-knowledgeBased-question/${taskId}?qId=${data.questionId}`}
                            state={{singleReview : true,questionId:data.questionId}}
                            className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer`}
                          >
                            <FontAwesomeIcon icon={regular("eye")} />
                          </Link>
                        ) : (
                          <span
                            className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 cm-pointer cm-sidebar-btn-disabled`}
                          >
                            <FontAwesomeIcon icon={regular("eye")} />
                          </span>
                        )}
                      </CustomToolTip>
                      )}

                    {/* <CustomToolTip title={"Review and Run Solution"}>
                      {!data.targetDateExceeded &&
                      data.reviewerStatus === "InProgress" ? (
                        <Link
                          // to={`/review-question?reqId=${taskId}&qId=${data.questionId}`}
                          to={questionType==="MCQ" ? `/question-library-mcq/${taskId}` : `/review-question?reqId=${taskId}&qId=${data.questionId}`}
                          className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer`}
                        >
                          <FontAwesomeIcon icon={regular("eye")} />
                        </Link>
                      ) : (
                        <span
                          className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 cm-pointer cm-sidebar-btn-disabled`}
                        >
                          <FontAwesomeIcon icon={regular("eye")} />
                        </span>
                      )}
                    </CustomToolTip> */}
                  </li>
                  <li>
                    <CustomToolTip title={"Approve/Reject Question"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer ${
                          data.reviewerStatus !== "InProgress"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        } ${
                          data.targetDateExceeded
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        onClick={
                          (questionType==="Codelysis") ? () =>
                          approveRejectQuestionReviewer(
                            data.questionId,
                            data.targetDateExceeded,
                            data.ansLangId === null ? false : true
                          ) :
                          () => {
                             approveRejectQuestionReviewer(
                              data.questionId,
                              data.targetDateExceeded,
                              true
                            ) 

                            
                          }
                        }
                      >
                        <FontAwesomeIcon
                          icon={regular("comments-question-check")}
                        />
                      </span>
                    </CustomToolTip>
                  </li>
                </>
              )}

              {user.role === "ADMIN" && (
                <>
                  <li>
                    <CustomToolTip title={(questionType==="Codelysis") ? "Review and Run Solution" : "Review Knowledge Based Question"}>
                      <Link
                        to={questionType==="Codelysis" ? `/review-question?reqId=${taskId}&qId=${data.questionId}` : `/preview-knowledgeBased-question?qId=${data.questionId}` }
                        className={`text-action-blue d-inline cm-sm-txt fw-medium px-3 py-1 cm-pointer`}
                      >
                        <FontAwesomeIcon icon={regular("eye")} />
                      </Link>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Check Plagiarism"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer  ${
                          data.creatorStatus !== "SentForReview"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        onClick={() =>
                          handlePlagModalToggle(
                            data.questionId,
                            data.questionText
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={regular("file-magnifying-glass")}
                        />
                      </span>
                    </CustomToolTip>
                  </li>
                  <li>
                    <CustomToolTip title={"Check grammer"}>
                      <span
                        className={`text-action-blue cm-sm-txt fw-medium px-3 py-1 cm-pointer  ${
                          data.creatorStatus !== "SentForReview"
                            ? "cm-sidebar-btn-disabled"
                            : ""
                        }`}
                        onClick={() =>
                          handleLangModalToggle(
                            data.questionId,
                            data.creatorStatus
                          )
                        }
                      >
                        <FontAwesomeIcon icon={regular("language")} />
                      </span>
                    </CustomToolTip>
                  </li>
                </>
              )}
            </>
          )}

        </ul>
      </div>

      {requestType === "ForReview" && data.targetDateExceeded && (
        <div className="cm-event-lib-task-err py-1 px-3 d-flex align-items-center col-sm-3 ms-3">
          <FontAwesomeIcon
            icon={regular("exclamation-triangle")}
            className="text-danger pe-2"
          />
          <span className="cm-sm-txt fw-medium text-danger fw-medium">
            Inactive Request - Deadline Exceeded
          </span>
          {user.role === "ADMIN" && (
            <>
              <span
                className="d-flex ms-2 align-items-center justify-content-center cm-pointer me-4 txt-danger"
                onClick={handleOpenNotifMenu}
              >
                <FontAwesomeIcon icon={regular("calendar")} />
              </span>
              <Menu
                sx={{ mt: "32px" }}
                id="notif-menu"
                anchorEl={anchorElNotif}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElNotif)}
                onClose={handleCloseNotifMenu}
                MenuListProps={{ disablePadding: true }}
                elevation={0}
                PaperProps={{
                  style: {
                    width: 400,
                  },
                }}
              >
                {
                  <div className="form-group mb-3">
                    <label className="pb-2 text-blue-gray-700 cm-xs-txt fw-medium">
                      Edit Target Date
                    </label>
                    <div className=" text-blue-800 fw-medium">
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          value={value}
                          onChange={(newValue) => {
                            handleDtChange(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                          minDate={format(currentDate, "yyyy-MM-dd")}
                        />
                      </LocalizationProvider>
                    </div>
                    <button
                      className={`btn bg-action-blue text-white cm-sm-txt fw-medium mt-2`}
                      onClick={() => getMapping(data.questionId)}
                    >
                      Extend
                    </button>
                  </div>
                }
              </Menu>
            </>
          )}
        </div>
      )}


      {langModal.status && (
        <LanguageToolModal
          t={t}
          modalStatus={langModal.status}
          questId={langModal.qId}
          reqId={langModal.reqId}
          handleModalToggle={handleLangModalToggle}
        />
      )}

      {plagModal.status && (
        <PlagiarismModal
          modalStatus={plagModal.status}
          questId={plagModal.qId}
          quesText={plagModal.qText}
          handleModalToggle={handlePlagModalToggle}
        />
      )}

    </div>
  );
};

export default QuestionLibraryItem;
