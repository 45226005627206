import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ReactResizeDetector from 'react-resize-detector';

import AceEditor from 'react-ace';
import * as ace from 'brace';
import 'brace/theme/monokai';
import 'brace/mode/javascript';
import 'brace/mode/java';
import 'brace/mode/c_cpp';
import 'brace/mode/python';
import 'brace/ext/language_tools';
import "ace-builds/src-noconflict/ext-language_tools";
import './QuestionCreation.styles.css';
import { useTranslation } from 'react-i18next';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { useSelector } from 'react-redux';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import AnimationDataLoader from '../../components/Loaders/animationData';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';

const editorModes = {
    c: 'c_cpp',
    cpp: 'c_cpp',
    java: 'java',
    javascript: 'javascript',
    python: 'python'
}

const java_template = "/* package whatever; // don't place package name! */import java.util.*; import java.lang.*; import java.io.*; /* Name of the class has to be 'main' only if the class is public. */class Main{ public static void main (String[] args) throws java.lang.Exception { // your code goes here } }"



const AddSolutionPreviewForm = ({ questId, getSolutionValue, questStepData, checkCodeCompile, enableCases, compileTestCases, isLoading, compilationError, handleEnableCases }) => {
    const initial_state = {
        ...questStepData.addSolution
    }
    let ansLang;

    if (!!questStepData.addSolution.selLang.value) {
        ansLang = questStepData.addSolution.selLang.value;
    }

    const ansDescState = useSelector(state => state.question.addSolution.ansDescription.value);

    const { t } = useTranslation();
    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        lang: null,
    });
    const [formVal, setFormVal] = React.useState(initial_state);
    const [codeCompile, setCodeCompile] = React.useState(false);
    // const [codeEditor,setCodeEditor]=React.useEffect(false);
    const [editorDimension, setEditorDimension] = React.useState({
        editorWidth: 'auto',
        editorHeight: 500
    })
    // React.useEffect(() => {
    //     setTimeout(() => { setCodeEditor(true) }, 2000)
    // }, [])


    React.useEffect(() => {
        setFormVal(questStepData.addSolution)
        handleEnableCases("disable");
    }, [questStepData])

    // React.useEffect(() => {
    //     checkCodeCompile()
    // }, [codeCompile])

    //console.log("reacchheeedddd", compileTestCases);

    const handleFieldChange = (e, fieldName) => {
        getSolutionValue({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // //console.log('handleFormSubmit', formVal);
        //handleValidation();
    }

    const handleSelectChange = async (event, fieldName, arr) => {
        let val = {};

        val = arr[arr.findIndex(el => el.value === event.target.value)]
        ////console.log('event', event.target.value);

        //console.log(ansLang.value);
        //console.log("event",event.target.value);
        if (ansLang.value === event.target.value) {

            getSolutionValue({
                ...formVal,
                [fieldName]: {
                    ...formVal[fieldName],
                    value: val
                },
                ansDescription: {
                    ...formVal.ansDescription,
                    value: ansDescState
                }
            })

            setFormVal({
                ...formVal,
                [fieldName]: {
                    ...formVal[fieldName],
                    value: val
                },
                ["ansDescription"]: {
                    ...formVal['ansDescription'],
                    value: ansDescState
                }
            })
        }
        else {
            const res = await instance.get(`${getApiUrl('getCodingTemplate')}?questionId=${questStepData.questId}&codeLanguage=${event.target.value.toLowerCase()}`);
            setFormVal({
                ...formVal,
                [fieldName]: {
                    ...formVal[fieldName],
                    value: val
                },
                ["ansDescription"]: {
                    ...formVal['ansDescription'],
                    value: res.data
                }
            })
        }





        //     if(!!questStepData.addSolution.ansDescription.value)
        //    {
        //     //     setFormVal({...formVal,[fieldName]:{ ...formVal[fieldName],
        //     //         value: val}, ["ansDescription"]:{...formVal['ansDescription'],
        //     //         value: java_template}})
        //     // }

        //     // else if(event.target.value!==ansLang)
        //     // {
        //     //     setFormVal({...formVal,[fieldName]:{ ...formVal[fieldName],
        //     //         value: val}, ["ansDescription"]:{...formVal['ansDescription'],
        //     //         value: "java_template"}})

        //     // }
        //     console.log("val",val)

        //     getSolutionValue({
        //                     ...formVal,
        //                     [fieldName]: {
        //                         ...formVal[fieldName],
        //                         value: val
        //                     }
        //                 })

        //     setFormVal({
        //         ...formVal,
        //         [fieldName]: {
        //             ...formVal[fieldName],
        //             value: val
        //         }, ["ansDescription"]:{...formVal['ansDescription'],
        //                value: !!questStepData.addSolution.ansDescription.value}
        //     })
        // }
        // else
        // {
        // const res = await instance.get(`${getApiUrl('getCodingTemplate')}?questionId=${questStepData.questId}&codeLanguage=${event.target.value.toLowerCase()}`);
        // setFormVal({...formVal,[fieldName]:{ ...formVal[fieldName],
        //     value: val}, ["ansDescription"]:{...formVal['ansDescription'],
        //     value: res.data}})

        //    if(ansLang.value===event.target.value)
        // {
        // console.log("herrreee",questStepData.addSolution.ansDescription.value);
        //     getSolutionValue({
        //         ...formVal,
        //         [fieldName]: {
        //             ...formVal[fieldName],
        //             value: val
        //         }
        //     })

        //     setFormVal({
        //         ...formVal,
        //         ['ansDescription']: {
        //             ...formVal['ansDescription'],
        //             value:questStepData.addSolution.ansDescription.value
        //         }
        //     })
        // }
        //}
    }

    const fetchCodeTemplate = async () => {
        const res = await instance.get(`${getApiUrl('getCodingTemplate')}?questionId=${questStepData.questId}&codeLanguage=${formVal.selLang.value?.value.toLowerCase()}`);
        setFormVal({
            ...formVal,
            ["ansDescription"]: {
                ...formVal['ansDescription'],
                value: res.data
            }
        })
    }

    //console.log("fkmsjnxs",formVal);

    const handleEditorChange = (val) => {
        getSolutionValue({
            ...formVal,
            ['ansDescription']: {
                ...formVal['ansDescription'],
                value: val
            }
        })

        setFormVal({
            ...formVal,
            ['ansDescription']: {
                ...formVal['ansDescription'],
                value: val
            }
        })

    }

    const checkSolution = () => {
        checkCodeCompile("enable");
    }

    const handleDialogToggle = (lang) => {
        setDialogStatus({
            status: !dialogStatus.status,
            language: lang
        });
    }

    const dialogActionFn = () => {
        fetchCodeTemplate()
        setDialogStatus({
            status: false,
            lang: null,
        })
    }

    const handleDialogForTemplate = (lang) => {
        handleDialogToggle(lang);
    }


    return (
        <div className='cm-event-library-content-wrapper bg-blue-gray-50'>
            <form className="cm-event-details-form cm-login-form mx-5" onSubmit={handleFormSubmit}>
                <div className="row pt-4">
                    <div className="form-group mb-3">
                        <label htmlFor="avLanguages" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Choose language for adding solution</label>
                        <div className="d-flex">
                            <div className="col-10 d-flex">
                                <Select
                                    labelId="avLanguagesLabel"
                                    id="avLanguages"
                                    value={formVal.selLang.value?.value}
                                    onChange={(e) => handleSelectChange(e, 'selLang', formVal?.avLanguages.value)}
                                    fullWidth={true}
                                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field">
                                    {
                                        formVal?.avLanguages.value.map(el => <MenuItem value={el.value} key={el.value} className="fw-medium cm-sm-txt text-blue-800">{el.label}</MenuItem>)
                                    }
                                </Select>
                                <div className={`cm-sidebar-save-btn bg-action-white d-flex align-items-center justify-content-center cm-pointer`} style={{ 'position': 'relative', 'left': '-30px', 'width': '0' }}>
                                    {formVal.selLang.value.version?.split(" ")[1]}
                                </div>
                                <CustomToolTip title="Reset Editor">
                                    <span className={`mx-2 cm-sidebar-save-btn bg-action-gray d-flex align-items-center justify-content-center cm-pointer`} onClick={() => { handleDialogForTemplate(formVal.selLang.value?.value) }}>
                                        <FontAwesomeIcon icon={regular("refresh")} />
                                    </span>
                                </CustomToolTip>
                                {/* <div className={`ms-2 cm-sidebar-save-btn bg-action-white text-blue-gray-700 d-flex align-items-center justify-content-center cm-pointer`} >
                                <span className='fw-medium cm-sm-txt p-2'>Reset</span>
                            </div> */}
                            </div>

                            <CustomButtonLoader
                                showLoadingState ={isLoading}
                                colorTheme= "blue"
                                icon={regular('angle-right')}
                                reverseIconDirection={false}
                                buttonLabel={t("compile_and_run")}
                                buttonStyle={"col-2 cm-sidebar-save-btn px-12 py-2 cm-mar-left-icon"}
                                handleOnClick={checkSolution}
                            />

                        </div>
                        {formVal.selLang.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.selLang.msg}</span>}
                    </div>
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="avLanguages" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Choose language for adding solution</label>
                    <div className="resizable">
                        <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => setEditorDimension({ editorWidth: w, editorHeight: h })} />
                        <AceEditor
                            mode={!!editorModes[formVal.selLang.value.value?.toLowerCase()] ? editorModes[formVal.selLang.value.value?.toLowerCase()] : null}
                            setOptions={{
                                enableBasicAutocompletion: true,
                                enableLiveAutocompletion: true,
                                showPrintMargin: false
                                // enableSnippets: true
                            }}
                            onChange={handleEditorChange}
                            value={formVal.ansDescription.value}
                            width={editorDimension.editorWidth}
                            height={editorDimension.editorHeight}
                        />
                    </div>
                    {formVal.ansDescription.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.ansDescription.msg}</span>}
                </div>
                {compileTestCases.length != 0 && !isLoading && <><div className='my-0 text-blue-800 cm-mmc-font pb-3' style={{ 'font-size': '18px' }} >Testcase Details</div></>}
                {compileTestCases !== [] && !isLoading && compilationError === null &&
                    compileTestCases.map((x, i) => {
                        return (
                            <div className="mb-4 text-break" key={i}>
                                <Accordion defaultExpanded={i == 0 ? true : false}>
                                    <AccordionSummary
                                        expandIcon={<FontAwesomeIcon icon={regular('chevron-down')} className="text-blue-800" />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className='bg-gray-100'>
                                        <div className="d-flex">
                                            {!x.status && <FontAwesomeIcon style={{ 'color': 'red' }} icon={regular('circle-xmark')} className="pt-1" />}
                                            {x.status && <FontAwesomeIcon style={{ 'color': 'green' }} icon={regular('circle-check')} className="pt-1" />}
                                            <div className='my-0 ps-2 text-blue-800 cm-mmc-font'>{x.name}</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <AccordionDetails>
                                            <hr role="presentation" className="mx-4 w-25 text-blue-gray-500 lineWidth"></hr>
                                            <p className="mb-0 text-blue-800 font-weight-500">
                                                <FontAwesomeIcon icon={regular("code")} />  TEST CASE INFORMATION
                                            </p>
                                            <p className="px-4 mb-0 mt-2 text-blue-gray-700">Input</p>
                                            <div className="px-4 mb-0 text-blue-800 pt-0 question-description-text">
                                                <p>{x.inputValues.join("\r\n")}</p>
                                            </div>

                                            <p className="px-4 mb-0 mt-2 text-blue-gray-700">Expected Output</p>
                                            <div className="px-4 mb-0 text-blue-800 pt-0 question-description-text">
                                                <p>{x.outputValues.join("\r\n")}</p>
                                            </div>

                                            <p className="px-4 mb-0 mt-2 text-blue-gray-700">Actual Output</p>
                                            <div className="px-4 mb-0 text-blue-800 pt-0 question-description-text">
                                                <p>{x.outputResult}</p>
                                            </div>
                                            {/* <label className="cm-sm-txt text-blue-800 fw-medium text-break">TEST CASE INFORMATION</label> */}
                                            {/* {!!x.inputValues && <div className="cm-sm-text text-blue-800">Input:{x.inputValues.join(', ')}</div>} */}
                                            {/* {!!x.outputValues && <div className="cm-sm-text text-blue-800">Expected Output:{x.outputValues.join(', ')}</div>} */}
                                            {/* <div className="cm-sm-text text-blue-800">Actual Output:{x.outputResult}</div> */}
                                        </AccordionDetails>
                                        {/* <label className="cm-sm-txt text-blue-800 fw-medium">TEST CASE INFORMATION</label>
                                        {!!x.inputValues && <div className="cm-sm-text text-blue-800 question-description-text">Input:<br></br> {x.inputValues.join("\r\n ")}</div>}
                                        {!!x.outputValues && <div className="cm-sm-text text-blue-800 question-description-text">Expected Output:<br></br> {x.outputValues.join("\r\n ")}</div>}
                                        <div className="cm-sm-text text-blue-800 question-description-text">Actual Output:<br></br> {x.outputResult}</div> */}
                                    </AccordionDetails>
                                </Accordion>
                            </div>)
                    }

                    )}
                {!isLoading && compilationError !== null &&
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={regular('plus')} className="text-blue-800" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='bg-gray-100'>
                            <div className="d-flex">
                                <FontAwesomeIcon style={{ 'color': 'red' }} icon={regular('circle-xmark')} className="pt-1" />
                                <div className='my-0 ps-2 text-blue-800 cm-mmc-font'>Code Compilation Failed</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='bg-white'>
                            <div className="cm-sm-text text-blue-800 pre-wrap-compilation-error">{compilationError}</div>
                        </AccordionDetails>
                    </Accordion>
                }
                <CustomDialog
                    dialogHeading="Reset Template"
                    cancelBtnLabel="No, Cancel"
                    actionBtnLabel={<span>Reset Template</span>}
                    actionBtnBg="bg-danger"
                    cancelFn={handleDialogToggle}
                    actionFn={dialogActionFn}
                    dialogStatus={dialogStatus.status}
                >
                    <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to reset template?</p>
                </CustomDialog>
                {isLoading &&
                    <div className="d-flex justify-content-center">
                        <AnimationDataLoader />
                    </div>
                }
            </form>

        </div>
    )
}
export default AddSolutionPreviewForm;