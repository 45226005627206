import React from 'react';
import MetaComp from '../../components/meta-comp/MetaComp.component';
import DownloadQuestionBankContainer from '../../containers/tools/DownloadQuestionBank.container';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import { useTranslation } from 'react-i18next';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';

const DowloadQuestionBankPage = () => {
    const { t } = useTranslation();

    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: t("download_question_bank"),
        }
    ]

    return <>
        <MetaComp title="Download Question Bank - Mercer | Mettl"/>
        <Breadcrumb data={breadcrumbData}/>
        <DownloadQuestionBankContainer t={t} />
    </>
}

export default DowloadQuestionBankPage;