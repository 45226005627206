import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import { Outlet, useParams } from 'react-router-dom';
import QuestionLibraryContainer from '../../containers/question-library/QuestionLibrary.container';
import './QuestionLibrary.styles.css';

const QuestionLibrary = () => {
  const { qId } = useParams();

  const breadcrumbData = React.useMemo(() => ([
    {
        iconClass: regular("house"),
        link: '/'
    },
    {
        label: "Question Library",
        link: '/'
    }
]), []);

  return (
    <div className='cm-cms-container'>
      <Breadcrumb data={breadcrumbData} backBtn={true} />
      <QuestionLibraryContainer qId={qId} />
      <Outlet />
    </div>
  )
}

export default QuestionLibrary;