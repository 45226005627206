import React from 'react'


const OldCmsConfigurationModal = ({ modalData }) => {

    return (
        <>
            <div className="mt-3 cm-create-task-form px-4 pb-4">
                <div className='d-flex gap-2'>
                    <label className='cm-sm-txt text-blue-gray-700 mb-2 fw-medium'>Manager email :</label>
                    <span className='cm-sm-txt mb-2 fw-medium'>{modalData.managerEmail}</span>
                </div>
                <div className='d-flex gap-2'>
                    <label className='cm-sm-txt text-blue-gray-700 mb-2 fw-medium'>Assigned users email :</label>
                    <div className='d-flex flex-column'>
                    {modalData.assignedUsersEmail.map((element) => (
                        <li className='cm-sm-txt mb-2 fw-medium'>{element}</li>
                    ))}
                    </div>
                </div>
            </div>
        </>
    )

}

export default OldCmsConfigurationModal;