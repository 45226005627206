export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SAVE_SME_INFO = "SAVE_SME_INFO";

//Snackbar
export const SNACK_OPEN = "SNACK_OPEN";
export const SNACK_CLOSE = "SNACK_CLOSE";

//Add Question
export const GET_QUEST_DETAILS = "GET_QUEST_DETAILS";
export const UPDATE_QUEST_DETAILS = "UPDATE_QUEST_DETAILS";
export const UPDATE_QUEST_DETAILS_BY_KEY = "UPDATE_QUEST_DETAILS_BY_KEY";
export const UPDATE_TEST_CASE_DETAILS = "UPDATE_TEST_CASE_DETAILS";
export const ADD_TEST_CASE = "ADD_TEST_CASE";
export const REMOVE_TEST_CASE = "REMOVE_TEST_CASE";
export const RESET_QUEST = "RESET_QUEST";
export const APP_ERROR = "APP_ERROR";

//Env
export const FETCH_ENV = "FETCH_ENV";

//Skill-taxonomy
export const SET_SKILL_DASHBOARD_DATA = "SET_SKILL_DASHBOARD_DATA";

// selectAll checkbox
export const SETROWS="SETROWS"
export const DISSELECTALL="DISSELECTALL"
export const DISETROWS="DISETROWS"

// otp
export const OTP_VERIFIED = "OTP_VERIFIED"
