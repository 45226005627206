import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const SkillTaxanomyInfoGeneral = ({
  aggregateDataInfo,
  handleNetworkFilter,
  filterEntityRecordArray,
}) => {
  const removeNodeTypeKeyhandler = (key) => {
    handleNetworkFilter(key);
  };

  const skillTaxData = React.useMemo(
    () => [
      {
        name: "Primary Skill: ",
        colorClass: "cm-skill-info_p",
        count: aggregateDataInfo.primarySkillCount,
        value: "Skill",
      },
      {
        name: "Similar skill: ",
        colorClass: "cm-skill-info_w",
        count: aggregateDataInfo.similarSkill,
        value: "A-Skill",
      },
      {
        name: "Jobs:",
        colorClass: "cm-skill-info_b",
        count: aggregateDataInfo.totalJobs,
        value: "Job",
      },
      {
        name: "Industry: ",
        colorClass: "cm-skill-info_s",
        count: aggregateDataInfo.industry,
        value: "Industry",
      },
      {
        name: "Skill Type: ",
        colorClass: "cm-skill-info_e",
        count: aggregateDataInfo.skillType,
        value: "Skill Type",
      },
    ],
    [aggregateDataInfo]
  );

  return (
    <div className="container m-0 px-4 ms-3">
      <div className="d-flex cm-xs-txt">
        {skillTaxData.map((el) => (
          <div className="me-3 border cm-btn-radius bg-blue-gray-50 ps-2 pe-1 py-1">
            <FontAwesomeIcon
              className={`${el.colorClass} me-1`}
              icon={solid("circle")}
            />
            {el.name}
            {el.count}
            <FontAwesomeIcon
              className="cm-skill-info text-blue-gray-700 ms-1 me-n2 cm-pointer"
              icon={
                filterEntityRecordArray.includes(el.value)
                  ? solid("plus")
                  : solid("times")
              }
              onClick={() => removeNodeTypeKeyhandler(el.value)}
            />
          </div>
        ))}
        {/* <div className="border cm-btn-radius bg-blue-gray-50 px-2 py-1">
          <FontAwesomeIcon
            className="skill-info_o me-1"
            icon={solid("circle")}
          />
          Others:
          {props.aggregateDataInfo.others ? (
            <>{" " + props.aggregateDataInfo.others}</>
          ) : (
            " --"
          )}
          <FontAwesomeIcon
            icon={solid("xmark")}
            className="text-blue-gray-700 ms-1 cm-pointer"
          />
        </div> */}
      </div>
    </div>
  );
};
export default SkillTaxanomyInfoGeneral;
