
import React from 'react';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import ProfileContainer from '../../containers/profile/Profile.container';

import './Profile.styles.css';
import MetaComp from '../../components/meta-comp/MetaComp.component';

const ProfilePage = () => {
    const { t } = useTranslation();

    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: t("profile"),
        }
    ]

    return(
        <div className='cm-profile-container cm-cand-page-container'>
            <MetaComp title="Profile - Mercer | Mettl" />
            <Breadcrumb data={breadcrumbData}/>
            <ProfileContainer t={t} />
            <Outlet />
        </div>
    )

}

export default ProfilePage;