import { LOGOUT, SAVE_SME_INFO } from './actions.types'
import instance from '../../utils/axios';
import { LOGIN } from './actions.types';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from './snackbar.actions';
import { OTP_VERIFIED } from './actions.types';

export const getUserDetails = () => async (dispatch, getState) => {
    // const {t}=useTranslation();
    try {
        const res = await instance.get(getApiUrl('getUser'));
        dispatch({
            type: LOGIN,
            payload: res.data
        })
        // window.location = '/';
    } catch (error) {
        const t = window.reactI18next;
        // console.log(error);
        const errObj = apiErrorHandler(error);
        dispatch(openSnackBar({
            msg: t(errObj.statusText),
            type: 'error'
        }))
    }
}

export const handleLogOut = () => async (dispatch, getState) => {
    try {
        const res = await instance.get(getApiUrl('logout'));
        if (res.status === 200) {
            localStorage.clear();
            window.location.href = "/";
        }

        dispatch({
            type: LOGOUT
        })
    }
    catch (error) {
        console.log(error);
        if (error.response.status === 401) {
            dispatch({
                type: LOGOUT
            })
        }
    }
}

export const saveSmeInfo = (firstname, lastname, email, userId) => ({
    type: SAVE_SME_INFO,
    payload: { firstname, lastname, email, userId }
});

export const otpVerified = () => ({
    type: OTP_VERIFIED
});