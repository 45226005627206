import React from "react";
import { Modal } from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const PharseSearchModal=({modalStatus, handleModalToggle})=>{


    return (
        <div>
         <Modal open={modalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
         <div className='cm-Pharse-search-container bg-white my-3'>
                    <div className='cm-upload-bank-questions-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h5 className='text-blue-800 my-0'>Phrase Search Functionality</h5>
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalToggle} />
                    </div>
                    <div className='w-100 ps-3 pe-3 d-flex align-items-center justify-content-around mt-3'>
                        <label className='text-blue-gray-700 cm-sm-txt fw-medium'>Phrase Search allows to search for banks which have the searched phrase somewhere in their name.</label>
                    </div>
        </div>
        </Modal>  
        </div>
    )
}

export default PharseSearchModal;