import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import useWindowSize from '../../hooks/useWindowSize';
import { ReactComponent as EmptyTaskIcon } from '../../assets/images/home/empty-data.svg';
import ReviewerItem from '../../components/reviewer-item/ReviewerItem.component';
import instance from '../../utils/axios';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import moment from 'moment';
import { apiErrorHandler } from '../../utils/errorHandler';
import { getApiUrl } from '../../utils/apiUrls';
import { format } from 'date-fns';
import AnimationDataLoader from '../../components/Loaders/animationData';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';
 
const ViewReviewerModal = ({ t, reviewModalStatus, handleModalToggle, timelineQuestionId, dashboardData, timelineQuestionStatus, timelineAdminQuestionStatus, isMultiReview, checkedQuestion, setCheckedQuestion, checkedQuestionMap, setCheckedQuestionMap, resetQuestionMap, canAddReviewerMultiple,questionReviewerStatusMap }) => {
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    const currentDate = new Date();
    const [formVal, setFormVal] = React.useState({
        assignedTo: '',
        targetDate: ''
    })
    const [errorMsg, setErrorMsg] = React.useState({ assignedTo: '', targetDate: "" });
    const [value, setValue] = React.useState(null);
 
    const modalSubHeadRef = React.useRef(null);
    const modalHeadRef = React.useRef(null);
    const modalFootRef = React.useRef(null);
    const [contentHeight, setContentHeight] = React.useState(null);
    const [allUsers, setAllUsers] = React.useState([]);
    const [isLoading,setIsLoading]=React.useState(false)
    const [state, setState] = React.useState({
        data: [],
        isLoading: true,
        addedTasks: [],
        canAddReviewer: true
    });
 
    const getAllReviewerList = () => {
        var qIds;
        if(isMultiReview) {
           qIds = checkedQuestion.join();      
        }
        else {
            qIds = timelineQuestionId;
        }

        instance.get(`${getApiUrl('getReviewerList')}/${qIds}`)
            .then(function (response) {
                if (response.data) {
                    const allUsers = []
                    for (var i = 0; i < response.data.length; i++) {
                        allUsers.push({ label: response.data[i].firstName + " " + response.data[i].lastName + " <" + response.data[i].emailId + ">", value: response.data[i].userId })
                    }
                    setAllUsers(allUsers);
                }
            })
            .catch(function (error) {
                console.log(error);
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            })
    }
 
 
    React.useEffect(() => {
        if (reviewModalStatus) {
            getAllReviewerList();
            getAllReviewers();
        }
 
    }, [reviewModalStatus])
 
    React.useEffect(() => {
        setTimeout(() => {
            if (!!modalHeadRef.current && !!modalSubHeadRef.current && !!modalFootRef.current) {
                setContentHeight(window.innerHeight - (modalHeadRef.current.clientHeight + modalSubHeadRef.current.clientHeight + modalFootRef.current.clientHeight));
            }
        }, 100)
    }, [state])
 
    const toggleDrawer = (open) => (event) => {
        setErrorMsg({ ...errorMsg, targetDate: "" });
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
 
        handleModalToggle(open);
        if (!open)
            setState({
                data: [],
                isLoading: true,
                addedTasks: [],
                canAddReviewer: true
            })
    };
 
    const loadTaskItems = () => {
        return state.data.map((el) => {
            return <ReviewerItem t={t} key={el.assignedTo} data={el} dispatch={dispatch} handleAddReviewer={handleAddReviewer} handleDeleteReviewer={handleDeleteReviewer} toggleDrawer={toggleDrawer} handleRefresh={refreshDashboardAndReviewerList} />
        })
    }
 
    const handleDtChange = (newValue) => {
        setValue(newValue);
        setFormVal({
            ...formVal,
            ['targetDate']: Date.parse(newValue._d)
        })
    }
 
    const handleDeleteReviewer = () => {
        dashboardData(null)
    }
 
    const handleAddReviewer = (data, action) => {
        const addedTaskData = state.addedTasks;
 
        const chkExists = addedTaskData.some(el => data.id === el.id);
 
        if (!chkExists) {
            if (state.addedTasks.length < 2) {
                setState({
                    ...state,
                    addedTasks: [...state.addedTasks, data]
                })
            }
 
        }
        else {
            setState({
                ...state,
                addedTasks: addedTaskData.filter(el => data.id !== el.id)
            })
        }
    }
 
    const addSelectedReviewers = () => {
        if (state.canAddReviewer && !!timelineAdminQuestionStatus) {
            const chkObj = {
                targetDate: "",
                assignedTo: ""
            }
 
            for (let key in chkObj) {
                if (key === 'targetDate')
                    chkObj[key] = formVal[key] === '' ? 'This field is mandatory' : ""
                else
                    chkObj[key] = formVal[key] === 0 ? 'This field is mandatory' : ""
            }
            setErrorMsg(chkObj);
 
           
            if(isMultiReview){
                let completedCount = 0;
                for(let questionNum in checkedQuestion){
 
                    if (formVal.assignedTo !== 0 && formVal.targetDate !== "") {
                        const data = { questionId: checkedQuestion[questionNum], assignedTo: formVal.assignedTo, targetDate: Number(moment(formVal.targetDate).endOf('day').unix() + "999") };
                        console.log(data);
                        instance.post(`${getApiUrl('saveQuestionReviewerMapping')}`, data)
                            .then(function (response) {
                                if (response.data.status) {
                                    dispatch(openSnackBar({
                                        msg: "Question is allocated to reviewer.",
                                        type: 'success'
                                    }))
                                    getAllReviewers();
                                    dashboardData(null);
                                    setFormVal({ assignedTo: '', targetDate: '' })
                                    setValue(null);
                                   
                                }
                                else {
                                    getAllReviewers();
                                }
                                completedCount = completedCount + 1;
                            })
                            .catch(function (error) {
                                console.log(error);
                                const errObj = apiErrorHandler(error);
                                dispatch(openSnackBar({
                                    msg: t(errObj.statusText),
                                    type: 'error'
                                }))
                            });
 
                        setState({
                            data: [],
                            isLoading: true,
                            addedTasks: [],
                        })
                    }
                }
                setTimeout(() => {
                    if(completedCount === checkedQuestion.length){
                        setCheckedQuestion([]);
                        handleModalToggle();
                        setCheckedQuestionMap(resetQuestionMap);
   
                    }
                }, 3000);
               
            }
 
            if (formVal.assignedTo !== 0 && formVal.targetDate !== "" && !isMultiReview) {
                setIsLoading(true);
                const data = { questionId: timelineQuestionId, assignedTo: formVal.assignedTo, targetDate: Number(moment(formVal.targetDate).endOf('day').unix() + "999") };
                instance.post(`${getApiUrl('saveQuestionReviewerMapping')}`, data)
                    .then(function (response) {
                        if (response.data.status) {
                            dispatch(openSnackBar({
                                msg: "Question is allocated to reviewer.",
                                type: 'success'
                            }))
                            getAllReviewers();
                            dashboardData(null);
                            setFormVal({ assignedTo: '', targetDate: '' })
                            setValue(null);
                        }
                        else {
                            getAllReviewers();
                        }
                        setIsLoading(false)
                    })
                    .catch(function (error) {
                        console.log(error);
                        const errObj = apiErrorHandler(error);
                        dispatch(openSnackBar({
                            msg: t(errObj.statusText),
                            type: 'error'
                        }))
                    });
                // handleModalToggle();
                setState({
                    data: [],
                    isLoading: true,
                    addedTasks: [],
                })
            }
        }
    }
 
    const refreshDashboardAndReviewerList = () => {
        getAllReviewers();
        dashboardData(null);
    }
 
    const getAllReviewers = () => {
        instance.get(`${getApiUrl('getQuestionReviewerMapping')}/${timelineQuestionId}`)
            .then(function (response) {
                if (response.data) {
                    setState({
                        ...state,
                        data: response.data,
                        isLoading: false,
                        canAddReviewer: !(response.data[0]?.adminReviewedStatus === null)
                    })
                }
 
            })
            .catch(function (error) {
                console.log(error);
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            });
    }
 
 
    const handleSelectChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: e.target.value
        })
 
    }
 
 
    const loadSelectMenuItem = (list) => list.map(el => <MenuItem value={el.value} key={el.value}>{el.label}</MenuItem>)
    const loadQuestions = () => {
        return (
            <div>
             {state.canAddReviewer && canAddReviewerMultiple &&
              <div className='text-blue-gray-700 cm-sm-txt fw-medium'><span className='cm-sm-txt fw-medium ques-num'>{checkedQuestion.length} Questions Selected for Review </span></div>
             }
            </div>
 
        )
       
    }
 
    const list = (anchor) => (
        <Box
            sx={{ width: windowSize.screenWidth <= 991 ? 'auto' : 800 }}
            role="presentation">
            <div className="cm-taskdetail-modal-header d-flex justify-content-between align-items-center" ref={modalHeadRef}>
                <p className='my-0 fw-medium'>{t("add_reviewers")}</p>
                <FontAwesomeIcon icon={regular("times")} className="text-blue-800 cm-pointer" onClick={toggleDrawer(false)} />
            </div>
            <div className="cm-taskdetail-modal-sh cm-sm-txt text-primary bg-blue-gray-50" ref={modalSubHeadRef}>
                <p className='my-0 cm-xs-txt fw-medium text-blue-gray-700'>{t("you_can_select_multiple_reviewers")}</p>
            </div>
            <div className="cm-taskdetail-modal-content cm-sm-txt text-primary position-relative" style={{ height: !!contentHeight ? `${contentHeight}px` : 'auto', overflow: "auto" }}>
                {state.isLoading ?
                    <div className='d-flex justify-content-center'><AnimationDataLoader /></div> : <>
                        {state.canAddReviewer && canAddReviewerMultiple ?
                            <div className="cm-add-task-content-header d-flex justify-content-between align-items-center">
                                <div className="position-relative">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group mb-3">
                                                <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium pe-2'>Select SME/Vendor</label>
                                                <div className=' text-blue-800 fw-medium cm-sm-txt'>
                                                    <Select
                                                        id="vendor-select"
                                                        sx={{ fontSize: "14px", width: "350px", height: "45px" }}
                                                        value={formVal.assignedTo}
                                                        onChange={val => handleSelectChange(val, 'assignedTo')}>
                                                        {loadSelectMenuItem(allUsers)}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 cm-task-search">
                                            <div className="form-group mb-3">
                                                <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium '>Target Date</label>
                                                {errorMsg.targetDate !== "" && <span className='cm-xs-txt text-danger fw-medium pt-2 ms-2'>{errorMsg.targetDate}</span>}
                                                <div className=' text-blue-800 fw-medium'>
                                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                                        <DatePicker
                                                            sx={{ fontSize: "14px", width: "350px", height: "45px" }}
                                                            value={value}
                                                            onChange={(newValue) => {
                                                                handleDtChange(newValue);
                                                            }} renderInput={(params) => <TextField {...params} />}
                                                            minDate={format(currentDate, 'yyyy-MM-dd')}
                                                        />
                                                    </LocalizationProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div>
                                 { !(state.canAddReviewer && canAddReviewerMultiple) &&
                 checkedQuestion.map((element) => {
                    if(questionReviewerStatusMap.get(element)==="InProgress")
                    return <span className='cm-sm-txt fw-medium ques-num'>{element} </span>
                })
            }
                           
                            <p className='text-blue-grey-800 fw-semibold my-0 pb-2'>
                            Some of the selected questions are already in progress or have not yet been accepted/approved, so they cannot be assigned to a reviewer.
                            </p>
                            </div>                                        
                          }
                   
                        {isMultiReview ?  loadQuestions() : null
                        }
                        {state.data.length === 0 ?
                            <div className="cm-content mt-3 text-center pt-5 cm-add-task-modal-empty position-absolute">
                                <EmptyTaskIcon />
                                <p className='text-blue-700 fw-semibold my-0 pb-2'>Nothing to see!</p>
                                <p className="my-0 text-blue-gray-500 cm-sm-txt fw-medium">You can start by adding Reviewer to this question</p>
                            </div> :
                            loadTaskItems()
                        }
                    </>
                }
            </div>
            <div className="cm-taskdetail-modal-footer" ref={modalFootRef}>
                <div className="cm-taskdetail-modal-footer-bottom">
                <div className="d-flex flex-column">
                <div className="cm-event-progress-controls d-flex align-items-center " >
                    <CustomButtonLoader
                        showLoadingState ={isLoading}
                        colorTheme= "blue"
                        icon={regular('angle-right')}
                        reverseIconDirection={false}
                        buttonLabel={t("allocate_for_review")}
                        buttonStyle={`px-12 py-10 cm-mar-left-icon ${state.canAddReviewer && canAddReviewerMultiple ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                        handleOnClick={addSelectedReviewers}
                        disabled={!(state.canAddReviewer && canAddReviewerMultiple) }
                    />
                    <button className='ms-2 btn cm-trans-btn text-action-blue cm-sm-txt fw-medium' onClick={toggleDrawer(false)}>
                        {t('discard')}
                    </button>
                </div>
                </div>

                </div>
            </div>
        </Box >
    );
    // ${state.isLoading ? 'cm-allocate-disabled' : (state.canAddReviewer && canAddReviewerMultiple) ? '' : 'cm-allocate-disabled'}`} onClick={addSelectedReviewers}
    return (
        <div className='cm-add-task-modal-container'>
            <div>
                <React.Fragment>
                    <Drawer
                        anchor={'right'}
                        open={reviewModalStatus}
                        onClose={toggleDrawer(false)}
                        className="cm-tasklist-modal-wrapper"
                    >
                        {!!reviewModalStatus ? list('right') : <div className='py-4 d-flex align-items-center justify-content-center'><CircularProgress /></div>}
                    </Drawer>
                </React.Fragment>
            </div>
        </div>
    )
 
}
 
export default ViewReviewerModal;