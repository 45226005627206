import { SET_SKILL_DASHBOARD_DATA } from "../actions/actions.types";
const INITIAL_STATE = {
  inputVal: "",
  toggleViewComponent: "",
};

const skilltaxanomyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SKILL_DASHBOARD_DATA:
      return action.payload;
    default:
      return state;
  }
};
export default skilltaxanomyReducer;
