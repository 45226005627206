// import { applyMiddleware, createStore } from "redux";
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { createLogger } from "redux-logger";
import reduxThunk from 'redux-thunk';
import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import persistReducer from 'redux-persist/es/persistReducer';
import userReducer from './reducers/user.reducer';
import snackbarReducer from './reducers/snackbar.reducer';
import questionReducer from './reducers/question.reducer';
import errorReducer from './reducers/error.reducer';
import envReducer from './reducers/env.reducer';
import skillDashboardReducer from './reducers/skillDashboard.reducer';
import checkboxReducer from './reducers/checkbox.reducer';


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['env','user','skillDashboard','checkbox']
};

const rootReducer = combineReducers({
    user: userReducer,
    snack: snackbarReducer,
    question: questionReducer,
    error: errorReducer,
    env: envReducer,
    skillDashboard:skillDashboardReducer,
    checkbox:checkboxReducer
});

const middleware = [reduxThunk];
middleware.push(createLogger());
const enhancers = [...middleware];

export const store = configureStore(
    {
        reducer: persistReducer(persistConfig, rootReducer),
        middleware: (getDefaultMiddleware) => {
            getDefaultMiddleware({
                serializableCheck: false
            });
            
            return enhancers;
        },
    },
);

export const persistor = persistStore(store);