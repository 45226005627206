import { LOGIN, LOGOUT, OTP_VERIFIED, SAVE_SME_INFO } from "../actions/actions.types"

const INITIAL_STATE = null;

const userReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case LOGIN: 
            return  action.payload;
        
        case SAVE_SME_INFO: 
        return {
            ...state,
            sme: action.payload
        }

        case LOGOUT:
            {
            return INITIAL_STATE;
            }
        case OTP_VERIFIED: return {
            ...state,
            isMfaAuthenticated: true
        }

        default:
            return state;
    }
}

export default userReducer;