import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import { LOGIN } from '../../redux/actions/actions.types';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { apiErrorHandler } from '../../utils/errorHandler';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { getUserDetails } from '../../redux/actions/user.actions';
import { store } from '../../redux/store';
import CustomButtonLoader from '../global/CustomButtonLoader.component';

const initial_state = {
    email: {
        value: '',
        isRequired: true,
        msg: null
    },
    password: {
        value: '',
        isRequired: true,
        msg: null
    },
    passwordVisible: false,
    validOptions: {
        requiredFields: ['email', 'password'],
        errorFields: [],
    },
};

const LoginForm = () => {

    const { REACT_APP_OAUTH_ISSUER, REACT_APP_OAUTH_CLIENTID, REACT_APP_OAUTH_REDIRECT_URI } = store.getState().env;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [formVal, setFormVal] = React.useState(initial_state);
    const [isLoading, setIsLoading] = React.useState(false);
    const [oktaIsLoading,setOktaIsLoading]=React.useState(false)
    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })
    }

    const handlePassVisiblity = (fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: !formVal[fieldName]
        })
    }

    const getReqMsg = (fieldName) => {
        if (fieldName === 'email') {
            return t('email_required');
        } else if (fieldName === 'password') {
            return t('password_required');
        } else {
            return t('field_required')
        }
    }

    const login = (data) => async (dispatch, getState) => {
        try {
            setIsLoading(true);

            const loginData = await axios.post(getApiUrl('login'), data);

            if (loginData.data?.jwtToken) {

                setIsLoading(false);
                const decodedData = jwt_decode(loginData.data.jwtToken);

                localStorage.setItem('token', loginData.data.jwtToken);

                dispatch({
                    type: LOGIN,
                    payload: JSON.parse(decodedData.sub),
                });

            } else {
                throw Error('Something went wrong. Please try again.')
            }
        } catch (err) {
            setIsLoading(false);

            if (err.response.data.status === 401) {
                dispatch(openSnackBar({
                    msg: t(err.response.data.details[0]),
                    type: 'error'
                }))
            } else {
                const errObj = apiErrorHandler(err);

                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            }
        }
    }

    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = formVal;

        formVal.validOptions.requiredFields.forEach(el => {
            if (currentFormVal[el].value === '') {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                //Email Validation
                if (el === 'email') {
                    // eslint-disable-next-line
                    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                    currentFormVal.email.msg = !regex.test(currentFormVal.email.value) ? t("valid_email_address") : null;
                    return;
                }

                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if (formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if (isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
            !isLoading && encProcess()
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (e.nativeEvent.submitter.name === "normalLogin") {
            handleValidation();
        } else {
            handleSSOLogin();
        }
    }

    const handleLogin = async (dataToSend) => {
        try {
            setIsLoading(true);
            // const dataToSend = {
            //     username: formVal.email.value,
            //     password: formVal.password.value
            // }

            const res = await instance.post(getApiUrl('login'), dataToSend);

            if (res.data.isAuthenticated) {
                dispatch(getUserDetails());
                if (res.data.isMfaAuthenticationRequired) {
                    dispatch(openSnackBar({
                        msg: "Please check your email for the OTP",
                        type: 'info'
                    }))
                    navigate("/mfa-verify");
                }
                // else if(res.data.hasPasswordExpired) {
                //     // dispatch()
                //     navigate("/change-password");
                // }   
            } else {
                throw Error(!!res.data.message ? res.data.message : 'Something went wrong. Please try again.')
            }
        } catch (error) {
            setIsLoading(false);
            console.log('Login err', error);

            if (!!error.response && error?.response.status === 401) {
                dispatch(openSnackBar({
                    msg: t("invalid_username_password"),
                    type: 'error'
                }))
            } else {
                // const errObj = apiErrorHandler(error);

                dispatch(openSnackBar({
                    msg: t(error.message),
                    type: 'error'
                }))
            }

        }
    }

    const encProcess = async () => {
        try {
            setIsLoading(true);
            const getKey = await instance.get(getApiUrl('getSk'));
            if (!!getKey.data) {
                setIsLoading(false);
                const JSEncrypt = window.JSEncrypt;

                //RSA Public Key
                const rsaKey = getKey.data.key;
                const passToEncrypt = formVal.password.value;
                const skid = getKey.data.id;

                //generate AES key
                // var secretPhrase = CryptoJS.lib.WordArray.random(16);
                // var salt = CryptoJS.lib.WordArray.random(128 / 8);
                // //aes key 128 bits (16 bytes) long
                // var aesKey = CryptoJS.PBKDF2(secretPhrase.toString(), salt, {
                //     keySize: 128 / 32
                // });


                // var aesOptions = { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7};
                // var aesEncTrans = CryptoJS.AES.encrypt(JSON.stringify(passToEncrypt), aesKey, aesOptions);

                // console.log(`Plain Password: ${JSON.stringify(passToEncrypt)}`);
                // console.log('[Enc Pass] - AES encrypted transaction [Base64]: ' + aesEncTrans.toString());
                // console.log('AES key [hex]: ' + aesEncTrans.key);

                //encrypt AES key with RSA public key
                var rsaEncrypt = new JSEncrypt({ default_key_size: 2048 });
                rsaEncrypt.setPublicKey(rsaKey);
                var rsaEncryptedAesKey = rsaEncrypt.encrypt(passToEncrypt.toString());

                var encryptedTransaction = { username: formVal.email.value, password: rsaEncryptedAesKey.toString(), skid };

                // dispatch(login(encryptedTransaction));

                handleLogin(encryptedTransaction);

            }

        } catch (error) {
            setIsLoading(false);
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }
    const handleSsOLogin=async()=>{
        const urlParams = new URLSearchParams(window.location.search);
        const status = urlParams.get('status');
        if(status==='error') {
            dispatch(openSnackBar({
                msg: t("oktalogin_error"),
                type: 'error'
            }))
            navigate("/login");
        }
        else if(status==='user_not_added'){
            dispatch(openSnackBar({
                msg: t("oktainvalid_user"),
                type: 'error'
            }))
            navigate("/login");
        }
        else if(status==='success'){
            dispatch(openSnackBar({
                msg: t("okta_success"),
                type: 'success'
            }))
          dispatch(getUserDetails());
            navigate('/')
        }
    }
      React.useEffect(()=>{
        handleSsOLogin();
    },[])

    const handleSSOLogin = () => {
        setOktaIsLoading(true);
        window.location = ("https://" + REACT_APP_OAUTH_ISSUER + "/oauth2/v1/authorize?response_type=code&client_id=" + REACT_APP_OAUTH_CLIENTID + "&state=state&scope=openid%20profile%20email&redirect_uri=" + REACT_APP_OAUTH_REDIRECT_URI)
        setOktaIsLoading(true);
    }

    return (
        <form className="cm-auth-form cm-login-form" onSubmit={handleFormSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Email Address</label>
                <div className="cm-icon-field position-relative">
                    <input type="email" className="form-control text-blue-800 cm-sm-txt fw-medium" id="email" value={formVal.email.value} placeholder="abc@email.com" onChange={(e) => handleFieldChange(e, 'email')} />
                    <FontAwesomeIcon icon={regular('circle-question')} className={`form-text text-blue-gray-500 position-absolute cm-mob-icon d-md-none d-lg-none`} onClick={() => handlePassVisiblity('passwordVisible')} />
                </div>
                {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
            </div>
            <div className="form-group mb-3">
                <label htmlFor="password" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>Password</label>
                <div className="cm-icon-field position-relative">
                    <input type={formVal.passwordVisible ? 'text' : 'password'} className="form-control text-blue-800 cm-sm-txt fw-medium" id="password" value={formVal.password.value} placeholder="Enter password here" onChange={(e) => handleFieldChange(e, 'password')} />
                    <FontAwesomeIcon icon={formVal.passwordVisible ? regular('eye-slash') : regular('eye')} className={`form-text text-blue-gray-500 position-absolute cm-pass-visiblility-changer`} onClick={() => handlePassVisiblity('passwordVisible')} />
                </div>
                {formVal.password.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.password.msg}</span>}
            </div>
            <p className='my-0 cm-sm-txt fw-medium'>
                <Link to="/forgot-password" className="text-blue-500">Forgot Password?</Link>
            </p>

            <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                <CustomButtonLoader
                    showLoadingState ={isLoading}
                    colorTheme= "blue"
                    icon={regular('angle-right')}
                    reverseIconDirection={false}
                    buttonLabel={t("login")}
                    buttonStyle={"px-12 py-2 cm-mar-left-icon"}
                    disabled={oktaIsLoading}
                    buttonProps={{ name: "normalLogin" }}
                />
                <CustomButtonLoader
                    showLoadingState ={oktaIsLoading}
                    colorTheme= "blue"
                    icon={regular('angle-right')}
                    reverseIconDirection={false}
                    buttonLabel={t("sign_in_with_okta_sso")}
                    buttonStyle={"ms-3 px-12 py-2 cm-mar-left-icon"}
                    disabled={isLoading}
                    buttonProps={{ name: "oktaLogin" }}
                />
            </div>

        </form>
    )

}

export default LoginForm;