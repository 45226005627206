import React from "react";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextTruncate from "react-text-truncate";
import "./Reviewertem.styles.css";
import ProgressControl from "../status-control/ProgressControl.component";
import { Button } from "react-bootstrap";
import instance from "../../utils/axios";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { useDispatch } from "react-redux";
import { apiErrorHandler } from "../../utils/errorHandler";
import { useTranslation } from "react-i18next";
import { getApiUrl } from "../../utils/apiUrls";
import { getDateAndTimeInFormat } from "../../utils/moments";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import CustomDialog from "../../components/custom-dialog/CustomDialog.component";

const ReviewerItem = ({ data, handleAddReviewer, handleDeleteReviewer, toggleDrawer, handleRefresh }) => {
    const dispatch = useDispatch();
    const t = useTranslation;
    const [deleteReviewerStatus, setDialogStatus] = React.useState({
        status: false,
        questionId: null,
        smeId: null,
    });

    const handleDeallocateReviewerToggle = (questionId, smeId) => {
        setDialogStatus({
            status: !deleteReviewerStatus.status,
            questionId: !!questionId ? questionId : null,
            smeId: !!smeId ? smeId : null,
        });
    };

    const deallocateReviewerFn = (e) => {
        deallocateReviewer(
            deleteReviewerStatus.questionId,
            deleteReviewerStatus.smeId,
            e
        );
        setDialogStatus({
            status: false,
            questionId: null,
            smeId: null,
        });
    };

    const deallocateReviewer = async (questionId, smeId, e) => {
        try {
            const res = await instance.get(
                `${getApiUrl("deleteQuestionReviewerMapping")}?questionId=${questionId}&assignedTo=${smeId}`
            );
            if (res.data.status) {
                // toggleDrawer(false)(e);
                handleRefresh();
                dispatch(
                    openSnackBar({
                        msg: res.data.message,
                        type: "success",
                    })
                );
                handleDeleteReviewer();
            } else {
                console.log(res.data);
            }
        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(
                openSnackBar({
                    msg: errObj.statusText,
                    type: "error",
                })
            );
        }
    };

    const handlePermission = (status, e) => {
        var dataToSend = {};
        var msg = "";
        if (status === "grant") {
            dataToSend = {
                assignedTo: data.assignedTo,
                questionId: data.questionId,
                showTestcase: true,
            };
            msg = "The permission is successfully granted.";
        } else {
            dataToSend = {
                assignedTo: data.assignedTo,
                questionId: data.questionId,
                showTestcase: false,
            };
            msg = "The permission is denied.";
        }
        instance
            .post(`${getApiUrl("grantTestcasePermission")}`, dataToSend)
            .then(function (response) {
                if (response.data) {
                    // toggleDrawer(false)(e);
                    handleRefresh();
                    dispatch(
                        openSnackBar({
                            msg: msg,
                            type: "success",
                        })
                    );
                }
            })
            .catch(function (error) {
                console.log(error);
                const errObj = apiErrorHandler(error);
                dispatch(
                    openSnackBar({
                        msg: t(errObj.statusText),
                        type: "error",
                    })
                );
            });
    };

    const [isReviewedByAdmin, setIsReviewByAdmin] = React.useState(data.adminReviewedStatus);

    const handleReviewReport = (val) => {
        setIsReviewByAdmin(val);
        let dataToSend = {
            assignedTo: data.assignedTo,
            questionId: [data.questionId],
            status: val,
        };
        let msg = "The review report is " + val;
        instance
            .post(`${getApiUrl("saveReviewReportAdminStatus")}`, dataToSend)
            .then(function (response) {
                if (response.data) {
                    handleRefresh();
                    dispatch(
                        openSnackBar({
                            msg: msg,
                            type: "success",
                        })
                    );
                }
            })
            .catch(function (error) {
                console.log(error);
                const errObj = apiErrorHandler(error);
                dispatch(
                    openSnackBar({
                        msg: t(errObj.statusText),
                        type: "error",
                    })
                );
            });
    };

    return (
        <>
            <div
                className={`cm-modal-task-item py-3 px-4 ${isReviewedByAdmin === "Approved"
                    ? `border-success bg-opacity-10 bg-success`
                    : isReviewedByAdmin === "Rejected"
                        ? `border-danger bg-opacity-10 bg-danger`
                        : ``
                    }`}
            >
                <div className="d-flex flex-row justify-content-between">
                    <p className="my-0 text-blue-800 fw-medium d-flex align-items-center">
                        <TextTruncate
                            line={1}
                            element="span"
                            truncateText="…"
                            text={data.assignedName}
                        />
                    </p>
                    <CustomToolTip title={data.status === "InProgress" ? "De-Allocate" : "De-Allocate is disabled"}>
                        <span
                            className={`text-action-blue cm-sm-txt fw-medium px-3 cm-pointer d-flex align-items-center ${data.status !== "InProgress" ? "cm-sidebar-btn-disabled" : ""}`}
                            onClick={(e) =>
                                data.status === "InProgress" ? handleDeallocateReviewerToggle(data.questionId, data.assignedTo, e) : null
                            } >
                            <FontAwesomeIcon icon={regular("trash")} />
                        </span>
                    </CustomToolTip>
                </div>
                <div className="d-flex flex-row">
                    <ul className="cm-modal-task-info text-blue-gray-700 d-flex align-items-center mb-0 p-0 mt-1">
                        <li>
                            <FontAwesomeIcon
                                icon={regular("envelope")}
                                className="cm-xs-txt pe-2"
                            />
                            <span className="cm-xs-txt fw-medium text-blue-gray-700">
                                {data.assignedEmail}
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="d-flex flex-row justify-content-between mt-3">
                    {data.comment && <p className="cm-sm-txt fw-medium text-blue-gray-700 m-3 cm-comment-data">
                        {data.comment}
                    </p>}
                    <p className="cm-sm-txt fw-medium text-blue-800 align-self-center mb-0" style={{ flex: "none" }}>
                        <ProgressControl value={data.status}></ProgressControl>
                    </p>
                </div>
                <div className="d-flex flex-row">
                    <p className="text-blue-800 fw-medium text-blue-gray-700 mt-3">
                        Assigned Deadline: {getDateAndTimeInFormat(data.targetDate)}
                    </p>
                </div>
                {data.showTestcase && (
                    <Button
                        className="cm-dialog-cancel-btn border-danger text-white cm-sm-txt fw-medium ms-2 bg-danger mt-2"
                        onClick={(e) => handlePermission("revoke", e)}
                    >
                        Revoke Testcase Permission
                    </Button>
                )}

                {data.requestTestcase && (
                    <>
                        <p className="cm-sm-txt fw-medium text-blue-500">
                            {" "}
                            Reviewer requested for testcase view permission. You can either
                            grant or revoke.
                        </p>
                        <div className="d-flex">
                            <Button
                                className="cm-dialog-action-btn cm-sm-txt border-action-blue bg-action-blue"
                                onClick={(e) => handlePermission("grant", e)}
                            >
                                Grant
                            </Button>
                            <Button
                                className="cm-dialog-cancel-btn border-danger text-white cm-sm-txt fw-medium ms-2 bg-danger"
                                onClick={(e) => handlePermission("revoke", e)}
                            >
                                Revoke
                            </Button>
                        </div>
                    </>
                )}
                {data.status === "InProgress" && (
                    <CustomDialog
                        dialogHeading="De-allocate Review"
                        cancelBtnLabel="No, Don't De-allocate"
                        actionBtnLabel={<span>De-allocate Review</span>}
                        actionBtnBg="bg-danger"
                        cancelFn={handleDeallocateReviewerToggle}
                        actionFn={(e) => deallocateReviewerFn(e)}
                        dialogStatus={deleteReviewerStatus.status}
                    >
                        <p className="my-0 cm-sm-txt text-blue-800">
                            Are you sure you want to delete the selected review from the{" "}
                            <strong>{data.assignedName}</strong>?
                        </p>
                    </CustomDialog>
                )}
                {(data.status === "Approved" || data.status === "Rejected") && isReviewedByAdmin === null && (
                    <div className="d-flex flex-end align-items-center justify-content-end">
                        <span className="me-2 align-items-center">
                            Approve/Reject Review Report :{" "}
                        </span>
                        <button className="rounded-circle border border-success me-1 px-2 py-1" value="accepted" onClick={() => handleReviewReport("Approved")}>
                            <FontAwesomeIcon icon={regular("check")} style={{ color: "#2cc90d" }} />
                        </button>
                        <button className="rounded-circle border border-danger ms-1 px-2 py-1" value="rejected" onClick={() => handleReviewReport("Rejected")} >
                            <FontAwesomeIcon icon={regular("xmark")} style={{ color: "#c70f0f", paddingLeft: "1px", paddingRight: "1px", }} />
                        </button>
                    </div>
                )}

                {isReviewedByAdmin !== null && (
                    <div
                        className={`cm-sm-txt fw-medium text-blue-800  align-self-center border-success`}
                    >
                        Review Report : {isReviewedByAdmin}
                        {isReviewedByAdmin === "Approved" && (
                            <FontAwesomeIcon
                                icon={regular("check-to-slot")}
                                beat
                                size="lg"
                                style={{ color: "#16b118" }}
                                className="ms-1"
                            ></FontAwesomeIcon>
                        )}
                        {isReviewedByAdmin === "Rejected" && (
                            <FontAwesomeIcon
                                icon={regular("xmark-to-slot")}
                                beat
                                size="lg"
                                className="ms-1"
                                style={{ color: "#ec2222" }}
                            ></FontAwesomeIcon>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default ReviewerItem;
