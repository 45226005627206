import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import instance from '../../utils/axios';
import { getApiUrl } from '../../utils/apiUrls';
import Select from 'react-select';

function PreviewQuestionCard({ question }) {
  
  const correctAnswer = question.multipleChoiceAnswerDescription?.correctChoiceIndex;
  // const [questiontype,setQuestionType]=question.questiontype;
  const correctAnswers=question.multipleChoiceAnswerDescription?.correctChoiceIndices
  const questionType=question.questionType;
  const user = useSelector(state => state.user);
  const [reviewerList, setReviewerList] = React.useState([]);
  const [questionAllAnswersMCA, setQuestionAllAnswersMCA] = React.useState([
    {
      value: question.multipleChoiceAnswerDescription?.correctChoiceIndices,
      label: 'Creator'
    },
  ]);
  const [questionAllAnswers, setQuestionAllAnswers] = React.useState([
    {
      value: correctAnswer,
      label: 'Creator'
    },
  ]);
  const [currentAnswer, setCurrentAnswer] = React.useState();
  const [callGetReviewerListApi, setCallGetReviewerListApi] = React.useState(true);
const [currentAnswerMCA,setCurrentAnswerMCA]=React.useState([]);


  useEffect(() => {
    const getReviewerList = async () => {
      try {
        const res = await instance.get(`${getApiUrl('reviewQuestion')}/${question.questionId}`);
        setReviewerList(res.data);
        // const reviewerAnswers = res.data?.map((item) => (
        //   { value: parseInt(`${item.mcqAnswer}`, 10), label: `${item.assignedName}` }
        // ))
        for(let i=0;i<res.data.length;i++) {
          const item = res.data[i];
          if(item.mcqAnswer.length==1 && item.questionType==="MCQ") {
            setQuestionAllAnswers([...questionAllAnswers, { value: parseInt(`${item.mcqAnswer[0]}`, 10), label: `${item.assignedName}` }]);
          }
          else if(item.mcqAnswer.length>0){
            setQuestionAllAnswersMCA([...questionAllAnswersMCA, { value: item.mcqAnswer, label: `${item.assignedName}` }])
          }
        }

      //  setQuestionAllAnswers([...questionAllAnswers, ...reviewerAnswers]);
        setCallGetReviewerListApi(false);
      } catch (err) {
        console.log("error occured while getting reviewer ans", err);
      }
    }
    if (callGetReviewerListApi && (question.questionType==="MCQ" || question.questionType==="MCA")) {
      getReviewerList();
    }

  }, [callGetReviewerListApi]);

  const handleSelectChange = (selected) => {
    setCurrentAnswer(selected.value);
    if(Array.isArray(selected.value)) setCurrentAnswerMCA(selected.value);
  }


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: (state.isFocused || state.isSelected) ? 'lightGrey' : '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px',

    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      fontWeight: 600,
      color: state.isSelected ? '#002C77' : '#001F52',
      backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
      cursor: "pointer",
      width: '92%',
      marginLeft: '4%',
      marginBottom: '2%',
      borderRadius: '5px',
    }),
  };



  return (
    <div className={`question-card ${user.role !== "SME" ? "w-90" : ""}`} style={{ backgroundColor: "white" }} >
      <div className='ques-id'>
        <span className='ques-id-txt inline-block'>Question Id : {question.questionId}</span>
        {user.role !== "SME" && (question.questionType==="MCQ" || question.questionType==="MCA") &&
          <div className="selectBox">
            <span className='selectBox-txt inline-block'>Select : </span>
            <Select
              className="bg-white cm-sm-txt fw-medium  cm-select-field"
              options={question.questionType==="MCQ"?questionAllAnswers:questionAllAnswersMCA}
              defaultValue={question.questionType==="MCQ"?questionAllAnswers[0]:questionAllAnswersMCA[0]}
              isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
              onChange={handleSelectChange}
              styles={customStyles}
            />
          </div>
        }
      </div>

      <div className='ques-text clearfix'>
        {question.questionText && <div dangerouslySetInnerHTML={{ __html: question.questionText }}></div>}
      </div>

      <div className='my-3'>
        {question.multipleChoiceAnswerDescription?.choices.map((element, index) => {
          return (<div className='my-2 answer-text d-flex align-items-start' key={index}>
           { question.questionType === "MCQ" ? 
            <input
              className='inline-block mt-1'
              type= "radio" 
              value={index}
              name={'answerOptions' + question.questionId}
              checked={currentAnswer ? currentAnswer === index + 1 : correctAnswer === index + 1}
            />
            :
            <input
              className='inline-block mt-1'
              type= "checkbox" 
              value={index}
              name={'answerOptions' + question.questionId}
              checked={currentAnswerMCA.length>0 ? currentAnswerMCA.includes(index+1) : question.multipleChoiceAnswerDescription?.correctChoiceIndices.includes(index+1)}
            />

          }
            <div className='w-100 text-blue-800 clearfix flex-grow-1 ps-3'>
              {element.choiceText && <div dangerouslySetInnerHTML={{ __html: element.choiceText }}></div>}
            </div>
          </div>)
        })}
      </div>

      <div>
        <span className='mcq-tags'>{question.difficultyLevel}</span>
        <span className='mcq-tags'>{question.questionTitle}</span>
        <span className='mcq-tags'>{question.questionType}</span>
      </div>
    </div>
  )
}

export default PreviewQuestionCard;

