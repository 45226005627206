import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';

import { ReactComponent as ErrorIcon } from '../../assets/images/error/500-icon.svg';

import './ErrorPage.styles.css';

const ServerErrorPage = () => {
    const { t } = useTranslation();

    const handleRefresh = () => window.location.reload();

    return(
        <div className='cm-error-container cm-notfound-page-container d-flex align-items-center justify-content-center flex-column'>
            <ErrorIcon />
            <p className='text-blue-700 fw-bold fw-semibold pb-2 my-0'>{ t("internal_server_error") }</p>
            <p className='my-0 text-center text-blue-gray-500 pb-2 fw-medium cm-sm-txt' style={{ 'max-width': '450px' }}>{ t("err_msg_500") }</p>
            <span className='text-blue-gray-400 cm-xs-txt'>{ t("error_code_500") }</span>
            <span className='cm-btn cm-trans-btn border border-action-blue text-action-blue cm-sm-txt mt-2 cm-pointer' onClick={handleRefresh}>{t("refresh_page")} <FontAwesomeIcon icon={regular("rotate-right")} className="ps-2" /></span>
            <Outlet />
        </div>
    )

}

export default ServerErrorPage;