import * as React from 'react';
import Modal from '@mui/material/Modal';
import './ViewTasksToAdmin.styles.css'
import TaskListItem from './TaskListItem.componet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';


export default function ViewAllActiveRequestModal({ openStatus, closeStatus }) {
  return (
    <div>
      <Modal
        open={openStatus}
        onClose={closeStatus}
        disableAutoFocus={true}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description">
        <div className='cm-view-task-container bg-white my-3'>
          <div className='cm-view-task-header py-3 px-4 d-flex align-items-center justify-content-between'>
            <h5 className='text-blue-800 my-0'>Request Lists</h5>
            <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={closeStatus} />
          </div>
          <div className='cm-view-task-data px-4 pb-4'>
            <TaskListItem></TaskListItem>
          </div>
        </div>
      </Modal>
    </div>
  );
}
