import { Autocomplete, Checkbox, TextField } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';
import ToolsLoginFormContainer from './ToolsLoginForm.container';
import { saveAs } from "file-saver";
import './DownloadQuestionBank.styles.css';
import AnimationDataLoader from '../../components/Loaders/animationData';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';

const DownloadQuestionBankContainer = ({ t }) => {
    const dispatch = useDispatch();
    const [downloadQuestionData, setDownloadQuestionData] = React.useState({ data: [], token: null, loginExpand: false, isLoading: false, fetchDataLoadingStatus: false });
    const [formVal, setFormVal] = React.useState({ downloadFrom: '', questionData: null, removeHtml: false });

    const fetchAssessments = async (token) => {
        try {
            setDownloadQuestionData({ ...downloadQuestionData, fetchDataLoadingStatus: true })
            const res = await instance.post(getApiUrl("fetchAssessments"), {token});

            if(res.data.status) {
                setDownloadQuestionData({data: res.data.assessmentList, token, loginExpand: false, fetchDataLoadingStatus: false})
            }

        } catch (error) {
            setDownloadQuestionData({ ...downloadQuestionData, fetchDataLoadingStatus: false });
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const fetchBanks = async (token) => {
        try {
            setDownloadQuestionData({ ...downloadQuestionData, fetchDataLoadingStatus: true })
            const res = await instance.post(getApiUrl("fetchBanks"), {token});

            if(res.data.status) {
                setDownloadQuestionData({data: res.data.bankList, token, loginExpand: false, fetchDataLoadingStatus: false})
            }

        } catch (error) {
            setDownloadQuestionData({ ...downloadQuestionData, fetchDataLoadingStatus: false });
            const errObj = apiErrorHandler(error);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const toggleAccordian = () => setDownloadQuestionData({ ...downloadQuestionData, loginExpand: !downloadQuestionData.loginExpand })

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // handleValidation();
    }

    const handleFieldChange = (key, value) => {
        setFormVal({ ...formVal, [key]: value })
        if(key === "downloadFrom" && !downloadQuestionData.loginExpand) toggleAccordian();

    }

    const downloadQuest = async() => {
        try {
            setDownloadQuestionData({ ...downloadQuestionData, isLoading: true });
            const res = await instance.post(getApiUrl("downloadQuestionToExcel"), {
                name: formVal.questionData?.name,
                type: formVal.downloadFrom,
                id: Number(formVal.questionData?.id),
                removeHTMLAndCode: formVal.removeHtml,
                token: downloadQuestionData.token,
                questionCount: formVal.questionData?.count ? Number(formVal.questionData?.count) : null
            }, {responseType: 'blob',})

            const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
            setDownloadQuestionData({ ...downloadQuestionData, isLoading: false });
            saveAs(res.data, `${formVal.questionData?.name}.xls`);

        } catch (err) {
            setDownloadQuestionData({ ...downloadQuestionData, isLoading: false });
            if(err?.response?.data) {
                const aa = new Promise((resolve, reject) => {
                  let reader = new FileReader();
                  reader.onload = () => {
                    err.response.data = JSON.parse(reader?.result);
                    const msgObj = apiErrorHandler(err);
                    dispatch(
                      openSnackBar({
                        msg: t(msgObj.statusText),
                        type: "error",
                      })
                    );
                    resolve(Promise.reject(err));
                  };
        
                  reader.onerror = () => {
                    reject(err);
                  };
                  reader.readAsText(err.response.data);
                });
              } else {
                const msgObj = apiErrorHandler(err);
                    dispatch(
                    openSnackBar({
                        msg: t(msgObj.statusText),
                        type: "error",
                    })
                );
            }
        }
    }

    return(
        <div className='pt-4 cm-download-quest-bank-container' style={{ maxWidth: "800px", margin: "0 auto" }}>
            <label htmlFor="ASSESSMENTS" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("download_questions_from")}</label>
            <div className='d-flex align-items-center mb-4'>
                <div className="d-flex align-items-center">
                    <input disabled={downloadQuestionData.token} type="radio" name="downloadFrom" id="assessments" value="ASSESSMENTS" onChange={(e) => handleFieldChange('downloadFrom', e.target.value)} />
                    <label htmlFor="assessments" className='text-blue-gray-700 cm-sm-txt fw-semibold text-uppercase ps-2'>{t("assessments")}</label>
                </div>
                <div className="d-flex align-items-center ps-3">
                    <input disabled={downloadQuestionData.token} type="radio" name="downloadFrom" id="banks" value="BANKS" onChange={(e) => handleFieldChange('downloadFrom', e.target.value)} />
                    <label htmlFor="banks" className='text-blue-gray-700 cm-sm-txt fw-semibold text-uppercase ps-2'>{t("banks")}</label>
                </div>
            </div>

            {formVal.downloadFrom && <>
            <ToolsLoginFormContainer t={t} permissionName="downloadQuestionBank" fetchData={formVal.downloadFrom === "ASSESSMENTS" ? fetchAssessments : fetchBanks} loginExpand={downloadQuestionData.loginExpand} toggleAccordian={toggleAccordian} downloadFrom={formVal.downloadFrom} btnLabel={formVal.downloadFrom === "ASSESSMENTS" ? t("fetch_assessments") : t("fetch_banks")} />

            <div className='bg-white border border-blue-gray-100 bg-white mt-4'>
                    <p className='mb-0 px-4 py-2 cm-sm-txt fw-semibold text-blue-gray-700 border-bottom border-blue-gray-100'>{formVal.downloadFrom === "ASSESSMENTS" ? t("select_assessment_to_download") : t("select_bank_to_download")}</p>
                    <form onSubmit={downloadQuestionData.token && formVal.questionData ? handleFormSubmit : null} className='pt-3 px-4 pb-4'>
                        <div className="form-group mb-3">
                            <label htmlFor="assessment" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{formVal.downloadFrom === "ASSESSMENTS" ? t("assessment") : t("bank")}</label>
                            <div className="position-relative">
                                <Autocomplete
                                    disablePortal
                                    options={downloadQuestionData.data}
                                    getOptionLabel={(option) => formVal.downloadFrom === "ASSESSMENTS" ? `${option.assessmentName} (${option.assessmentId})` : `${option.bankName} (${option.questionCount})`}
                                    fullWidth={true}
                                    sx={{ padding: 0 }}
                                    onInputChange={(e) => handleFieldChange('questionData', { name: e.target.getAttribute("data-name"), id: e.target.getAttribute("data-id"), ...(formVal.downloadFrom === "BANKS" ? {count: e.target.getAttribute("data-count")} : {}) })}
                                    renderInput={(params) => <TextField {...params} />}
                                    disableClearable={true}
                                    disabled={!downloadQuestionData.token}
                                    renderOption={(props, option) => (
                                        formVal.downloadFrom === "ASSESSMENTS" ? <li
                                            data-name={option.assessmentName }
                                            data-id={option.assessmentId}
                                            {...props}
                                        >
                                           {option.assessmentName} ({option.assessmentId})
                                        </li> : 
                                        <li
                                            data-name={option.bankName }
                                            data-id={option.bankId}
                                            data-count={option.questionCount}
                                            {...props}
                                        >
                                        {option.bankName} ({option.questionCount})
                                        </li>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-3 d-flex align-items-center">
                            <Checkbox
                                size="small"
                                style={{ height: "30px", padding: "10px" }}
                                checked={formVal.removeHtml}
                                onChange={(e) => handleFieldChange("removeHtml", !formVal.removeHtml)}

                            />
                            <span className='cm-sm-txt text-blue-800 fw-medium'>{t("remove_html_get_plain_text")}</span>
                        </div>

                        <CustomButtonLoader
                            showLoadingState ={downloadQuestionData.isLoading}
                            colorTheme= "blue"
                            reverseIconDirection={false}
                            buttonLabel={formVal.downloadFrom === "ASSESSMENTS" ? t("download_questions_from_assessment") : t("download_questions_from_bank")}
                            buttonStyle={"px-12 py-2 cm-mar-left-icon"}
                            handleOnClick={downloadQuest}
                            disabled={!(downloadQuestionData.token && formVal.questionData)}
                        />
                    </form>
            </div>
            {downloadQuestionData.fetchDataLoadingStatus && <div className="cm-fp-loader d-flex justify-content-center align-items-center">
                <div className='text-center'>
                    <AnimationDataLoader />
                    <p className='mb-0 ps-2 cm-sm-txt text-blue-800 fw-medium'>{t("fetching_data")}</p>
                </div>
            </div>}
            </>}
        </div>
    )

}

export default DownloadQuestionBankContainer;