import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const CustomPieChart = ({ data, totalQuestionCount }) => {

    var tagData = {
        name: 'Tags',
        colorByPoint: true,
        data: []
    };

    var c = 0;

    Object.entries(data).forEach(([key, value]) => {
        if (c === 0) {
            tagData.data.push({ name: `${key}`, y: (Number(`${value}`) / totalQuestionCount) * 100, sliced: true, selected: true })
            c = -1;
        }
        else {
            tagData.data.push({ name: `${key}`, y: (Number(`${value}`) / totalQuestionCount) * 100 })
        }
    });
    // const options = React.useMemo(() => ({
    //     chart: {
    //         plotBackgroundColor: null,
    //         plotBorderWidth: null,
    //         plotShadow: false,
    //         type: 'pie'
    //     },
    //     title: {
    //         text: '',
    //         align: 'center'
    //     },
    //     tooltip: {
    //         pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    //     },
    //     accessibility: {
    //         point: {
    //             valueSuffix: '%'
    //         }
    //     },
    //     series: {
    //         name: 'Tags',
    //         colorByPoint: true,
    //         data: tagData.data

    //     }

    // }), []);

    const options = {
        chart: {

            plotBackgroundColor: null,

            plotBorderWidth: null,

            plotShadow: false,

            type: 'pie',

            width: 500,

            height: 250,

            options3d: {

                enabled: true,

                alpha: 45,

                beta: 0

            },
            margin: [0, 0, 0, 0],
            spacingTop: 0,

            spacingBottom: 0,

            spacingLeft: 0,

            spacingRight: 0,

            backgroundColor: 'transparent',

        },
        title: {
            text: null,
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: true,
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                // colors: ['#BED3E4', '#4E6287', '#009DE0', '#00968F'],
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },

        series: [{

            showInLegend: false,

            name: 'Question Tag Usage',

            colorByPoint: true,

            groupPadding: 0,

            pointPadding: 0,

            minPadding: 0,

            maxPadding: 0,
            data: tagData.data
        }]

    };


    return <div className='col-sm-12 d-flex align-items-center justify-content-center bg-white border border-blue-gray-100 border-top-0 py-4'><HighchartsReact highcharts={Highcharts} options={options} /></div>
}

CustomPieChart.defaultProps = {
    data: []
}

export default CustomPieChart;