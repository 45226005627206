import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import CreateQuestionContainer from '../../containers/question-creation/CreateQuestion.container';
import { getQuestionDetails, updateQuestData, updateQuestDataByKey } from '../../redux/actions/question.actions';

const AddQuestionDetailsPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const getReqId = searchParams.get('reqId');
    const getQuestid = searchParams.get('qId');

    const questData = useSelector(state => state.question);

    const breadcrumbData = React.useMemo(() => ([
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: "Dashboard",
            link: '/'
        }
    ]), []);

    React.useEffect(() => {
        if (!!getReqId && !!location.state) {
            dispatch(updateQuestDataByKey('questDetails', {
                questionTopic: {
                    value: location.state.topicName,
                    msg: null
                },
                difficultyLevel: {
                    value: { label: location.state.difficultyLevel, value: location.state.difficultyLevel }
                }
            }))
        } else if (!!getReqId && !!getQuestid) {
            dispatch(getQuestionDetails(getQuestid, 0));
        } else {
            navigate(`/question-library/${getReqId}`)
        }
    }, [])

    return (
        <div className='cm-cms-container'>
            <Breadcrumb data={breadcrumbData} />
            <CreateQuestionContainer t={t} initStep={0} getQuestId={getQuestid} getReqId={getReqId} questData={questData} />
            <Outlet />
        </div>
    )
}

export default AddQuestionDetailsPage;