import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { Link, useNavigate } from 'react-router-dom';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './Breadcrumb.styles.css';
import { t } from 'i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

const Breadcrumb = ({ data, enableDateRange, selDtRange, handleDtRangeChange, backBtn, handleDtSubmit, handleDtClear, isButtonOption, buttonName, handleToggleModal }) => {
    const navigate = useNavigate();
    var sme = {};
    sme = useSelector(state => state.user?.sme);
    const user = useSelector(state => state.user);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const loadBreadcrumb = () => {
        return data.map((el, index) => {
            let innerStruct = '';
            if (el.iconClass) {
                innerStruct = <FontAwesomeIcon icon={el.iconClass} />
            } else {
                innerStruct = <span className='cm-sm-txt'>{el.label}</span>
            }

            if (!!el.link) {
                return (
                    <span key={index}>
                        <Link to={el.link} className="text-blue-800 cm-sm-txt">
                            {innerStruct}
                        </Link>
                        {index < data.length - 1 && <FontAwesomeIcon icon={regular('angle-right')} className="px-2" />}
                    </span>
                )
            } else {
                return (
                    <span key={index}>
                        <span className="text-blue-800 cm-sm-txt">
                            {innerStruct}
                        </span>
                    </span>
                )
            }
        })
    }


    const onDtApply = () => {
        handleDtSubmit();
        handleClose();
    }

    const onDtClear = () => {
        handleDtClear();
        handleClose();
    }


    return (<>

        <div className="cm-breadcrumb-container bg-white py-0">
            {!isButtonOption && sme?.firstname  && <nav className="d-flex align-items-center justify-content-center py-2 px-3 sticky-top" style={{ 'backgroundColor': 'bisque' }}>
                <p className="text-blue-gray-700 cm-sm-txt my-0">You are viewing requests and questions for <span className="text-blue-gray-700 fw-medium cm-sm-txt text-uppercase">{sme?.firstname} {sme?.lastname} </span> {sme?.email !== null && <span className="text-blue-gray-700 fw-medium cm-sm-txt">  ({sme?.email})</span>}
                </p>
            </nav >}

            <div className={`px-3 py-2 d-flex justify-content-between align-items-center`}>
                <div>
                    {loadBreadcrumb()}
                </div>
                <div className="d-flex">


                    {
                        enableDateRange ?
                            <div className='cm-date-range-picker-wrap'>
                                <div className="cm-date-range-val position-relative d-flex align-items-center border border-blue-gray-300 d-flex align-items-center justify-content-between cm-pointer" onClick={handleClick} >
                                    <input type="text" className='cm-sm-txt' readOnly={true} placeholder="Select date range" value={selDtRange.startDate !== null && selDtRange.endDate !== null ? `${moment(selDtRange.startDate).format('DD/MM/YYYY')} - ${moment(selDtRange.endDate).format('DD/MM/YYYY')}` : ''} />
                                    <FontAwesomeIcon icon={regular("calendar-lines")} className="ms-2" />
                                    <div className="cm-dt-field-overlay position-absolute" />
                                </div>

                                <Menu
                                    id="date-range-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'date-range',
                                    }}
                                >
                                    <MenuItem onClick={null} disableRipple={true}>
                                        <DateRangePicker
                                            ranges={[selDtRange]}
                                            onChange={handleDtRangeChange}
                                        />
                                    </MenuItem>
                                    <MenuItem onClick={null} disableRipple={true} className="bg-white cm-dt-actions d-flex justify-content-end px-3">
                                        <span onClick={onDtApply} className='cm-apply-range-btn btn cm-btn cm-sm-txt fw-medium bg-action-blue text-white border border-action-blue mb-3'>Apply</span>
                                        <span onClick={onDtClear} className='ms-3 cm-clear-range-btn btn cm-btn-trans cm-sm-txt fw-medium bg-white text-action-blue border border-action-blue mb-3'>Clear</span>
                                    </MenuItem>
                                </Menu>
                            </div>
                            : null
                    }
                    {/* {sme !== {} &&


                        <div className="d-grid ms-4">
                            <div className="d-flex">
                                {sme?.firstname && <><FontAwesomeIcon icon={regular('user-circle')} className="px-2 pt-1" />
                                    <div className='text-blue-800 cm-sm-txt me-2 text-uppercase'>{sme?.firstname} {sme?.lastname}</div></>}
                            </div>
                            <div className='text-blue-gray-700 cm-sm-txt ms-2'>{sme?.email}</div>
                        </div>
                    } */}

                    {
                        backBtn ?
                            <span className='cm-breadcrumb-back fw-medium cm-sm-txt text-blue-800 justify-content-center cm-pointer ms-3' onClick={() => navigate(-1)}>
                                {t("back")}
                            </span>
                            : null
                    }
                </div>
                {isButtonOption && user?.role!=="SME" && <div>
                    <span
                        onClick={handleToggleModal}
                        id="createRequestButton"
                        className=" mx-4 mt-2 cm-add-bank-btn border border-blue-gray-300 bg-white text-blue-800 cm-sm-txt fw-medium cm-pointer "
                    >
                        <FontAwesomeIcon
                            icon={regular("plus")}
                            className="pe-2"
                        />
                        {buttonName}
                    </span>
                </div>}
            </div>
        </div></>
    )

}

Breadcrumb.defaultProps = {
    data: [],
    enableDateRange: false,
    backBtn: false
}

export default Breadcrumb;