import React from 'react'
import AceEditor from 'react-ace';
import * as ace from 'brace';
import 'brace/theme/monokai'
import 'brace/mode/javascript'
import 'brace/mode/java'
import 'brace/mode/python'
import Select from "react-select";
import 'brace/ext/language_tools';
import "ace-builds/src-noconflict/ext-language_tools"
import ReactResizeDetector from 'react-resize-detector';

export default function QuestionSettings({saveAnsDescription}) {
    var modeSet="javascript";
    const languages = [
        { value: "javascript", label: "javascript" },
        { value: "python", label: "python" },
        { value: "java", label: "java" },
        { value: "cpp", label: "cpp" }
    ];
    
  const [language,setLanguage]=React.useState([]);
  const [answerDesc,setAnswerDesc]=React.useState("");
  const [editorDimension, setEditorDimension] = React.useState({
    editorWidth: 'auto',
    editorHeight: 500
  })
  
  const handleSingleSelect=(selectedOption)=> {
      setLanguage(selectedOption)
    }

    const handleEditorChange=(newValue) =>
    {
        setAnswerDesc(newValue);
        
    }

    React.useEffect(() => {
        saveAnsDescription(answerDesc);
    }, [answerDesc]);


  return (
  <>
    <div className='p-4 ms-3'>
    <p className="headText" style={{'font-size':'16px'}}> Add Solution</p>
       <form className='ps-0'>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group mb-3 cm-lang-selector">
                        <label className='pb-2 labelText'>Select language</label>
                        <Select value={language} options={languages}  onChange={ handleSingleSelect}/>
                    </div>
                </div>
            </div>
        </form>
        <label className='pb-2 labelText'>Code Editor</label>
        <div className="resizable">
        <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => setEditorDimension({ editorWidth: w, editorHeight: h })} />
        <AceEditor mode={language.value} setOptions={{
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      //enableSnippets: true
    }} onLoad={(editorInstance) => {editorInstance.container.style.resize = "both";document.addEventListener("mouseup", () => editorInstance.resize()); }} 
    onChange={handleEditorChange}
    width={editorDimension.editorWidth}
    height={editorDimension.editorHeight}/>
             </div>
      
    </div>
    </>
  )
}
