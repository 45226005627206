import React from "react";
import UserInput from "./UserInput.container";
import SkillTaxanomyInfoGeneral from "./SkillTaxanomyInfoGeneral.container";
import SkillInfoTable from "./SkillInfoTable.contaniner";
import NothingToSee from "./NothingToSee.container";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, light } from "@fortawesome/fontawesome-svg-core/import.macro";
import { saveTaxonomyDashboardData } from "../../redux/actions/skillDashboard.action";
import { useDispatch, useSelector } from "react-redux";
import { getApiUrlSkill } from "../../utils/apiUrls";
import { addPad } from "../../utils/helperFn";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import axios from "axios";
import NetworkGraph from "../../components/charts/NetworkGraph.component";
import "./SkillDashboard.styles.scss";
import instance from '../../utils/axios';

const MainSkillManagement = () => {
  const dispatch = useDispatch();
  const currentSkillDashboardState = useSelector(
    (state) => state.skillDashboard
  );
  const [inputVal, setInputVal] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [toggleViewComponent, setToggleViewComponent] = React.useState("chart");
  const [aggregateDataInfo, setAggregateDataInfo] = React.useState([]);
  const [networkGraphData, setNetworkGraphData] = React.useState({});
  const [tableDataDTO, setTableDataDTO] = React.useState({});
  const [filterEntityRecordArray, setFilterEntityRecordArray] = React.useState(
    []
  );

  const handleNetworkFilter = (val) => {
    if (filterEntityRecordArray.includes(val))
      setFilterEntityRecordArray(
        filterEntityRecordArray.filter((el) => el !== val)
      );
    else {
      setFilterEntityRecordArray([...filterEntityRecordArray, val]);
    }
  };

  React.useEffect(() => {
    if (!!currentSkillDashboardState.skillDashBoardData?.inputVal && !loading) {
      fetchData(currentSkillDashboardState.skillDashBoardData?.inputVal);
    }
  }, []);
  const fetchData = async (data) => {
    try {
      setToggleViewComponent("table");
      const res = await instance.get(
        `${getApiUrlSkill("searchData")}?taxonomyName=` +
          data
            .replaceAll(" ", "%20")
            .replaceAll("+", "%2B")
            .replaceAll("/", "%2F")
            .replaceAll("?", "%3F")
            .replaceAll("%", "%25")
            .replaceAll("#", "%25")
            .replaceAll("&", "%26")
            .replaceAll("=", "%3D")
      );
      if (res.data.nodeType !== "No matched" && !!res.data.distinctSkills) {
        // master name
        setInputVal(data);

        // data for table
        setTableDataDTO(res.data.tableDataDTO);

        // Node Graph Data
        networkGraphHandler(res.data);

        // skill dashboard
        skillDashboardInfo(res.data.distinctSkills);

        // dispatch data
        dispatchHandler(data);

        setLoading(true);
      } else {
        dispatchHandler("");
        setLoading(false);
        dispatch(
          openSnackBar({
            msg: "Data not found",
            type: "error",
          })
        );
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        openSnackBar({
          msg: "Network error",
          type: "error",
        })
      );
    }
  };

  const dispatchHandler = (inputVal) => {
    var temp =
      inputVal.charAt(0).toUpperCase() + inputVal?.replace("_", " ")?.slice(1);
    var tempDispatch = {
      skillDashBoardData: {
        inputVal: temp,
        toggleViewComponent: "table",
      },
    };
    dispatch(saveTaxonomyDashboardData(tempDispatch));
  };

  const skillDashboardInfo = (data) => {
    var temp = {
      Job: 1,
      Skill: 1,
      Industry: 1,
      "Skill Type": 1,
      default: 1,
      "A-Skill": 1,
    };
    data.forEach((e) => {
      !!temp[e.nodeType] ? temp[e.nodeType]++ : temp.default++;
    });
    setAggregateDataInfo({
      totalJobs: addPad(temp.Job - 1),
      primarySkillCount: addPad(temp.Skill - 1),
      skillType: addPad(temp["Skill Type"] - 1),
      similarSkill: addPad(temp["A-Skill"] - 1),
      industry: addPad(temp.Industry - 1),
      others: addPad(temp.default - 1),
    });
  };

  const networkGraphHandler = (data) => {
    var node = [];
    var edge = [];
    var startId = [];
    data.nodeRelationObjectList.forEach((e) =>
      edge.push({
        from: e.fromNodeID,
        to: e.toNodeID,
        label: e.relation,
        length: e.relation.length > 16 ? 400 : 300,
        nodeType: e.fromNodeType,
      })
    );
    var tempSty = {
      Skill: "#2aa775",
      Industry: "#ffd265",
      Job: "#e8544e",
      "Skill Type": "#d44ee8",
      "A-Skill": "#0000FF",
    };
    data.distinctSkills.forEach((e) => {
      var tempLabel = e.name.split(" ");
      var wordToRemoveAfter = "&";
      var indexOfWord = tempLabel.indexOf(wordToRemoveAfter);
      if (indexOfWord !== -1) {
        var remainingWords = tempLabel.slice(0, indexOfWord);
        tempLabel = remainingWords.join(" ");
      } else {
        tempLabel = e.name;
      }
      node.push({
        id: e._id,
        name: e.name,
        label: tempLabel.replaceAll(" ", "\n"),
        title: e.name,
        color: tempSty[e.nodeType],
        nodeType: e.nodeType,
      });
    });
    startId = data.startIds;
    setNetworkGraphData({ edge, node, startId });
  };
  return (
    <div className="container-fluid bg-white p-0">
      <UserInput fetchData={fetchData} inputVal={inputVal} />
      <div>
        <div className="">
          {loading && toggleViewComponent === "chart" && (
            <SkillTaxanomyInfoGeneral
              aggregateDataInfo={aggregateDataInfo}
              handleNetworkFilter={handleNetworkFilter}
              filterEntityRecordArray={filterEntityRecordArray}
            />
          )}
        </div>
        {loading && (
          <div className="toggle-sty bg-white">
            <CustomToolTip title="Graph View">
              <div
                className={`cm-pointer toggle-sty_icon d-flex align-items-center justify-content-center ${
                  toggleViewComponent === "chart"
                    ? "bg-primary text-white"
                    : "bg-blue-gray-50"
                }`}
                onClick={() => setToggleViewComponent("chart")}
              >
                <FontAwesomeIcon icon={light("chart-network")} />
              </div>
            </CustomToolTip>
            <CustomToolTip title="Table View">
              <div
                className={`cm-pointer toggle-sty_icon d-flex align-items-center justify-content-center ${
                  toggleViewComponent !== "chart"
                    ? "bg-primary text-white"
                    : "bg-blue-gray-50"
                }`}
                onClick={() => setToggleViewComponent("graph")}
              >
                <FontAwesomeIcon icon={solid("table-cells")} />
              </div>
            </CustomToolTip>
          </div>
        )}
        <div className="mx-4 mt-2 pb-5">
          {loading && toggleViewComponent === "chart" ? (
            <>
              <NetworkGraph
                networkGraphData={networkGraphData}
                filterEntityRecordArray={filterEntityRecordArray}
                inputVal={inputVal}
              />
            </>
          ) : (
            loading && <SkillInfoTable tableDataDTO={tableDataDTO} />
          )}
          {!loading && <NothingToSee />}
        </div>
      </div>
    </div>
  );
};

export default MainSkillManagement;
