import React from 'react';
import { useCKEditor, CKEditorEventAction } from 'ckeditor4-react';
import { getApiUrl } from '../../utils/apiUrls';

const { useEffect, useState, useReducer, useMemo } = React;

const Editor = ({ dispatch, state, sharedSpacesConfig, placeholder, editorUpdateSource, id, type }) => {
    const [element, setElement] = useState();

    const { editor } = useCKEditor({
        element,
        dispatchEvent: dispatch,
        subscribeTo: ['focus', 'change'],
        initContent: state.data,
        editorUrl: '/ckeditor4/ckeditor.js',
        config: {
            sharedSpaces: sharedSpacesConfig,
            uploadImageUrl: getApiUrl('uploadFile') + '?type=image',
        },
        type : type ? type : 'classic'
    });

    /**
     * Invoking `editor.setData` too often might freeze the browser.
     */
    const setEditorData = useMemo(() => {
        if (editor) {
            return new window.CKEDITOR.tools.buffers.throttle(500, data => {
                if (editor) {
                    editor.setData(data);
                }
            }).input;
        }
    }, [editor]);

    /**
     * Sets editor data if it comes from a different source.
     */
    useEffect(() => {
        if ((state.currentEditor !== 'CKEditor' || editorUpdateSource === "CUSTOM") && setEditorData) {
            setEditorData(state.data);
        }
    }, [setEditorData, state]);

    return <div ref={setElement} id={id} />;
}


const reducer = (state, action) => {
    switch (action.type) {
        case 'textareaData': {
            return {
                ...state,
                data: action.payload
            };
        }
        case 'textareaFocus': {
            return {
                ...state,
                currentEditor: 'textarea'
            };
        }
        case CKEditorEventAction.change: {
            return {
                ...state,
                data:
                    state.currentEditor === 'CKEditor' ?
                        action.payload.editor.getData() :
                        state.data
            };
        }
        case CKEditorEventAction.focus: {
            return {
                ...state,
                currentEditor: 'CKEditor'
            };
        }
    }
}

const TextEditor = ({ data, handeTxtEditorChange, placeholder, id, sharedSpacesConfig, editorUpdateSource, type }) => {
    const [firstRun, setFirstRun] = React.useState(false);
    const [state, dispatch] = useReducer(reducer, {
        data,
        currentEditor: undefined,
    });

    React.useEffect(() => {
       if(firstRun) handeTxtEditorChange(state.data);
    }, [state]);

    React.useEffect(() => {
        if(firstRun)  dispatch({ type: 'textareaData', payload: data });
        if(!firstRun) setFirstRun(true);
    }, [data])
    
    return (
        <div>
            <div className="editor-instance">
                <Editor dispatch={dispatch} state={state} sharedSpacesConfig={sharedSpacesConfig} placeholder={placeholder} editorUpdateSource={editorUpdateSource} id={id} type={type} />
            </div>
        </div>
    );
}

export default TextEditor;