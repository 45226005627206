import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import './CustomTooltip.styles.css';

const CustomToolTip = (props) => {

    return(
        <Tooltip title={props.title} placement={props.placement} arrow>
            {props.children}
        </Tooltip>
    )

}

CustomToolTip.defaultProps = {
    title: "",
    placement: "bottom-start"
}

export default CustomToolTip;