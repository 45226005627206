import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './QuestionCreation.styles.css';

import TextEditor from '../../components/text-editor/TextEditor.component';
import { useDispatch } from 'react-redux';
import { addTestcase, removeTestcase } from '../../redux/actions/question.actions';
import CustomDialog from '../../components/custom-dialog/CustomDialog.component';
import CustomToolTip from '../../components/custom-tooltip/CustomTooltip.component';
import AnimationDataLoader from '../../components/Loaders/animationData';

// if(!/^\d+$/.test(e.target.value))
//            formVal[index].inputArr[innerIndex].msg= "Error: Provide the correct input format."
//         else

const testcaseTypes = [
    { value: 'Default Testcase', label: 'Default' },
    { value: 'Basic Testcase', label: 'Basic' },
    { value: 'Necessary Testcase', label: 'Necessary' },
    { value: 'Corner Testcase', label: 'Corner' },
    { value: 'Time Complexity Testcase', label: 'Time Complexity', size: [{ label: "Small", value: "Small" }, { label: "Medium", value: "Medium" }, { label: "Large", value: "Large" }] }
]

const intial_state_testcase = {
    testcaseType: {
        value: '',
        msg: null
    },
    testcaseName: {
        value: '',
        msg: null
    },
    testcaseDesc: {
        value: '',
        msg: null
    },
    weightage: 0,
    inputArr: [],
    outputArr: [],
}

const editorConfiguration = {
    toolbar: ['heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'sourceEditing',
        // 'wproofreader'
    ]
};

const AddTestCasesForm = ({ getTestCaseValue, questStepData }) => {
    const dispatch = useDispatch();
    const initial_state = questStepData.addTestcases.data;

    const [dialogStatus, setDialogStatus] = React.useState({
        status: false,
        testcaseId: null,
    });
    const [formVal, setFormVal] = React.useState(initial_state);

    React.useEffect(() => {
        setFormVal(questStepData.addTestcases.data)
    }, [questStepData]);

    const handleDialogToggle = (testcaseId) => {
        setDialogStatus({
            status: !dialogStatus.status,
            testcaseId: !!testcaseId ? testcaseId : null
        });
    }

    const dialogActionFn = () => {
        dispatch(removeTestcase(dialogStatus.testcaseId));
        setDialogStatus({
            status: false,
            testcaseId: null,
        })
    }

    const handleFieldChange = (e, fieldName, index) => {
        const currentFormVal = formVal;

        currentFormVal[index][fieldName].value = e.target.value;

        getTestCaseValue(currentFormVal);

        setFormVal(currentFormVal);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    // const handleSelectChange = (event, fieldName, arr, index) => {
    //     let val = {};

    //     const currentFormVal = formVal;

    //     val = arr[arr.findIndex(el => el.value === event.target.value)]
    //     //console.log('event', event.target.value);

    //     currentFormVal[index][fieldName].value = val;

    //     getTestCaseValue(currentFormVal);

    //     setFormVal(currentFormVal)
    // }

    const handleSelectChange = (event, fieldName, arr, index) => {

        let val = {};
        const currentFormVal = formVal;

        val = arr[arr.findIndex(el => el.value === event.target.value)]



        if (fieldName == "testcaseType") {
            currentFormVal[index]['testcaseName'].value = val.label + " Test Case ";
            currentFormVal[index][fieldName].value = val;
        }
        else if (fieldName == "size") {
            currentFormVal[index].size.value = val;
        } else {
            currentFormVal[index][fieldName].value = val;
        }

        getTestCaseValue(currentFormVal);
        setFormVal(currentFormVal);
    }


    const handleMultiSelectChange = (val, fieldName) => {
        getTestCaseValue({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })

        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: val
            }
        })
    }

    const handeTxtEditorChange = (val, index) => {
        const currentFormVal = formVal;

        currentFormVal[index].testcaseDesc.value = val;

        getTestCaseValue(currentFormVal)

        setFormVal(currentFormVal)
    }

    const handleIoFieldChange = (e, fieldName, index, innerIndex) => {
        const currentFormVal = formVal;
        currentFormVal[index][fieldName][innerIndex].value = e.target.value
        getTestCaseValue(currentFormVal);
        setFormVal(currentFormVal);
    }

    const loadTestcases = () => {
        //console.log('formVal', formVal);
        return formVal.map((el, index) => {
            return (
                <div className='cm-test-case-item pt-4' key={index}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<FontAwesomeIcon icon={regular('plus')} className="text-blue-800" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            className='bg-gray-100'
                        >
                            <p className='my-0'>Testcase {index + 1}</p>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="row pt-4">
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor="questionTag" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium d-block'>Testcase Type</label>
                                        <div className="position-relative">
                                            <Select
                                                labelId="testcaseTypeLabel"
                                                id="testcaseType"
                                                value={formVal[index].testcaseType.value.value}
                                                onChange={(e) => handleSelectChange(e, 'testcaseType', testcaseTypes, index)}
                                                fullWidth={true}
                                                className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                            >
                                                {
                                                    testcaseTypes.map(el => <MenuItem value={el.value} key={el.value} className=" fw-medium cm-sm-txt text-blue-800">{el.label}</MenuItem>)
                                                }
                                            </Select>
                                            <FontAwesomeIcon icon={regular("chevron-down")} className="cm-select-arrow text-blue-800 position-absolute" />
                                        </div>
                                        {formVal[index].testcaseType.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index].testcaseType.msg}</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mb-3">
                                        <label htmlFor={`testcaseName-${index}`} className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Testcase Name</label>
                                        <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id={`testcaseName-${index}`} value={formVal[index].testcaseName.value} onChange={(e) => handleFieldChange(e, 'testcaseName', index)} />
                                        {formVal[index].testcaseName.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index].testcaseName.msg}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="testcaseDesc" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Testcase Description</label>
                                <TextEditor data={formVal[index].testcaseDesc.value} id={`testCaseDesc-${index}`} handeTxtEditorChange={() => {}}//handeTxtEditorChange={(val) => handeTxtEditorChange(val, index)} 
                                />
                                {formVal[index].testcaseDesc.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index].testcaseDesc.msg}</span>}
                            </div>

                            <div className="row">
                                <div className="col">
                                    {formVal[index].testcaseType.value.value !== '' &&
                                        <>{formVal[index].testcaseType.value.value !== "Default Testcase" && <div className="form-group mb-3">
                                            <label htmlFor="weightage" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Testcase Weightage</label>
                                            <input type="number" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id="functionName" value={formVal[index].weightage.value} onChange={(e) => handleFieldChange(e, 'weightage', index)} />
                                            {formVal[index].weightage.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index].weightage.msg}</span>}
                                        </div>}</>}
                                </div>
                                
                                <div className="col">
                                    {formVal[index].testcaseType.value.value === 'Time Complexity Testcase' &&

                                        <div className="form-group mb-3">
                                            <label htmlFor="weightage" className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Testcase Size</label>
                                            <Select
                                                labelId="testcaseTypeLabel"
                                                id="testcaseType"
                                                value={formVal[index]?.size?.value.value}
                                                onChange={(e) => handleSelectChange(e, 'size', testcaseTypes[4].size, index)}
                                                fullWidth={true}
                                                className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field"
                                            >
                                                {
                                                    testcaseTypes[4].size.map(el => <MenuItem value={el.value} key={el.value} className=" fw-medium cm-sm-txt text-blue-800">{el.label}</MenuItem>)
                                                }
                                            </Select>
                                            {formVal[index]?.size?.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index]?.size?.msg}</span>}

                                        </div>

                                    }
                                </div>
                            </div>

                            <div className="row pt-2">
                                {
                                    el?.inputArr.map((innerEl, innerInd, arr) => {
                                        return (
                                            <div key={innerInd} className="col-lg-6">
                                                <div className="form-group mb-3 position-relative cm-helper-icon" key={'inp-' + index + '-' + innerInd}>
                                                    <label htmlFor={`testcase-${index}-inp-${innerInd}`} className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Input{innerInd + 1} Value</label>
                                                    <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id={`testcase-${index}-inp-${innerInd}`} value={formVal[index].inputArr[innerInd].value} placeholder={`Input Type: ${questStepData.questDetails.addInputs.value[innerInd]?.label + " " + questStepData.questDetails.addInputs.value[innerInd]?.placeholder}`} onChange={(e) => handleIoFieldChange(e, 'inputArr', index, innerInd)} />
                                                    <CustomToolTip title={`Input Type: ${questStepData.questDetails.addInputs.value[innerInd]?.label + " " + questStepData.questDetails.addInputs.value[innerInd]?.placeholder}`}>
                                                        <FontAwesomeIcon icon={regular("circle-question")} className="text-blue-gray-700 position-absolute cm-test-case-helper ps-2" />
                                                    </CustomToolTip>
                                                    {formVal[index].inputArr[innerInd].msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index].inputArr[innerInd].msg}</span>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {
                                    el?.outputArr.map((innerEl, innerInd) => (
                                        <div key={innerInd} className="col-lg-6">
                                            <div className="form-group mb-3 position-relative cm-helper-icon" key={'out-' + index + '-' + innerInd}>
                                                <label htmlFor={`testcase-${index}-out-${innerInd}`} className='pb-2 text-blue-gray-700 cm-xs-txt pb-2 fw-medium'>Output{innerInd + 1} Value</label>
                                                <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt fw-medium" id={`testcase-${index}-out-${innerInd}`} placeholder={`Output Type: ${questStepData.questDetails.addOutputs.value[innerInd]?.label + " " + questStepData.questDetails.addOutputs.value[innerInd]?.placeholder}`} value={formVal[index].outputArr[innerInd].value} onChange={(e) => handleIoFieldChange(e, 'outputArr', index, innerInd)} />
                                                <CustomToolTip title={`Output Type: ${questStepData.questDetails.addOutputs.value[innerInd]?.label + questStepData.questDetails.addOutputs.value[innerInd]?.placeholder}`}>
                                                    <FontAwesomeIcon icon={regular("circle-question")} className="ms-2 text-blue-gray-700 position-absolute cm-test-case-helper" />
                                                </CustomToolTip>
                                                {formVal[index].outputArr[innerInd].msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal[index].outputArr[innerInd].msg}</span>}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>

                            {index > 0 && <div className="cm-test-case-foote d-flex align-items-center justify-content-end mt-2">
                                <span className='cm-btn bg-white border border-danger text-danger cm-sm-txt fw-medium cm-pointer' onClick={() => handleDeleteTestCase(el.id)}><FontAwesomeIcon icon={regular("trash")} className="pe-2" /> Delete Testcase</span>
                            </div>}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )
        })
    }

    const handleAddTestCases = () => {
        const init_obj = {
            id: Date.now(),
            testcaseType: {
                value: { value: '', label: '' },
                msg: null
            },
            testcaseName: {
                value: '',
                msg: null
            },
            testcaseDesc: {
                value: '',
                msg: null
            },
            inputArr: formVal[0].inputArr.map(el => ({ value: '', msg: null })),
            outputArr: formVal[0].outputArr.map(el => ({ value: '', msg: null })),
            weightage:
            {
                value: 0,
                msg: null
            },
            size: {
                value: { value: '', label: '' },
                msg: null
            },
        };

        dispatch(addTestcase(init_obj));
    }

    const handleDeleteTestCase = (testCaseId) => {
        handleDialogToggle(testCaseId)
    }

    //console.log('formval', formVal);

    return (
        <div className='cm-event-library-content-wrapper bg-blue-gray-50 mb-5'>
            {
                !!formVal ?
                    <form className="cm-event-details-form cm-login-form mx-5" onSubmit={handleFormSubmit}>
                        {loadTestcases()}

                        <div className="pt-5 cm-add-test-cases-wrap text-center">
                            <span className='cm-add-test-cases-btn cm-btn bg-action-blue text-white d-inline-block cm-pointer' onClick={handleAddTestCases}>Add Testcase</span>
                        </div>

                        <CustomDialog
                            dialogHeading="Remove Testcase"
                            cancelBtnLabel="No, Don't Delete"
                            actionBtnLabel={<span>Delete Testcase</span>}
                            actionBtnBg="bg-danger"
                            cancelFn={handleDialogToggle}
                            actionFn={dialogActionFn}
                            dialogStatus={dialogStatus.status}
                        >
                            <p className='my-0 cm-sm-txt text-blue-800'>Are you sure you want to remove the selected test case? All the data defined in the test case, will be removed as well.</p>
                            <p className='my-0 cm-sm-txt text-danger'>Do not forget to click on 'Save and Continue' for changes to apply.</p>
                        </CustomDialog>

                    </form>
                    :
                    <div className='p-4 d-flex align-items-center justify-content-center'>
                        <AnimationDataLoader />
                    </div>
            }
        </div>
    )

}

export default AddTestCasesForm;