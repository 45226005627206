import { SETROWS,DISSELECTALL,DISETROWS } from "../actions/actions.types";

const INITIAL_STATE = [];

const checkboxReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case SETROWS:
            return [...state, ...(action.payload.filter((id)=>!state.includes(id)))];
            
        case DISETROWS:
            return state.filter(selectedRows => selectedRows !== action.payload);


        case DISSELECTALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default checkboxReducer;