import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';

const AssignBankForm = ({ t, modalData, handleBankModalToggle }) => {
    const dispatch = useDispatch();
    const [allUsers, setAllUsers] = React.useState([])
    const [assignBank, setAssignBank] = React.useState({
        assignedUsers: [],
        canComment: false,
        canViewComment: false,
        canViewCommenterDetails: false,
        canViewSolution: false,
        canDownloadQuestion: false,
        canEditQuestion: false,
        canDeleteQuestion: false
    });

    const permisionsArr = React.useMemo(() => [{ value: 'canComment', label: 'Allow Comment' }, { value: 'canViewComment', label: 'View Other Comments' }, { value: 'canViewCommenterDetails', label: 'View Other Commentor Name' }, { value: 'canViewSolution', label: 'View Solutions' }, { value: 'canDownloadQuestion', label: 'Download Question Bank' }, { value: 'canEditQuestion', label: 'Edit Questions' }, { value: 'canDeleteQuestion', label: 'Delete Questions' },])

    React.useEffect(() => {
        fetchSmeUsers()
    }, []);

    const fetchSmeUsers = async() => {
        try {
            const response = await instance.get(`${getApiUrl('getAllUsers')}?role=SME`);
            const aUsers = [];
            
            if (response.data) {
                for (let i = 0; i < response.data.length; i++) {
                    aUsers.push({ label: response.data[i].firstName + " " + response.data[i].lastName, value: response.data[i].userId, emailId: response.data[i].emailId })
                }
                // setAllUsers([...aUsers, {label: 'Asdmin cms', value: 3, emailId: 'asassme@mettlcms.com'}]);
                setAllUsers(aUsers);
            }
        }
        catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleChange = (key, value) => {
        let dataToSet = value;

        if(key === "assignedUsers") {
            dataToSet = typeof value === 'string' ? value.split(',') : value;
        }

        setAssignBank({
            ...assignBank,
            [key]: dataToSet
        })
    }

    const removeAssignedUser = (e, value) => {
        setAssignBank({
            ...assignBank,
            assignedUsers: assignBank.assignedUsers.filter(el => el.value !== value)
        })
    }

    const validateAssignBankForm = () => {
        let errorTxt = null;

        if(assignBank.assignedUsers.length === 0) {
            errorTxt = 'Please choose users to assign from the list.';
        }

        if(errorTxt) {
            dispatch(openSnackBar({ type: 'error', msg: errorTxt }))
        }

        return !!errorTxt;
    }

    const handleAssignSubmit = async () => {
        try {
            if(!validateAssignBankForm()) {
                let reviewBankPermissionDTO = {}

                for(let key in assignBank) {
                    if(key !== 'assignedUsers') {
                        reviewBankPermissionDTO[key] = assignBank[key]
                    }
                }

                const dataToSend = {
                    assignedTo: assignBank.assignedUsers.map(el => el.value),
                    reviewBankPermissionDTO
                }
               
                const res = await instance.post(`${getApiUrl('assignBank')}?bankId=${modalData.reviewBankId}`, dataToSend);

                if(res.data?.status) {
                    dispatch(openSnackBar({
                        msg: res.data?.message,
                        type: 'success'
                    }))
                    handleBankModalToggle();
                }
            }
        } catch (error) {
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    return(
        <>
            <div className="mt-3 cm-create-task-form px-4 pb-4">
                <div>
                <label className='cm-sm-txt text-blue-gray-700 mb-2 fw-medium'>Assign To:</label>
                <Select
                    multiple
                    value={assignBank.assignedUsers}
                    onChange={(e) => handleChange('assignedUsers', e.target.value)}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((el) => (
                                <Chip clickable deleteIcon={
                                    <FontAwesomeIcon icon={regular("times-circle")} onMouseDown={(event) => event.stopPropagation()} />
                                  } onDelete={(e) => removeAssignedUser(e, el.value)} key={el.value} label={<><span>{el.label} {`<${el.emailId}>`}</span></>} />
                                ))}
                            </Box>
                            )
                    }}
                    MenuProps={{
                        className: "cm-select-modal cm-select-inside-modal",
                    }}
                    autoFocus={false}
                >
                    {allUsers.map((el) => {
                        const isAdded = assignBank.assignedUsers.some(innerEl => innerEl.value === el.value);
                        return (
                        <MenuItem
                            key={el.value}
                            value={el}
                            disabled={isAdded}
                            className="cm-sm-txt fw-medium"
                        >
                            {`${el.label}<${el.emailId}>`}
                        </MenuItem>
                    )})}
                </Select>
                </div>

                <h5 className='cm-mmc-font my-3 text-action-blue'>Permissions</h5>
                {
                    permisionsArr.map(el => <div className='mb-2 d-flex align-items-center user-select-none'>
                        <input type="checkbox" name={el.value} id={el.value} checked={assignBank[el.value]} onChange={() => handleChange(el.value, !assignBank[el.value])} />
                        <label htmlFor={el.value} className='ps-2 cm-sm-txt fw-medium'>{el.label}</label>
                    </div>)
                }
            </div>
            <div className="cm-form-btn-group py-2  cm-create-modal-form-footer mt-2  d-flex align-items-center justify-content-end">
                <button onClick={handleAssignSubmit} className={`cm-icon-btn position-relative btn btn-primary bg-action-blue cm-sm-txt cm-btn border border-action-blue cm-prim-btn d-flex align-items-center`}>
                    <div>
                        <span className="text-white">Assign</span>
                        <FontAwesomeIcon icon={regular('angle-right')} className="ps-2" />
                    </div >
                </button>
            </div>
        </>
    )

}

export default AssignBankForm;