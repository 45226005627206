import React from 'react';
import authBg from '../../assets/images/auth/BG.jpg';
import footerLogo from '../../assets/images/auth/mercer-mettl-footer_logo.svg';
import { useTranslation } from 'react-i18next';
import SetPasswordForm from '../../components/auth/SetPasswordForm';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MetaComp from '../../components/meta-comp/MetaComp.component';

const SetPasswordPage = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const getDecryptId = searchParams.get('id');

    React.useEffect(() => {
        if(!getDecryptId) navigate('/');
    })

    return(
        <main className='cm-page-container cm-auth-page-container d-flex row mx-0'>
            <MetaComp title="Set Password - Mercer | Mettl"/>
            <div className='col-sm-12 col-md-6 cm-md-12 cm-auth-content-wrap cm-signup-content-wrap d-flex align-items-center'>
                <div className='cm-auth-content-inner col-12'>
                    <div className="cm-txt-wrap">
                        <h2 className='h2 text-blue-gray-700 cm-fs-24 cm-mmc-font'>{t("reset_password")} <span className='d-block h1 text-primary fw-bold cm-fs-40'>{t("setup_new_password")}</span></h2>
                        <h4 className='h4 text-blue-800'>{t("provide_new_password")}</h4>
                    </div>

                    <hr className='cm-auth-hr bg-blue-gray-300' />

                    <SetPasswordForm t={t} decryptId={getDecryptId} />

                    <div className="cm-auth-footer align-items-center d-none d-lg-flex d-md-flex">
                        <p className='my-0 pe-3 fw-medium'>{t("powered_by")}: </p>
                        <img src={footerLogo} alt="Mercer Mettl" className='d-block' />
                    </div>
                </div>
            </div>
            <div className='col-sm-6 cm-auth-bg-container py-8 position-relative d-none d-lg-block d-md-block w-md-50' style={{ backgroundImage: `url('${authBg}')` }} />
        </main>
    )

}

export default SetPasswordPage;