import { APP_ERROR } from "../actions/actions.types";

const INITIAL_STATE = {
    location: null,
    err: null
}

const errorReducer = (state=INITIAL_STATE, action) => {
    switch(action.type) {
        case APP_ERROR:
            return {
                location: action.payload.location,
                err: action.payload.err
            }
        default:
            return INITIAL_STATE
    }
}

export default errorReducer;