import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AceEditor from 'react-ace';
import * as ace from 'brace';
import 'brace/theme/monokai';
import 'brace/mode/javascript';
import 'brace/mode/java';
import 'brace/mode/c_cpp';
import 'brace/mode/python';
import 'brace/ext/language_tools';
import "ace-builds/src-noconflict/ext-language_tools";
import '../question-creation/QuestionCreation.styles.css';
import { useParams } from 'react-router-dom';
import instance from '../../utils/axios';
import './ReviewSolution.styles.css'
import { apiErrorHandler } from '../../utils/errorHandler';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import ReactResizeDetector from 'react-resize-detector';

const editorModes = {
  c: 'c_cpp',
  cpp: 'c_cpp',
  java: 'java',
  javascript: 'javascript',
  python: 'python'
}
export default function ReviewSolutionContainer({questData}) {
  var modeSet = "javascript";
  const { questionId } = useParams();
  const languages = [
      { value: "javascript", label: "javascript" },
      { value: "python", label: "python" },
      { value: "java", label: "java" },
      { value: "cpp", label: "cpp" }
  ];
  const [language, setLanguage] = React.useState([]);
  const [languageCode, setLanguageCode] = React.useState([]);
  const [answerDesc, setAnswerDesc] = React.useState("");
  const [editorDimension, setEditorDimension] = React.useState({
    editorWidth: 'auto',
    editorHeight: 500   
    })
  const {t}=useTranslation();
  const dispatch=useDispatch();
  const handleSingleSelect = (selectedOption) => {
      setLanguageCode(selectedOption)
  }

  const handleEditorChange = (newValue) => {
      setAnswerDesc(newValue);
  }

  React.useEffect(() => {
        getPreviousData();
}, [])

  const getPreviousData = () => {
    instance.get(`${getApiUrl('getCodelysisQuestionData')}/${questionId}`)
        .then(function (response) {
            if (response.data) {
              const codeLang=[];
                 for(var i=0;i<response.data.codeLanguages.length;i++)
                 {
                   codeLang.push({label:response.data.codeLanguages[i], value:response.data.codeLanguages[i]})
                 }
                setLanguage(codeLang);
                // console.log("response", response.data)
                // let atTag = [];
                // allTags.forEach((item, index) => {
                //     for (var i = 0; i < response.data.tagIds.length; i++) {
                //         console.log(response.data.tagIds[i]);
                //         console.log(item.id);
                //         if (response.data.tagIds[i] === item.id)

                //             atTag.push({ label: item.label, value: item.value, id: item.id });
                //     }
                // })
                // //setAllTags(atTag);
                // console.log(atTag);
                // setTagNames(atTag);
            }
        })
        .catch(function (error) {
            console.log(error);
            const errObj = apiErrorHandler(error);
            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        });
}

  return (
    <div className='p-4 ms-1' >
                <p className="headText" style={{ 'font-size': '20px' }}>Run Code</p>
                <form className='ps-0'>
                    <div className="row">
                        <div className="col-lg-6" style={{'width':'100%', 'min-width':'100%','max-width':'120%'}}>
                            <div className="form-group mb-3 cm-lang-selector">
                                <label className='pb-2 labelText'>Choose language for adding solution</label>
                                <div className="d-flex">
                            <div className="col-sm-10">
                            <Select
                                    labelId="avLanguagesLabel"
                                    id="avLanguages"
                                    value={languageCode.value}
                                    onChange={(e) => handleSingleSelect(e)}
                                    fullWidth={true}
                                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field">
                                    {
                                        language.map(el => <MenuItem value={el.value} key={el.value} className=" fw-medium cm-sm-txt text-blue-800">{el.label}</MenuItem>)
                                    }
                                </Select>
                            </div>
                            <span className={`ms-2 cm-sidebar-save-btn bg-action-blue text-white d-flex align-items-center justify-content-center cm-pointer`}>
                                <span className='fw-medium cm-sm-txt p-2'>Compile and Run</span>
                            </span>
                        </div>
                            </div>
                        </div>
                    </div>
                </form>
                <label className='pb-2 labelText'>Code Editor</label>
                <div style={{
                    overflow: "auto",
                    resize: "both",
                    maxheight:"150vw"
                }}  >
                     <div className="resizable">
                    <ReactResizeDetector handleWidth handleHeight onResize={(w, h) => setEditorDimension({ editorWidth: w, editorHeight: h })} />
                    <AceEditor mode={language.value} id="codeEditor" setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        //enableSnippets: true
                    }}  
                    width={editorDimension.editorWidth}
                    height={editorDimension.editorHeight}
                    onChange={handleEditorChange} />
                    </div>
                </div>
            </div>
  )
}

