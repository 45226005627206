import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MenuItem, Modal, Select, Tab, Tabs } from "@mui/material";
import React from "react";
import LangResultItem from "../../components/lang-result-item/LangResultItem.component";
import LangTextEditor from "../../components/text-editor/LangTextEditor.component";
import { getApiUrl } from "../../utils/apiUrls";
import instance from "../../utils/axios";
import AnimationDataLoader from "../../components/Loaders/animationData";
import CustomButtonLoader from "../../components/global/CustomButtonLoader.component";

export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, "g"), replace);
};

function ReplaceAt(input, search, replace, start, end) {
  return (
    input.slice(0, start) +
    input.slice(start, end).replace(search, replace) +
    input.slice(end)
  );
}

var decodeEntities = (function () {
  // this prevents any overhead from creating the object each time
  var element = document.createElement("div");

  function decodeHTMLEntities(str) {
    if (str && typeof str === "string") {
      //strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return decodeHTMLEntities;
})();

const languages = [
  { value: "en-UK", label: "English" },
  // {value: 'es', label: 'Spanish'},
];

const LanguageToolModal = ({ modalStatus, handleModalToggle, questId, t }) => {
  const [tabID, setTabID] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ignore, setIgnore] = React.useState([]);
  const [formVal, setFormVal] = React.useState({
    questionText: {
      msg: null,
      value: "",
    },
    selLang: {
      value: languages[0],
      msg: null,
    },
    exampleDescription: [],
    chkStatus: false,
    editorData: "",
    restData: null,
    requiredFields: ["questionText", "selLang"],
  });

  const [result, setResults] = React.useState([]);

  React.useEffect(() => {
    questId !== null && modalStatus && getQuestText();
  }, [modalStatus]);

  const getQuestText = async () => {
    try {
      const res = await instance.get(
        `${getApiUrl("getCodelysisQuestionData")}/${questId}`
      );

      if (res.data) {
        let exampleDescriptionArray = [];
        let i = 0;
        res.data.testCasesDTO.forEach((testCase) => {
          if (testCase.type === "Default Testcase") {
            exampleDescriptionArray.push({
              key: i,
              value: testCase.description,
            });
            i += 1;
          }
        });
        setFormVal({
          ...formVal,
          questionText: {
            ...formVal.questionText,
            value: res.data.questionText,
          },
          exampleDescription: exampleDescriptionArray,
          editorData: res.data.questionText,
          restData: res.data,
        });
      }
    } catch (error) {
      console.log("getQuestText err", error);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateEditorData();
    evaluateLanguage();
  };

  const handleLangValidation = () => {
    let dataObj = formVal;
    let req_msg = "Error: Please provide an input";

    const txtFields = ["questionText"];
    txtFields.forEach(
      (el) => (dataObj[el].msg = dataObj[el].value === "" ? req_msg : null)
    );

    dataObj.selLang.msg = !!dataObj.selLang.value?.value ? null : req_msg;

    let isValid = true;


    setFormVal({
      ...formVal,
      ...dataObj,
    });

    for (const key of Object.keys(dataObj)) {
      if (
        ["questionText", "selLang"].includes(key) &&
        dataObj[key].msg !== null
      ) {
        isValid = false;
        return isValid;
      }
    }

    return isValid;
  };

  const evaluateLanguage = async () => {
    try {
      const isFormValid = handleLangValidation();

      if (!isFormValid) {
        return;
      }

      let questText = replaceAll(formVal.editorData, "<span[^>.]*>", "");
      questText = replaceAll(questText, "</span>", "");
      questText = replaceAll(questText, '&nbsp;', ' ');
      setIsLoading(true);
      const res = await instance.post(getApiUrl("languageEvaluator"), {
        data: questText,
        language: formVal.selLang.value.value,
        ignoreWords: ignore,
      });

      if (res.data) {
        setResults(res.data.reverse());

        let showErrTxt = questText;

        res.data.forEach((el, ind, arr) => {
          showErrTxt = ReplaceAt(
            showErrTxt,
            el.errorInText,
            `<span data-pos="${el.errorStart},${el.errorEnd}">${el.errorInText}</span>`,
            el.errorStart,
            el.errorEnd
          );
        });
        setIsLoading(false);

        setFormVal({
          ...formVal,
          editorData: showErrTxt,
          chkStatus: res.data.length === 0,
        });
      }
    } catch (error) {
      console.log("evaluateLanguage err", error);
    }
  };


  const handelTxtEditorChange = (newEditorData) => {
    setFormVal({
      ...formVal,
      editorData: newEditorData,
    });
  };

  const handleSelectChange = (e) => {
    const langEl = languages.filter((el) => el.value === e.target.value);

    setFormVal({
      ...formVal,
      selLang: {
        ...formVal.selLang,
        value: langEl[0],
      },
    });
  };

  const handleModalClose = () => {
    setFormVal({
      questionText: {
        msg: null,
        value: "",
      },
      selLang: {
        value: languages[0],
        msg: null,
      },
      exampleDescription: [],
      editorData: "",
      chkStatus: false,
      restData: null,
    });
    setTabID(1);
    setResults([]);
    setIgnore([]);
    handleModalToggle();
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const dataToSend = formVal.restData;
      dataToSend.questionText = formVal.questionText.value
        .replaceAll("<br>", "<br />")
        .replaceAll('<figure class="table">', "")
        .replaceAll("</figure>", "")
        .replace(
          "<table>",
          '<table border="1" cellpadding="1" cellspacing="1" style=\'width:98%;border-collapse:collapse;text-align:center\'>'
        )
        .replaceAll("<td>", "<td style='border:1px solid black'>");
      let i = 0;
      let j = 0;
      dataToSend.testCasesDTO.forEach((testCase) => {
        if (testCase.type === "Default Testcase") {
          dataToSend.testCasesDTO[j].description = formVal.exampleDescription[
            i
          ].value
            .replaceAll("<br>", "<br />")
            .replaceAll('<figure class="table">', "")
            .replaceAll("</figure>", "")
            .replace(
              "<table>",
              '<table border="1" cellpadding="1" cellspacing="1" style=\'width:98%;border-collapse:collapse;text-align:center\'>'
            )
            .replaceAll("<td>", "<td style='border:1px solid black'>");
          i += 1;
        }
        j++;
      });
      setIsLoading(true);
      const res = await instance.post(
        `${getApiUrl("updateCodelysisQuestion")}`,
        dataToSend
      );

      if (res.data.status) {
        handleModalClose();
      }
      setIsLoading(false)
    } catch (error) {
      console.log("handleSave err", error);
    }
  };

  const handleCorrection = (data) => {
    if (!!data?.wordSelected) {
      const correctedTxt = replaceAll(
        formVal.editorData,
        `<span data-pos="${data.errorStart},${data.errorEnd}">${data.errorInText}</span>`,
        data?.wordSelected
      );
      const filterResult = result.filter(
        (el) =>
          el.errorInText !== data.errorInText &&
          el.errorStart !== data.errorStart
      );
      setResults(filterResult);
      if (tabID === 1) {
        let questionTextData = formVal.questionText;
        questionTextData.value = correctedTxt;
        setFormVal({
          ...formVal,
          questionText: questionTextData,
          editorData: correctedTxt,
          chkStatus: filterResult.length === 0,
        });
      } else {
        let newDescription = formVal.exampleDescription;
        newDescription[tabID - 2].value = correctedTxt;
        setFormVal({
          ...formVal,
          exampleDescription: newDescription,
          editorData: correctedTxt,
          chkStatus: filterResult.length === 0,
        });
      }
    }
  };

  const updateEditorData = () => {
    if (tabID === 1) {
      let questionTextData = formVal.questionText;
      questionTextData.value = formVal.editorData;
      setFormVal({
        ...formVal,
        questionText: questionTextData
      });
    }
    else {
      let newDescription = formVal.exampleDescription;
      newDescription[tabID - 2].value = formVal.editorData;
      setFormVal({
        ...formVal,
        exampleDescription: newDescription
      });
    }
  }

  const handleTabChange = (event, newTabId) => {
    updateEditorData();
    setTabID(newTabId);

    setFormVal({
      ...formVal,
      editorData:
        newTabId == 1
          ? formVal.questionText.value
          : formVal.exampleDescription[newTabId - 2].value,
      chkStatus: false,
    });
    setIgnore([]);
    setResults([]);
  };

  const handleIgnoreWords = (ignoreWord) => {
    const resultElIndex = result.findIndex(el => el.errorInText === ignoreWord);
    const ignoreData = result[resultElIndex];
    ignoreData.wordSelected = ignoreWord;
    handleCorrection(ignoreData);
    resultElIndex !== -1 && result.splice(resultElIndex, 1)
    setIgnore([...ignore, ignoreWord]);
  }

  return (
    <div className="p-0">
      <Modal open={modalStatus} onClose={handleModalClose} disableAutoFocus={true}>
        <div className="cm-create-task-form-container cm-add-tag-form-container bg-white my-3 cm-lang-modal-container" style={{ maxWidth: '100%' }}>
          <div className="cm-create-task-form-header cm-lang-modal-header py-3 px-4 d-flex align-items-center justify-content-between">
            <h4 className="text-blue-800 my-0">Check your language</h4>
            <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalClose} />
          </div>
          <form onSubmit={handleFormSubmit} className="cm-create-task-form px-4 pb-4">
            <div className="row">
              <div className="pb-4">
                <div className="cm-lang-sel col-sm-4 position-relative">
                  <Select
                    labelId="avLanguagesLabel"
                    id="avLanguages"
                    displayEmpty
                    value={formVal.selLang.value.label}
                    onChange={(e) => handleSelectChange(e, "selLang")}
                    fullWidth={true}
                    renderValue={(val) => {
                      if (val === "") {
                        return (
                          <span className="text-blue-gray-400">
                            Select Language
                          </span>
                        );
                      }

                      return val;
                    }}
                    className="bg-white cm-sm-txt fw-medium text-blue-800 cm-select-field">
                    {languages.map((el) => (
                      <MenuItem
                        value={el.label}
                        key={el.value}
                        className=" fw-medium cm-sm-txt text-blue-800"
                      >
                        {el.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FontAwesomeIcon
                    icon={solid("angle-down")}
                    className="cm-sel-arrow-icon text-blue-gray-700 position-absolute"
                  />
                </div>
                {formVal.selLang.msg !== null && (
                  <span className="cm-xs-txt text-danger fw-medium pt-2">
                    {formVal.selLang.msg}
                  </span>
                )}
              </div>
              <Tabs value={tabID} variant="scrollable" className="border-bottom" onChange={handleTabChange}>
                <Tab value={1} label="question Text" className="border rounded-top" key={1}></Tab>
                {formVal.exampleDescription &&
                  formVal.exampleDescription.map((description) => (
                    <Tab className="border rounded-top" value={description.key + 2} label={`Default Test Case ${description.key + 1}`} key={description.key + 1}></Tab>
                  ))}
              </Tabs>
              <div className="col-sm-8">
                <div className="mt-3 form-group mb-3">
                  <LangTextEditor
                    data={formVal.editorData}
                    handelTxtEditorChange={handelTxtEditorChange}
                    result={result}
                    handleCorrection={handleCorrection}
                    newTabID={tabID}
                  />
                  {formVal.questionText.msg !== null && (
                    <span className="cm-xs-txt text-danger fw-medium pt-2">
                      {formVal.questionText.msg}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-4 mt-3">
                <div className="cm-result-table d-flex flex-column">
                  <div className="cm-result-table-header bg-blue-gray-50 py-2 px-2">
                    <p className="my-0 fw-medium d-flex flex-wrap align-items-center">
                      Errors & Suggestions{" "}
                      {result.length > 0 && (
                        <span className="ms-2 cm-err-count bg-danger text-white cm-xs-txt d-flex align-items-center d-inline-block justify-content-center shadow-sm">
                          {result.length}
                        </span>
                      )}
                    </p>
                  </div>
                  {!isLoading ? (
                    <>
                      <div className={`cm-result-table-content py-2 px-2 ${!!formVal.chkStatus ? "d-flex justify-content-center align-items-center" : ""}`}>
                        {!!formVal.chkStatus ? (
                          <div className="cm-sucess-lang-wrapper text-center">
                            <FontAwesomeIcon icon={regular("badge-check")} className="text-success" style={{ "fontSize": "50" }} />
                            <p className="mt-3 my-0 text-blue-800 fw-medium cm-sm-txt">
                              The content is error-free!
                            </p>
                          </div>
                        ) : (
                          result.map((el, ind) => (
                            <LangResultItem data={el} key={ind} handleCorrection={handleCorrection} handleIgnoreWords={handleIgnoreWords} t={t} />
                          ))
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <AnimationDataLoader />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
              <CustomButtonLoader
                  showLoadingState ={isLoading}
                  colorTheme= "blue"
                  icon={regular('angle-right')}
                  reverseIconDirection={false}
                  buttonLabel={t("check_now")}
                  buttonStyle={`px-12 py-10 cm-mar-left-icon`}
                  handleOnClick={handleFormSubmit}
              />
              <CustomButtonLoader
                  showLoadingState ={isLoading}
                  colorTheme= "blue"
                  icon={regular('angle-right')}
                  reverseIconDirection={false}
                  buttonLabel={t("save")}
                  buttonStyle={`px-12 py-10 cm-mar-left-icon`}
                  handleOnClick={handleSave}
              />
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default LanguageToolModal;
