import React from 'react'
import './create-question.styles.css';
import Select from "react-select";
import TextEditor from '../../components/text-editor/TextEditor.component';

const initial = [
    { 
        type:"",
        name:"",
        description:"",
        inputValues:[],
        outputValues:[]
    }
]

export default function AddTestcases({totalInputs,totalOutputs,saveAllTestCasesDetails}) {
    const [allTestcases,setAllTestCases]=React.useState(initial);
    var ind=0;
    const testcaseTypes = [{ value: 'Default Testcase', label: 'Default Testcase' ,value:1},
    { value: 'Basic Testcase', label: 'Basic Testcase', value:1},
    { value: 'Necessary Testcase', label: 'Necessary Testcase',value:1 },
    { value: 'Corner Testcase', label: 'Corner Testcase',value:1 },
    { value: 'Time Complexity Testcase', label: 'Time Complexity Testcase',value:1 }
    ]

    const editorConfiguration = {
        toolbar: ['heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'mediaEmbed',
            'undo',
            'redo',
            'sourceEditing',
            // 'wproofreader'
        ]
    };

    const handleSingleSelect = (val,i, fieldName) => {
        const allTestcasesArr = allTestcases;
       allTestcasesArr[i].type = val;
       setAllTestCases(allTestcasesArr)
    }

    const [inputList, setInputList] = React.useState([[{value:'' }]]);

    const handleAddClick = () => {
        const allTestCasesArr=allTestcases;
        setInputList([...inputList, { value:'' }]);
        setAllTestCases([...allTestcases, initial[0]]);
    }

    
    const inputHandler=(e,i,editor)=>
   {
       const allTestcasesArr = allTestcases;
       allTestcasesArr[i].description = editor.getData();
       setAllTestCases(allTestcasesArr)
   }

    const handleInpChange=(e,i,fieldName)=>
    {
      const allTestcasesArr=allTestcases;
      allTestcasesArr[i].name = e.target.value

      setAllTestCases(allTestcasesArr);
    }

    const handleInputs=(e, parentIndex, index)=>
    {  
        const allInputsArr=allTestcases;
        allInputsArr[parentIndex].inputValues[index]=e.target.value;
        setAllTestCases(allInputsArr)

    }

    const handleOutputs=(e, parentIndex, index)=>
    {  
      
        const allOutputsArr=allTestcases;
        allOutputsArr[parentIndex].outputValues[index]=e.target.value;
        setAllTestCases(allOutputsArr)
    }


    React.useEffect(() => {
        saveAllTestCasesDetails(allTestcases);
    }, [allTestcases]);


    return (
        <>
        <div className="pb-3">
            <p className="headText pt-4"> Add Testcases </p>
            {
                allTestcases.map((x, i) => {
                    return (<div className='ps-4' key={i}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label className='pb-2 labelText'>Testcase Type</label>
                                    <Select name="" className="" options={testcaseTypes}
                                        value={allTestcases[i].testcaseType}
                                        onChange={(selectedVal) => handleSingleSelect(selectedVal, i,'type')}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group mb-3">
                                    <label  className='pb-2 labelText'>Testcase Name</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium" onChange={(e)=>handleInpChange(e, i, 'name')}/>
                                </div>
                            </div>
                        </div>
                        
                        <div className="form-group mb-3">
                            <label className='pb-2 labelText'>Testcase Description</label>
                            <TextEditor onChange={(e, editor)=>inputHandler(e,i, editor)}/>
                        </div>
                        {totalInputs.map((c, index) => {
                    return (<div key={index}>
                                <div className="form-group mb-3">
                                    <label className='pb-2 labelText'>Input{index + 1} value</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium mt-1" onChange={(e) => handleInputs(e,i,index,'inputValues')}  />
                                </div>
                            </div>);
                })}
                    {totalOutputs.map((c, index) => {
                    return (<div key={index}>
                                <div className="form-group mb-3">
                                    <label className='pb-2 labelText'>Output {index + 1} value</label>
                                    <input type="text" className="form-control text-blue-800 fw-medium mt-1" onChange={(e) => handleOutputs(e,i,index,'outputValues')} />
                                </div>
                            </div>);
                })}
                        
                    </div>
                    )
                }
                )}
            <div className="btn btn-sm bg-action-blue text-white" onClick={handleAddClick}>Add Testcase</div>
        </div>
        </>
    )
  

}
