import React from "react";
import { Link, useParams } from "react-router-dom";
import { getDateAndTimeInFormat } from "../../utils/moments";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Menu } from "@mui/material";
import { TextField } from "@mui/material";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import CustomToolTip from "../../components/custom-tooltip/CustomTooltip.component";
import ProgressControl from "../../components/status-control/ProgressControl.component";
import moment from "moment";
import instance from "../../utils/axios";
import { getApiUrl } from "../../utils/apiUrls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { t } from "i18next";
import { openSnackBar } from "../../redux/actions/snackbar.actions";
import { apiErrorHandler } from "../../utils/errorHandler";
import { saveAs } from 'file-saver';

function SmeDashboardTaskItemcomponent({ index, task, allData, setAllData }) {
  const dispatch = useDispatch();
  const { vid } = useParams();
  const currentDate = new Date();
  const [anchorElNotif, setAnchorElNotif] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [formVal, setFormVal] = React.useState({});
  const [showEmptyDateError, setShowEmptyDateError] = React.useState(false);
  const loggedInUser = useSelector((state) => state.user);
  const review = { review: true, smeId: vid };


  const handleOpenNotifMenu = (event) => {
    setAnchorElNotif(event.currentTarget);
  };

  const handleCloseNotifMenu = () => {
    setValue(null);
    setFormVal({});
    setAnchorElNotif(null);
  };

  const handleDeadlineDtChange = (newValue) => {
    setValue(newValue);
    setFormVal({
      targetDate: Number(moment(newValue._d).endOf("day").unix() + "999"),
    });
  };

  const getMapping = (taskId) => {
    extendDeadline(formVal.targetDate, taskId);
  };

  const extendDeadline = async (targetDate, taskId) => {
    const dataToSend = { targetDate, taskId };
    try {
      const response = await instance.post(
        `${getApiUrl("extendSMETaskTargetDate")}`,
        dataToSend
      );

      if (response.data.status === true) {
        const newList = allData.tasksList.map((task) => {
          if (task.taskId === taskId)
            return { ...task, ["targetDate"]: targetDate };
          else return { ...task };
        });
        setAllData((prev) => ({
          ...prev,
          tasksList: newList,
        }));
      }

      if (response.data) {
        dispatch(
          openSnackBar({
            msg: response.data.message,
            type: response.data.status ? "success" : "error",
          })
        );
        handleCloseNotifMenu();
      }
    } catch (error) {
      const errObj = apiErrorHandler(error);
      dispatch(
        openSnackBar({
          msg: t(errObj.statusText),
          type: "error",
        })
      );
    }
  };

  const handleReportMultipleChoiceDownload = async (taskId) => {
    try {
      const res = await instance.get(`${getApiUrl('getCodelysisQuestionByTaskId')}/${taskId}?smeId=${vid}`);
      const questionInsideTask = res.data?.questionList.map((question) => {
           return question.questionId;
      })
      saveAs(`${getApiUrl('downloadMultipleChoiceQuestions')}?questionIds=${questionInsideTask}&topic=${task.topicName}`, `MultipleChoice.xls`);
    } catch(error) {
      const errObj = apiErrorHandler(error);
      dispatch(
        openSnackBar({
          msg: t(errObj.statusText),
          type: "error",
        })
      );
    }
  }


  return (
    <div>
      <div
        className="cm-candidate-report-list-content bg-white text-center"
        key={index}
      >
        <div className="cm-candidate-report-item">
          <div className="d-flex align-items-center text-center">
            <div className="col-sm-1 ">
              <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                {loggedInUser.role === "ADMIN" && task.type === "ForCreation" && (
                  task.taskType === "Codelysis" ? <Link to={`/question-library/${task.taskId}`} className="p-2">
                    {task.taskId} [{task.taskType}]
                  </Link> : <Link to={`/question-library/${task.taskId}`} className="p-2">
                    {task.taskId} [{task.taskType}]
                  </Link>
                )}
                {loggedInUser.role === "SME" && task.type === "ForCreation" && (
                  <Link to={`/question-library/${task.taskId}`} className="p-2">
                    {task.taskId} [{task.taskType}]
                  </Link>
                )}
                {task.type === "ForReview" && (task.taskType !== "Codelysis" ?
                  <Link
                    to={`/question-library/${task.taskId}`}
                    className="p-2"
                  >
                    {task.taskId} [{task.taskType}]
                  </Link> :
                  <Link
                    to={`/question-library/${task.taskId}`}
                    state={review}
                    className="p-2"
                  >
                    {task.taskId} [{task.taskType}]
                  </Link>
                )}
              </p>
            </div>
            <div className="col-sm-1">
              <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                {task.questionCount}
              </p>
            </div>
            <div className="col-sm-1">
              {task.type !== "ForReview" && (
                <p
                  className="my-0 cm-sm-txt fw-medium text-blue-800 cm-truncate-1"
                  title={task.topicName}
                >
                  {task.topicName}
                </p>
              )}
              {task.type === "ForReview" && (
                <p className="my-0 cm-sm-txt fw-medium text-blue-800 cm-truncate-1">
                  {task.topicName}
                </p>
              )}
            </div>
            <div className="col-sm-1">
              {task.type === "ForCreation" && (
                <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                  {task.difficultyLevel !== null ? task.difficultyLevel : "Mixed"}
                </p>
              )}
              {task.type === "ForReview" && (
                <p className="my-0 cm-sm-txt fw-medium text-blue-800">-</p>
              )}
            </div>
            <div className="col-sm-1">
              {task.type === "ForCreation" && (
                <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                  Creation
                </p>
              )}
              {task.type === "ForReview" && (
                <p className="my-0 cm-sm-txt fw-medium text-blue-800">Review</p>
              )}
            </div>

            <div className="col-sm-1">
              <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                {task.type === "ForReview"
                  ? "-"
                  : getDateAndTimeInFormat(task.createdOn)}
              </p>
            </div>
            <div className="col-sm-2">
              <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                {task.type === "ForReview" ? "-" : task.creatorName}
              </p>
            </div>
            <div className="col-sm-1">
              <p className="my-0 cm-sm-txt fw-medium text-blue-gray-700">
                {task.type === "ForReview"
                  ? "-"
                  : moment(task.targetDate).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="col-sm-2">
              <p className="my-0 cm-sm-txt fw-medium text-blue-800">
                {task.status !== null && (
                  <ProgressControl value={task?.status} />
                )}
                {task.status === null && <div>---</div>}
              </p>
            </div>
            <div className="col-sm-1 ">
              {task.type === "ForCreation" && loggedInUser.role != "ADMIN" &&
                <CustomToolTip title="Go to question library">
                  <Link to={`/question-library/${task.taskId}`} state={{isForTopic: true, topic : task.topicName}} className="p-2">
                    <FontAwesomeIcon
                      icon={regular("arrow-right")}
                      className="text-action-blue"
                    />
                  </Link>
                </CustomToolTip>
              }
              {(task.type === "ForCreation" || task.type === "ForReview") && loggedInUser.role === "ADMIN" &&
                (
                  <CustomToolTip title="Go to question library">

                    {loggedInUser.role === "ADMIN" &&
                      task.taskType === "KNOWLEDGE BASED QUESTIONS" ?
                      <>
                        {task.type === "ForCreation" &&
                            <CustomToolTip title="Download Questions">
                              <span
                                className={`ms-3 cm-report-download-btn p-1 ${task.status !== null && task.status === "Completed" ? 'text-blue-gray-700' : 'text-gray-300'}`}
                                onClick={ () => { task.status !== null && task.status === "Completed" &&  handleReportMultipleChoiceDownload(task.taskId) }}
                                style={{cursor:`${task.status !== null && task.status === "Completed" && "pointer"}`}}                    >
                                <FontAwesomeIcon icon={regular("circle-down")} />
                              </span>
                            </CustomToolTip>
                        }
                        <Link to={`/question-library/${task.taskId}`} state={{isForTopic: true, topic : task.topicName}} className="p-2">
                          <FontAwesomeIcon
                            icon={regular("arrow-right")}
                            className="text-action-blue"
                          />
                        </Link>
                      </>
                      :
                      <Link to={`/question-library/${task.taskId}`} state={{isForTopic: true, topic : task.topicName}}>
                        <FontAwesomeIcon
                          icon={regular("arrow-right")}
                          className="text-action-blue"
                        />
                      </Link>
                    }
                  </CustomToolTip>
                )
              }

              {task.type === "ForReview" && loggedInUser.role != "ADMIN" &&
                <div className="d-flex gap-3">
                  {(loggedInUser.role != "ADMIN" && task.taskType != "Codelysis" &&
                    <CustomToolTip title="Review All">
                      <Link
                        to={`/review-knowledgeBased-question/${task.taskId}`}
                        state={{ review: review, singleReview: false }}
                        className="p-1"
                      >
                        <FontAwesomeIcon
                          icon={regular("eye")}
                          className="text-action-blue"
                        />
                      </Link>
                    </CustomToolTip>)
                  }

                  <CustomToolTip title="Go to question library">
                    <Link
                      to={`/question-library/${task.taskId}`}
                      state={review}
                      className="p-1"
                    >
                      <FontAwesomeIcon
                        icon={regular("arrow-right")}
                        className="text-action-blue"
                      />
                    </Link>
                  </CustomToolTip>

                </div>
              }
            </div>
          </div>

          {task.type === "ForCreation" &&
            task.targetDate < new Date().valueOf() &&
            task.status !== "Completed" && (
              <div className="cm-event-lib-task-err py-1 px-3 d-flex align-items-center col-sm-3 ms-3">
                <FontAwesomeIcon
                  icon={regular("exclamation-triangle")}
                  className="text-danger pe-2"
                />
                <span className="cm-sm-txt fw-medium text-danger fw-medium">
                  Inactive Request - Deadline Exceeded
                </span>
                {loggedInUser.role === "ADMIN" && (
                  <div>
                    <span
                      className="d-flex ms-2 align-items-center justify-content-center cm-pointer me-4 txt-danger"
                      onClick={handleOpenNotifMenu}
                    >
                      <FontAwesomeIcon icon={regular("calendar")} />
                    </span>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
      <Menu
        sx={{ mt: "32px" }}
        id="notif-menu"
        anchorEl={anchorElNotif}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElNotif)}
        onClose={handleCloseNotifMenu}
        MenuListProps={{ disablePadding: true }}
        elevation={0}
        PaperProps={{
          style: {
            width: 400,
          },
        }}
      >
        {
          <div className="form-group mb-3">
            <label className="pb-2 text-blue-gray-700 cm-xs-txt fw-medium">
              Edit Target Date for Task ID: {task.taskId}
            </label>
            <div className=" text-blue-800 fw-medium">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  value={value}
                  onChange={(newValue) => {
                    handleDeadlineDtChange(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  minDate={format(currentDate, "yyyy-MM-dd")}
                />
              </LocalizationProvider>
            </div>
            {showEmptyDateError && (
              <div className="cm-xs-txt text-danger fw-medium pt-2">
                Date cannot be empty
              </div>
            )}
            <button
              className={`btn bg-action-blue text-white cm-sm-txt fw-medium mt-2`}
              onClick={() => {
                if (!formVal.targetDate) setShowEmptyDateError(true);
                else {
                  setShowEmptyDateError(false);
                  getMapping(task.taskId);
                }
              }}
            >
              Extend
            </button>
          </div>
        }
      </Menu>
    </div>
  );
}

export default SmeDashboardTaskItemcomponent;
