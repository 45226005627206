import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { React } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import instance from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import "./OtpForm.css";
import { handleLogOut, otpVerified } from '../../redux/actions/user.actions';
import { useNavigate } from 'react-router-dom';
import CustomButtonLoader from '../global/CustomButtonLoader.component';

const OtpForm = () => {
  const length = 6;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [err, setErr] = useState({ msg: '' })

  const [otp, setOtp] = useState(
    new Array(length).fill(""));
  const inputRefs = useRef([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const pastedValues = pastedData
      .split("")
      .filter((value) => !isNaN(value))
      .slice(0, length);

    const newOtp = [...otp];
    for (let i = 0; i < 6; i++) {
      if (i <= pastedValues.length) newOtp[i] = pastedValues[i];
      else {
        newOtp[i] = ''
      }
    }
    setOtp(newOtp);
  };
  const handleChange = (index, e) => {
    const value = e.target.value;
    if (isNaN(value)) return;

    const newOtp = [...otp];
    newOtp[index] =
      value.substring(value.length - 1);
    setOtp(newOtp);
    if (value && index < length - 1 &&
      inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };
  const handleClick = (index) => {
    inputRefs.current[index].setSelectionRange(1, 1);
    if (index > 0 && !otp[index - 1]) {
      inputRefs.current[otp.indexOf("")].focus();
    }
  };

  const handleKeyDown = (index, e) => {
    if (
      e.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1].focus();
    }

    else if (e.key === "ArrowRight" && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    } else if (e.key === "ArrowLeft" && index > 0 && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleLogOutFn = () => {
    dispatch(handleLogOut());
  }
  const handleOtpSubmit = async () => {
    const combinedNumber = parseInt(otp.join(''));
    if (!otp) {
      setErr({ msg: "Please provide OTP" });
    }
    else if (otp.length != 6) {
      setErr({ msg: "Please provide Valid OTP" });
    }
    else {

      setErr({ msg: "" });
      const dataToSend = { otp: combinedNumber };
      setIsLoading(true)
      try {
        const res = await instance.post(getApiUrl("validateOTP"), dataToSend);
        if (res.data.status) {
          // change the user 
          setIsLoading(false)
          dispatch(otpVerified());
          dispatch(openSnackBar({
            msg: t(res.data.message),
            type: 'success'
          }))
          navigate("/");
        } else {
          setIsLoading(false)
          // need to implement for otp regenarate
          dispatch(openSnackBar({
            msg: t(res.data.message),
            type: 'error'
          }))
        }
      }
      catch (error) {
        setIsLoading(false)
        const errObj = apiErrorHandler(error);
        dispatch(
          openSnackBar({
            msg: t(errObj.statusText),
            type: "error",
          })
        );
      }
    }
  }

  return (
    <div>
      <p className="pb-2 text-blue-gray-700  fw-medium">Enter your OTP here</p>
      {otp.map((value, index) => {
        return (
          <input
            key={index}
            type="digit"
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(e) => handleChange(index, e)}
            onClick={() => handleClick(index)}
            onPaste={handlePaste}
            onKeyDown={(e) => handleKeyDown(index, e)}
            className="inputStyle"
          />
        );
      })}
      {/* <p className="pb-2 text-blue-gray-700 cm-xs-txt fw-medium mt-3">Didn't receive the code?</p> */}
      {err.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{err.msg}</span>}
      <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
        <CustomButtonLoader
            showLoadingState ={isLoading}
            colorTheme= "blue"
            icon={regular('angle-right')}
            reverseIconDirection={false}
            buttonLabel={t("verify")}
            buttonStyle={"px-12 py-2 cm-mar-left-icon"}
            handleOnClick={handleOtpSubmit}
        />
        <span className="btn btn-primary cm-sm-txt cm-btn cm-trans-btn ms-3 text-blue-800" onClick={handleLogOutFn}>{t("logout")}</span>
      </div>
    </div>
  );
};
export default OtpForm;