import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import { apiErrorHandler } from '../../utils/errorHandler';
import CustomButtonLoader from '../global/CustomButtonLoader.component';

const initial_state = {
    email: {
        value: '',
        isRequired: true,
        msg: null
    },
    infoBadgeStatus: false,
    validOptions: {
        requiredFields: ['email'],
    },
};

const ResetPasswordForm = ({ t }) => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = React.useState(false);
    const [formVal, setFormVal] = React.useState(initial_state);

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value
            }
        })
    }

    const getReqMsg = (fieldName) => {
        if (fieldName === 'email') {
            return t('email_required');
        } else {
            return t('field_required')
        }
    }

    const handleValidation = async () => {
        //Check required fields are filled
        let currentFormVal = formVal;

        formVal.validOptions.requiredFields.forEach(el => {
            if (currentFormVal[el].value === '') {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                //Email Validation
                if (el === 'email') {
                    // eslint-disable-next-line
                    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
                    currentFormVal.email.msg = !regex.test(currentFormVal.email.value) ? t("valid_email_address") : null;
                    return;
                }

                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if (formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if (isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
            !isLoading && forgotPasswordMail();
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }

    const forgotPasswordMail = async () => {
        try {
            setIsLoading(true);
            const res = await axios.post(`${getApiUrl('forgotPassword')}`, { email: formVal.email.value });

            if (res.data.status === "SUCCESS") {
                setIsLoading(false);

                dispatch(openSnackBar(
                    {
                        msg: t(res.data.message),
                        type: 'success'
                    }));

                setFormVal({
                    ...formVal,
                    infoBadgeStatus: true
                });
            } else {
                throw Error(res.data.message)
            }
        } catch (err) {
            setIsLoading(false);
            console.log('Forgot password err', err);
            const errObj = apiErrorHandler(err);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleValidation();
    }

    return (
        <form className="cm-auth-form cm-login-form" onSubmit={handleFormSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("login_email_label")}</label>
                <input type="email" value={formVal.email.value} className="form-control text-blue-800 fw-medium" id="email" placeholder={t("login_email_placeholder")} onChange={(e) => handleFieldChange(e, 'email')} />
                {formVal.email.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.email.msg}</span>}
            </div>

            {formVal.infoBadgeStatus && <div className='cm-info-badge-wrap d-flex align-items-center bg-info-yellow'>
                <FontAwesomeIcon icon={regular('circle-exclamation')} className="text-primary pe-2" />
                <p className='cm-sm-txt text-blue-800 fw-medium my-0'>{t("reset_pass_link_sent")} "{formVal.email.value}”</p>
            </div>}

            <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                <CustomButtonLoader
                    showLoadingState ={isLoading}
                    colorTheme= "blue"
                    icon={regular('angle-right')}
                    reverseIconDirection={false}
                    buttonLabel={formVal.infoBadgeStatus ? t('resend_link') : t('send_link')}
                    buttonStyle={"px-12 py-10 cm-mar-left-icon"}
                    handleOnClick={handleFormSubmit}
                />
                <Link className="btn btn-primary cm-btn cm-trans-btn ms-3 text-blue-800 cm-sm-txt" to="/login">{t("back_to_login")}</Link>
            </div>

        </form>
    )

}

export default ResetPasswordForm;