import React from 'react';
import MetaComp from '../../components/meta-comp/MetaComp.component';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb.component';
import { useTranslation } from 'react-i18next';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import HierarchyContainer from '../../containers/tools/Hierarchy.container';

const HierarchyPage = () => {
    const { t } = useTranslation();

    const breadcrumbData = [
        {
            iconClass: regular("house"),
            link: '/'
        },
        {
            label: t("profile"),
        }
    ]

    return <>
        <MetaComp title="Hierarchy - Mercer | Mettl"/>
        <Breadcrumb data={breadcrumbData}/>
        <HierarchyContainer t={t} />
    </>
}

export default HierarchyPage;