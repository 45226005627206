
import { inputDataType, outputDataType } from "../../containers/question-creation/QuestionDetailsForm.container";
import { getApiUrl } from "../../utils/apiUrls";
import instance from "../../utils/axios";
import { apiErrorHandler } from "../../utils/errorHandler";
import { UPDATE_QUEST_DETAILS, GET_QUEST_DETAILS, UPDATE_QUEST_DETAILS_BY_KEY, UPDATE_TEST_CASE_DETAILS, ADD_TEST_CASE, REMOVE_TEST_CASE, RESET_QUEST } from "./actions.types";
import { openSnackBar } from "./snackbar.actions";



export const getQuestionDetails = (qId, step, reviewer = false) => async (dispatch, getState) => {
    // const {t}=useTranslation();
    try {
        const res = await instance.get(`${getApiUrl(reviewer ? "getCodelysisQuestionForPreview" : "getCodelysisQuestionData")}/${qId}`);
        if (!!res.data) {
            // console.log("This is data from preview : ",res.data);
            // if (res.data.creatorStatus === "SentForReview") {
            //     window.location.href = "/question-library/" + res.data.taskId
            // }
            const dataToSave = {};

            const createIoData = (data, specsData, type) => {
                return data.map((el, index) => {

                    const dtVal = type === "addInputs" ? inputDataType.filter(innerEl => el === innerEl.value) : outputDataType.filter(innerEl => el === innerEl.value);
                    const resObj = {
                        ...dtVal[0],
                        preText: !!specsData[index] ? specsData[index] : '',
                        id: Date.now() + index,
                        msg: null
                    }

                    return resObj;
                })
            }

            /* Add Question Details Start */
            let allTags = [];

            if (res.data.tagIds.length > 0) {
                const resTag = await instance.get(`${getApiUrl("getAllTags")}`);

                if (!!resTag.data && resTag.data.length > 0) {
                    resTag.data.forEach(el => {
                        if (res.data.tagIds.includes(el.tagId)) {
                            allTags.push({ label: el.tagName, value: el.tagId })
                        };
                    })
                }
            }

            dataToSave.questDetails = {
                questionTopic: {
                    value: !!res.data.topicName ? res.data.topicName : "",
                    msg: null,
                },
                difficultyLevel: {
                    value: !!res.data.difficultyLevel ? { value: res.data.difficultyLevel, label: res.data.difficultyLevel } : { value: '', label: '' },
                    msg: null
                },
                questionTag: {
                    value: allTags,
                    msg: null,
                },
                functionName: {
                    value: !!res.data.functionName ? res.data.functionName : '',
                    msg: null
                },
                questionTitle: {
                    value: !!res.data.questionTitle ? res.data.questionTitle : '',
                    msg: null
                },
                questionText: {
                    value: !!res.data.questionText ? res.data.questionText : '',
                    msg: null
                },
                codingLanguages: {
                    value: !!res.data.codeLanguages ? res.data.codeLanguages.map(el => ({ label: el, value: el })) : [],
                    msg: null
                },
                addInputs: {
                    value: !!res.data.inputType ? createIoData(res.data.inputType, res.data.inputSpecification, 'addInputs') : [],
                    msg: null
                },
                addOutputs: {
                    value: !!res.data.outputType ? createIoData(res.data.outputType, res.data.outputSpecification, 'addOutputs') : [],
                    msg: null
                }
            };

            //Match Specs
            dataToSave.matchSpecs = {
                addInputs: !!res.data.inputType ? createIoData(res.data.inputType, res.data.inputSpecification, 'addInputs') : [],
                addOutputs: !!res.data.outputType ? createIoData(res.data.outputType, res.data.outputSpecification, 'addOutputs') : [],
            }


            /* Add Question deatils End */

            if (res.data.testCasesDTO === null) {
                const testCaseInitData = {
                    id: Date.now(),
                    testcaseType: {
                        value: { value: '', label: '' },
                        msg: null
                    },
                    testcaseName: {
                        value: '',
                        msg: null
                    },
                    testcaseDesc: {
                        value: '',
                        msg: null
                    },
                    inputArr: res.data.inputType.map(el => ({ value: '', inpLabel: el, msg: null })),
                    outputArr: res.data.outputType.map(el => ({ value: '', inpLabel: el, msg: null })),
                    weightage: {
                        value: 0,
                        msg: null
                    },
                    size:
                    {
                        value: { value: '', label: '' },
                        msg: null
                    }
                };

                dataToSave.addTestcases = {
                    data: [
                        testCaseInitData
                    ],
                }
            }
            if (!!res.data?.testCasesDTO && res.data.testCasesDTO.length > 0) {
                const testCaseData = res.data.testCasesDTO.map((el, index) => ({
                    id: Date.now() + index,
                    testcaseType: {
                        value: { value: el.type, label: el.type },
                        msg: null
                    },
                    testcaseName: {
                        value: el.name,
                        msg: null
                    },
                    testcaseDesc: {
                        value: el.description,
                        msg: null
                    },
                    inputArr: el.inputValues.map(innerEl => ({ value: innerEl, msg: null })),
                    outputArr: el.outputValues.map(innerEl => ({ value: innerEl, msg: null })),
                    weightage: {
                        value: !!el.weightage ? el.weightage : 0,
                        msg: null
                    },
                    size: {
                        // value: el?.('size') ? {value:el.size, label:el.size} : '',
                        value: el?.size ? { value: el.size, label: el.size } : { value: null, label: null },
                        msg: null
                    }
                }))

                dataToSave.addTestcases = {
                    data: testCaseData,
                }
            }

            const codingLangs = [];
            let selectedLang = {};
            if (!!res.data.codeLanguages && res.data.codeLanguages.length > 0) {
                const resCl = await instance.get(`${getApiUrl("getCodeLanguage")}`);

                if (!!resCl.data && resCl.data.length > 0) {
                    resCl.data.forEach(el => {
                        if (res.data.codeLanguages.includes(el.codeName)) {
                            codingLangs.push({ label: el.codeName, value: el.codeName, id: el.codeId, version: el.codeVersion })
                        };
                    })

                    if (res.data.ansLangId !== null) {
                        resCl.data.forEach(el => {
                            if (el.codeId === res.data.ansLangId) {
                                selectedLang = { label: el.codeName, value: el.codeName, id: el.codeId, version: el.codeVersion }
                            }
                        });
                    }
                }
            }

            dataToSave.addSolution = {
                avLanguages: {
                    value: codingLangs,
                    msg: null
                },
                selLang: {
                    value: selectedLang,
                    msg: null
                },
                ansDescription: {
                    value: res.data.ansDescription,
                    msg: null
                }
            }

            // }

            // Adding question status 
            dataToSave.questStatus = {
                creator: res.data.creatorStatus,
                admin: res.data.adminStatus
            }

            // console.log('Test', dataToSave)
            dispatch({
                type: GET_QUEST_DETAILS,
                payload: dataToSave
            })
        }

    } catch (error) {
        const t = window.reactI18next;
        const msgObj = apiErrorHandler(error);
        console.log('getQuestionDetails err', error);
        if (error.response.status === 501) {
            dispatch(openSnackBar({
                msg: "This user doesn't have access to the requested url",
                type: 'error'
            }))
            window.location.href = '/';
        }
        const errObj = apiErrorHandler(error);
        dispatch(openSnackBar({
            msg: t(errObj.statusText),
            type: 'error'
        }))
    }
}

export const getQuestionDetailsAdmin = (qId) => async (dispatch, getState) => {
    try {
        const res = await instance.get(`${getApiUrl("getCodelysisQuestionForAdminPreview")}/${qId}`);
        if (!!res.data) {
            const dataToSave = {};
            const createIoData = (data, specsData, type) => {
                return data.map((el, index) => {
                    const dtVal = type === "addInputs" ? inputDataType.filter(innerEl => el === innerEl.value) : outputDataType.filter(innerEl => el === innerEl.value);
                    const resObj = {
                        ...dtVal[0],
                        preText: !!specsData[index] ? specsData[index] : '',
                        id: Date.now() + index,
                        msg: null
                    }

                    return resObj;
                })
            }

            //if(!!step) {
            /* Add Question Details Start */
            let allTags = [];

            if (res.data.tagIds.length > 0) {
                const resTag = await instance.get(`${getApiUrl("getAllTags")}`);

                if (!!resTag.data && resTag.data.length > 0) {
                    resTag.data.forEach(el => {
                        if (res.data.tagIds.includes(el.tagId)) {
                            allTags.push({ label: el.tagName, value: el.tagId })
                        };
                    })
                }
            }

            dataToSave.questDetails = {
                questionTopic: {
                    value: !!res.data.topicName ? res.data.topicName : "",
                    msg: null,
                },
                difficultyLevel: {
                    value: !!res.data.difficultyLevel ? { value: res.data.difficultyLevel, label: res.data.difficultyLevel } : { value: '', label: '' },
                    msg: null
                },
                questionTag: {
                    value: allTags,
                    msg: null,
                },
                functionName: {
                    value: !!res.data.functionName ? res.data.functionName : '',
                    msg: null
                },
                questionTitle: {
                    value: !!res.data.questionTitle ? res.data.questionTitle : '',
                    msg: null
                },
                questionText: {
                    value: !!res.data.questionText ? res.data.questionText : '',
                    msg: null
                },
                htmlData: {
                    value: !!res.data.htmlData ? res.data.htmlData : '',
                    msg: null
                },
                codingLanguages: {
                    value: !!res.data.codeLanguages ? res.data.codeLanguages.map(el => ({ label: el, value: el })) : [],
                    msg: null
                },
                addInputs: {
                    value: !!res.data.inputType ? createIoData(res.data.inputType, res.data.inputSpecification, 'addInputs') : [],
                    msg: null
                },
                addOutputs: {
                    value: !!res.data.outputType ? createIoData(res.data.outputType, res.data.outputSpecification, 'addOutputs') : [],
                    msg: null
                }
            };
            /* Add Question deatils End */

            if (res.data.testCasesDTO === null) {
                const testCaseInitData = {
                    id: Date.now(),
                    testcaseType: {
                        value: { value: '', label: '' },
                        msg: null
                    },
                    testcaseName: {
                        value: '',
                        msg: null
                    },
                    testcaseDesc: {
                        value: '',
                        msg: null
                    },
                    inputArr: res.data.inputType !== null ? res.data.inputType.map(el => ({ value: '', inpLabel: el, msg: null })) : [],
                    outputArr: res.data.outputType !== null ? res.data.outputType.map(el => ({ value: '', inpLabel: el, msg: null })) : [],
                };

                dataToSave.addTestcases = {
                    data: [
                        testCaseInitData
                    ],
                }
            }
            if (!!res.data?.testCasesDTO && res.data.testCasesDTO.length > 0) {
                const testCaseData = res.data.testCasesDTO.map((el, index) => ({
                    id: Date.now() + index,
                    testcaseType: {
                        value: { value: el.type, label: el.type },
                        msg: null
                    },
                    testcaseName: {
                        value: el.name,
                        msg: null
                    },
                    testcaseDesc: {
                        value: el.description,
                        msg: null
                    },
                    inputArr: el.inputValues.map(innerEl => ({ value: innerEl, msg: null })),
                    outputArr: el.outputValues.map(innerEl => ({ value: innerEl, msg: null })),
                }))

                dataToSave.addTestcases = {
                    data: testCaseData,
                }
            }

            const codingLangs = [];
            let selectedLang = { label: '', value: '' };
            if (!!res.data.codeLanguages && res.data.codeLanguages.length > 0) {
                const resCl = await instance.get(`${getApiUrl("getCodeLanguage")}`);

                if (!!resCl.data && resCl.data.length > 0) {
                    resCl.data.forEach(el => {
                        if (res.data.codeLanguages.includes(el.codeName)) {
                            codingLangs.push({ label: el.codeName, value: el.codeName, id: el.codeId })
                        };
                    })

                    if (res.data.ansLangId !== null) {
                        resCl.data.forEach(el => {
                            if (el.codeId === res.data.ansLangId) {
                                selectedLang = { label: el.codeName, value: el.codeName, id: el.codeId }
                            }
                        });
                    }
                }
            }

            const creatorDet = {
                assignedName: 'Creator',
                assignedTo: 'creator',
                ansDescription: res.data.ansDescription,
                ansLangId: res.data.ansLangId
            }

            dataToSave.addSolution = {
                avLanguages: {
                    value: codingLangs,
                    msg: null
                },
                selLang: {
                    value: selectedLang,
                    msg: null
                },
                ansDescription: {
                    value: res.data.ansDescription,
                    msg: null
                },
                reviewerDTOList: {
                    value: !!res.data.reviewerDTOList ? [creatorDet, ...res.data.reviewerDTOList] : [creatorDet]
                },
                mcqAnsDescription: {
                    value: res.data.mcqAnsDescription,
                    msg: null
                },
            }

            // }

            //console.log('Test', dataToSave)
            dispatch({
                type: GET_QUEST_DETAILS,
                payload: dataToSave
            })
        }

    } catch (error) {
        const msgObj = apiErrorHandler(error);
        console.log('getQuestionDetails err', error);
        // dispatch({
        //     type: SNACK_OPEN,
        //     payload: {
        //         msg: t(msgObj.statusText),
        //         type: 'error'
        //     }
        // })
    }
}

export const updateQuestData = (data) => ({
    type: UPDATE_QUEST_DETAILS,
    payload: data
});

export const updateTestCaseData = (data) => ({
    type: UPDATE_TEST_CASE_DETAILS,
    payload: data
});

export const addTestcase = (data) => ({
    type: ADD_TEST_CASE,
    payload: data,
})

export const removeTestcase = (id) => ({
    type: REMOVE_TEST_CASE,
    payload: id,
})

export const updateQuestDataByKey = (key, data) => ({
    type: UPDATE_QUEST_DETAILS_BY_KEY,
    payload: { key, data }
});

export const resetQuest = () => ({
    type: RESET_QUEST
});
