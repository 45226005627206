import * as React from 'react';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import './AddTagModal.styles.css';
import { apiErrorHandler } from '../../utils/errorHandler';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { useDispatch } from 'react-redux';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import CustomButtonLoader from '../../components/global/CustomButtonLoader.component';

const initial_state = {
    tagName: {
        value: '',
        isRequired: true,
        msg: null
    },
    validOptions: {
        requiredFields: ['tagName'],
        errorFields: [],
    },
};

const reset_state = {
    tagName: {
        value: '',
        isRequired: true,
        msg: null
    },
    validOptions: {
        requiredFields: ['tagName'],
        errorFields: [],
    },
};

export default function AddTagModal({ modalStatus, handleModalToggle, t, getAllTags, editTag }) {
    const dispatch = useDispatch();
    const [formVal, setFormVal] = React.useState(initial_state);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if(editTag !== null) {
            setFormVal({
                tagName: {
                    value: editTag.tagName,
                    isRequired: true,
                    msg: null
                },
                validOptions: {
                    requiredFields: ['tagName'],
                    errorFields: [],
                },
            });
        } else {
            setFormVal(reset_state);
        }
    }, [editTag])

    const handleFieldChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value,
            }
        })
    }

    const getReqMsg = () => {
        return t('field_required');
    }

    const handleEditTag = async () => {
        try {
            setIsLoading(true);
            const res = await instance.post(`${getApiUrl('editTag')}?id=${editTag.tagId}`, {tagName: formVal.tagName.value});

    
            if(res.data.status) {
                setIsLoading(false);
                setFormVal(reset_state);
                dispatch(openSnackBar({
                    msg: "Tag edited successfully",
                    type: 'success'
                }));
                handleModalToggle();
                getAllTags();
            } else {
                throw Error(res.data.message);
            }
        } catch (err) {
            setIsLoading(false);
            console.log('add user error', err)
            const errObj = apiErrorHandler(err);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    const handleAddTag = async () => {
        try {
            setIsLoading(true);
            const res = await instance.post(getApiUrl('addTag'), {tagName: formVal.tagName.value});
    
            if(res.data.status) {
                setIsLoading(false);
                setFormVal(reset_state);
                dispatch(openSnackBar({
                    msg: "Tag added successfully",
                    type: 'success'
                }));
                handleModalToggle();
                getAllTags();
            } else {
                throw Error(res.data.message);
            }
        } catch (err) {
            setIsLoading(false);
            console.log('add user error', err)
            const errObj = apiErrorHandler(err);

            dispatch(openSnackBar({
                msg: t(errObj.statusText),
                type: 'error'
            }))
        }
    }

    
    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = formVal;

        formVal.validOptions.requiredFields.forEach(el => {
            if(currentFormVal[el].value.trim() === '' && currentFormVal[el].isRequired) {
                currentFormVal[el].msg = getReqMsg(el);
            } else {
                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if(formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if(isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
            if(!isLoading) {
                !!editTag ? handleEditTag() : handleAddTag() ;
            }
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleValidation();
    }

    return (
        <div>
            <Modal open={modalStatus} onClose={handleModalToggle} disableAutoFocus={true}>
                <div className='cm-create-task-form-container cm-add-tag-form-container bg-white my-3'>
                    <div className='cm-create-task-form-header py-3 px-4 d-flex align-items-center justify-content-between'>
                        <h4 className='text-blue-800 my-0'>Fill Details to {!!editTag ? 'Edit' : 'Add'} Tag</h4>
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleModalToggle} />
                    </div>
                    <form onSubmit={handleFormSubmit} className="cm-create-task-form px-4 pb-4">
                        <div className="col-sm-12">
                            <div className="form-group mb-3">
                                <label htmlFor="tagName" className='pb-2 text-blue-gray-700 cm-xs-txt fw-medium'>{t("tag_name")}*</label>
                                <input type="text" className="form-control text-blue-800 fw-medium cm-sm-txt" id="tagName" value={formVal.tagName.value} placeholder={t("tag_name_placeholder")} onChange={(e) => handleFieldChange(e, 'tagName')} />
                                {formVal.tagName.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.tagName.msg}</span>}
                            </div>
                        </div>

                        <div className="cm-form-btn-group mt-4 pt-2 d-flex align-items-center">
                            <CustomButtonLoader
                                showLoadingState ={isLoading}
                                colorTheme= "blue"
                                icon={regular('angle-right')}
                                reverseIconDirection={false}
                                buttonLabel={!!editTag ? t("edit_tag") : t("add_tag")}
                                buttonStyle={"px-12 py-10 cm-mar-left-icon"}
                                handleOnClick={handleFormSubmit}
                            />
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}