import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, MenuItem, OutlinedInput, Select } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import CustomInput from '../../components/global/CustomInput.component';
import { openSnackBar } from '../../redux/actions/snackbar.actions';
import { getApiUrl } from '../../utils/apiUrls';
import instance from '../../utils/axios';
import { apiErrorHandler } from '../../utils/errorHandler';

const RenameBankForm = ({ t, handleBankModalToggle, modalData, isRoot = true, handlerefreshAllBanks }) => {
    const dispatch = useDispatch();


    const [formVal, setFormVal] = React.useState({
        bankName: { value: modalData.bankName, msg: null }
    });

    const validateField = (key, value) => {
        if (key === "bankName") {
            if (!value) return 'This field is required.';
            if (value === modalData.bankName) return 'Bank Name cannot be same as current bank name';
            return ''
        }
    }

    const handleFormField = (key, value) => {
        setFormVal({
            ...formVal,
            [key]: { value, msg: validateField(key, value) }
        })
    }

    const handleAssignSubmit = async () => {

        if (modalData.isRenameBank) {
            try {
                if (!formVal.bankName.msg) {
                    let url = (!modalData.isRoot ? getApiUrl('renameBank') : getApiUrl('renameRootBank'));
                    url = (!modalData.isRoot ? url + `?rootBankId=${modalData.rootBankId}` : url)
                    const res = await instance.post(`${url}`, { reviewBankName: formVal.bankName.value, reviewBankId: modalData.reviewBankId });
                    dispatch(openSnackBar({
                        msg: res.data?.message,
                        type: res.data?.status ? 'success' : 'error'
                    }))
                    if (res.data?.status) {
                        handleBankModalToggle("RENAME_BANK_CLOSE", { bankName: formVal.bankName.value, reviewBankId: modalData.reviewBankId });
                    }
                }
            } catch (error) {
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            }
        }
        else {
            try {
                if (!formVal.bankName.msg) {
                    const dataTosend = {
                        reviewBankName: formVal.bankName.value,
                        parentReviewBankId: modalData.parentReviewBankId,
                        isTopic: modalData.isTopic
                    }
                    const res = await instance.post(`${getApiUrl(`addBankOrTopic`)}?rootBankId=${modalData.rootBankId}`, dataTosend);

                    dispatch(openSnackBar({
                        msg: res.data?.message,
                        type: res.data?.status ? 'success' : 'error'
                    }))
                    if (res.data?.status) {
                        handleBankModalToggle("RENAME_BANK_CLOSE", { bankName: formVal.bankName.value, reviewBankId: modalData.reviewBankId });
                    }
                }
            } catch (error) {
                const errObj = apiErrorHandler(error);
                dispatch(openSnackBar({
                    msg: t(errObj.statusText),
                    type: 'error'
                }))
            }
        }
        handlerefreshAllBanks();
    }

    return (
        <>
            <div className="mt-3 cm-create-task-form px-4 pb-4">
                <div>
                    <label className='cm-sm-txt text-blue-gray-700 mb-2 fw-medium'>Bank Name:</label>
                    <CustomInput
                        type="text"
                        inputclass="w-100"
                        value={formVal.bankName.value}
                        msg={formVal.bankName.msg}
                        haserror={!!formVal.bankName.msg ? formVal.bankName.msg : ''}
                        onChange={(e) => handleFormField('bankName', e.target.value)}
                        focus={true}
                    />
                </div>
                {/* {!modalData.isRenameBank &&
                } */}


            </div>
            <div className="cm-form-btn-group py-2  cm-create-modal-form-footer mt-2  d-flex align-items-center justify-content-end">
                <button onClick={handleAssignSubmit} className={`cm-icon-btn position-relative btn btn-primary bg-action-blue cm-sm-txt cm-btn border border-action-blue cm-prim-btn d-flex align-items-center`}>
                    <div>
                        <span className="text-white">{modalData.title}</span>
                        <FontAwesomeIcon icon={regular('angle-right')} className="ps-2" />
                    </div >
                </button>
            </div>
        </>
    )

}

export default RenameBankForm;