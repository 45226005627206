import * as React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, Select } from '@mui/material';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../custom-dialog/CustomDialog.styles.css';
import moment from 'moment';

const initial_state = {
    startDate: {
        value: '',
        isRequired: true,
        msg: null
    },
    endDate: {
        value: '',
        isRequired: true,
        msg: null
    },
    sme: {
        value: {label: '', value: ''},
        isRequired: true,
        msg: null
    },
    validOptions: {
        requiredFields: ['startDate', 'endDate', 'sme'],
        errorFields: [],
    },
}

const vendorOptions = [
    { label: 'SME Mettl cms', value: '2', emailId: 'sme@mettlcms.com' }
];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <FontAwesomeIcon icon={regular("times")} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DownloadReportForm({ dialogHeading, t, cancelBtnLabel, actionBtnLabel, cancelFn, dialogStatus, actionBtnBg, style }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [formVal, setFormVal] = React.useState(initial_state);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDtClose = () => {
        setAnchorEl(null);
    };

    const handleValidation = () => {
        //Check required fields are filled
        let currentFormVal = Object.assign({}, formVal);

        formVal.validOptions.requiredFields.forEach(el => {
            if(el === 'sme' && currentFormVal[el].value.value === '') {
                currentFormVal[el].msg = t('field_required');
            }
            else if(currentFormVal[el].value === '') {
                currentFormVal[el].msg = el === 'startDate' || el === 'endDate' ? t('date_range_field_required') : t('field_required');
            } else {
                currentFormVal[el].msg = null;
            }
        });

        //Check if form is valid
        let isValidFlag = 0;

        currentFormVal.validOptions.requiredFields.forEach((el) => {
            if(formVal[el].msg === null) {
                isValidFlag++;
            }
        });

        if(isValidFlag === currentFormVal.validOptions.requiredFields.length) {
            //Api Call
        } else {
            setFormVal({
                ...formVal,
                ...currentFormVal,
            })
        }

    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleValidation();
    }

    const handleDtRangeChange = (dt) => {
        setFormVal({
            ...formVal,
            startDate: {
                ...formVal.startDate,
                value: dt.range1.startDate
            },
            endDate: {
                ...formVal.endDate,
                value: dt.range1.endDate
            }
        });
    }

    const handleClose = (e) => {
    // setOpen(false);
    setFormVal({
        startDate: {
            value: '',
            isRequired: true,
            msg: null
        },
        endDate: {
            value: '',
            isRequired: true,
            msg: null
        },
        sme: {
            value: {label: '', value: ''},
            isRequired: true,
            msg: null
        },
        validOptions: {
            requiredFields: ['startDate', 'endDate', 'sme'],
            errorFields: [],
        },
    });
    cancelFn(e)
    };

    const handleSelectChange = (e, fieldName) => {
        setFormVal({
            ...formVal,
            [fieldName]: {
                ...formVal[fieldName],
                value: e.target.value,
            }
        })
    }

    const loadSelectMenuItem = (list) => list.map(el => <MenuItem value={el.value}>{el.label}</MenuItem>);

    return (
    <div>
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={dialogStatus}
        className="cm-dialag-container"
        style={style}
        >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
            {dialogHeading}
        </BootstrapDialogTitle>
        <DialogContent dividers>
            <form className="cm-download-report-form" onSubmit={handleFormSubmit}>
                <div className="form-group mb-3">
                    <label htmlFor="lastName" className='pb-2 text-blue-gray-700 cm-sm-txt fw-medium'>Select SME/Vendor</label>
                    <Select
                        id="vendor-select"
                        value={formVal.sme?.value}
                        onChange={val => handleSelectChange(val, 'sme')} 
                        fullWidth={true}
                    >{loadSelectMenuItem(vendorOptions)}
                    </Select>
                    {formVal.sme.msg !== null && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.sme.msg}</span>}

                </div>

                <div className='cm-date-range-picker-wrap form-group mb-3'>
                    <label htmlFor="email" className='pb-2 text-blue-gray-700 cm-sm-txt fw-medium'>Select Date Range:</label>
                    <div className="cm-date-range-val position-relative d-flex align-items-center d-flex align-items-center justify-content-between cm-pointer" onClick={handleClick} >
                        <input type="text" className='cm-sm-txt text-blue-800' readOnly={true} placeholder="Select date range" value={!!formVal.startDate.value && !!formVal.endDate.value   ? `${moment(formVal.startDate.value).format('DD/MM/YYYY')} - ${moment(formVal.endDate.value).format('DD/MM/YYYY')}`: ''} />
                        <FontAwesomeIcon icon={regular("calendar-lines")} className="ms-2" />
                        <div className="cm-dt-field-overlay position-absolute" />
                    </div>
                    {(formVal.startDate.msg !== null || formVal.endDate.msg !== null) && <span className='cm-xs-txt text-danger fw-medium pt-2'>{formVal.endDate.msg}</span>}
                    <Menu
                        id="date-range-menu-report"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleDtClose}
                        MenuListProps={{
                            'aria-labelledby': 'date-range',
                        }}
                    >
                        <MenuItem onClick={null} disableRipple={true}>
                            <DateRangePicker
                                ranges={[{ startDate: formVal.startDate.value, endDate: formVal.endDate.value }]}
                                onChange={handleDtRangeChange}
                            />
                        </MenuItem>
                    </Menu>
                </div>
            </form>
        </DialogContent>
        <DialogActions>
            {!!cancelBtnLabel ? <Button disableRipple={true} onClick={handleClose} className="cm-dialog-cancel-btn text-action-blue cm-sm-txt fw-medium">
            {cancelBtnLabel}
            </Button> : null}
            <Button disableRipple={true} onClick={handleFormSubmit} className={`cm-dialog-action-btn border-action-blue ${actionBtnBg} text-white cm-sm-txt fw-medium`}>
            {actionBtnLabel}
            </Button>
        </DialogActions>
        </BootstrapDialog>
    </div>
    );
}

DownloadReportForm.defaultProps = {
    dialogHeading: "",
    actionBtnBg: 'bg-action-blue',
    style: {}
}