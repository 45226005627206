import * as React from 'react';
import { Modal } from '@mui/material';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import "./CreateBankModal.css";
import AssignBankForm from './AssignBankForm.component';
import ViewAssignment from './ViewAssignment.component';
import RenameBankForm from './RenameBankForm.component';
import DownloadBankFom from '../downlaod-bank/DownloadBankForm';
import OldCmsConfigurationModal from './OldCmsConfiguration.component';

const BankSettingModal = ({ modalData, handleBankModalToggle, getAllBanks, role, isRoot = true, handlerefreshAllBanks }) => {
    const { t } = useTranslation();

    const renderModal = () => {
        if (modalData.type === "ASSIGN_BANK") {
            return <AssignBankForm t={t} handleBankModalToggle={handleBankModalToggle} modalData={modalData.data} reviewBankId={modalData.data.reviewBankId} />
        }

        if (modalData.type === "VIEW_ASSIGNMENT") {
            return <ViewAssignment role={role} t={t} handleBankModalToggle={handleBankModalToggle} modalData={modalData.data} />
        }

        if (modalData.type === "RENAME_BANK") {
            return <RenameBankForm t={t} handleBankModalToggle={handleBankModalToggle} modalData={modalData.data} handlerefreshAllBanks={handlerefreshAllBanks} />
        }
        if (modalData.type === 'DOWNLOAD_BANK') {
            return <DownloadBankFom t={t} handleBankModalToggle={handleBankModalToggle} modalData={modalData.data} handlerefreshAllBanks={handlerefreshAllBanks} />
        }
        if (modalData.type === 'CREATE_GROUP') {
            return <RenameBankForm t={t} handleBankModalToggle={handleBankModalToggle} modalData={modalData.data} handlerefreshAllBanks={handlerefreshAllBanks} />
        }
        if (modalData.type === 'CREATE_TOPIC') {
            return <RenameBankForm t={t} handleBankModalToggle={handleBankModalToggle} modalData={modalData.data} handlerefreshAllBanks={handlerefreshAllBanks} />
        }
        if(modalData.type === 'OLD_CMS_CONFIGURATION') {
            return <OldCmsConfigurationModal  modalData={modalData.data} />
        }
    }

    return (
        <div>
            <Modal open={Boolean(modalData?.type)} onClose={handleBankModalToggle} disableAutoFocus={true}>
                <div className='cm-create-bank-form-container cm-add-bank-form-container bg-white my-3' style={{ minWidth: modalData.type === "VIEW_ASSIGNMENT" ? '80%' : '800px' }}>
                    <div className='cm-create-modal-form-header py-2 px-4 d-flex align-items-center justify-content-between'>
                        {modalData?.data?.title && <h5 className='text-blue-800 my-0'>{modalData.data.title}</h5>}
                        <FontAwesomeIcon icon={regular("times")} className="p-2 cm-pointer" onClick={handleBankModalToggle} />
                    </div>

                    {renderModal()}
                </div>
            </Modal>
        </div>
    )
}
export default BankSettingModal;